import api from '@api/index'
import { useUserContext } from '@hooks/user/UserContextProvider'
import { useState } from 'react'

export type Reference = {
  full_name: string
  leadership: number
  execution: number
  customer_service: number
  work_ethic: number
  initiative: number
  creativity: number
  resilience: number
  team_relationships: number
  work_quality: number
  strengths: string
  weaknesses: string
  additional_comments: string
  relationship: string
}

type Params = {
  id: any
  data: Reference
}

export const useReferences = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const { logOut } = useUserContext()

  const sendReferenceReview = async ({ id, data }: Params) => {
    setLoading(true)
    try {
      const result = await api.post(`/profiles/${id}/references`, {
        reference: data
      })
      return result
    } catch (error: any) {
      if (error?.response?.status === 401) logOut()
    } finally {
      setLoading(false)
    }
  }

  return {
    loading,
    sendReferenceReview
  }
}

import { ProfileCardUserIconLabel } from '@commons/Icons'
import { Pill as OriginalPill } from '@components/atoms/Pill'
import { ProfileCard } from '@components/atoms/ProfileCard'
import styled from 'styled-components'

export const Card = styled(ProfileCard)`
  display: block;
  flex-direction: column;
  padding-top: 0;
  height: auto;
  padding: 0;
`

export const CardContent = styled.div`
  padding: 40px;
  min-height: 126px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 40px 0;

    .row {
      padding-left: 40px;
      padding-right: 40px;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 24px 0;
    .row {
      padding-left: 24px;
      padding-right: 24px;
    }
  }
`
export const UserIcon = styled(ProfileCardUserIconLabel)`
  width: 16px;
  height: 16px;
`

export const Pill = styled(OriginalPill)`
  background-color: ${({ theme }) => theme.colors.shades.secondary100};
  cursor: pointer;
`

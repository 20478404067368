import { PdfView } from '@components/atoms/PdfView'
import { Text } from '@components/atoms/Text'
import { ResumeIcon } from '@components/commons/Icons'
import { ProfileCardPlaceholder } from '@components/molecules/ProfileCardPlaceholder'
import { UploadPdf } from '@components/molecules/UploadPdf'
import { useDeleteResume, useResume } from '@hooks/experience/useResume'
import { useProfileContext } from '@hooks/profile/ProfileContextProvider'
import { useEffect, useState } from 'react'
import { Card, CardContent } from '../commons/Card.styles'
import * as S from './ResumeProfileCard.styles'
import { ResumeProfileCardProps } from './interfaces'

export const ResumeProfileCard = ({
  testId = 'resume-profile-card-id',
  profileData
}: ResumeProfileCardProps) => {
  const { profile: profileGetData } = useProfileContext()

  const [edit, setEdit] = useState(false)
  const { response, update } = useResume()
  const { response: resDelete, deleteResume } = useDeleteResume()
  const { fetchProfile } = useProfileContext()

  useEffect(() => {
    if (response?.status === 200) fetchProfile()
  }, [fetchProfile, response])

  useEffect(() => {
    if (resDelete?.status === 200) fetchProfile()
  }, [fetchProfile, resDelete])

  const profile = profileData ? profileData : profileGetData

  return (
    <div data-testid={testId}>
      {profile?.resume_url || edit ? (
        <>
          <Card>
            <CardContent>
              <S.Heading>
                <ResumeIcon />
                <div>
                  <Text type="GlobalH5">Resume</Text>
                  <Text type="BodyMR">
                    You can see the details from your resume below as a PDF
                    file.
                  </Text>
                </div>
              </S.Heading>
              <S.UploadWrapper>
                <UploadPdf
                  onFileUpload={(file) => {
                    update(file)
                  }}
                  file={
                    profile?.resume_url
                      ? {
                          name: profile?.resume_url.split('/').pop()
                        }
                      : null
                  }
                  onFileRemove={() => deleteResume()}
                />
              </S.UploadWrapper>
            </CardContent>
          </Card>

          {<PdfView fileUrl={profile?.resume_url} />}
        </>
      ) : (
        <Card>
          <CardContent>
            <ProfileCardPlaceholder
              icon={<ResumeIcon />}
              title="Resume"
              subtitle="You haven’t uploaded your resume yet"
              callToAction="Upload file"
              onClick={() => setEdit(true)}
            />
          </CardContent>
        </Card>
      )}
    </div>
  )
}

export const ResumeCard = ({
  testId = 'resume-card-id',
  profileData
}: ResumeProfileCardProps) => {
  const { profile: profileGetData } = useProfileContext()

  const { response } = useResume()
  const { response: resDelete } = useDeleteResume()
  const { fetchProfile } = useProfileContext()

  useEffect(() => {
    if (response?.status === 200) fetchProfile()
  }, [fetchProfile, response])

  useEffect(() => {
    if (resDelete?.status === 200) fetchProfile()
  }, [fetchProfile, resDelete])

  const profile = profileData ? profileData : profileGetData

  return (
    <div data-testid={testId}>
      {profile?.resume_url ? (
        <>
          <Card>
            <CardContent>
              <S.Heading>
                <ResumeIcon />
                <div>
                  <Text type="GlobalH5">Resume</Text>
                  <Text type="BodyMR">
                    You can see the details from the candidate&apos;s resume
                    below as a PDF file.
                  </Text>
                </div>
              </S.Heading>
            </CardContent>
          </Card>

          {<PdfView fileUrl={profile?.resume_url} />}
        </>
      ) : (
        <Card>
          <CardContent>
            <S.Placeholder
              icon={<ResumeIcon />}
              title="Resume"
              subtitle="The candidate hasn't uploaded a resume yet"
            />
          </CardContent>
        </Card>
      )}
    </div>
  )
}

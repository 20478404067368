import styled from 'styled-components'
import { EditButton } from '@components/atoms/EditButton'
import { BodyLB, BodyMB } from '@components/atoms/Text/Text.styles'

export const Container = styled.div<{ edit: boolean }>`
  background-color: ${({ theme }) => theme.colors.primaryWhite};
  width: 100%;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.shades.gray500};
  flex-grow: unitless value;
  opacity: ${({ edit }) => (edit ? 0.5 : 1)};
`
export const TitleBarEditButton = styled(EditButton)`
  width: 32px;
  height: 32px;
  margin-right: 40px;

  @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
    margin-right: 24px;
  }
`

export const TitleBarEditButtonContainer = styled.div`
  display: flex;
  justify-content: right;
  height: 32px;
  align-self: center;
`

export const UserIconContainer = styled.div`
  & svg {
    margin-right: auto;
    margin-left: 0;
    margin-top: 8px;
    width: 24px;
    margin-left: 40px;

    @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
      margin-left: 24px;
    }
  }
`
export const Title = styled.h4`
  margin-right: auto;
  margin-left: 19px;
  width: 100%;
  font-weight: 600;
  line-height: 32px;
  font-size: 20px;
  margin-top: 5px;

  ${BodyLB}

  @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
    font-size: 16px;
  }
`

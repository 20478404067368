import * as S from './Tab.styles'
import styled from 'styled-components'
import { TabProps } from './interfaces'

const TabWrapper = ({ ...props }: TabProps) => <button {...props} />

const StyledWrapper = styled(TabWrapper)`
  ${S.tabStyle}
`

export const Tab = ({
  testId = 'tab-test-id',
  children,
  ...rest
}: TabProps) => {
  return (
    <StyledWrapper data-testid={testId} {...rest}>
      {children}
    </StyledWrapper>
  )
}

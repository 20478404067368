import { BodyMR } from '@components/atoms/Text/Text.styles'
import styled, { css } from 'styled-components'

type SelectedProps = {
  isSelected: boolean
}

export const NavigationItemWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 54px;
  width: 100%;
  cursor: pointer;
`

export const ButtonWrapper = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`

export const NavigationContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const SelectedIndicator = styled.div<SelectedProps>`
  height: 40px;
  width: 8px;
  border-radius: 0px 99px 99px 0px;

  ${(p) =>
    p.isSelected &&
    css`
      background: ${({ theme }) => theme.colors.shades.secondary500};
    `}
  left: 0;
`

export const Label = styled.p<SelectedProps>`
  ${BodyMR}

  color: white;

  ${(p) =>
    p.isSelected &&
    css`
      font-weight: 600;
    `}
`

export const ContentWrapper = styled(ButtonWrapper)`
  display: flex;
  align-items: center;
  margin-left: 24px;
  margin-right: 32px;
  width: 100%;
  height: 100%;

  &:hover {
    background-color: rgba(28, 46, 64, 0.56);
    border-radius: 12px;
  }
`

export const IconWrapper = styled.div<SelectedProps>`
  margin: 16px;
  margin-left: 14px;
  color: ${({ theme }) => theme.colors.shades.primary300};

  & svg {
    ${(p) =>
      p.isSelected &&
      css`
        color: ${({ theme }) => theme.colors.shades.secondary500};
      `};
  }
`

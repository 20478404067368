import { Star } from '@commons/Icons'
import { EditButton } from '@components/atoms/EditButton'
import { ProfileCardTitleBar } from '@components/molecules/ProfileCardTitleBar'
import { ProfessionalHighlightsForm } from '@components/organisms/ProfessionalHighlightsForm'
import {
  CardContainer,
  CardText,
  CardTextContainer
} from '../../commons/Card.styles'
import { Text } from '@components/atoms/Text'

interface Highlight {
  id: number
  description: string
}

interface IProfessionalHighlight {
  edit?: boolean
  professionalHighlights?: Highlight[]
  onCancel?: () => void
  onSucess?: () => void
  editButton?: (id: number) => void
  editId?: number | null
}

export const ProfessionalHighlight = ({
  edit = false,
  professionalHighlights = [],
  editButton,
  onSucess = () => {},
  onCancel = () => {},
  editId
}: IProfessionalHighlight) => (
  <>
    <ProfileCardTitleBar
      edit={edit}
      title="Professional highlights"
      icon={<Star width={24} height={24} color="#8E97A0" />}
    />
    <CardContainer>
      {professionalHighlights.map(({ id, description }, idx, all) => (
        <CardTextContainer key={id}>
          <CardText isEditing={edit}>
            <Text type="BodyMR">{description}</Text>
            {editButton && (
              <EditButton onClick={() => editButton(id)}></EditButton>
            )}
          </CardText>
          {editId === id && edit && (
            <ProfessionalHighlightsForm
              defaultValue={description}
              isEditing
              id={id}
              highlights={all}
              onCancel={onCancel}
              onSucess={onSucess}
            />
          )}
        </CardTextContainer>
      ))}
    </CardContainer>
  </>
)

import styled from 'styled-components'

export const Status = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 32px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-direction: column;
    padding: 0 24px;
  }
`

export const Item = styled.div`
  p {
    margin-bottom: 12px;
  }
`

import api from '@api/index'
import { addToast } from '@components/atoms/Toast'
import { useUserContext } from '@hooks/user/UserContextProvider'
import { useState } from 'react'

interface Values {
  jobSearch: string
  relocation: boolean
  relocateRegion: string
  employementStatus: string
  jobTitle: string
}

export const useUpdateJobStatus = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [response, setResponse] = useState<any>(null)

  const { logOut } = useUserContext()

  const update = async (data: Values) => {
    setLoading(true)
    try {
      const res = await api.put(`/users/profile`, {
        profile: {
          job_status_attributes: {
            job_search: data.jobSearch,
            willing_to_relocate: data.relocation,
            where_to_relocate: data.relocateRegion,
            employment_status: data.employementStatus,
            job_title: data.jobTitle
          }
        }
      })
      setResponse(res)
      addToast({ title: 'Successfully updated' }, 'success', {})
    } catch (error: any) {
      if (error?.response?.status === 401) logOut()
    } finally {
      setLoading(false)
    }
  }

  return { loading, response, update }
}

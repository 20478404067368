import styled from 'styled-components'
import { ProfileCard } from '@components/atoms/ProfileCard'
import { ProfileCardTitleBar } from '@components/molecules/ProfileCardTitleBar'
import { CertificationItem } from '@components/molecules/CertificationItem'
import { AddItemButton } from '@components/atoms/AddItemButton'
import { CertificationsForm } from '@components/organisms/CertificationsForm'
import { ProfileCardPlaceholder } from '@components/molecules/ProfileCardPlaceholder'

interface CertificationItemProps {
  disabled?: boolean
}

export const Item = styled(CertificationItem)<any>`
  ${({ disabled }) => (disabled ? `opacity: 0.56;` : '')}
`

export const Placeholder = styled(ProfileCardPlaceholder)`
  margin-top: 50px;
  margin-bottom: 60px;
`

export const Form = styled(CertificationsForm)``
export const Card = styled(ProfileCard)`
  padding-top: 2px;
  padding-bottom: 0;
  height: auto;
`

export const Divider = styled.hr`
  border-top: 1px solid #e6ebf0;
  border-bottom: none;
  border-left: none;
  border-right: none;
  height: 1px;
`

export const PaddedDivider = styled(Divider)`
  margin-left: 30px;
  margin-right: 30px;
`

export const FullWidthDivider = styled(Divider)`
  display: block;
  margin-bottom: 0;
  margin-top: auto;
  width: 100%;
`

export const TitleBar = styled(ProfileCardTitleBar)`
  top: 0;
  height: 72px !important;
`
export const Add = styled(AddItemButton)`
  margin-bottom: 28px;
  margin-top: 28px;
`

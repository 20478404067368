import { AngleSmallDown } from '@commons/Icons'
import { Logo } from '@components/atoms/Logo'
import { Text as OriginalText } from '@components/atoms/Text'
import { BodySMR } from '@components/atoms/Text/Text.styles'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionDetails, {
  AccordionDetailsProps
} from '@mui/material/AccordionDetails'
import MuiAccordionSummary, {
  AccordionSummaryProps
} from '@mui/material/AccordionSummary'
import { styled as MuiStyled } from '@mui/system'
import styled from 'styled-components'
import { Item as LinkStyles } from './Footer.styles'

const Accordion = MuiStyled((props: AccordionProps) => (
  <MuiAccordion square {...props} />
))(({ theme }) => ({
  backgroundColor: 'transparent',
  color: 'white',
  borderTop: `1px solid rgba(242,245,247, 0.1)`
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<AngleSmallDown />}
    sx={{ padding: '12px', paddingLeft: 0 }}
    {...props}
  />
))(({ theme }) => ({}))

const AccordionDetails = styled((props: AccordionDetailsProps) => (
  <MuiAccordionDetails sx={{ padding: 0 }} {...props} />
))(({ theme }) => ({}))

export const Wrapper = styled.div`
  padding: 48px 24px;
  background: linear-gradient(326.83deg, #1d2f41 -0.04%, #0c1925 100%);
`

const Text = styled(OriginalText)`
  color: white;
`

const LinksWrapper = styled.div`
  > a:last-child {
    margin-bottom: 30px;
  }
`

const Link = styled(LinkStyles)`
  ${BodySMR}
  margin-bottom: 24px;
`

const LogoWrapper = styled.div`
  margin-bottom: 41px;
`

export const MobileAccordion = ({
  testId = 'component-mobileaccordion-id'
}) => {
  return (
    <Wrapper data-testid={testId}>
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
      <Accordion disableGutters style={{ boxShadow: 'none' }}>
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
          <Text type="BodyMB">Who we are</Text>
        </AccordionSummary>

        <AccordionDetails>
          <LinksWrapper>
            <Link href="https://www.jombine.com/" rel="noopener noreferrer">
              Home
            </Link>
            <Link
              href="https://www.jombine.com/contact"
              rel="noopener noreferrer"
            >
              Contact
            </Link>
            <Link href="https://www.jombine.com/blog" rel="noopener noreferrer">
              Blog
            </Link>
            <Link href="https://www.jombine.com/jobs" rel="noopener noreferrer">
              Jobs
            </Link>
          </LinksWrapper>
        </AccordionDetails>
      </Accordion>

      <Accordion disableGutters style={{ boxShadow: 'none' }}>
        <AccordionSummary aria-controls="panel3a-content" id="panel3a-header">
          <Text type="BodyMB">What we do</Text>
        </AccordionSummary>

        <AccordionDetails>
          <Link
            href="https://www.jombine.com/employers"
            rel="noopener noreferrer"
          >
            Employers
          </Link>
          <Link
            href="https://www.jombine.com/candidate-network"
            rel="noopener noreferrer"
          >
            Candidates Coaching
          </Link>
          <Link href="https://www.jombine.com/agents" rel="noopener noreferrer">
            Agents (Recruiters)
          </Link>
          <Link
            href="https://www.jombine.com/combine"
            rel="noopener noreferrer"
          >
            Combine Program
          </Link>
        </AccordionDetails>
      </Accordion>

      <Accordion disableGutters style={{ boxShadow: 'none' }}>
        <AccordionSummary aria-controls="panel4a-content" id="panel4a-header">
          <Text type="BodyMB">Helpful links</Text>
        </AccordionSummary>
        <AccordionDetails>
          <Link
            href="https://www.jombine.com/resources"
            rel="noopener noreferrer"
          >
            Resources
          </Link>
          <Link
            href="https://calendly.com/jombine-topher/jombine-demo-clients"
            rel="noopener noreferrer"
          >
            Schedule a Demo
          </Link>
        </AccordionDetails>
      </Accordion>
    </Wrapper>
  )
}

import { CheckIconWithBackground } from '@commons/Icons'
import { Modal } from '@components/atoms/Modal'
import { Text } from '@components/atoms/Text'
import { ModalContentPanel } from '@components/organisms/ModalContentPanel'
import { useUserContext } from '@hooks/user/UserContextProvider'

export interface ApplicationClaimedModalProps {
  testId?: string
  email: string
  isModalOpen: boolean
  handleCloseModal: () => void
}

export const ApplicationClaimedModal = ({
  testId = 'application-claimed-modal-id',
  email,
  isModalOpen,
  handleCloseModal,
  ...props
}: ApplicationClaimedModalProps) => {
  const { user } = useUserContext()

  return (
    <Modal
      isOpen={isModalOpen}
      handleClose={handleCloseModal}
      data-testid={testId}
      {...props}
    >
      <ModalContentPanel
        Icon={<CheckIconWithBackground />}
        title="Application claimed!"
        body={
          <Text
            type="BodyMR"
            style={{ display: 'inline-block', textAlign: 'center' }}
          >
            Share the invitation link to the applicant to {` `}
            <Text type="BodyMB" as="b" style={{ display: 'inline-block' }}>
              {email}
            </Text>{' '}
            so they can join Jombine’s platform.
          </Text>
        }
        textToCopy={`${window.location.host}/users/sign-up/${user?.secret_id}`}
      />
    </Modal>
  )
}

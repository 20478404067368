import { CheckIconWithBackground } from '@commons/Icons'
import { Button } from '@components/atoms/Button'
import { Text } from '@components/atoms/Text'
import * as F from '@components/molecules/AccountConfirmMessage/AcccountConfirmMessage.styles'
import { HeaderWithCenteredLogo } from '@components/organisms/HeaderWithCenteredLogo'
import { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import * as S from './ReferenceReviewSent.styles'

const JOMBINE_WEBSITE = 'https://www.jombine.com/'

export const ReferenceReviewSent = ({
  testId = 'reference-review-sent-id'
}: any) => {
  const _handleRedirection = useCallback(() => {
    window.location.assign(JOMBINE_WEBSITE)
  }, [])

  const { name } = useParams()

  return (
    <S.Container>
      <HeaderWithCenteredLogo />

      <S.Body>
        <S.Rounded>
          <F.Wrapper data-testid={testId}>
            <F.GreenCheckerWrapper>
              <CheckIconWithBackground />
            </F.GreenCheckerWrapper>

            <Text as="h1" type="GlobalH4">
              Your review was sent!
            </Text>

            <S.TextHolder>
              <Text type="BodyLR">
                Thank you for your time and helping {name} with his career
                growth!
              </Text>
            </S.TextHolder>

            <F.Footer>
              <Button
                size="full"
                height="large"
                type="button"
                onClick={_handleRedirection}
              >
                Learn about us
              </Button>
            </F.Footer>
          </F.Wrapper>
        </S.Rounded>
      </S.Body>
    </S.Container>
  )
}

import { ACTION_TYPES } from './constants'

export const searchQueryReducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.SET_SEARCH_QUERY:
      return {
        ...state,
        search: action.payload
      }
    case ACTION_TYPES.SET_PAGE:
      return {
        ...state,
        page: action.payload
      }
    case ACTION_TYPES.SET_ORDER_BY:
      return {
        ...state,
        order_by: action.payload
      }
    case ACTION_TYPES.SET_ORDER_DIRECTION:
      return {
        ...state,
        order_direction: action.payload
      }
    default:
      return state
  }
}

import { Check } from '@commons/Icons'
import { AiOutlineClose } from 'react-icons/ai'
import styled from 'styled-components'
import { GreenCheckerProps, iconSizes, sizes } from './interfaces'

export const Wrapper = styled.div<GreenCheckerProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  color: ${({ theme, styling }) =>
    styling === 'success'
      ? theme.colors.primaryBlue
      : theme.colors.primaryWhite};

  background-color: ${({ theme, styling }) =>
    styling === 'success' ? theme.colors.success : theme.colors.alert};

  height: ${({ size }) => (size ? sizes[size] : sizes[48])};
  width: ${({ size }) => (size ? sizes[size] : sizes[48])};
  border-radius: 50%;
`

export const CheckIcon = styled(Check)<GreenCheckerProps>`
  height: calc(${({ size }) => (size ? iconSizes[size] : iconSizes[48])} - 4px);
  width: calc(${({ size }) => (size ? iconSizes[size] : iconSizes[48])} - 4px);
`

export const ErrorIcon = styled(AiOutlineClose)<GreenCheckerProps>`
  height: ${({ size }) => (size ? iconSizes[size] : iconSizes[48])};
  width: ${({ size }) => (size ? iconSizes[size] : iconSizes[48])};
`

import styled from 'styled-components'
import {
  Card as OriginalCard,
  CardContent as OriginalCardContent
} from '../commons/Card.styles'

export const Card = styled(OriginalCard)``

export const CardContent = styled(OriginalCardContent)`
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 24px 0;
  }
`

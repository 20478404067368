import {
  Filter,
  SearchIconPure,
  ShareIcon,
  ShareIconPure
} from '@commons/Icons'
import { Button } from '@components/atoms/Button'
import { ContentSwitcher } from '@components/atoms/ContentSwitcher'
import { Modal } from '@components/atoms/Modal'
import { Table } from '@components/atoms/Table'
import { Text } from '@components/atoms/Text'
import { Tooltip } from '@components/atoms/Tooltip'
import { RecruiterBaseLayout } from '@components/layout/RecruiterBaseLayout'
import { ApplicationClaimedModal } from '@components/molecules/ApplicationClaimedModal'
import { ModalContentPanel } from '@components/organisms/ModalContentPanel'
import { usePaginationQuery } from '@hooks/paginationQuery'
import { useRecruiterContext } from '@hooks/recruiter/RecruiterContextProvider'
import { LineProps } from '@hooks/recruiter/interfaces'
import { useMediaQuery } from '@mui/material'
import { debounce, head, size, truncate } from 'lodash'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { TablePagination } from '../../atoms/TablePagination'
import * as S from './RecruiterApplicationsScreen.styles'
import { RecruiterApplicationsScreenProps } from './interfaces'

export default function RenderPillPopper({ pillNumber, pillText = '' }) {
  return (
    <>
      <Tooltip
        title={<S.PoppoverText>{pillText}</S.PoppoverText>}
        placement="top"
        arrow
      >
        <S.Pill>+ {pillNumber}</S.Pill>
      </Tooltip>
    </>
  )
}

export const RecruiterApplicationsScreen = ({
  testId = 'recruiter-applications-screen-id',
  ...props
}: RecruiterApplicationsScreenProps) => {
  const IS_MOBILE = useMediaQuery('(max-width: 800px)')

  const { getAllApplicationForms, applications: remoteApplications } =
    useRecruiterContext()

  const { claimCandidate } = useRecruiterContext()
  const [applications, setApplications] = useState<LineProps[]>(
    remoteApplications?.data || []
  )

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalFilterOpen, setIsFilterModalOpen] = useState(false)
  const [sortField, setSortField] = useState<string>('')
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc')
  const [mobileFilterChoice, setMobileFilterChoice] = useState([
    { value: 'Candidate name', sortKey: 'candidate_name', checked: false },
    { value: 'Career status', sortKey: 'career_status', checked: false },
    { value: 'Location', sortKey: 'city', checked: false },
    { value: 'Assignation', sortKey: 'assignation', checked: false }
  ])

  const { setOrderBy, setOrderDirection, setSearchQuery, setPage } =
    usePaginationQuery(getAllApplicationForms)

  const handleOpenModal = () => setIsModalOpen(true)
  const handleCloseModal = () => setIsModalOpen(false)

  const [isClaimedModalOpen, setIsClaimedModalOpen] = useState(false)
  const [claimedEmail, setClaimedEmail] = useState('')
  const handleOpenClaimedModal = () => setIsClaimedModalOpen(true)
  const handleCloseClaimedModal = () => setIsClaimedModalOpen(false)

  const handleOpenFilterModal = () => setIsFilterModalOpen(true)
  const handleCloseFilterModal = () => setIsFilterModalOpen(false)

  const navigate = useNavigate()

  useEffect(() => {
    setOrderBy(sortField)
    setOrderDirection(sortOrder)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortField, sortOrder])

  const requestSort = (fieldName) => {
    const SAME_FIELD = fieldName === sortField
    SAME_FIELD && setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
    setSortField(fieldName)
  }

  const search = debounce(
    (searchTerms) => {
      setSearchQuery(searchTerms)
    },
    300,
    {
      trailing: true
    }
  )

  useEffect(() => {
    getAllApplicationForms()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (remoteApplications?.data) {
      setApplications(remoteApplications.data)
    }
  }, [remoteApplications?.data])

  const _handleNavigateToDetails = (id) => {
    navigate(`/recruiter/applications/details/${id}`)
  }

  const _handleClaim = async ({ id, email }: any) => {
    await claimCandidate({ id })
    handleOpenClaimedModal()
    setClaimedEmail(email)
    getAllApplicationForms()
  }

  const renderTableLine = (props: LineProps, key) => {
    const pillSize = size(props.pills)
    const clickFunctions = {
      onClick: () => _handleNavigateToDetails(props.id)
    }

    return (
      <S.TRHoverWrapper key={key} data-testid={'applications-tr'}>
        <td {...clickFunctions}>
          <S.InlineWrapper>
            <S.ProfilePicture
              alt={`Avatar image of ${props.name}`}
              src={props.image}
            >
              {props.name && props.name.charAt(0)}
            </S.ProfilePicture>
            <Text type="BodySMB">{props.name}</Text>
          </S.InlineWrapper>
        </td>
        <td {...clickFunctions}>
          <S.CareerWrapper>
            <S.Pill>{truncate(head(props.pills), { length: 32 })}</S.Pill>
            {pillSize > 1 && (
              <RenderPillPopper
                pillNumber={pillSize - 1}
                pillText={props.pills.slice(1).join(', ')}
              />
            )}
          </S.CareerWrapper>
        </td>

        <td {...clickFunctions}>
          <Text type="BodySMR">{props.location}</Text>
        </td>

        <td {...clickFunctions}>
          <Text type="BodySMR">
            {truncate(props.recruiter?.full_name, { length: 24 })}
          </Text>
        </td>

        <S.ClaimButtonWrapper>
          {!props.isClaimed && (
            <S.Button onClick={() => _handleClaim(props)}>Claim</S.Button>
          )}
        </S.ClaimButtonWrapper>
      </S.TRHoverWrapper>
    )
  }

  const renderDesktopTable = () => (
    <Table testId={'applications-table'}>
      <thead>
        <tr>
          <S.TableHeaderWrapper onClick={() => requestSort('candidate_name')}>
            <S.TableHeaderContent>
              <Text type="BodySMB">Candidate Name</Text>
              {sortField === 'candidate_name' && sortOrder === 'desc' ? (
                <S.AngleSmallDown />
              ) : (
                <S.AngleSmallUp />
              )}
            </S.TableHeaderContent>
          </S.TableHeaderWrapper>

          <S.TableHeaderWrapper onClick={() => requestSort('career_status')}>
            <S.TableHeaderContent>
              <Text type="BodySMB">Career status</Text>
              {sortField === 'career_status' && sortOrder === 'desc' ? (
                <S.AngleSmallDown />
              ) : (
                <S.AngleSmallUp />
              )}
            </S.TableHeaderContent>
          </S.TableHeaderWrapper>

          <S.TableHeaderWrapper>
            <S.TableHeaderContent onClick={() => requestSort('city')}>
              <Text type="BodySMB">Location</Text>
              {sortField === 'city' && sortOrder === 'desc' ? (
                <S.AngleSmallDown />
              ) : (
                <S.AngleSmallUp />
              )}
            </S.TableHeaderContent>
          </S.TableHeaderWrapper>

          <S.TableHeaderWrapper>
            <S.TableHeaderContent onClick={() => requestSort('assignation')}>
              <Text type="BodySMB">Assignation</Text>
              {sortField === 'assignation' && sortOrder === 'desc' ? (
                <S.AngleSmallDown />
              ) : (
                <S.AngleSmallUp />
              )}
            </S.TableHeaderContent>
          </S.TableHeaderWrapper>

          <th></th>
        </tr>
      </thead>

      <tbody>{applications.map(renderTableLine)}</tbody>

      <TablePagination
        pagination={remoteApplications?.pagination}
        setPage={setPage}
      />
    </Table>
  )

  const renderMobileTable = () => (
    <S.MobileTable>
      {applications.map((item, key) => (
        <S.MobileTableItem
          key={key}
          onClick={() => _handleNavigateToDetails(item.id)}
        >
          <S.ProfilePicture
            alt={`Avatar image of ${item.name}`}
            src={item.image}
          >
            {item.name && item.name.charAt(0)}
          </S.ProfilePicture>

          <S.TextWrapper>
            <Text type="BodyMB">{item.name}</Text>
            <Text type="BodySMR">{item.location}</Text>
          </S.TextWrapper>
        </S.MobileTableItem>
      ))}
    </S.MobileTable>
  )

  return (
    <RecruiterBaseLayout data-testid={testId} {...props}>
      <S.Container>
        <S.Header>
          <S.Title>Applications</S.Title>
          {IS_MOBILE ? (
            <S.MobileIconsWrapper>
              <S.MobileShareButton
                onClick={handleOpenFilterModal}
                role="button"
                aria-label="Share button"
              >
                <Filter />
              </S.MobileShareButton>
              <S.MobileShareButton
                onClick={handleOpenModal}
                role="button"
                aria-label="Share button"
              >
                <ShareIconPure />
              </S.MobileShareButton>
            </S.MobileIconsWrapper>
          ) : (
            <S.ShareButton
              onClick={handleOpenModal}
              role="button"
              aria-label="Share button"
            >
              Share Link <ShareIconPure />
            </S.ShareButton>
          )}
        </S.Header>
        <S.InputWrapper>
          <S.InputText
            endIcon={<SearchIconPure />}
            placeholder="Search"
            onChange={(e) => {
              e.preventDefault()
              search(e.target.value)
            }}
          ></S.InputText>
        </S.InputWrapper>
        {IS_MOBILE ? renderMobileTable() : renderDesktopTable()}
      </S.Container>

      <Modal isOpen={isModalOpen} handleClose={handleCloseModal}>
        <ModalContentPanel
          testId={testId}
          Icon={<ShareIcon />}
          title="Share application link"
          body="Share the following link to candidates so they can apply to the program."
          textToCopy={`${window.location.host}/candidate/welcome`}
        />
      </Modal>

      <ApplicationClaimedModal
        email={claimedEmail}
        isModalOpen={isClaimedModalOpen}
        handleCloseModal={handleCloseClaimedModal}
      />

      <Modal isOpen={isModalFilterOpen} handleClose={handleCloseFilterModal}>
        <S.FilterModalContainer>
          <Text type="BodyLB">Sort By</Text>

          <S.MobileFilterWrapper>
            <ContentSwitcher
              name={'mobile-applications-filter'}
              items={mobileFilterChoice}
              onChange={(selectedOption) => {
                setMobileFilterChoice((choices) => {
                  return choices.map((choice) => {
                    choice.value === selectedOption
                      ? (choice.checked = true)
                      : (choice.checked = false)
                    return choice
                  })
                })
              }}
            />
          </S.MobileFilterWrapper>

          <S.FilterButtons>
            <Button
              size="full"
              onClick={() => {
                const selected = mobileFilterChoice.find((item) => item.checked)
                setSortOrder('asc')
                setSortField(selected?.sortKey || 'candidate_name')
                handleCloseFilterModal()
              }}
            >
              Apply
            </Button>
            <Button
              size="full"
              styling="secondary"
              onClick={() => {
                setMobileFilterChoice((choices) =>
                  choices.map((item) => {
                    item.checked = false
                    return item
                  })
                )
              }}
            >
              Clear
            </Button>
          </S.FilterButtons>
        </S.FilterModalContainer>
      </Modal>
    </RecruiterBaseLayout>
  )
}

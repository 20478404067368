import * as S from './ComputerSkillsProfileCard.styles'
import { ComputerSkillsProfileCardProps } from './interfaces'
import { ProfileCardPlaceholder } from '@components/molecules/ProfileCardPlaceholder'
import { LaptopSymbol, LaptopIcon } from '@commons/Icons'
import { useComputerSkills } from '@hooks/experience/useComputerSkills'
import { capitalize } from 'lodash'
import { useProfileContext } from '@hooks/profile/ProfileContextProvider'
import { useState } from 'react'
import { Pill } from '../Pill'
import { Placeholder } from '@components/organisms/ProfileCards/IndustriesProfileCard/IndustriesProfileCard.styles'

export const ComputerSkillsProfileCard = ({
  testId = 'computer-skills-profile-card-id'
}: ComputerSkillsProfileCardProps) => {
  const { fetchProfile, profile } = useProfileContext()
  const { update } = useComputerSkills()

  const [edit, setEdit] = useState(false)
  const profileComputerSkills = edit ? [] : profile.computer_skills || []

  return (
    <S.Card data-testid={testId}>
      {(profileComputerSkills?.length || edit) && (
        <S.TitleBar
          edit={edit}
          title="Computer skills"
          onEditButtonClick={() => setEdit(true)}
          icon={<LaptopSymbol />}
        />
      )}

      {!edit && profileComputerSkills?.length < 1 && (
        <ProfileCardPlaceholder
          icon={<LaptopIcon />}
          title="Computer skills"
          subtitle="Add your computer skills here"
          callToAction="Add computer skills"
          onClick={() => setEdit(true)}
        />
      )}

      <S.PillContainer>
        {profileComputerSkills?.map((skill) => (
          <Pill key={skill.id}>{capitalize(skill.description)}</Pill>
        ))}
      </S.PillContainer>

      {edit && (
        <S.Form
          testId={`${testId}-form`}
          onSubmit={async (data) => {
            await update(data.computer_skills.map((item) => item.id))
            fetchProfile()
            setEdit(false)
          }}
          initialValues={profile.computer_skills?.map((skill) => {
            return {
              id: skill.id,
              value: skill.description
            }
          })}
          onCancel={() => setEdit(false)}
        ></S.Form>
      )}
    </S.Card>
  )
}

export const ComputerSkillsCard = ({ profile }) => {
  return (
    <>
      {profile?.computer_skills?.length ? (
        <>
          <S.TitleBar title="Computer Skills" icon={<LaptopSymbol />} />
          <S.PillViewContainer>
            {profile?.computer_skills?.map((skill) => (
              <Pill key={skill.id}>{capitalize(skill.description)}</Pill>
            ))}
          </S.PillViewContainer>
        </>
      ) : (
        <Placeholder
          icon={<LaptopIcon />}
          title="Computer skills"
          subtitle="Add your computer skills here"
        />
      )}
    </>
  )
}

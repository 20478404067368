import api from '@api/index'
import { useUserContext } from '@hooks/user/UserContextProvider'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

export const usePublicProfile = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const { logOut } = useUserContext()
  const navigate = useNavigate()

  const fetchPublicProfile = async ({ candidateId }) => {
    setLoading(true)
    try {
      const res = await api.get<any>(`/candidates/${candidateId}/public_view`)
      const IS_ARCHIVED = res?.data?.status === 'archived'
      if (IS_ARCHIVED) return navigate('/not-found')

      return res.data
    } catch (error: any) {
      if (error?.response?.status === 401) logOut()
    } finally {
      setLoading(false)
    }
  }

  return {
    loading,
    fetchPublicProfile
  }
}

import api from '@api/index'
import { useState } from 'react'

export const useUploadProfilePicture = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<any>(null)
  const [response, setResponse] = useState<any>(null)

  const uploadProfilePicture = async (image: any) => {
    setLoading(true)

    try {
      const formData = new FormData()
      formData.append('profile[profile_picture]', image)

      const headersConfig = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }

      const res = await api.put(`/users/profile`, formData, headersConfig)

      setResponse(res)
    } catch (error: any) {
      setError(error?.response?.data?.message)
    } finally {
      setLoading(false)
    }
  }

  const removeProfilePicture = async () => {
    setLoading(true)

    try {
      const res = await api.delete(`/users/profile_pictures/profile_picture`)
      return res
    } catch (error: any) {
      setError(error?.response?.data?.message)
    } finally {
      setLoading(false)
    }
  }

  return {
    loading,
    error,
    response,
    uploadProfilePicture,
    removeProfilePicture
  }
}

import { InitialScreen } from '@components/layout/InitialScreen'
import { SESSION_ROUTES } from '@routes/SessionRoutes'
import { useNavigate } from 'react-router-dom'
import { CandidateSignInForm } from '@components/organisms/CandidateSignInForm'

export interface SignInProps {
  testId?: string
}

export const SignIn = ({ testId = 'component-signin-id' }: SignInProps) => {
  const navigate = useNavigate()
  const _redirectToForgotPassword = () => {
    navigate(`/users${SESSION_ROUTES.FORGOT_PASSWORD}`)
  }

  return (
    <InitialScreen testId={testId}>
      <CandidateSignInForm
        redirectToForgotPassword={_redirectToForgotPassword}
      />
    </InitialScreen>
  )
}

import { grid } from '@styles/grid'
import React from 'react'
import styled from 'styled-components'
import { FlexRowProps } from './interfaces'

const defaultProps: Partial<FlexRowProps> = {
  align: 'flex-start',
  cols: grid.cols,
  gutter: grid.gutter.regular,
  justify: 'flex-start',
  direction: 'row'
}

const StyledFlexRow = styled.div<FlexRowProps>`
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${(props) => props.direction};
  margin-right: -${(props) => props.gutter}px;
  margin-left: -${(props) => props.gutter}px;
  justify-content: ${(props) => props.justify};
  align-items: ${(props) => props.align};
`

export const FlexRow = ({ children, ...props }) => {
  const childrenWithProps = React.Children.toArray(children)
    .filter((child) => !!child)
    .map((child) =>
      React.cloneElement(child as any, {
        gutter: props.gutter,
        cols: props.cols
      })
    )
  return <StyledFlexRow {...props}>{childrenWithProps}</StyledFlexRow>
}

FlexRow.defaultProps = defaultProps

import { BriefCaseIcon, Comment } from '@commons/Icons'
import { ProfileCardTitleBar } from '@components/molecules/ProfileCardTitleBar'
import { useProfileContext } from '@hooks/profile/ProfileContextProvider'
import get from 'lodash/get'
import { Card } from '../commons/Card.styles'
import * as S from './NotesProfileCard.styles'

export const NotesProfileCardView = ({
  testId = 'notes-card-id',
  profileData
}: any) => {
  const { profile: profileGetData } = useProfileContext()

  const profile = profileData ? profileData : profileGetData
  return (
    <Card data-testid={testId}>
      {get(profile, 'video_interview.notes') ? (
        <>
          <ProfileCardTitleBar title="Interview Notes" icon={<Comment />} />
          {get(profile, 'video_interview.notes', []).map((note, index) => (
            <S.Item key={note.id} title={note.title} body={note.description} />
          ))}
        </>
      ) : (
        <S.Placeholder
          icon={<BriefCaseIcon />}
          title="Interview Notes"
          subtitle="After having the video interview with one of our Agents, they will add your interview notes here. You don’t have to do anything but wait."
        />
      )}
    </Card>
  )
}

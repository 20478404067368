import { CandidateProfileOverview } from '@components/organisms/CandidateProfileOverview/CandidateProfileOverview'
import styled, { css } from 'styled-components'
import { FlexColumn, FlexContainer, FlexRow } from '../Flex'
import { CandidateProfileScreenProps } from './interfaces'

export const candidateProfileScreenProps = css<CandidateProfileScreenProps>`
  background-color: #f2f5f7;
`

export const Container = styled(FlexContainer)`
  background-color: #f2f5f7;
  max-width: ${({ theme }) => theme.breakpoints.desktop}px;

  min-height: 1000px;
`

export const ContainerRow = styled.div`
  display: flex;
  padding-top: 60px;
  flex-direction: column;
  gap: 32px;

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    gap: 70px;
    flex-direction: row;
  }
  padding-bottom: 100px;
`

export const ContentContainer = styled.div`
  padding-bottom: 100px;
`

export const ContentColumn = styled(FlexColumn)`
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media (max-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    padding-left: 24px;
    padding-right: 24px;
  }
`

export const ContentOverview = styled(FlexColumn)`
  max-width: 400px;

  @media (max-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    max-width: max-content;
  }
`

export const ProfileOverview = styled(CandidateProfileOverview)``

import styled from 'styled-components'
import { ProfileCard } from '@components/atoms/ProfileCard'

export const Card = styled(ProfileCard)``

export const CardContent = styled.div`
  padding: 40px;
  min-height: 126px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 24px;

    .row {
      padding-left: 40px;
      padding-right: 40px;
    }

    > p {
      padding: 0;
    }
  }
`

import api from '@api/index'
import { addToast } from '@components/atoms/Toast'
import { useUserContext } from '@hooks/user/UserContextProvider'
import { useState } from 'react'

export const useArchive = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [response, setResponse] = useState<any>(null)
  const { logOut } = useUserContext()

  const archive = async (id) => {
    setLoading(true)
    try {
      const res = await api.post(`/candidates/${id}/archive`)
      setResponse(res)
      addToast({ title: 'Successfully archived' }, 'success', {})
    } catch (error: any) {
      if (error?.response?.status === 401) logOut()
    } finally {
      setLoading(false)
    }
  }

  const unarchive = async (id) => {
    setLoading(true)
    try {
      const res = await api.post(`/candidates/${id}/unarchive`)
      setResponse(res)
      addToast({ title: 'Successfully Unarchived' }, 'success', {})
    } catch (error: any) {
      if (error?.response?.status === 401) logOut()
    } finally {
      setLoading(false)
    }
  }

  return { loading, response, archive, unarchive }
}

import { BlackCross } from '@commons/Icons'
import { PhotoUpload } from '@components/molecules/PhotoUpload'
import {
  Photo,
  RemoveIcon
} from '@components/molecules/PhotoUpload/PhotoUpload.styles'
import { usePhotoUpload } from '@hooks/aboutMe/usePhotoUpload'
import { useEffect, useState } from 'react'
import ImageEnlarger from 'react-image-enlarger'
import { PhotosProps } from './interfaces'
import { PhotosContainer } from './Photos.styles'

export const Photos = ({
  testId = 'photos-id',
  pictures,
  onSucess,
  ...props
}: PhotosProps) => {
  const [photos, setPhotos] = useState(new Array(4).fill(null))
  const { loading, response, update, remove } = usePhotoUpload()
  const [zoomed, setZoomed] = useState('')

  const handleFileUpload = (file: any, one) => {
    update(file)
  }

  useEffect(() => {
    if (pictures) {
      const fillPhotos = [...photos]
      fillPhotos.forEach((_, i) => {
        if (pictures[i]) {
          fillPhotos[i] = pictures[i]
        }
      })
      setPhotos(fillPhotos)
    }
  }, [pictures])

  useEffect(() => {
    if (response?.status === 200) onSucess && onSucess()
  }, [response])

  const _handleRemovePhoto = (photo) => {
    remove(photo?.id)
  }

  return (
    <PhotosContainer data-testid={testId} {...props}>
      {photos.map((photo, i) =>
        photo ? (
          <>
            <Photo click={true} key={i}>
              <img
                src={photo.url}
                onClick={() => {
                  setZoomed(photo.url)
                }}
              />

              {zoomed === photo.url ? (
                <ImageEnlarger
                  src={photo.url}
                  zoomed={true}
                  onClick={() => {}}
                  onRequestClose={() => {
                    setZoomed('')
                  }}
                />
              ) : (
                <RemoveIcon onClick={() => _handleRemovePhoto(photo)}>
                  <BlackCross />
                </RemoveIcon>
              )}
            </Photo>
          </>
        ) : (
          <PhotoUpload
            key={i}
            onFileUpload={handleFileUpload}
            loading={loading}
          />
        )
      )}
    </PhotosContainer>
  )
}

export const PhotosView = ({ photos, ...props }: any) => {
  const [zoomed, setZoomed] = useState('')

  return (
    <PhotosContainer {...props}>
      {photos?.map((photo, i) =>
        photo ? (
          <Photo click={true} key={i}>
            <img
              src={photo.url}
              onClick={() => {
                setZoomed(photo.url)
              }}
            />

            {zoomed === photo.url ? (
              <ImageEnlarger
                src={photo.url}
                zoomed={true}
                onClick={() => {}}
                onRequestClose={() => {
                  setZoomed('')
                }}
              />
            ) : (
              '-'
            )}
          </Photo>
        ) : null
      )}
    </PhotosContainer>
  )
}

import { BriefCaseIcon, Comment } from '@commons/Icons'
import { Note } from '@hooks/profile/interfaces'
import { useVideoInterview } from '@hooks/videoInterview/useVideoInterview'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Card } from '../commons/Card.styles'
import * as S from './NotesProfileCard.styles'

const getEmptyNotes = () => ({
  id: '',
  title: '',
  description: ''
})

export const NotesProfileCard = ({
  testId = 'notes-card-id',
  profile,
  fetch
}: any) => {
  const { id } = useParams()
  const { loading, update } = useVideoInterview(id)
  const [edit, setEdit] = useState(false)
  const [add, setAdd] = useState(false)
  const [editId, setEditId] = useState<number | null>(null)
  const [notes, setNotes] = useState<Note[]>(
    profile?.video_interview?.notes_attributes || getEmptyNotes()
  )

  const PlaceholderSection = () => (
    <S.Placeholder
      icon={<BriefCaseIcon />}
      title="Interview Notes"
      subtitle="Add your interview notes here"
      callToAction="Add notes"
      onClick={() => {
        setEdit(true)
        setNotes([...notes, getEmptyNotes()])
      }}
    />
  )

  const EditForm = (note: any) => (
    <S.Form
      onCancel={() => {
        setEdit(false)
        setAdd(false)
        setEditId(null)
      }}
      onSubmit={async (data) => {
        await update({ note: data })
        setEdit(false)
        setAdd(false)
        fetch()
      }}
      initialData={
        note.note
          ? {
              title: note.note.title,
              description: note.note.description,
              id: note.note.id
            }
          : getEmptyNotes()
      }
      loading={loading}
      testId={`${testId}-form`}
    ></S.Form>
  )

  const ItemViewSection = ({ note }) => (
    <S.Item
      disabled={edit}
      key={note.id}
      title={note.title}
      body={note.description}
      onEdit={() => {
        setNotes(note)
        setEdit(true)
        setEditId(note.id)
      }}
    >
      {editId === note.id ? <EditForm note={note} /> : <></>}
    </S.Item>
  )

  const AddNoteSection = ({ disabled }) => (
    <S.Add
      onClick={() => {
        if (!disabled) {
          setAdd(true)
          setEdit(true)
          setNotes([...notes, getEmptyNotes()])
        }
      }}
      disabled={disabled}
    >
      Add note
    </S.Add>
  )

  const TitleBar = () => (
    <S.TitleBar edit={edit} title="Interview Notes" icon={<Comment />} />
  )

  return (
    <Card data-testid={testId}>
      {!profile?.video_interview?.notes ? (
        !edit ? (
          <PlaceholderSection />
        ) : (
          <>
            <TitleBar />
            <EditForm />
          </>
        )
      ) : (
        <>
          <TitleBar />

          {profile?.video_interview?.notes?.map((note, index) => (
            <ItemViewSection key={index} note={note} />
          ))}

          {!add ? <AddNoteSection disabled={!!editId} /> : <EditForm />}
        </>
      )}
    </Card>
  )
}

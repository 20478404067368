import * as S from '../Footer.styles'

export const WhoWeAre = () => (
  <S.LinksColumnContainer>
    <S.ItemTitle>Who we are</S.ItemTitle>

    <S.Item href="https://www.jombine.com/" rel="noopener noreferrer">
      Home
    </S.Item>

    <S.Item href="https://www.jombine.com/contact" rel="noopener noreferrer">
      Contact
    </S.Item>

    <S.Item href="https://www.jombine.com/blog" rel="noopener noreferrer">
      Blog
    </S.Item>

    <S.Item href="https://www.jombine.com/jobs" rel="noopener noreferrer">
      Jobs
    </S.Item>
  </S.LinksColumnContainer>
)

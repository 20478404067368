import { useEffect, useState } from 'react'
import api from '@api/index'
import { addToast } from '@components/atoms/Toast'
import upperFirst from 'lodash/upperFirst'
import { useUserContext } from '@hooks/user/UserContextProvider'

export const useWorkplace = (key: string) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [response, setResponse] = useState<any>(null)

  const update = async (data: any) => {
    setLoading(true)
    try {
      data = data.length > 0 ? data : [0]
      // [0] will be use in the backend as a DELETE_ALL_INDICATOR
      const res = await api.put(`/users/profile`, {
        profile: {
          [key]: data
        }
      })
      setResponse(res)
      addToast({ title: 'successfully updated' }, 'success', {})
    } finally {
      setLoading(false)
    }
  }

  return { loading, response, update }
}

export const useGetWorkplace = (endpoint: string) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [data, setData] = useState([])

  const { logOut } = useUserContext()

  const get = async () => {
    setLoading(true)
    try {
      const res = await api.get(endpoint)
      setData(
        res.data.map((item: any) => ({
          ...item,
          description: upperFirst(item.description)
        }))
      )
    } catch (error: any) {
      if (error?.response?.status === 401) logOut()
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    get()
  }, [])

  return { loading, data }
}

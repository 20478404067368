import api from '@api/index'
import { useState } from 'react'

import { INITIAL_VALUES } from '../StepManager/FormMetadata'
import { extractOnlyNumbers } from '@utils/utils'

export const useApplicationForm = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<any>(null)
  const [response, setResponse] = useState<any>(null)

  /**
   * @param {Object} data
   *
   * This is a sample of the mapping of the data:
   *
   * const formData = {
   *		email: data.email,
   *		first_name: data.name,
   *		last_name: data.lastName,
   *		city: data.city,
   *		phone: data.phone,
   *		profile_picture: data.profilePicture,
   *		career_defining_job: data.careerDefining,
   *		next_job_title: data.nextJobTitle,
   *		career_track: data.careerTrack,
   *		biggest_professional_goal: data.biggestProfessionalGoal,
   *		minimum_salary: data.minimumSalary,
   *		career_rating: data.careerRate,
   *		unique_value_proposition: data.uniqueValueProposition,
   *		outside_of_work_achievement: data.personalHighlight,
   *		biggest_project: data.biggestWorkedProject,
   *		biggest_professional_accomplishment: data.noteworthyAccomplishment,
   *		best_at: data.threeWordsBestCharacteristics,
   *		best_in: data.thingThatYouAreTheBestAt,
   *		career_search_choices_attributes: data.choices
   *		job_title: data.jobTitle
   *		industry: data.industry
   *	}
   */
  const sendApplicationForm = async (data: typeof INITIAL_VALUES) => {
    setLoading(true)
    setError(null)

    try {
      const formData = new FormData()

      formData.append('application_form[email]', data.email)
      formData.append('application_form[first_name]', data.name)
      formData.append('application_form[last_name]', data.lastName)
      formData.append('application_form[city]', data.city)
      formData.append('application_form[phone]', extractOnlyNumbers(data.phone))
      data.profilePicture &&
        formData.append(
          'application_form[profile_picture]',
          data.profilePicture
        )

      formData.append('application_form[job_title]', data.jobTitle)
      formData.append('application_form[industry]', data.industry)

      formData.append(
        'application_form[career_defining_job]',
        data.careerDefining
      )
      formData.append('application_form[next_job_title]', data.nextJobTitle)
      formData.append('application_form[career_track]', data.careerTrack)
      formData.append(
        'application_form[biggest_professional_goal]',
        data.biggestProfessionalGoal
      )
      formData.append(
        'application_form[minimum_salary]',
        extractOnlyNumbers(data.minimumSalary)
      )
      formData.append('application_form[career_rating]', data.careerRate)
      formData.append(
        'application_form[unique_value_proposition]',
        data.uniqueValueProposition
      )
      formData.append(
        'application_form[outside_of_work_achievement]',
        data.personalHighlight
      )
      formData.append(
        'application_form[biggest_project]',
        data.biggestWorkedProject
      )
      formData.append(
        'application_form[biggest_professional_accomplishment]',
        data.noteworthyAccomplishment
      )
      formData.append(
        'application_form[best_at]',
        data.threeWordsBestCharacteristics
      )

      data.choices.map((choice) => {
        formData.append(
          `application_form[career_search_choices_attributes][][choice]`,
          choice
        )
      })

      const headersConfig = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }

      const res = await api.post(`/application_forms`, formData, headersConfig)

      setResponse(res)
    } catch (error: any) {
      setError(error?.response?.data?.message)
    } finally {
      setLoading(false)
    }
  }

  return { loading, error, response, sendApplicationForm }
}

import { FormContainer } from '@components/atoms/FormContainer'
import { Form, Formik, FormikProps } from 'formik'
import { PasswordInformationProps } from './interfaces'
import * as S from '../FormCommons/Form.styles'
import { Button } from '@components/atoms/Button'
import * as Yup from 'yup'
import {
  yupPassword,
  yupPasswordConfirmation,
  yupRequired
} from '@components/commons/Form/validations'
import { Text } from '@components/atoms/Text'
import { PasswordField } from '@components/atoms/PasswordField'

interface Values {
  currentPassword: string
  password: string
  confirmNewPassword: string
}

const validationSchema = () => {
  return Yup.object().shape({
    currentPassword: yupRequired,
    password: yupPassword,
    confirmNewPassword: yupPasswordConfirmation
  })
}

export const PasswordInformation = ({
  testId = 'password-information-id',
  testSubmit,
  ...props
}: PasswordInformationProps) => {
  const loading = false
  const update = () => {}

  return (
    <FormContainer data-testid={testId} {...props} border={false}>
      <S.FormHeading>
        <Text type="BodyLB">Password Information</Text>
      </S.FormHeading>
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          currentPassword: '',
          password: '',
          confirmNewPassword: ''
        }}
        enableReinitialize
        onSubmit={(values) => {
          const method = testSubmit ? testSubmit : update
          method(values)
        }}
      >
        {(props: FormikProps<Values>) => (
          <Form>
            <S.InputRow>
              <S.InputCol>
                <PasswordField
                  label="Current password *"
                  placeholder="Enter your current password"
                  name="currentPassword"
                  testId="fieldId"
                  disabled={loading}
                  {...props}
                />
              </S.InputCol>
            </S.InputRow>
            <S.InputRow>
              <S.InputCol>
                <PasswordField
                  label="New Password *"
                  placeholder="Enter your new password"
                  name="password"
                  testId="fieldId"
                  disabled={loading}
                  {...props}
                />
              </S.InputCol>
            </S.InputRow>
            <S.InputRow>
              <S.InputCol>
                <PasswordField
                  label="Confirm New Password *"
                  placeholder="Confirm your new password"
                  name="confirmNewPassword"
                  testId="fieldId"
                  disabled={loading}
                  {...props}
                />
              </S.InputCol>
            </S.InputRow>

            <S.ButtonsWrapper>
              <Button
                loading={loading}
                disabled={!props.isValid || !props.dirty}
                type="submit"
                testId="fieldId"
              >
                Save changes
              </Button>
            </S.ButtonsWrapper>
          </Form>
        )}
      </Formik>
    </FormContainer>
  )
}

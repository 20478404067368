import * as S from './TablePagination.styles'

export const PaginationIcon = ({
  isDisabled = false,
  children,
  ...props
}: any) => {
  return (
    <S.IconContainer isDisabled={isDisabled} {...props}>
      {children}
    </S.IconContainer>
  )
}

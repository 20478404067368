import * as S from './CareerInformationSection.styles'

export interface CareerInformationSectionProps {
  testId?: string
  title?: React.ReactNode
  children?: React.ReactNode
}

export const CareerInformationSection = ({
  testId = 'career-information-section-id',
  title,
  children,
  ...props
}: CareerInformationSectionProps) => {
  return (
    <S.Container data-testid={testId} {...props}>
      {title ? (
        <>
          <S.TitleWrapper>{title}</S.TitleWrapper>
          <S.Card>{children}</S.Card>
        </>
      ) : (
        <S.CardWithBorder>{children}</S.CardWithBorder>
      )}
    </S.Container>
  )
}

import styled from 'styled-components'

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.primaryWhite};
  width: 100%;
  padding: 0px;
  border-radius: 16px;
  display: flex;
  overflow: hidden;
  padding-top: 64px;
  padding-bottom: 64px;
  flex-direction: column;
  height: 413px;

  @media (max-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    height: auto;
    padding-top: 40px;
    padding-bottom: 40px;
  }
`

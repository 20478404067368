import { SocialLinksIcon } from '@commons/Icons'
import { IconLabel } from '@components/atoms/IconLabel'
import { ProfileCardTitleBar } from '@components/molecules/ProfileCardTitleBar'
import { AiFillLinkedin } from 'react-icons/ai'
import { FaFacebook, FaInstagram, FaLink } from 'react-icons/fa'
import { CardContent } from '../../commons/Card.styles'
import * as S from '../SocialLinksProfileCard.styles'

interface SocialLink {
  label: string
  href: string
}

interface ISocialLinks {
  edit?: boolean
  onEditButtonClick?: () => void
  linkedin?: SocialLink
  facebook?: SocialLink
  website?: SocialLink
  instagram?: SocialLink
}

export const SocialLinks = ({
  edit,
  onEditButtonClick,
  linkedin,
  facebook,
  website,
  instagram
}: ISocialLinks) => (
  <>
    <ProfileCardTitleBar
      edit={edit}
      title="Social links"
      icon={<SocialLinksIcon width={24} height={24} color="#8E97A0" />}
      onEditButtonClick={onEditButtonClick}
    ></ProfileCardTitleBar>
    <CardContent>
      <S.Links>
        {linkedin && (
          <IconLabel href={linkedin.href} asLink icon={<AiFillLinkedin />}>
            {linkedin.label}
          </IconLabel>
        )}
        {facebook && (
          <IconLabel href={facebook.href} asLink icon={<FaFacebook />}>
            {facebook.label}
          </IconLabel>
        )}
        {website && (
          <IconLabel href={website.href} asLink icon={<FaLink />}>
            {website.label}
          </IconLabel>
        )}
        {instagram && (
          <IconLabel href={instagram.href} asLink icon={<FaInstagram />}>
            {instagram.label}
          </IconLabel>
        )}
      </S.Links>
    </CardContent>
  </>
)

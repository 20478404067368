import styled, { css } from 'styled-components'

export const Container = styled.button<any>`
  background-color: ${({ theme }) => theme.colors.shades.secondary500};
  width: 28px;
  height: 28px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  border: 1px solid transparent;
  outline: 0;

  ${({ animate }) =>
    animate &&
    css`
      animation: jello-horizontal 0.9s linear both;
    `}

  @keyframes jello-horizontal {
    0% {
      transform: scale3d(1, 1, 1);
    }
    30% {
      transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      transform: scale3d(1.05, 0.95, 1);
    }
    100% {
      transform: scale3d(1, 1, 1);
    }
  }
`

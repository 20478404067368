import { QuestionFormProps } from './interfaces'
import { Form, Formik, FormikProps } from 'formik'
import {
  InputRow,
  InputCol,
  ButtonsWrapper
} from '../../organisms/FormCommons/Form.styles'
import { Button } from '@components/atoms/Button'
import { TextArea } from '@components/atoms/TextArea'
import * as Yup from 'yup'
import { BackButton } from '@components/organisms/ApplicationForm/StepManager/StepManager.styles'
import { BackIcon } from '@commons/Icons'
import * as S from './QuestionForm.styles'

interface Values {
  question: string
}

const validationSchema = () => {
  return Yup.object().shape({
    question: Yup.string()
      .max(200, 'Max 200 characters')
      .required('This is a required field')
  })
}

export const QuestionForm = ({
  testId = 'question-form-id',
  question,
  initialValue,
  isfirstTime,
  isLastStep,
  isFirstStep,
  onCompleteLater,
  onBackStep,
  onSubmit,
  onCancel,
  ...props
}: QuestionFormProps) => {
  return (
    <div data-testid={testId} {...props}>
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          question: initialValue || ''
        }}
        enableReinitialize
        onSubmit={(values) => {
          if (onSubmit) onSubmit(values)
        }}
      >
        {(props: FormikProps<Values>) => (
          <Form>
            <InputRow>
              <InputCol>
                <TextArea
                  label={question}
                  placeholder="Your answer here"
                  type="text"
                  name="question"
                  testId="fieldId"
                  maxLength={200}
                  {...props}
                />
              </InputCol>
            </InputRow>
            {isfirstTime ? (
              <S.Footer>
                <div>
                  <Button
                    styling="link"
                    aria-label="Complete later"
                    onClick={() => onCompleteLater && onCompleteLater()}
                  >
                    Complete later
                  </Button>
                </div>
                <div>
                  {!isFirstStep ? (
                    <BackButton
                      type="button"
                      aria-label="Back button"
                      onClick={() => onBackStep && onBackStep()}
                    >
                      <BackIcon />
                    </BackButton>
                  ) : (
                    <div />
                  )}
                  <Button type="submit" testId="fieldId" aria-label="Next step">
                    {isLastStep ? 'Save' : 'Next'}
                  </Button>
                </div>
              </S.Footer>
            ) : (
              <ButtonsWrapper>
                <Button styling="secondary" type="button" onClick={onCancel}>
                  Cancel
                </Button>
                <Button type="submit" testId="fieldId">
                  Save
                </Button>
              </ButtonsWrapper>
            )}
          </Form>
        )}
      </Formik>
    </div>
  )
}

import { yupTextArea } from '@commons/Form/validations'
import { TextArea } from '@components/atoms/TextArea'
import * as Yup from 'yup'
import * as S from '../commons/commons.styles'

interface Values {
  threeWordsBestCharacteristics: string
}
export const initialValues = {
  threeWordsBestCharacteristics: ''
}

const idContext = `candidate-application-form-8`

export const validationSchema = () => {
  return Yup.object().shape({
    threeWordsBestCharacteristics: yupTextArea.max(100, 'Max 100 characters')
  })
}

export const Step8 = ({ testId = 'application-Step8-test-id', props }: any) => {
  return (
    <S.FormWrapper data-testid={testId}>
      <S.Body>
        <S.InputWrapper>
          <TextArea
            label="Describe the thing that you are best at in just 3 words or less?"
            id={`three-words-${idContext}`}
            placeholder="Your answer here..."
            name="threeWordsBestCharacteristics"
            maxLength={100}
            data-testid="input"
            {...props}
          />
        </S.InputWrapper>
      </S.Body>
    </S.FormWrapper>
  )
}

import { ClickAwayListener } from '@mui/material'
import { useField } from 'formik'
import { useRef, useState, useEffect } from 'react'
import * as S from './InputDate.styles'

export const InputDate = ({
  testId = 'input-date-id',
  label,
  startIcon,
  endIcon,
  initialValue,
  hasError = false,
  maxDate,
  ...props
}: any) => {
  const [field, meta, helpers] = useField(props)
  const [showCalendar, setShowCalendar] = useState<boolean>(false)
  const [value, setValue] = useState(initialValue || new Date())
  const [inputFieldYPosition, setInputFieldYPosition] = useState<
    number | undefined
  >(0)
  const inputFieldRef = useRef<HTMLInputElement>(null)

  const onChange = (value) => {
    setValue(value)
    setShowCalendar(false)
    helpers.setValue(value)
  }

  const handleScroll = () => {
    const inputFieldY = inputFieldRef.current?.getBoundingClientRect().y
    setInputFieldYPosition(inputFieldY)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    handleScroll()
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <ClickAwayListener onClickAway={() => setShowCalendar(false)}>
      <div>
        {label && <S.Label>{label}</S.Label>}
        <S.InputWrapper>
          {startIcon && <S.IconWrapper startIcon>{startIcon}</S.IconWrapper>}
          <S.Input
            data-testid={testId}
            startIcon={startIcon}
            onFocus={() => setShowCalendar(true)}
            hasError={hasError || (meta.touched && meta.error)}
            ref={inputFieldRef}
            {...field}
            {...props}
            value={value.toLocaleDateString('en-US')}
            aria-label={label}
          />

          {showCalendar && (
            <S.StyledCalendarWrapper
              data-testid={`${testId}-calendar-wrapper`}
              style={{ top: `${50 + (inputFieldYPosition || 0)}px` }}
            >
              <S.StyledCalendar
                onChange={onChange}
                value={value}
                maxDate={maxDate}
              />
            </S.StyledCalendarWrapper>
          )}
          {endIcon && (
            <S.IconWrapper startIcon={false}>{endIcon}</S.IconWrapper>
          )}
        </S.InputWrapper>
        {meta.touched && meta.error ? (
          <S.ErrorMessage>{meta.error}</S.ErrorMessage>
        ) : null}
      </div>
    </ClickAwayListener>
  )
}

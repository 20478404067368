import BlueLogo from '@assets/logo-blue.svg'
import DefaultLogo from '@assets/logo.svg'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

export type Size = 'normal' | 'large'

const ROOT_URL = 'https://www.jombine.com'

export interface LogoProps extends React.HTMLAttributes<HTMLDivElement> {
  testId?: string
  size?: Size
  blue?: boolean
}

const MAP_BY_SIZE = {
  normal: {
    width: 118,
    height: 28
  },
  large: {
    width: 154,
    height: 37
  }
}

const LogoImage = styled.img<{
  size: Size
}>`
  width: ${({ size }) => MAP_BY_SIZE[size]}px;
  cursor: pointer;
`

export const Logo = ({
  testId = 'logo-test-id',
  size = 'normal',
  blue
}: LogoProps) => {
  const _navigateToHome = () => {
    window.location.replace(ROOT_URL)
  }

  return (
    <LogoImage
      data-testid={testId}
      size={size}
      src={blue ? BlueLogo : DefaultLogo}
      alt="Jombine's logo"
      onClick={_navigateToHome}
    />
  )
}

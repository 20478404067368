import { SearchIcon, SearchIconPure } from '@commons/Icons'
import { Pill } from '@components/atoms/Pill'
import { Text } from '@components/atoms/Text'
import { ProfileCardPlaceholder } from '@components/molecules/ProfileCardPlaceholder'
import { ProfileCardTitleBar } from '@components/molecules/ProfileCardTitleBar'
import { JobStatusForm } from '@components/organisms/JobStatusForm'
import {
  Card,
  CardContent
} from '@components/organisms/ProfileCards/commons/Card.styles'
import { useProfileContext } from '@hooks/profile/ProfileContextProvider'
import { useState } from 'react'
import {
  EMPLOYEMENT_STATUS_OPTIONS,
  JOB_SEARCH_OPTIONS,
  REALOCATE_REGION_OPTIONS,
  RELOCATION_OPTIONS
} from '../../JobStatusForm/JobStatus.options'
import * as S from './JobStatusProfileCard.styles'
import { JobStatusProfileCardProps } from './interfaces'

const getOptionLabel = (options, value) => {
  const option = options.find((option) => option.value === value)
  return option ? option.label : ''
}

export const JobStatusProfileCard = ({
  testId = 'job-status-profile-card-id'
}: JobStatusProfileCardProps) => {
  const [edit, setEdit] = useState(false)
  const { profile, fetchProfile } = useProfileContext()

  const update = () => {
    fetchProfile()
    setEdit(false)
  }

  return (
    <Card data-testid={testId}>
      {edit ? (
        <>
          <ProfileCardTitleBar
            edit={edit}
            title="Job status"
            icon={<SearchIconPure />}
            onEditButtonClick={() => setEdit(true)}
          />
          <CardContent>
            <JobStatusForm onCancel={() => setEdit(false)} onSucess={update} />
          </CardContent>
        </>
      ) : !profile?.job_status ? (
        <CardContent>
          <ProfileCardPlaceholder
            icon={<SearchIcon />}
            title="Job status"
            subtitle="You haven’t added your job status yet"
            callToAction="Add job status"
            onClick={() => setEdit(true)}
          />
        </CardContent>
      ) : (
        <>
          <ProfileCardTitleBar
            edit={edit}
            title="Job status"
            icon={<SearchIconPure />}
            onEditButtonClick={() => setEdit(true)}
          />
          <CardContent>
            <S.Status>
              {profile?.job_status.job_search && (
                <S.Item>
                  <Text type="Label">Job search</Text>
                  <Pill>
                    {getOptionLabel(
                      JOB_SEARCH_OPTIONS,
                      profile?.job_status.job_search
                    )}
                  </Pill>
                </S.Item>
              )}
              {profile?.job_status.willing_to_relocate !== undefined && (
                <S.Item>
                  <Text type="Label">Relocation</Text>
                  <Pill>
                    {getOptionLabel(
                      RELOCATION_OPTIONS,
                      profile?.job_status.willing_to_relocate
                    )}
                  </Pill>
                  {profile?.job_status.willing_to_relocate && (
                    <Pill>
                      {getOptionLabel(
                        REALOCATE_REGION_OPTIONS,
                        profile?.job_status.where_to_relocate
                      )}
                    </Pill>
                  )}
                </S.Item>
              )}
              {profile?.job_status.employment_status && (
                <S.Item>
                  <Text type="Label">Employment status</Text>
                  <Pill>
                    {getOptionLabel(
                      EMPLOYEMENT_STATUS_OPTIONS,
                      profile?.job_status.employment_status
                    )}
                  </Pill>
                </S.Item>
              )}
              {profile?.job_status.job_title && (
                <S.Item>
                  <Text type="Label">Job Title</Text>
                  <Pill>{profile?.job_status?.job_title}</Pill>
                </S.Item>
              )}
            </S.Status>
          </CardContent>
        </>
      )}
    </Card>
  )
}

export const JobStatusCard = ({ profile }: any) => {
  return (
    <>
      {!profile?.job_status ? (
        <CardContent>
          <ProfileCardPlaceholder
            icon={<SearchIcon />}
            title="Job status"
            subtitle="haven’t added your job status yet"
          />
        </CardContent>
      ) : (
        <>
          <ProfileCardTitleBar title="Job status" icon={<SearchIconPure />} />
          <CardContent>
            <S.Status>
              {profile?.job_status.job_search && (
                <S.Item>
                  <Text type="Label">Job search</Text>
                  <Pill>
                    {getOptionLabel(
                      JOB_SEARCH_OPTIONS,
                      profile?.job_status.job_search
                    )}
                  </Pill>
                </S.Item>
              )}
              {profile?.job_status.willing_to_relocate !== undefined && (
                <S.Item>
                  <Text type="Label">Relocation</Text>
                  <Pill>
                    {getOptionLabel(
                      RELOCATION_OPTIONS,
                      profile?.job_status.willing_to_relocate
                    )}
                  </Pill>
                  {profile?.job_status.willing_to_relocate && (
                    <Pill>
                      {getOptionLabel(
                        REALOCATE_REGION_OPTIONS,
                        profile?.job_status.where_to_relocate
                      )}
                    </Pill>
                  )}
                </S.Item>
              )}
              {profile?.job_status.employment_status && (
                <S.Item>
                  <Text type="Label">Employment status</Text>
                  <Pill>
                    {getOptionLabel(
                      EMPLOYEMENT_STATUS_OPTIONS,
                      profile?.job_status.employment_status
                    )}
                  </Pill>
                </S.Item>
              )}
              {profile?.job_status.job_title && (
                <S.Item>
                  <Text type="Label">Job title</Text>
                  <Pill>{profile?.job_status.job_title}</Pill>
                </S.Item>
              )}
            </S.Status>
          </CardContent>
        </>
      )}
    </>
  )
}

import { Button } from '@components/atoms/Button'
import { FormContainer } from '@components/atoms/FormContainer'
import { InputText } from '@components/atoms/InputText'
import { Text } from '@components/atoms/Text'
import { yupFirstName, yupLastName } from '@components/commons/Form/validations'
import { Form, Formik, FormikProps } from 'formik'
import * as Yup from 'yup'
import { useSettings } from '../../../hooks/candidate/useSettings'
import { useUserContext } from '../../../hooks/user/UserContextProvider'
import * as S from '../FormCommons/Form.styles'
import { PersonalInformationProps } from './interfaces'

interface Values {
  firstName: string
  lastName: string
  email: string
}

const validationSchema = () => {
  return Yup.object().shape({
    firstName: yupFirstName,
    lastName: yupLastName
  })
}

export const PersonalInformation = ({
  testId = 'personal-information-id',
  testSubmit,
  ...props
}: PersonalInformationProps) => {
  const { loading } = useSettings()
  const update = () => {}
  const { user } = useUserContext()

  return (
    <FormContainer data-testid={testId} {...props} border={false}>
      <S.FormHeading>
        <Text type="BodyLB">Personal Information</Text>
      </S.FormHeading>
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          firstName: user?.first_name || '',
          lastName: user?.last_name || '',
          email: user?.email || ''
        }}
        enableReinitialize
        onSubmit={(values) => {
          const method = testSubmit ? testSubmit : update
          method(values)
        }}
      >
        {(props: FormikProps<Values>) => (
          <Form>
            <S.InputRow>
              <S.InputCol>
                <InputText
                  label={'First name *'}
                  placeholder="Your name"
                  type="text"
                  name="firstName"
                  testId="fieldId"
                  disabled={loading}
                  {...props}
                />
              </S.InputCol>
              <S.InputCol>
                <InputText
                  label={'Last name *'}
                  placeholder="Your last name"
                  type="text"
                  name="lastName"
                  testId="fieldId"
                  disabled={loading}
                  {...props}
                />
              </S.InputCol>
            </S.InputRow>
            <S.InputRow>
              <S.InputCol>
                <InputText
                  label={'Email address *'}
                  placeholder="email@example.com"
                  type="email"
                  name="email"
                  testId="fieldId"
                  disabled
                  {...props}
                />
                <S.InputMessage>
                  <Text type="BodySMR">
                    To change you email, please contact an admin user
                  </Text>
                </S.InputMessage>
              </S.InputCol>
            </S.InputRow>
            <S.ButtonsWrapper>
              <Button
                loading={loading}
                disabled={!props.isValid || !props.dirty}
                type="submit"
                testId="fieldId"
              >
                Save changes
              </Button>
            </S.ButtonsWrapper>
          </Form>
        )}
      </Formik>
    </FormContainer>
  )
}

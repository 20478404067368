import { Logo } from '@components/atoms/Logo'
import * as S from './HeaderWithCenteredLogo.styles'
import { HeaderWithCenteredLogoProps } from './interfaces'

export const HeaderWithCenteredLogo = ({
  testId = 'header-with-centered-logo-id',
  ...props
}: HeaderWithCenteredLogoProps) => {
  return (
    <S.Container data-testid={testId} {...props}>
      <Logo />
    </S.Container>
  )
}

import { GettingToKnowMeProfileCardProps } from './interfaces'
import { ProfileCardPlaceholder } from '@components/molecules/ProfileCardPlaceholder'
import { GettingToKnowMeIcon, SmileIcon } from '@commons/Icons'
import { Card, CardContent } from '../commons/Card.styles'
import { useProfileContext } from '@hooks/profile/ProfileContextProvider'
import { useEffect, useState } from 'react'
import { GettingToKnowMe } from '@components/organisms/GettingToKnowMe'
import { ProfileCardTitleBar } from '@components/molecules/ProfileCardTitleBar'
import { get } from 'lodash'
import { useGettingToKnowMe } from '@hooks/aboutMe/useGettingToKnowMe'
import { Question } from './components/Question'

export const GettingToKnowMeProfileCard = ({
  testId = 'getting-to-know-me-profile-card-id',
  ...props
}: GettingToKnowMeProfileCardProps) => {
  const { profile, fetchProfile } = useProfileContext()
  const {
    loading,
    response,
    update: updateGettingToKnowMe
  } = useGettingToKnowMe()

  const [edit, setEdit] = useState(false)
  const [editId, setEditId] = useState<number | null>(null)

  const update = () => {
    fetchProfile()
    setEdit(false)
    setEditId(null)
  }

  const changeAnswer = (newId: number, newAnswer: string) => {
    const newAnswers = profile?.getting_to_know_me.map(
      ({ id, answer, getting_to_know_me_id }) => ({
        getting_to_know_me_id: getting_to_know_me_id,
        description: id === newId ? newAnswer : answer,
        id
      })
    )

    updateGettingToKnowMe(newAnswers)
  }

  useEffect(() => {
    if (response?.status === 200) update()
  }, [response])

  return (
    <Card data-testid={testId} {...props}>
      {!profile?.getting_to_know_me ? (
        !edit ? (
          <CardContent>
            <ProfileCardPlaceholder
              icon={<SmileIcon />}
              title="Getting to know me"
              subtitle="Show off your top hobbies and interests to potential employers."
              callToAction="Answer questions"
              onClick={() => setEdit(true)}
            />
          </CardContent>
        ) : (
          <>
            <ProfileCardTitleBar
              edit={edit}
              title="Getting to know me"
              icon={
                <GettingToKnowMeIcon width={24} height={24} color="#8E97A0" />
              }
            />
            <CardContent>
              <GettingToKnowMe
                onCancel={() => setEdit(false)}
                onSuccess={update}
              />
            </CardContent>
          </>
        )
      ) : (
        <Question
          questions={get(profile, 'getting_to_know_me', [])}
          edit={edit}
          editId={editId}
          onCancel={() => setEdit(false)}
          onSubmit={(id, question) => changeAnswer(id, question)}
          editButton={(id) => {
            if (!edit) {
              setEditId(id)
              setEdit(true)
            }
          }}
        />
      )}
    </Card>
  )
}

export const GettingToKnowMeCard = ({ profile }: any) => {
  return (
    <>
      {!profile?.getting_to_know_me ? (
        <CardContent>
          <ProfileCardPlaceholder
            icon={<SmileIcon />}
            title="Getting to know me"
            subtitle="Show off your top hobbies and interests to potential employers."
          />
        </CardContent>
      ) : (
        <Question questions={get(profile, 'getting_to_know_me', [])} />
      )}
    </>
  )
}

import { Button } from '@components/atoms/Button'
import { FormContainer } from '@components/atoms/FormContainer'
import { Select } from '@components/atoms/Select'
import { yupRequired } from '@components/commons/Form/validations'
import { useUpdateJobStatus } from '@hooks/aboutMe/useJobStatus'
import { useProfileContext } from '@hooks/profile/ProfileContextProvider'
import { Form, Formik, FormikProps } from 'formik'
import get from 'lodash/get'
import { useEffect } from 'react'
import * as Yup from 'yup'
import { InputText } from '../../atoms/InputText'
import * as S from '../FormCommons/Form.styles'
import {
  EMPLOYEMENT_STATUS_OPTIONS,
  JOB_SEARCH_OPTIONS,
  REALOCATE_REGION_OPTIONS,
  RELOCATION_OPTIONS
} from './JobStatus.options'
import { JobStatusFormProps } from './interfaces'

interface Values {
  jobSearch: string
  relocation: boolean
  relocateRegion: string
  employementStatus: string
  jobTitle: string
}

const DEFAULT_REALOCATE_REGION = REALOCATE_REGION_OPTIONS[0].value

const validationSchema = () => {
  return Yup.object().shape({
    jobSearch: yupRequired,
    relocation: yupRequired,
    relocateRegion: Yup.string().when('relocation', {
      is: (val: boolean) => val === RELOCATION_OPTIONS[0].value,
      then: Yup.string().required('This field is required')
    }),
    employementStatus: yupRequired,
    jobTitle: yupRequired
  })
}

export const JobStatusForm = ({
  testId = 'job-status-id',
  testSubmit,
  isFirstTime,
  onSucess,
  onCancel,
  ...props
}: JobStatusFormProps) => {
  const { loading, response, update } = useUpdateJobStatus()
  const { profile } = useProfileContext()

  useEffect(() => {
    if (response?.status === 200) onSucess()
  }, [response])

  return (
    <FormContainer data-testid={testId} {...props}>
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          jobSearch: isFirstTime
            ? ''
            : get(profile, 'job_status.job_search', ''),
          relocation: isFirstTime
            ? ''
            : get(profile, 'job_status.willing_to_relocate', ''),
          relocateRegion: isFirstTime
            ? DEFAULT_REALOCATE_REGION
            : get(
                profile,
                'job_status.where_to_relocate',
                DEFAULT_REALOCATE_REGION
              ),
          employementStatus: isFirstTime
            ? ''
            : get(profile, 'job_status.employment_status', ''),
          jobTitle: isFirstTime ? '' : get(profile, 'job_status.job_title', '')
        }}
        enableReinitialize
        onSubmit={(values) => {
          const method = testSubmit ? testSubmit : update
          method(values)
        }}
      >
        {(props: FormikProps<Values>) => (
          <Form>
            <S.InputRow>
              <S.InputCol>
                <Select
                  label="Job search *"
                  placeholder="Select an option"
                  name="jobSearch"
                  id="jobSearch"
                  testId="fieldId"
                  options={JOB_SEARCH_OPTIONS.map((it) => ({
                    ...it
                  }))}
                  disabled={loading}
                  {...props}
                />
              </S.InputCol>
            </S.InputRow>
            <S.InputRow>
              <S.InputCol>
                <Select
                  label="Are you willing to relocate *"
                  placeholder="Select an option"
                  name="relocation"
                  id="relocation"
                  testId="fieldId"
                  options={RELOCATION_OPTIONS.map((it) => ({
                    ...it
                  }))}
                  disabled={loading}
                  {...props}
                />
              </S.InputCol>
            </S.InputRow>
            {props.values.relocation === RELOCATION_OPTIONS[0].value && (
              <S.InputRow>
                <S.InputCol>
                  <Select
                    label="To which regions could you relocate? *"
                    placeholder="Select an option"
                    name="relocateRegion"
                    id="relocateRegion"
                    testId="fieldId"
                    options={REALOCATE_REGION_OPTIONS.map((it) => ({
                      ...it
                    }))}
                    disabled={loading}
                    {...props}
                  />
                </S.InputCol>
              </S.InputRow>
            )}
            <S.InputRow>
              <S.InputCol>
                <Select
                  label="Employment status *"
                  placeholder="Select an option"
                  name="employementStatus"
                  id="employementStatus"
                  testId="fieldId"
                  options={EMPLOYEMENT_STATUS_OPTIONS.map((it) => ({
                    ...it
                  }))}
                  disabled={loading}
                  {...props}
                />
              </S.InputCol>
            </S.InputRow>
            <S.InputRow>
              <S.InputCol>
                <InputText
                  label="What job title do you currently have or best describes your most recent experience? *"
                  id="jobTitle"
                  placeholder="Enter your job title"
                  name="jobTitle"
                  data-testid="jobTitle"
                  {...props}
                />
              </S.InputCol>
            </S.InputRow>
            <S.ButtonsWrapper>
              <Button styling="secondary" type="button" onClick={onCancel}>
                Cancel
              </Button>
              <Button
                loading={loading}
                type="submit"
                testId="submitId"
                disabled={!props.isValid}
              >
                Save
              </Button>
            </S.ButtonsWrapper>
          </Form>
        )}
      </Formik>
    </FormContainer>
  )
}

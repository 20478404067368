import NumberFormat from 'react-number-format'
import styled from 'styled-components'
import { InputCss } from '../InputText/InputText.styles'
import { InputNumberProps } from './interfaces'

export {
  ErrorMessage,
  InputWrapper,
  Label
} from '../InputText/InputText.styles'

export const Input = styled(NumberFormat)<InputNumberProps>`
  ${InputCss}
`

const USER_SIGNED_UP_EVENT_NAME = 'user_signed_up'
const USER_SIGNED_IN_EVENT_NAME = 'user_signed_in'

const extractUserData = (userData) => {
  if (!userData) {
    return { anonymous: true }
  }

  return userData
}

export const fireUserSignedUpEvent = (userData) => {
  window.analytics.track(USER_SIGNED_UP_EVENT_NAME, {
    ...extractUserData(userData)
  })
}

export const fireUserSignedInEvent = (userData) => {
  window.analytics.track(USER_SIGNED_IN_EVENT_NAME, {
    ...extractUserData(userData)
  })
}

const Analytics = () => ({
  fireUserSignedUpEvent,
  fireUserSignedInEvent
})

export default Analytics

import { ProfileCardPlaceholder } from '@components/molecules/ProfileCardPlaceholder'
import { CommentIcon, ProfileAboutMeIcon } from '@commons/Icons'
import { AboutMeProfileCardProps } from './interfaces'
import * as S from './AboutMeProfileCard.styles'
import { useState } from 'react'
import { useProfileContext } from '@hooks/profile/ProfileContextProvider'
import { AboutMeForm } from '@components/organisms/AboutMeForm'
import { ProfileCardTitleBar } from '@components/molecules/ProfileCardTitleBar'
import { Text } from '@components/atoms/Text'

export const AboutMeProfileCard = ({
  testId = 'about-me-profile-card-id'
}: AboutMeProfileCardProps) => {
  const [edit, setEdit] = useState(false)
  const { profile, fetchProfile } = useProfileContext()

  const update = () => {
    fetchProfile()
    setEdit(false)
  }

  return (
    <S.Card data-testid={testId}>
      {edit ? (
        <>
          <ProfileCardTitleBar
            edit={edit}
            title="About me"
            icon={<ProfileAboutMeIcon width={24} height={24} color="#8E97A0" />}
            onEditButtonClick={() => setEdit(true)}
          ></ProfileCardTitleBar>
          <S.CardContent>
            <AboutMeForm
              onCancel={() => setEdit(false)}
              onSucess={update}
              isFirstTime={!profile?.about_me}
            />
          </S.CardContent>
        </>
      ) : !profile?.about_me ? (
        <S.CardContent>
          <ProfileCardPlaceholder
            icon={<CommentIcon />}
            title="About me"
            subtitle="Include a paragraph to interested employers that describes yourself"
            callToAction="Tell us about yourself"
            onClick={() => setEdit(true)}
          />
        </S.CardContent>
      ) : (
        <>
          <ProfileCardTitleBar
            title="About me"
            icon={<ProfileAboutMeIcon width={24} height={24} color="#8E97A0" />}
            edit={edit}
            onEditButtonClick={() => setEdit(true)}
          ></ProfileCardTitleBar>
          <S.CardContent>
            <Text type="Text">{profile?.about_me}</Text>
          </S.CardContent>
        </>
      )}
    </S.Card>
  )
}

export const AboutMeSection = ({ profile }) => {
  const Placeholder = profile?.isPublic ? null : (
    <S.CardContent>
      <ProfileCardPlaceholder
        icon={<CommentIcon />}
        title="About me"
        subtitle="Include a paragraph to interested employers that describes yourself"
      />
    </S.CardContent>
  )

  return (
    <>
      {!profile?.about_me ? (
        Placeholder
      ) : (
        <>
          <ProfileCardTitleBar
            title="About me"
            icon={<ProfileAboutMeIcon width={24} height={24} color="#8E97A0" />}
          ></ProfileCardTitleBar>
          <S.CardContent>
            <Text breakText type="BodySMR">
              {profile?.about_me}
            </Text>
          </S.CardContent>
        </>
      )}
    </>
  )
}

import {
  yupRequired,
  yupTextArea,
  yupTextAreaNotRequired
} from '@commons/Form/validations'
import { ArrowDownSmall, Badge, ChartArrowDown, Comment } from '@commons/Icons'
import { Text } from '@components/atoms/Text'
import { TextArea } from '@components/atoms/TextArea'
import { addToast } from '@components/atoms/Toast'
import { Footer } from '@components/organisms/Footer'
import { HeaderWithCenteredLogo } from '@components/organisms/HeaderWithCenteredLogo'
import { JoinRevolutionBanner } from '@components/organisms/JoinRevolutionBanner'
import { usePublicProfile } from '@hooks/profile/usePublicProfile'
import { useReferences } from '@hooks/recruiter/useReferences'
import { Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import {
  EvaluationSection,
  options as EvaluationOptions
} from './EvaluationSection'
import { GeneralInformationSection } from './GeneralInfoSection'
import { ReferenceFormScreenProps } from './interfaces'
import * as S from './ReferenceFormScreen.styles'

export const validationSchema = () => {
  return Yup.object().shape({
    fullName: yupRequired,
    relationship: yupRequired,
    strengths: yupTextArea,
    weaknesses: yupTextArea,
    additionalComments: yupTextAreaNotRequired
  })
}

const StrenghtsSection = () => (
  <S.CareerInformationSection
    title={
      <>
        <Badge /> <h1>Strengths</h1>
      </>
    }
  >
    <TextArea name="strengths" />
  </S.CareerInformationSection>
)

const WeaknessesSection = () => (
  <S.CareerInformationSection
    title={
      <>
        <ChartArrowDown /> <h1>Weaknesses</h1>
      </>
    }
  >
    <TextArea name="weaknesses" />
  </S.CareerInformationSection>
)

const AdditionalCommentsSection = () => (
  <S.CareerInformationSection
    title={
      <>
        <Comment /> <h1>Additional comments (Optional)</h1>
      </>
    }
  >
    <TextArea name="additionalComments" />
  </S.CareerInformationSection>
)

export const ReferenceFormScreen = ({
  testId = 'reference-form-screen-id',
  ...props
}: ReferenceFormScreenProps) => {
  const [evaluations, setEvaluations] = useState<any>({})
  const [user, setUser] = useState<any>()
  const { sendReferenceReview } = useReferences()
  const { id, secret_id } = useParams()
  const navigate = useNavigate()

  const { fetchPublicProfile } = usePublicProfile()

  useEffect(() => {
    const fetch = async () => {
      const user = await fetchPublicProfile({ candidateId: secret_id })

      const NOT_ALLOWED_TO_RECEIVE_REFERENCES =
        user && !user?.can_submit_references
      if (NOT_ALLOWED_TO_RECEIVE_REFERENCES) {
        navigate('/not-found')
      }

      setUser(user)
    }

    fetch()
  }, [])

  return (
    <S.Container data-testid={testId} {...props}>
      <HeaderWithCenteredLogo />

      <S.Main>
        <S.BodyWrapper>
          <S.Header>
            <S.UserPicture
              name={user?.full_name}
              image={user?.profile_picture_url}
            />
            <Text type="GlobalH4" as="h1">
              Leave your professional review!
            </Text>
            <Text type="BodyMR">
              This review will help <b>{user?.full_name}</b> to gain credibility
              in their CV when future employers review it.
            </Text>
          </S.Header>

          <Formik
            isInitialValid={false}
            validateOnChange
            validationSchema={validationSchema}
            initialValues={{
              fullName: '',
              relationship: '',
              strengths: '',
              weaknesses: '',
              additionalComments: ''
            }}
            onSubmit={async (values) => {
              const IS_EVALUATION_VALID =
                Object.keys(evaluations).length === EvaluationOptions.length

              if (!IS_EVALUATION_VALID) {
                return addToast(
                  {
                    title: 'Evaluations section',
                    subtitle: 'You have to rate all the values'
                  },
                  'error',
                  {}
                )
              }

              await sendReferenceReview({
                id,
                data: {
                  full_name: values.fullName,
                  strengths: values.strengths,
                  weaknesses: values.weaknesses,
                  additional_comments: values.additionalComments,
                  relationship: values.relationship,
                  leadership: evaluations.leadership,
                  execution: evaluations.execution,
                  customer_service: evaluations.customer_service,
                  work_ethic: evaluations.work_ethic,
                  initiative: evaluations.initiative,
                  creativity: evaluations.creativity,
                  resilience: evaluations.resilience,
                  team_relationships: evaluations.team_relationships,
                  work_quality: evaluations.work_quality
                }
              })

              navigate(`/candidate/review/sent/${user?.full_name}`)
            }}
          >
            <Form>
              <GeneralInformationSection />
              <EvaluationSection setEvaluations={setEvaluations} />
              <StrenghtsSection />
              <WeaknessesSection />
              <AdditionalCommentsSection />

              <S.SubmitWrapper>
                <S.Button type="submit">Send review</S.Button>
              </S.SubmitWrapper>
            </Form>
          </Formik>
        </S.BodyWrapper>

        <a href="#footer">
          <S.FloatingButton aria-label="Go to bottom">
            <ArrowDownSmall />
          </S.FloatingButton>
        </a>
      </S.Main>

      <JoinRevolutionBanner />
      <Footer id="footer" hideLinksBlock />
    </S.Container>
  )
}

import React from 'react'
import styled from 'styled-components'
import { grid } from '@styles/grid'

export interface FlexContainerProps
  extends React.HTMLAttributes<HTMLDivElement> {
  gutter?: number
}

const defaultProps: Partial<FlexContainerProps> = {
  gutter: grid.gutter.regular
}

const StyledFlexContainer = styled.div<FlexContainerProps>`
  width: 100%;
  max-width: 100%;

  padding-right: ${(props) => props.gutter}px;
  padding-left: ${(props) => props.gutter}px;
  margin-left: auto;
  margin-right: auto;
`

export const FlexContainer = (props: FlexContainerProps) => (
  <StyledFlexContainer {...props} />
)
FlexContainer.defaultProps = defaultProps

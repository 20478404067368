import api from '@api/index'
import { addToast } from '@components/atoms/Toast'
import { useUserContext } from '@hooks/user/UserContextProvider'
import React, { useContext, useEffect, useState } from 'react'
import {
  ApplicationFormData,
  CandidateInfoType,
  CandidateResponse,
  transformData
} from './interfaces'

export type State = {
  loading: boolean
  applications: ApplicationFormData
  candidates: CandidateResponse
  myCandidates: CandidateResponse
  archivedCandidates: CandidateResponse
  getAllApplicationForms: (query?: string) => Promise<any>
  getApplicationForm: ({ id }) => Promise<any>
  claimCandidate: ({ id }) => Promise<any>
  getAllCandidates: () => Promise<any>
  getMyCandidates: () => Promise<any>
  getArchivedCandidates: () => Promise<any>
  fetchCandidate: ({ id }) => Promise<any>
}

const RecruiterContext = React.createContext<State>({} as State)

const DEFAULT_QUERY = '?search=&page=1&order_by=&order_direction=asc'

export const RecruiterContextProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [applications, setApplications] = useState<any>({})
  const [candidates, setAllCandidates] = useState<any>({})
  const [myCandidates, setMyCandidates] = useState<any>({})
  const [archivedCandidates, setArchivedCandidates] = useState<any>({})

  const { logOut } = useUserContext()

  useEffect(() => {
    getAllApplicationForms()
    getAllCandidates()
    getMyCandidates()
    getArchivedCandidates()
  }, [])

  const getAllApplicationForms = async (query?: string) => {
    setLoading(true)
    try {
      // FIXME: this is not the correct URL, PLEASE CHANGE IT.
      const res = await api.get<CandidateResponse>(
        `/application_forms${query || DEFAULT_QUERY}`
      )
      const transformedApplications = transformData(res.data.data)
      setApplications({
        data: transformedApplications,
        pagination: res.data.pagination
      })
    } catch (error: any) {
      if (error?.response?.status === 401) logOut()
    } finally {
      setLoading(false)
    }
  }

  const getApplicationForm = async ({ id }) => {
    setLoading(true)
    try {
      const res = await api.get<CandidateInfoType>(`/application_forms/${id}`)
      return res.data
    } catch (error: any) {
      if (error?.response?.status === 401) logOut()
    } finally {
      setLoading(false)
    }
  }

  const claimCandidate = async ({ id }) => {
    setLoading(true)
    try {
      const result = await api.post(`/application_forms/${id}/claim`)
      addToast(
        {
          title: 'Candidate was Claimed!'
        },
        'success',
        {}
      )
      return result
    } catch (error: any) {
      if (error?.response?.status === 401) logOut()
    } finally {
      setLoading(false)
    }
  }

  const getAllCandidates = async (query?: string) => {
    setLoading(true)
    try {
      const res = await api.get<CandidateResponse>(
        `/candidates${query || DEFAULT_QUERY}`
      )
      setAllCandidates(res.data)
    } catch (error: any) {
      if (error?.response?.status === 401) logOut()
    } finally {
      setLoading(false)
    }
  }

  const getMyCandidates = async (query?: string) => {
    setLoading(true)
    try {
      const res = await api.get<CandidateResponse>(
        `/candidates${query || DEFAULT_QUERY}&recruited=true`
      )
      setMyCandidates(res.data)
    } catch (error: any) {
      if (error?.response?.status === 401) logOut()
    } finally {
      setLoading(false)
    }
  }

  const getArchivedCandidates = async (query?: string) => {
    setLoading(true)
    try {
      const res = await api.get<CandidateResponse>(
        `/candidates${query || DEFAULT_QUERY}&archived=true`
      )
      setArchivedCandidates(res.data)
    } catch (error: any) {
      if (error?.response?.status === 401) logOut()
    } finally {
      setLoading(false)
    }
  }

  const fetchCandidate = async ({ id }) => {
    setLoading(true)
    try {
      const res = await api.get<CandidateInfoType[]>(`/candidates/${id}`)
      return res.data
    } catch (error: any) {
      if (error?.response?.status === 401) logOut()
    } finally {
      setLoading(false)
    }
  }

  const value = {
    loading,
    applications,
    candidates,
    myCandidates,
    archivedCandidates,
    getAllApplicationForms,
    getApplicationForm,
    claimCandidate,
    getAllCandidates,
    getMyCandidates,
    getArchivedCandidates,
    fetchCandidate
  }

  return (
    <RecruiterContext.Provider value={value}>
      {children}
    </RecruiterContext.Provider>
  )
}

export const useRecruiterContext = () => {
  const context = useContext(RecruiterContext)
  if (typeof context === 'undefined') {
    throw new Error('useProfile must be used within a RecruiterContext')
  }
  return context
}

import { AngleSmallDownIcon } from '@commons/Icons'
import * as S from './Avatar.styles'
import { AvatarProps } from './interfaces'
import { NavbarDropdownMenu } from '../DropdownMenu/NavbarDropdownMenu'
import { useState } from 'react'

export const Avatar = ({
  testId = 'user-avatar-test-id',
  userName,
  email,
  userImage,
  ...rest
}: AvatarProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <S.UserInfoWrapper
        data-testid={testId}
        onClick={handleClick}
        aria-controls={open ? 'navbar-dropdown' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        {...rest}
      >
        <S.UserNameAndEmailWrapper>
          <S.UserName>{userName}</S.UserName>
          <S.UserEmail>{email}</S.UserEmail>
        </S.UserNameAndEmailWrapper>
        <S.StyledProfilePicture
          size="small"
          src={userImage}
        ></S.StyledProfilePicture>

        <S.AngleIconWrapper>
          <AngleSmallDownIcon />
        </S.AngleIconWrapper>
      </S.UserInfoWrapper>
      <NavbarDropdownMenu
        anchorEl={anchorEl}
        id="navbar-dropdown"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      ></NavbarDropdownMenu>
    </>
  )
}

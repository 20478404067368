import { ProfileCard } from '@components/atoms/ProfileCard'
import styled from 'styled-components'

export const Card = styled(ProfileCard)`
  display: block;
  flex-direction: column;
  padding-top: 0;
  height: auto;
  padding: 0;
`

export const CardContent = styled.div`
  padding: 32px 40px;
  min-height: 126px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 24px;

    .row {
      padding-left: 40px;
      padding-right: 40px;
    }

    > p {
      padding: 0;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
    > p {
      font-size: 14px;
    }
  }
`

import { InputText } from '@components/atoms/InputText'
import { Text } from '@components/atoms/Text'
import { CheckboxField } from '@components/molecules/CheckboxField'
import { useFormikContext } from 'formik'
import { useCallback, useEffect, useState } from 'react'
import * as Yup from 'yup'
import * as S from '../commons/commons.styles'

type Props = {
  testId?: string
}

export const initialValues = {
  choices: [],
  jobTitle: '',
  industry: ''
}
const idContext = `step2`

export const validationSchema = () => {
  return Yup.object().shape({
    choices: Yup.array()
      .min(1, 'Select at least one choice')
      .required()
      .nullable()
  })
}
// const CURRENT_STEP = 2
const MAXIMUM_SELECTION = 4

export const Step2 = ({ testId = 'application-Step2-test-id' }: Props) => {
  const { setFieldValue, values } = useFormikContext<any>()
  const [selectionData, setSelectionData] = useState<string[]>(
    values.choices || []
  )
  const [exceededMaximumSelection, setExceededMaximumSelection] = useState(true)

  const validateSelection = useCallback((event) => {
    const add = (currentSelectedCheckBoxValue: string) =>
      setSelectionData((oldSelectionData) =>
        Array.from(new Set([...oldSelectionData, currentSelectedCheckBoxValue]))
      )

    const remove = (toBeRemoved) =>
      setSelectionData((oldSelectionData) =>
        oldSelectionData.filter((e) => e !== toBeRemoved)
      )

    const isChecked = event.target.checked
    const currentSelectedCheckBoxValue = event.target.name
    isChecked
      ? add(currentSelectedCheckBoxValue)
      : remove(currentSelectedCheckBoxValue)
  }, [])

  useEffect(() => {
    const SELECTED_AMOUNT = selectionData.length
    const HAS_SELECTED_MORE_THAN_MAXIMUM = SELECTED_AMOUNT >= MAXIMUM_SELECTION

    SELECTED_AMOUNT > 0
      ? setFieldValue('choices', selectionData)
      : setFieldValue('choices', null)

    HAS_SELECTED_MORE_THAN_MAXIMUM
      ? setExceededMaximumSelection(false)
      : setExceededMaximumSelection(true)
  }, [selectionData])

  return (
    <S.FormWrapper data-testid={testId}>
      <S.Body>
        <S.HalfSizedInputWrapper>
          <InputText
            label={'Your job title'}
            id={`job-title-${idContext}`}
            placeholder="Your job title here"
            type="text"
            name="jobTitle"
            data-testid={`input`}
          />
        </S.HalfSizedInputWrapper>
        <S.HalfSizedInputWrapper>
          <InputText
            label="Industry"
            id={`industry-${idContext}`}
            placeholder="Add industry"
            type="text"
            name="industry"
            data-testid={`input`}
          />
        </S.HalfSizedInputWrapper>
        <S.InputWrapper>
          <Text type="BodyMB">Where are you in your career search? </Text>
          <Text type="BodyMR">Choose up to 4 options</Text>
        </S.InputWrapper>

        <S.HalfSizedInputWrapper>
          <CheckboxField
            label="Actively searching"
            value="Actively searching"
            name="actively_searching"
            checked={selectionData.includes('actively_searching')}
            disabled={!exceededMaximumSelection}
            onChange={validateSelection}
          />
          <CheckboxField
            label="Passively searching"
            value="Passively searching"
            name="passively_searching"
            checked={selectionData.includes('passively_searching')}
            disabled={!exceededMaximumSelection}
            onChange={validateSelection}
          />
          <CheckboxField
            label="Currently employed - full time"
            value="Currently employed - full time"
            name="currently_employed_full_time"
            checked={selectionData.includes('currently_employed_full_time')}
            disabled={!exceededMaximumSelection}
            onChange={validateSelection}
          />
          <CheckboxField
            label="Currently employed - part time"
            value="Currently employed - part time"
            name="currently_employed_part_time"
            checked={selectionData.includes('currently_employed_part_time')}
            disabled={!exceededMaximumSelection}
            onChange={validateSelection}
          />
          <CheckboxField
            label="Currently employed - seasonal"
            value="Currently employed - seasonal"
            name="currently_employed_seasonal"
            checked={selectionData.includes('currently_employed_seasonal')}
            disabled={!exceededMaximumSelection}
            onChange={validateSelection}
          />
          <CheckboxField
            label="Currently employed - independent contractor"
            value="Currently employed - independent contractor"
            name="currently_employed_independent_contractor"
            checked={selectionData.includes(
              'currently_employed_independent_contractor'
            )}
            disabled={!exceededMaximumSelection}
            onChange={validateSelection}
          />
        </S.HalfSizedInputWrapper>

        <S.HalfSizedInputWrapper>
          <CheckboxField
            label="Currently employed - self employed"
            value="Currently employed - self employed"
            name="currently_employed_self_employed"
            checked={selectionData.includes('currently_employed_self_employed')}
            disabled={!exceededMaximumSelection}
            onChange={validateSelection}
          />
          <CheckboxField
            label="Unemployed"
            value="Unemployed"
            name="unemployed"
            checked={selectionData.includes('unemployed')}
            disabled={!exceededMaximumSelection}
            onChange={validateSelection}
          />
          <CheckboxField
            label="Need career coaching support"
            value="Need career coaching support"
            name="need_career_coaching_support"
            checked={selectionData.includes('need_career_coaching_support')}
            disabled={!exceededMaximumSelection}
            onChange={validateSelection}
          />
          <CheckboxField
            label="Searching for a new career"
            value="Searching for a new career"
            name="searching_for_a_new_career"
            checked={selectionData.includes('searching_for_a_new_career')}
            disabled={!exceededMaximumSelection}
            onChange={validateSelection}
          />
          <CheckboxField
            label="Searching for a new industry"
            value="Searching for a new industry"
            name="searching_for_a_new_industry"
            checked={selectionData.includes('searching_for_a_new_industry')}
            disabled={!exceededMaximumSelection}
            onChange={validateSelection}
          />
          <CheckboxField
            label="Searching for the right company"
            value="Searching for the right company"
            name="searching_for_the_right_company"
            checked={selectionData.includes('searching_for_the_right_company')}
            disabled={!exceededMaximumSelection}
            onChange={validateSelection}
          />
        </S.HalfSizedInputWrapper>
      </S.Body>
    </S.FormWrapper>
  )
}

import { css } from 'styled-components'
import { IconLabelProps } from './interfaces'

export const iconLabelStyle = css<IconLabelProps>`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: ${({ theme }) => theme.typography.weights.thin};
  line-height: 26px;
  color: ${({ theme }) => theme.colors.shades.primary300};
  padding: 8px 0;

  & svg {
    display: block;
    margin-right: 12px;
    color: ${({ theme }) => theme.colors.shades.primary300};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
    font-size: 14px;
  }
`

import api from '@api/index'
import { addToast } from '@components/atoms/Toast'
import { useUserContext } from '@hooks/user/UserContextProvider'
import { useState } from 'react'

export interface Industry {
  id?: any
  ocupation: string
}

export const useIndustriesOfExperience = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [response, setResponse] = useState<any>(null)
  const [industriesOfExperience, setIndustriesOfExperience] = useState<any>([])

  const { logOut } = useUserContext()

  const fetchIndustriesOfExperience = async (query?: string) => {
    setLoading(true)
    try {
      const res = await api.get(
        `/industries_of_experience${query ? `?query=${query}` : ''}`
      )
      setIndustriesOfExperience(res.data)
    } catch (error: any) {
      if (error?.response?.status === 401) logOut()
    } finally {
      setLoading(false)
    }
  }

  const createIndustry = async (
    industry: Industry
  ): Promise<Industry | void> => {
    setLoading(true)
    try {
      const res = await api.post('/industries_of_experience', industry)
      return res.data as Industry
    } catch (error: any) {
      if (error?.response?.status === 401) logOut()
    } finally {
      setLoading(false)
    }
  }

  const update = async (data: Array<number>) => {
    setLoading(true)
    try {
      const res = await api.put(`/users/profile`, {
        profile: {
          industries_of_experiences_ids: data
        }
      })
      setResponse(res)
      addToast({ title: 'Successfully updated' }, 'success', {})
    } catch (error: any) {
      if (error?.response?.status === 401) logOut()
    } finally {
      setLoading(false)
    }
  }

  return {
    loading,
    response,
    update,
    industriesOfExperience,
    fetchIndustriesOfExperience,
    createIndustry
  }
}

import { InitialScreen } from '@components/layout/InitialScreen'
import { CheckEmailPassword } from '@components/molecules/CheckEmailPassword'
import { useUserContext } from '@hooks/user/UserContextProvider'

export const CheckPasswordEmailConfirmation = ({
  testId = 'component-checkemail-id'
}) => {
  const { user, resendForgotPasswordEmail } = useUserContext()

  const _handleResendEmail = () => {
    resendForgotPasswordEmail(user?.forgotEmail)
  }

  return (
    <InitialScreen testId={testId}>
      <CheckEmailPassword
        email={user?.forgotEmail}
        onResendClick={_handleResendEmail}
      />
    </InitialScreen>
  )
}

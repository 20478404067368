import { Form, Formik, FormikProps } from 'formik'
import { yupRequired } from '@components/commons/Form/validations'
import { CertificationsFormProps } from './interfaces'
import { InputText } from '@components/atoms/InputText'
import { InputDate } from '@components/atoms/InputDate'
import { Button } from '@components/atoms/Button'
import { DeleteButton } from '@components/atoms/DeleteButton'
import { Toast } from '@components/atoms/Toast'
import { CalendarIcon, TrashIcon } from '@commons/Icons'
import * as Yup from 'yup'
import * as S from '../FormCommons/Form.styles'

interface Values {
  name: string
  completion_date: Date
}

const idContext = `general-info-form`

const validationSchema = () => {
  return Yup.object().shape({
    name: yupRequired,
    completion_date: yupRequired
  })
}

export const CertificationsForm = ({
  testId = 'certifications-form-id',
  initialData,
  onCancel,
  loading,
  onSubmit,
  onDestroy,
  ...props
}: CertificationsFormProps) => {
  return (
    <S.PaddedFormContainer data-testid={testId} {...props}>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialData}
        enableReinitialize
        onSubmit={onSubmit}
      >
        {(props: FormikProps<Values>) => (
          <Form>
            <S.InputRow>
              <S.InputCol>
                <InputText
                  label="Certification name *"
                  id={`certification-name-${idContext}`}
                  placeholder="Certification name"
                  type="text"
                  name="name"
                  {...props}
                />
              </S.InputCol>
            </S.InputRow>
            <S.InputRow>
              <S.InputCol>
                <InputDate
                  label="Completion date *"
                  id={`completion-date-${idContext}`}
                  type="text"
                  name="completion_date"
                  initialValue={initialData.completion_date}
                  endIcon={<CalendarIcon />}
                  {...props}
                />
              </S.InputCol>
            </S.InputRow>
            <S.ButtonsContainer>
              <DeleteButton onClick={onDestroy} />
              <S.ButtonsWrapper>
                <Button styling="secondary" type="button" onClick={onCancel}>
                  Cancel
                </Button>
                <Button loading={loading} type="submit" testId="fieldId">
                  Save
                </Button>
              </S.ButtonsWrapper>
            </S.ButtonsContainer>
          </Form>
        )}
      </Formik>
      <Toast />
    </S.PaddedFormContainer>
  )
}

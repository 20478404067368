import { Text } from '@components/atoms/Text'
import { JoinRevolutionBannerProps } from './interfaces'
import * as S from './JoinRevolutionBanner.styles'
// import { useNavigate } from 'react-router-dom'

export const JoinRevolutionBanner = ({
  testId = 'join-revolution-banner-id',
  ...props
}: JoinRevolutionBannerProps) => {
  // todo: We are waiting for the routes to be defined.
  // const navigate = useNavigate()

  const _handleEmployerRedirection = () => {
    // navigate('/...')
  }

  const _handleCandidateRedirection = () => {
    // navigate('/candidate/profile')
  }

  return (
    <S.Container data-testid={testId} {...props}>
      <S.TextWrapper>
        <Text type="GlobalH4">Join our hiring revolution!</Text>
        <Text type="BodyLR">
          A career marketplace matching employers and candidates by culture,
          career goals, and team fit
        </Text>
      </S.TextWrapper>

      <S.ButtonsWrapper>
        <S.EmployerButton
          styling="secondary"
          onClick={_handleEmployerRedirection}
        >
          I’m an employer
        </S.EmployerButton>
        <S.CandidateButton onClick={_handleCandidateRedirection}>
          I’m a candidate
        </S.CandidateButton>
      </S.ButtonsWrapper>
    </S.Container>
  )
}

import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
`

export const InputWrapper = styled.div`
  width: 100%;
`

export const Body = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  gap: 32px 0;

  & p {
    display: inline;
  }
`

export const FormWrapper = styled.div``

import { Pill } from '@components/atoms/Pill'
import { ProfileCardTitleBar } from '@components/molecules/ProfileCardTitleBar'
import { CardContent, PillsContainer } from './Card.styles'

interface WorkplaceValuesProps {
  onEditButtonClick?: () => void
  icon: React.ReactNode
  title: string
  values: {
    description: string
    id: number
  }[]
}

export const WorkplaceValues = ({
  onEditButtonClick,
  values,
  icon,
  title
}: WorkplaceValuesProps) => (
  <>
    <ProfileCardTitleBar
      title={title}
      icon={icon}
      onEditButtonClick={onEditButtonClick}
    />
    <CardContent>
      <PillsContainer>
        {values.map(({ id, description }) => (
          <Pill key={id}>{description}</Pill>
        ))}
      </PillsContainer>
    </CardContent>
  </>
)

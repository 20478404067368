import { Card, CardContent, PillsContainer } from '../commons/Card.styles'
import { WorkEnvironmentProfileCardProps } from './interfaces'
import { PingPongIcon, WorkEnvironmentIcon } from '@commons/Icons'
import { ProfileCardPlaceholder } from '@components/molecules/ProfileCardPlaceholder'
import { useEffect, useState } from 'react'
import { useProfileContext } from '@hooks/profile/ProfileContextProvider'
import { ProfileCardTitleBar } from '@components/molecules/ProfileCardTitleBar'
import { Pill } from '@components/atoms/Pill'
import { WorkEnvironmentForm } from '@components/organisms/WorkEnvironmentForm/WorkEnvironmentForm'
import { useGetWorkplace, useWorkplace } from '@hooks/workplace/useWorkplace'
import { WorkplaceValues } from '../commons/WorkplaceValues'
import formatDescription from '../commons/FormatDescription'

export const WorkEnvironmentProfileCard = ({
  testId = 'work-environment-profile-card-id'
}: WorkEnvironmentProfileCardProps) => {
  const [edit, setEdit] = useState(false)
  const { profile, fetchProfile } = useProfileContext()
  const { loading, data: workEnvironments } =
    useGetWorkplace('/work_environments')
  const { response, update: updateWorkEnvironments } = useWorkplace(
    'work_environments_ids'
  )

  const update = () => {
    fetchProfile()
    setEdit(false)
  }

  useEffect(() => {
    if (response?.status === 200) update()
  }, [response])

  return (
    <Card data-testid={testId}>
      {edit ? (
        <>
          <ProfileCardTitleBar
            edit={true}
            title="Work environment"
            icon={
              <WorkEnvironmentIcon width={24} height={24} color="#8E97A0" />
            }
            onEditButtonClick={() => setEdit(true)}
          />
          <CardContent>
            <WorkEnvironmentForm
              workEnvironments={workEnvironments}
              onCancel={() => setEdit(false)}
              onSubmit={({ work_environment }) => {
                updateWorkEnvironments(
                  work_environment.map((value) => value.id)
                )
              }}
              initialValues={formatDescription(profile?.work_environments)}
            />
          </CardContent>
        </>
      ) : !profile?.work_environments ? (
        <CardContent>
          <ProfileCardPlaceholder
            icon={<PingPongIcon />}
            title="Work environment"
            subtitle="Which of the following work environment descriptions do you
        feel supports your optimal work conditions?"
            callToAction="Add work environment"
            onClick={() => setEdit(true)}
          />
        </CardContent>
      ) : (
        <WorkplaceValues
          values={formatDescription(profile?.work_environments)}
          onEditButtonClick={() => setEdit(true)}
          icon={<WorkEnvironmentIcon width={24} height={24} color="#8E97A0" />}
          title="Work environment"
        />
      )}
    </Card>
  )
}

export const WorkEnvironmentCard = ({ profile }: any) => {
  return (
    <>
      {profile?.work_environments ? (
        <>
          <WorkplaceValues
            values={profile?.work_environments}
            icon={
              <WorkEnvironmentIcon width={24} height={24} color="#8E97A0" />
            }
            title="Work environment"
          />
        </>
      ) : (
        <CardContent>
          <ProfileCardPlaceholder
            icon={<PingPongIcon />}
            title="Work environment"
            subtitle="Which of the following work environment descriptions do you feel supports your optimal work conditions?"
          />
        </CardContent>
      )}
    </>
  )
}

import styled from 'styled-components'

export const PhotosContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 30px;
  flex-wrap: wrap;

  @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
    grid-template-columns: 1fr;
    padding: 0 24px;
    gap: 24px;
  }
`

export const sizes = {
  24: '24px',
  32: '32px',
  48: '48px'
}

type SizeTypes = keyof typeof sizes

export const iconSizes = {
  24: '12px',
  32: '16px',
  48: '24px'
}

export type GreenCheckerProps = {
  testId?: string
  styling?: 'success' | 'error'
  size?: SizeTypes
}

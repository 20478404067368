import { startCase } from 'lodash'

export interface CareerSearchChoice {
  id: number
  choice: string
}

export interface Recruiter {
  id: number
  full_name: string
}

export interface CandidateInfoType {
  id: number
  first_name: string
  profile_picture_url: string
  last_name: string
  email: string
  minimum_salary: string
  phone: string
  city: string
  career_defining_job: string
  next_job_title: string
  career_track: string
  job_title: string
  biggest_professional_goal: string
  career_rating: string
  unique_value_proposition: string
  outside_of_work_achievement: string
  biggest_project: string
  biggest_professional_accomplishment: string
  best_at: string
  best_in: string
  career_search_choices: CareerSearchChoice[]
  recruiter: Recruiter
}

export interface Pagination {
  current_page: number
  previous: number | null
  next: number | null
  limit: number
  total_pages: number
  total_count: number
  per_page: number
}

export interface CandidateResponse {
  data: CandidateInfoType[]
  pagination: Pagination
}

export interface ApplicationFormData {
  data: LineProps[]
  pagination: Pagination
}

export type LineProps = {
  id: string
  name: string
  email: string
  image: string
  pills: string[]
  location: string
  isClaimed: boolean
  recruiter: Recruiter
}

export const transformData = (data: CandidateInfoType[]) => {
  return data.map((item) => ({
    id: item.id,
    name: `${item.first_name} ${item.last_name}`,
    email: `${item.email}`,
    image: item.profile_picture_url,
    pills: item.career_search_choices.map(({ choice }) => startCase(choice)),
    location: item.city,
    isClaimed: !!item?.recruiter?.id,
    recruiter: item.recruiter
  }))
}

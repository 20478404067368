import { InputNumber } from '@components/atoms/InputNumber'
import { InputText } from '@components/atoms/InputText'
import {
  yupCity,
  yupEmail,
  yupFirstName,
  yupLastName,
  yupPhoneRequired,
  yupRequired
} from '@components/commons/Form/validations'
import { UploadArea } from '@components/molecules/UploadArea'
import { useFormikContext } from 'formik'
import * as Yup from 'yup'
import * as S from './Step1.styles'

export interface Values {
  name: string
  lastName: string
  city: string
  phone: string
  email: string
  profilePicture: string
}

export const initialValues = {
  name: '',
  lastName: '',
  city: '',
  phone: '',
  email: '',
  profilePicture: ''
}

const idContext = `candidate-application-form`

export const validationSchema = () => {
  return Yup.object().shape({
    name: yupFirstName,
    lastName: yupLastName,
    city: yupCity,
    phone: yupPhoneRequired.max(12, 'Phone number is too long'),
    email: yupEmail
  })
}

export const Step1 = ({ testId = 'application-step1-test-id', props }: any) => {
  const { setFieldValue, values } = useFormikContext<any>()

  return (
    <S.Body data-testid={testId}>
      <S.HalfSizedInputWrapper>
        <InputText
          label={'First Name *'}
          id={`first-name-${idContext}`}
          placeholder="Your name"
          type="text"
          name="name"
          data-testid={`input`}
          {...props}
        />
      </S.HalfSizedInputWrapper>
      <S.HalfSizedInputWrapper>
        <InputText
          label="Last name *"
          id={`last-name-${idContext}`}
          placeholder="Your last name"
          type="text"
          name="lastName"
          data-testid={`input`}
          {...props}
        />
      </S.HalfSizedInputWrapper>
      <S.HalfSizedInputWrapper>
        <InputText
          label="City *"
          id={`city-${idContext}`}
          placeholder="City"
          type="city"
          name="city"
          data-testid={`input`}
          {...props}
        />
      </S.HalfSizedInputWrapper>
      <S.HalfSizedInputWrapper>
        <InputNumber
          label="Phone Number *"
          id={`phone-${idContext}`}
          placeholder="123 456 7890"
          format="### ### ####"
          mask="_"
          name="phone"
          data-testid={`input`}
          {...props}
        />
      </S.HalfSizedInputWrapper>

      <S.InputWrapper>
        <InputText
          label="Email address *"
          id={`email-${idContext}`}
          placeholder="email@example.com"
          type="email"
          name="email"
          data-testid={`input`}
          {...props}
        />
      </S.InputWrapper>

      <S.InputWrapper>
        <UploadArea
          file={values.profilePicture}
          onFileUpload={(originalFile) => {
            setFieldValue('profilePicture', originalFile)
          }}
        />
      </S.InputWrapper>
    </S.Body>
  )
}

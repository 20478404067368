import { CopyIcon } from '@commons/Icons'
import { HTMLAttributes, useState } from 'react'
import { useCopyToClipboard } from 'react-use'
import { addToast } from '../Toast'
import * as S from './CopyButton.styles'

export interface CopyButtonProps extends HTMLAttributes<HTMLButtonElement> {
  testId?: string
  textToCopy?: string
}

export const CopyButton = ({
  testId = 'copy-button-id',
  textToCopy = '',
  ...props
}: CopyButtonProps) => {
  const [, copyToClipboard] = useCopyToClipboard()
  const [animate, setAnimate] = useState(false)

  const triggerAnimation = () => {
    setAnimate(true)
    setTimeout(() => {
      setAnimate(false)
    }, 1000)
  }

  const handleClick = () => {
    triggerAnimation()
    addToast(
      {
        title: 'Text was copied to clipboard!'
      },
      'success',
      {}
    )
    copyToClipboard(textToCopy)
  }

  return (
    <S.Container
      onClick={handleClick}
      aria-label="edit"
      data-testid={testId}
      animate={animate}
      {...props}
    >
      <CopyIcon />
    </S.Container>
  )
}

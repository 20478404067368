import { ShareIcon, SocialLinksIcon } from '@commons/Icons'
import { ProfileCardPlaceholder } from '@components/molecules/ProfileCardPlaceholder'
import { ProfileCardTitleBar } from '@components/molecules/ProfileCardTitleBar'
import { SocialLinks as SocialLinksForm } from '@components/organisms/SocialLinks'
import { useProfileContext } from '@hooks/profile/ProfileContextProvider'
import { fixUrlProtocol } from '@utils/utils'
import { useState } from 'react'
import { Profile } from '../../../../hooks/profile/interfaces'
import { Card, CardContent } from '../commons/Card.styles'
import { SocialLinks } from './components/SocialLinks'
import { SocialLinksProfileCardProps } from './interfaces'

const keysToExclude = ['id', 'updated_at', 'created_at']

const generateLinkStructure = (profile: Profile) => {
  if (!profile || !profile?.social_media) {
    return {}
  }

  const links = Object.entries(profile.social_media)
    .filter(([platform]) => !keysToExclude.includes(platform))
    .reduce((acc, [platform, label]) => {
      if (!label) return acc

      const href = fixUrlProtocol(label) || label

      acc[platform] = { label, href }

      return acc
    }, {})

  return links
}

export const SocialLinksProfileCard = ({
  testId = 'social-links-profile-card-id'
}: SocialLinksProfileCardProps) => {
  const [edit, setEdit] = useState(false)
  const { profile, fetchProfile } = useProfileContext()

  const update = () => {
    fetchProfile()
    setEdit(false)
  }

  const socialLinksProps = generateLinkStructure(profile)

  return (
    <Card data-testid={testId}>
      {edit ? (
        <>
          <ProfileCardTitleBar
            edit={edit}
            title="Social links"
            icon={<SocialLinksIcon width={24} height={24} color="#8E97A0" />}
            onEditButtonClick={() => setEdit(true)}
          ></ProfileCardTitleBar>
          <CardContent>
            <SocialLinksForm
              onCancel={() => setEdit(false)}
              onSucess={update}
            />
          </CardContent>
        </>
      ) : !profile?.social_media ? (
        <CardContent>
          <ProfileCardPlaceholder
            icon={<ShareIcon />}
            title="Social links"
            subtitle="Add your social links here to show off to potential employers"
            callToAction="Add links"
            onClick={() => setEdit(true)}
          />
        </CardContent>
      ) : (
        <SocialLinks
          edit={edit}
          onEditButtonClick={() => setEdit(true)}
          {...socialLinksProps}
        />
      )}
    </Card>
  )
}

export const SocialLinksCard = ({ profile }: any) => {
  const socialLinksProps = generateLinkStructure(profile)

  return (
    <>
      {!profile?.social_media ? (
        <CardContent>
          <ProfileCardPlaceholder
            icon={<ShareIcon />}
            title="Social links"
            subtitle="haven’t added any social links yet"
          />
        </CardContent>
      ) : (
        <SocialLinks {...socialLinksProps} />
      )}
    </>
  )
}

import styled from 'styled-components'

export const PlusContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.secondaryGreen};
  border-radius: 50%;
  width: 32px;
  height: 32px;
  padding: 8px;
  transition: ${({ theme }) => theme.transition.fast};
  opacity: 1;

  svg {
    display: block;
    width: 16px;
    height: 16px;
  }
`
export const Container = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  padding: 0;
  border: 0;
  &:hover {
    div {
      background-color: ${({ theme }) => theme.colors.shades.primary500};
    }
    svg path {
      fill: ${({ theme }) => theme.colors.primaryWhite};
    }
  }
`

export const Label = styled.span`
  font-weight: 600;
  margin-left: 16px;
  margin-top: 5px;
`

const typography = {
  primary: `'Epilogue', sans-serif`,
  sizes: {
    text: {
      subtitle: '1.25rem',
      body: '1.125rem',
      small: '1rem',
      extraSmall: '0.75rem'
    },
    large: {
      display: '7.5rem',
      h1: '6rem',
      h2: '4rem',
      h3: '3rem',
      h4: '2.5rem',
      h5: '1.5rem'
    },
    medium: {
      display: '6.5rem',
      h1: '5rem',
      h2: '3.5rem'
    },
    mobile: {
      display: '3rem',
      h1: '2.5rem',
      h2: '2rem'
    },
    lineHeight: {
      display: '132px',
      subtitle: '31px',
      body: '32px',
      small: '19px',
      h1: '105px',
      h2: '70px',
      h3: '52px',
      h4: '47px',
      h5: '31px'
    }
  },
  weights: {
    thin: 400,
    regular: 500,
    semiBold: 600,
    extraBold: 700
  }
} as const

export { typography }

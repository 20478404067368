import * as S from './CertificationsProfileCard.styles'
import { CertificationsProfileCardProps } from './interfaces'
import { ShieldIcon, ShieldSymbol } from '@commons/Icons'
import { useProfileContext } from '@hooks/profile/ProfileContextProvider'
import { useState } from 'react'
import { Certification } from '@hooks/profile/interfaces'
import { format } from 'date-fns'
import { useCertification } from '@hooks/experience/useCertification'
import { truncate } from 'lodash'

const ITEM_DATE_FORMAT = 'MMMM yyyy'

const getEmptyCertification = () => ({
  name: '',
  completion_date: null
})

export const CertificationsProfileCard = ({
  testId = 'certifications-profile-card-id'
}: CertificationsProfileCardProps) => {
  const { profile, fetchProfile } = useProfileContext()
  const { update, loading } = useCertification()
  const [edit, setEdit] = useState(false)
  const [currentCertification, setCurrentCertification] =
    useState<Certification>(getEmptyCertification())

  const certifications =
    edit && profile.certifications?.length
      ? [profile.certifications![0]]
      : profile.certifications

  const getInitialData = () => ({
    ...currentCertification,
    completion_date: currentCertification.completion_date
      ? new Date(currentCertification.completion_date!)
      : null
  })

  const View = () => (
    <>
      {!profile.certifications && !edit && (
        <S.Placeholder
          icon={<ShieldIcon />}
          title="Certifications"
          subtitle="Add your certifications here"
          callToAction="Add certification"
          onClick={() => {
            setEdit(true)
            setCurrentCertification(getEmptyCertification())
          }}
        />
      )}

      {(profile.certifications || edit) && (
        <S.TitleBar
          edit={edit}
          title="Certifications"
          icon={<ShieldSymbol />}
        />
      )}

      {profile.certifications &&
        certifications?.map((certification: Certification, index) => {
          const completionDate = certification?.completion_date
            ? format(new Date(certification?.completion_date), ITEM_DATE_FORMAT)
            : ''

          return (
            <>
              <S.Item
                disabled={edit}
                key={certification.id}
                name={truncate(certification.name, { length: 32 })}
                completionDate={completionDate}
                onEdit={() => {
                  setCurrentCertification(certification)
                  setEdit(true)
                }}
              />
              {index + 1 < profile.certifications!.length && (
                <S.PaddedDivider />
              )}
            </>
          )
        })}

      {edit && (
        <S.Form
          onCancel={() => setEdit(false)}
          onDestroy={async () => {
            await update({ ...currentCertification, _destroy: true })
            setEdit(false)
            fetchProfile()
          }}
          onSubmit={async (data) => {
            await update(data)
            setEdit(false)
            fetchProfile()
          }}
          initialData={getInitialData()}
          loading={loading}
          testId={`${testId}-form`}
        ></S.Form>
      )}

      <S.FullWidthDivider />
      {profile.certifications && (
        <S.Add
          onClick={() => {
            setEdit(true)
            setCurrentCertification(getEmptyCertification())
          }}
        >
          Add certification
        </S.Add>
      )}
    </>
  )

  return (
    <S.Card data-testid={testId}>
      <View />
    </S.Card>
  )
}

export const CertificationsCard = ({ profile }) => {
  return (
    <>
      {!profile?.certifications ? (
        <S.Placeholder
          icon={<ShieldIcon />}
          title="Certifications"
          subtitle="Add your certifications here"
        />
      ) : (
        <>
          <S.TitleBar title="Certifications" icon={<ShieldSymbol />} />

          {profile?.certifications?.map(
            (certification: Certification, index) => {
              const completionDate = certification?.completion_date
                ? format(
                    new Date(certification?.completion_date),
                    ITEM_DATE_FORMAT
                  )
                : ''

              return (
                <>
                  <S.Item
                    key={certification.id}
                    name={certification.name}
                    completionDate={completionDate}
                  />
                  {index + 1 < profile?.certifications!.length && (
                    <S.PaddedDivider />
                  )}
                </>
              )
            }
          )}
        </>
      )}
    </>
  )
}

import api from '@api/index'
import { addToast } from '@components/atoms/Toast'
import { useUserContext } from '@hooks/user/UserContextProvider'
import { useEffect, useState } from 'react'

interface Values {
  website: string
  linkedin: string
  facebook: string
  instagram: string
}
export const useUpdateSocialLinks = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [response, setResponse] = useState<any>(null)
  const { logOut } = useUserContext()

  const update = async (data: Values) => {
    setLoading(true)
    try {
      const res = await api.put(`/users/profile`, {
        profile: {
          social_medium_attributes: {
            ...data
          }
        }
      })
      setResponse(res)
      addToast({ title: 'Successfully updated' }, 'success', {})
    } catch (error: any) {
      if (error?.response?.status === 401) logOut()
    } finally {
      setLoading(false)
    }
  }

  return { loading, response, update }
}

import styled from 'styled-components'
import media from 'styled-media-query'
import { IconLabel } from '@components/atoms/IconLabel'
import { Text as TextComponent } from '@components/atoms/Text'
import { FormContainer } from '@components/atoms/FormContainer'

export const FormWrapper = styled.div``

export const PaddedFormContainer = styled(FormContainer)`
  ${media.greaterThan('medium')`
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 40px;
    margin-bottom: 40px;
  `}
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  margin-bottom: 40px;

  ${media.lessThan('medium')`
		margin-bottom: 32px;
	`}
`

export const Body = styled.div``

export const InputWrapper = styled.div`
  margin: 20px 0;
`

export const InputRow = styled.div`
  display: flex;
  gap: 1.5rem;

  & + & {
    margin-top: 1.5rem;
  }

  ${media.lessThan('medium')`
    flex-direction: column;
  `}
`

export const InputCol = styled.div`
  flex-grow: 1;
`

export const InputMessage = styled.div`
  margin-top: 12px;
  > p {
    line-height: 22.4px;
  }
`

export const FormHeading = styled.div`
  margin-bottom: 40px;
`

export const BackLinkWrapper = styled.div`
  margin-bottom: 40px;

  ${media.lessThan('medium')`
		margin-bottom: 32px;
	`}
`

export const LinkWrapper = styled.div`
  margin: 40px 0;

  ${media.lessThan('medium')`
		margin: 32px 0;
	`}
`

export const CallToActionButtonWrapper = styled.div`
  display: inline;
`

export const Heading = styled.h1`
  font-size: 40px;
  font-weight: ${({ theme }) => theme.typography.weights.extraBold};
  font-weight: 600;
  letter-spacing: 0.02em;
  line-height: 47px;
  text-align: left;

  ${media.lessThan('medium')`
		font-size: 24px;
	`}
`

export const Text = styled.p`
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.02em;
  text-align: left;

  ${media.lessThan('medium')`
	  font-size: 16px;
	`}
`

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1.5rem;
  margin-top: 2.5rem;
  width: 100%;

  ${media.lessThan('medium')`
    button {
      flex-grow: 1;
    }
  `}
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2.5rem;

  ${ButtonsWrapper} {
    margin-top: 0;
  }

  ${media.lessThan('medium')`
    flex-direction: column-reverse;
    margin-top: 1.5rem;
    gap: 1.5rem;
    > div {
      width: 100%;
    }
  `}
`

export const DeleteIconText = styled(TextComponent)`
  color: #de1b3e;
  margin-top: 5px;
  cursor: pointer;
`
export const DeleteIconContainer = styled(IconLabel)`
  cursor: pointer;
  display: inline-flex;
  margin-left: 0;
  margin-right: auto;
`

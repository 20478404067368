import footerArrows from '@assets/candidate/double_arrows.svg'
import logo from '@assets/logo.svg'
import { Button } from '@components/atoms/Button'
import { Text } from '@components/atoms/Text'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import * as S from './StartApplyScreen.styles'

export interface CandidateStartApplyScreenProps {
  testId?: string
}

export const CandidateStartApplyScreen = ({
  testId = 'component-CandidateStartApplyScreen-id'
}: CandidateStartApplyScreenProps) => {
  const isMobile = window?.screen?.width < 700
  const navigate = useNavigate()

  const _handleSignInRedirection = useCallback(() => {
    navigate('/candidate/application-form/1')
  }, [])

  return (
    <S.Container data-testid={testId}>
      <S.MobileLogo src={logo} alt="Jombine's logo" />

      <S.Background />
      <S.CenterWrapper>
        <S.Logo src={logo} alt="Jombine's logo" />
        <S.ContentWrapper>
          <S.TextContent>
            <h1>Apply to our</h1>
            <h1>Jombine program!</h1>

            <p>
              These questions will help us learn more about you, what you are
              looking for in this next career move, and how we can best support
              your career motivations!
            </p>
          </S.TextContent>
          <S.ButtonContainer>
            <Button
              size={isMobile ? 'full' : 'small'}
              onClick={_handleSignInRedirection}
            >
              Start application
            </Button>
            <S.ClockContainer>
              <S.ClockIcon />
              <Text type="BodyMB">15 min*</Text>
            </S.ClockContainer>
          </S.ButtonContainer>
          <Text type="BodySR">
            *Progress will not be saved if you close the form without completing
            it.
          </Text>
        </S.ContentWrapper>
      </S.CenterWrapper>

      <S.FooterImage src={footerArrows} alt="Footer image with double arrows" />
    </S.Container>
  )
}

import { BackIcon, BriefCase, Trophy } from '@commons/Icons'
import { GreenChecker } from '@components/atoms/GreenChecker'
import { Pill } from '@components/atoms/Pill'
import { Text } from '@components/atoms/Text'
import { RecruiterBaseLayout } from '@components/layout/RecruiterBaseLayout'
import { ApplicationClaimedModal } from '@components/molecules/ApplicationClaimedModal'
import { CareerInformationSection } from '@components/molecules/CareerInformationSection'
import {
  AssignationSection,
  BorderedSection
} from '@components/molecules/CareerInformationSection/CareerInformationSection.styles'
import { CandidateProfileDetails } from '@components/organisms/CandidateProfileDetails'
import { CandidateInfoType } from '@hooks/recruiter/interfaces'
import { useRecruiterContext } from '@hooks/recruiter/RecruiterContextProvider'
import { useUserContext } from '@hooks/user/UserContextProvider'
import { get as lodashGet, startCase } from 'lodash'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import * as S from './RecruiterCandidateApplicationFormDetails.styles'

export interface RecruiterCandidateApplicationFormDetailsProps {
  testId?: string
}

export const CandidateAnswer = styled.p`
  word-break: break-word !important;
`

export const RecruiterCandidateApplicationFormDetails = ({
  testId = 'recruiter-candidate-details-screen-id',
  ...props
}: RecruiterCandidateApplicationFormDetailsProps) => {
  const { id } = useParams()
  const { claimCandidate, getApplicationForm } = useRecruiterContext()
  const { user } = useUserContext()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const handleOpenModal = () => setIsModalOpen(true)
  const handleCloseModal = () => setIsModalOpen(false)

  const navigate = useNavigate()

  const [candidateInfo, setCandidateInfo] = useState<CandidateInfoType | null>()

  const _handleClaimCandidate = async (id) => {
    const result = await claimCandidate({ id })
    const SUCCESS = result?.status === 200
    if (SUCCESS) {
      handleOpenModal()
      fetchCandidateInfo()
    }
  }

  const fetchCandidateInfo = async () => {
    const data = await getApplicationForm({ id })
    setCandidateInfo(data)
  }

  useEffect(() => {
    fetchCandidateInfo()
  }, [])

  const careerInformationSection = (
    <div>
      <CareerInformationSection
        title={
          <>
            <Trophy /> <h1>Goals & Aspirations</h1>
          </>
        }
      >
        <BorderedSection>
          <strong>What do you want to be your next job title?</strong>
          <CandidateAnswer>{candidateInfo?.next_job_title}</CandidateAnswer>
        </BorderedSection>
        <hr />
        <BorderedSection>
          <strong>
            What would be your dream &quot;career defining&quot; job?
          </strong>
          <CandidateAnswer>
            {candidateInfo?.career_defining_job}
          </CandidateAnswer>
        </BorderedSection>
        <hr />
        <BorderedSection>
          <strong>
            In 500 words or less, tell us why you&apos;ve chosen your career
            track or what you love most about it?
          </strong>
          <CandidateAnswer>{candidateInfo?.career_track}</CandidateAnswer>
        </BorderedSection>
        <hr />
        <BorderedSection>
          <strong>
            Besides a new job/career, what is your ONE biggest professional goal
            that you hope to accomplish within the next 3-5 years
          </strong>
          <CandidateAnswer>
            {candidateInfo?.biggest_professional_goal}
          </CandidateAnswer>
        </BorderedSection>
      </CareerInformationSection>
      <CareerInformationSection
        title={
          <>
            <BriefCase /> <h1>Career Background</h1>
          </>
        }
      >
        <BorderedSection>
          <strong>
            What about your Unique Value Proposition - the thing that you feel
            is most valuable and special about yourself in the workplace?
          </strong>
          <CandidateAnswer>
            {candidateInfo?.unique_value_proposition}
          </CandidateAnswer>
        </BorderedSection>
        <hr />
        <BorderedSection>
          <strong>
            What is an outside of work achievement or personal highlight that
            describes your personality or individuality?
          </strong>
          <CandidateAnswer>
            {candidateInfo?.outside_of_work_achievement}
          </CandidateAnswer>
        </BorderedSection>
        <hr />
        <BorderedSection>
          <strong>
            My biggest, and most noteworthy, professional accomplishment is?
          </strong>
          <CandidateAnswer>
            {candidateInfo?.biggest_professional_accomplishment}
          </CandidateAnswer>
        </BorderedSection>
        <hr />
        <BorderedSection>
          <strong>
            In 500 words or less, describe the BIGGEST project/task that
            you&apos;ve ever worked on?
          </strong>
          <CandidateAnswer>{candidateInfo?.biggest_project}</CandidateAnswer>
        </BorderedSection>
        <hr />
        <BorderedSection>
          <strong>
            Describe the thing that you&apos;re best at in 3 words or less?
          </strong>
          <CandidateAnswer>{candidateInfo?.best_at}</CandidateAnswer>
        </BorderedSection>
      </CareerInformationSection>
    </div>
  )

  const IS_CLAIMED_BY_ME =
    lodashGet(candidateInfo, 'recruiter.id', '') === user?.id

  const ClaimedBySection = ({ claimedBy }) => (
    <>
      <GreenChecker size={24} />
      <Text type="BodySMB" as="span">
        Claimed by {IS_CLAIMED_BY_ME ? 'you' : claimedBy}
      </Text>
    </>
  )

  const assignationSection = (
    <CareerInformationSection>
      <AssignationSection>
        <section>
          <Text type="BodyMB" as="h1">
            Assignation
          </Text>

          <S.AssignationText>
            {candidateInfo?.recruiter?.id ? (
              <ClaimedBySection
                claimedBy={candidateInfo?.recruiter.full_name}
              />
            ) : (
              <Text type="BodySMR" as="p">
                Not claimed
              </Text>
            )}
          </S.AssignationText>
        </section>

        <section>
          <Text type="BodyMB" as="h1">
            Job title
          </Text>

          <Pill>{candidateInfo?.job_title}</Pill>
        </section>
        <section>
          <Text type="BodyMB" as="h1">
            Career status
          </Text>

          {candidateInfo?.career_search_choices.map(({ id, choice }) => (
            <Pill key={id}>{startCase(choice)}</Pill>
          ))}
        </section>
        <section>
          <Text type="BodyMB" as="h1">
            Career rate
          </Text>

          <Pill>{startCase(candidateInfo?.career_rating)}</Pill>
        </section>
        <section>
          <Text type="BodyMB" as="h1">
            Salary expectation
          </Text>

          <Pill>${candidateInfo?.minimum_salary}</Pill>
        </section>
      </AssignationSection>
    </CareerInformationSection>
  )

  return (
    <RecruiterBaseLayout data-testid={testId} {...props}>
      <S.Container>
        <S.BackButton onClick={() => navigate('/recruiter/applications')}>
          <BackIcon /> All Applications
        </S.BackButton>
        <S.Header>
          {candidateInfo && (
            <CandidateProfileDetails
              name={candidateInfo?.first_name}
              email={candidateInfo?.email}
              profilePicture={candidateInfo?.profile_picture_url}
              phone={candidateInfo?.phone}
              location={candidateInfo?.city}
            />
          )}

          <S.ButtonWrapper>
            {candidateInfo?.recruiter?.id ? (
              <S.Button
                onClick={() => _handleClaimCandidate(candidateInfo?.id)}
                disabled
              >
                Claimed
              </S.Button>
            ) : (
              <S.Button
                onClick={() => _handleClaimCandidate(candidateInfo?.id)}
              >
                Claim candidate
              </S.Button>
            )}
          </S.ButtonWrapper>
        </S.Header>

        <S.InformationWrapper>
          {careerInformationSection} {assignationSection}
        </S.InformationWrapper>
      </S.Container>

      <ApplicationClaimedModal
        email={candidateInfo?.email || ''}
        isModalOpen={isModalOpen}
        handleCloseModal={handleCloseModal}
      />
    </RecruiterBaseLayout>
  )
}

import styled from 'styled-components'
import { ProfileCard } from '@components/atoms/ProfileCard'

export const Card = styled(ProfileCard)``

export const Divider = styled.hr`
  border-top: 1px solid #e6ebf0;
  border-bottom: none;
  border-left: none;
  border-right: none;
  height: 1px;
`

export const PaddedDivider = styled(Divider)`
  margin: 40px 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin: 24px;
  }
`

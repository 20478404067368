import * as S from '../FormCommons/Form.styles'
import { Button } from '@components/atoms/Button'
import { ComputerSkillsFormProps } from './interfaces'
import { AutocompleteSelect } from '@components/molecules/AutocompleteSelect'
import { AutocompleteOption } from '@components/molecules/AutocompleteSelect/interfaces'
import {
  ComputerSkill,
  useComputerSkills
} from '@hooks/experience/useComputerSkills'
import { useEffect, useState, useCallback } from 'react'
import { capitalize, debounce } from 'lodash'

export const ComputerSkillsForm = ({
  testId = 'computer-skills-form-id',
  onCancel,
  onSubmit,
  initialValues,
  ...props
}: ComputerSkillsFormProps) => {
  const [selectedItems, setSelectedItems] = useState<any>([])
  const { fetchComputerSkills, computerSkills, createComputerSkills } =
    useComputerSkills()

  useEffect(() => {
    fetchComputerSkills()
  }, [])

  const transformOptions = (option) => {
    return {
      id: option.id,
      value: capitalize(option.description)
    }
  }

  const fetchComputerSkillsOnce = useCallback(
    debounce(fetchComputerSkills, 200),
    []
  ) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <S.PaddedFormContainer data-testid={testId} {...props}>
      <AutocompleteSelect
        onChange={(items) => setSelectedItems(items)}
        options={computerSkills.map(transformOptions)}
        placeholder="Add a computer skill"
        limitReachedText="You reached the max number of skills"
        showAll={true}
        onQueryChange={fetchComputerSkillsOnce}
        initialValues={initialValues}
        max={10}
        onAdd={async (query) => {
          return new Promise<AutocompleteOption>((resolve) => {
            createComputerSkills({
              description: query
            } as ComputerSkill).then((computerSkill) => {
              if (computerSkill) {
                resolve({
                  id: computerSkill.id,
                  value: computerSkill.description
                })
              }
            })
          })
        }}
      />
      <S.ButtonsWrapper>
        <Button styling="secondary" type="button" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          type="submit"
          testId="fieldId"
          onClick={() =>
            onSubmit({
              computer_skills: selectedItems
            })
          }
        >
          Save
        </Button>
      </S.ButtonsWrapper>
    </S.PaddedFormContainer>
  )
}

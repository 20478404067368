import { Button as OriginalButton } from '@components/atoms/Button'
import styled from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 715px;

  ${media.lessThan('large')`
		margin: 72px 40px;
	`}

  ${media.lessThan('medium')`
    margin: 48px 24px;
	`}
`

export const FormWrapper = styled.div`
  height: 544px;
  width: 100%;
  margin: 40px 0;
  padding: 0 4px;

  ${media.lessThan('large')`
    max-height: none;
		overflow-y: none;
		height: 100%;
	`}
`

export const ProgressWrapper = styled.div`
  max-width: 215px;
  width: 100%;
`

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  gap: 24px;

  ${media.lessThan('small')`
    align-items: center;
	`}
`

export const BackButton = styled.button`
  border-radius: 50%;
  height: 56px;
  width: 56px;
  background-color: white;
  border: 2px solid ${({ theme }) => theme.colors.primaryBlue};
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  & svg {
    height: 24px;
    width: 24px;
  }
`

export const Button = styled(OriginalButton)`
  @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
    width: 183px;
  }
`

export const Footer = styled.footer`
  display: flex;
  justify-content: flex-end;
  gap: 40px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    justify-content: space-between;
  }
`

import styled from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.div`
  width: 100%;
  height: 100%;
`

export const InputWrapper = styled.div`
  width: 100%;
`

export const HalfSizedInputWrapper = styled.div`
  width: calc(50% - 11.5px);

  ${media.lessThan('small')`
		width: 100%;
	`}
`
export const Body = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  gap: 24px 0;
`

export const FormWrapper = styled.div``

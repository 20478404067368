import styled, { css } from 'styled-components'
import 'react-calendar/dist/Calendar.css'
import { InputDateProps } from './interfaces'
import media from 'styled-media-query'
import Calendar from 'react-calendar'

type IconWrapperProps = {
  startIcon?: boolean
}

export const StyledCalendarWrapper = styled.div`
  position: fixed !important;
  top: 10px;
  z-index: 999;

  ${media.lessThan('small')`
    left: 0;
  `}

  ${media.lessThan('medium')`
    position: sticky !important;
  `}
`

export const InputWrapper = styled.div`
  position: relative;
`

export const StyledCalendar = styled(Calendar)`
  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(73, 88, 102, 0.12);
  border-radius: 16px;
  border: none !important;
  margin-top: 25px;
  padding: 8px;
  width: 376px !important;

  & .react-calendar__navigation__arrow {
    font-size: 1.8rem;
  }

  & .react-calendar__navigation__label__labelText--from {
    font-weight: ${({ theme }) => theme.typography.weights.regular};
    font-size: ${({ theme }) => theme.typography.sizes.text.small};

    ${media.lessThan('small')`
      font-size: 12px;
      margin-left: -10px;
    `}
  }

  & .react-calendar__month-view__days__day {
    background: transparent !important;
    & abbr {
      width: 32px !important;
      height: 32px !important;
      align-items: center;
      justify-content: center;
      display: flex !important;
      border-radius: 100%;
      color: ${({ theme }) => theme.colors.primaryBlue};
    }
  }

  & .react-calendar__tile:hover {
    & abbr {
      background: ${({ theme }) => theme.colors.shades.gray300};
    }
  }

  & .react-calendar__tile:focus {
    & abbr {
      background: ${({ theme }) => theme.colors.shades.gray500};
    }
  }

  & .react-calendar__year-view__months__month:hover,
  .react-calendar__decade-view__years__year:hover {
    & abbr {
      background: transparent;
    }
  }

  & .react-calendar__year-view__months__month:hover,
  .react-calendar__decade-view__years__year:hover,
  .react-calendar__century-view__decades__decade:hover {
    background: #f2f5f7;
  }

  & .react-calendar__year-view__months__month,
  .react-calendar__year-view__months__month:hover,
  .react-calendar__decade-view__years__year,
  .react-calendar__decade-view__years__year:hover,
  .react-calendar__century-view__decades__decade,
  .react-calendar__century-view__decades__decade:hover {
    display: flex;
    height: 30px !important;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    margin-top: 3px;
    margin-bottom: 3px;
    padding: 25px;
  }

  & .react-calendar__navigation__label:hover,
  .react-calendar__navigation__label:focus,
  .react-calendar__navigation__label:active,
  .react-calendar__navigation__label:disabled {
    background: transparent !important;
  }

  & .react-calendar__tile--now.react-calendar__year-view__months__month,
  .react-calendar__tile--now.react-calendar__year-view__months__month:hover,
  .react-calendar__tile--now.react-calendar__decade-view__years__year,
  .react-calendar__tile--now.react-calendar__decade-view__years__year:hover,
  .react-calendar__tile--now.react-calendar__century-view__decades__decade,
  .react-calendar__tile--now.react-calendar__century-view__decades__decade:hover,
  .react-calendar__tile--hasActive {
    background: ${({ theme }) => theme.colors.secondaryGreen} !important;

    & abbr {
      background: transparent !important;
    }
  }

  & .react-calendar__month-view__days__day.react-calendar__tile--now {
    & abbr {
      background: ${({ theme }) => theme.colors.secondaryGreen};
    }
  }

  & .react-calendar__tile:disabled {
    opacity: 1;
    background-color: ${({ theme }) => theme.colors.shades.gray300} !important;
    cursor: not-allowed;
  }

  & .react-calendar__month-view__weekdays__weekday {
    padding-top: 0px;
    padding-bottom: 10px;

    & abbr {
      text-decoration: none;
      font-weight: ${({ theme }) => theme.typography.weights.thin};
      font-size: ${({ theme }) => theme.typography.sizes.text.extraSmall};
    }
  }
`

export const IconWrapper = styled.span<IconWrapperProps>`
  position: absolute;
  top: 1px;
  bottom: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  color: ${({ theme }) => theme.colors.shades.primary300};
  ${({ startIcon }) =>
    startIcon
      ? css`
          left: 1px;
        `
      : css`
          right: 1px;
        `}
`

export const ErrorMessage = styled.div`
  font-size: 14px;
  margin-top: 12px;
  color: ${({ theme }) => theme.colors.alert};
  max-width: fit-content;
`

export const Label = styled.label`
  color: ${({ theme }) => theme.colors.shades.primary900};
  display: block;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 17px;
  margin-bottom: 12px;
`

export const InputCss = css<InputDateProps>`
  -webkit-appearance: none;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.shades.primary100};
  box-shadow: 0 0 4px 0 transparent;
  color: ${({ theme }) => theme.colors.shades.primary700};
  font-size: 16px;
  font-weight: 400;
  height: 56px;
  letter-spacing: 0em;
  line-height: 25.6px;
  outline: none;
  padding-left: ${({ startIcon }) => !!startIcon && '60px'};
  padding-right: ${({ endIcon }) => !!endIcon && '60px'};
  padding: 0 16px;
  transition-property: border, box-shadow;
  transition: 0.2s ease-in-out;
  width: 100%;

  ${({ startIcon }) =>
    startIcon &&
    css`
      padding: 0 48px;
    `}

  ${({ hasError }) =>
    hasError &&
    css`
      border: 1.5px solid ${({ theme }) => theme.colors.alert};
      box-sizing: border-box;
      border-radius: 8px;
    `}

  &:focus,
  &:hover {
    border: 1.5px solid ${({ theme }) => theme.colors.shades.primary500};
    box-sizing: border-box;
    filter: drop-shadow(0px 0px 5px #4de295);
    border-radius: 8px;
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.shades.primary100};
    opacity: 1;
  }

  &:disabled {
    -webkit-text-fill-color: ${({ theme }) => theme.colors.shades.primary100};
    opacity: 1;
    border: 1px solid ${({ theme }) => theme.colors.shades.primary100};
    box-shadow: 0 0 4px 0 transparent;
    background-color: ${({ theme }) => theme.colors.shades.gray300};
    color: ${({ theme }) => theme.colors.shades.primary100};
    cursor: not-allowed;

    &:hover {
      filter: none;
    }
  }
`

export const Input = styled.input<InputDateProps>`
  ${InputCss}
`

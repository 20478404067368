import { AngleSmallDownIcon, AngleSmallUpIcon } from '@commons/Icons'
import { Button as OriginalButton } from '@components/atoms/Button'
import { InputTextWithoutFormik } from '@components/atoms/InputText'
import { pillStyle } from '@components/atoms/Pill/Pill.styles'
import { BodyS, BodySB } from '@components/atoms/Text/Text.styles'
import { Avatar } from '@mui/material'
import styled from 'styled-components'

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.shades.gray300};
  color: ${({ theme }) => theme.colors.primaryBlue};
  width: 100%;
  height: 100%;

  padding: 72px 72px 74px 64px;
  overflow: scroll;

  @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
    padding: 0;
    overflow: initial;
    background-color: #f2f5f7;
  }
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;

  @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
    margin: 32px 24px 24px 24px;
  }
`

export const Title = styled.h1`
  ${BodyS}
`

export const ProfilePicture = styled(Avatar).attrs({
  sx: { bgcolor: '#DBF9EA', color: '#27714B' }
})`
  width: 32px;
  height: 32px;
`

export const InlineWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`

export const CareerWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`

export const Pill = styled.div`
  ${pillStyle}
  ${BodySB}
  height: 28px;

  white-space: nowrap;
  margin: 0;
`

export const PoppoverText = styled.p`
  font-size: 12px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  color: white;
`

export const InputWrapper = styled.div`
  margin-bottom: 24px;

  @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
    padding: 0 24px;
    margin-bottom: 32px;
  }
`

export const TableHeaderWrapper = styled.th``

export const TableHeaderContent = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
`

export const AngleSmallUp = styled(AngleSmallUpIcon)`
  width: 16px;
  height: 16px;
`

export const AngleSmallDown = styled(AngleSmallDownIcon)`
  width: 16px;
  height: 16px;
`

export const TRHoverWrapper = styled.tr`
  cursor: pointer;
`

export const ClaimButtonWrapper = styled.td`
  height: 68px;
  min-width: 230px;
  z-index: 9;
`

export const Button = styled(OriginalButton)`
  display: none;
  height: 42px;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;

  ${TRHoverWrapper}:hover & {
    display: block;
  }
`

export const ClaimedBySection = styled.div`
  display: flex;
  align-items: center;

  > div {
    height: 20px;
    width: 20px;
    margin-right: 8px;
  }

  > span {
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    ${BodySB}
  }
`

export const InputText = styled(InputTextWithoutFormik)<any>`
  background-color: ${({ theme }) => theme.colors.shades.gray500};
  border: none;
  height: 48px;

  &:hover,
  &:focus {
    filter: none;
    background-color: #dfe6ed;
    border: none;
  }
`

export const ShareButton = styled(Button).attrs({ styling: 'secondary' })`
  display: flex;
  gap: 10px;
  background-color: transparent;
  height: 48px;
  font-size: 18px;
  line-height: 20px;
`

export const MobileIconsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`

export const MobileShareButton = styled.button`
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 24px;
    width: 24px;
  }

  cursor: pointer;
`

export const MobileTable = styled.div`
  width: 100%;
  display: flex;
  gap: 12px;
  flex-direction: column;
  background-color: #f2f5f7;
`

export const MobileTableItem = styled.div`
  background-color: ${({ theme }) => theme.colors.primaryWhite};
  display: flex;
  padding: 18px 24px;
  gap: 20px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.shades.gray700};
  }
`

export const TextWrapper = styled.div``

export const FilterModalContainer = styled.div`
  width: 100%;
  height: 100%;
`

export const MobileFilterWrapper = styled.div`
  margin: 56px 0;
`

export const FilterButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

import * as S from './EditButton.styles'
import { EditButtonProps } from './interfaces'

export const EditButton = ({
  testId = 'edit-button-id',
  ...props
}: EditButtonProps) => {
  return (
    <S.Container aria-label="edit" data-testid={testId} {...props}>
      <S.PencilIcon />
    </S.Container>
  )
}

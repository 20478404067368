import {
  AddUser,
  AddUserWithBackground,
  Filter,
  PlusSmall,
  SearchIconPure
} from '@commons/Icons'
import { Button } from '@components/atoms/Button'
import { ContentSwitcher } from '@components/atoms/ContentSwitcher'
import { Modal } from '@components/atoms/Modal'
import { Tab } from '@components/atoms/Tab'
import { Table } from '@components/atoms/Table'
import { TablePagination } from '@components/atoms/TablePagination'
import { Text } from '@components/atoms/Text'
import { RecruiterBaseLayout } from '@components/layout/RecruiterBaseLayout'
import { CandidatesCardPlaceholder } from '@components/molecules/CandidatesCardPlaceholder'
import { ModalContentPanel } from '@components/organisms/ModalContentPanel'
import { useRecruiterContext } from '@hooks/recruiter/RecruiterContextProvider'
import { useUserContext } from '@hooks/user/UserContextProvider'
import { useMediaQuery } from '@mui/material'
import { format } from 'date-fns'
import { debounce, size, truncate } from 'lodash'
import { useEffect, useState } from 'react'
import { useMatch, useNavigate } from 'react-router-dom'
import { usePaginationQuery } from '../../../hooks/paginationQuery'
import * as S from './RecruiterAllCandidatesScreen.styles'
import { RecruiterAllCandidatesScreenProps } from './interfaces'

const CREATED_AT_DATE_FORMAT = 'MM-dd-yyyy'

const formatDate = (date: string, formatToUse = CREATED_AT_DATE_FORMAT) => {
  if (!date) return ''

  return format(new Date(date), formatToUse)
}

const AllCandidatesPlaceholder = () => (
  <CandidatesCardPlaceholder
    title="There’s no candidates yet"
    subtitle="Invite candidates and start tracking their progress by sharing the sign up link"
  />
)

const MyCandidatesPlaceholder = () => (
  <CandidatesCardPlaceholder
    title="You don’t have any candidates"
    subtitle="Invite candidates and start tracking their progress by sharing the sign up link"
  />
)

const ArchivedCandidatesPlaceholder = () => (
  <CandidatesCardPlaceholder
    title="You don’t have any archived candidates"
    subtitle="Start archiving candidates to see them here"
  />
)

export const RecruiterAllCandidatesScreen = ({
  testId = 'recruiter-candidates-screen-id',
  ...props
}: RecruiterAllCandidatesScreenProps) => {
  const IS_MOBILE = useMediaQuery('(max-width: 800px)')
  const navigate = useNavigate()
  const { user } = useUserContext()

  const IS_ALL_CANDIDATES_ROUTE = useMatch('/recruiter/candidate/all')
  const IS_MY_CANDIDATES_ROUTE = useMatch('/recruiter/candidate/my-candidates')
  const IS_ARCHIVED_CANDIDATES_ROUTE = useMatch('/recruiter/candidate/archived')
  const {
    candidates: remoteCandidates,
    myCandidates: remoteMyCandidates,
    archivedCandidates: remoteArchivedCandidates,
    getMyCandidates,
    getAllCandidates,
    getArchivedCandidates
  } = useRecruiterContext()

  const [candidates, setCandidates] = useState<any[]>(() => {
    if (IS_ALL_CANDIDATES_ROUTE) return remoteCandidates?.data || []
    if (IS_MY_CANDIDATES_ROUTE) return remoteMyCandidates?.data || []
    if (IS_ARCHIVED_CANDIDATES_ROUTE)
      return remoteArchivedCandidates?.data || []
    return []
  })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalFilterOpen, setIsFilterModalOpen] = useState(false)
  const [sortField, setSortField] = useState<string>('')
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc')
  const [mobileFilterChoice, setMobileFilterChoice] = useState([
    { value: 'Candidate name', sortKey: 'full_name', checked: false },
    { value: 'Job position', sortKey: 'job_title', checked: false },
    ...(IS_MY_CANDIDATES_ROUTE
      ? []
      : [
          {
            value: 'Assignation',
            sortKey: 'recruiter_full_name',
            checked: false
          }
        ]),
    { value: 'Date Created', sortKey: 'created_at', checked: false },
    { value: 'Recently Updated', sortKey: 'updated_at', checked: false }
  ])

  const getPaginatedData = () => {
    if (IS_MY_CANDIDATES_ROUTE) return remoteMyCandidates?.pagination
    if (IS_ARCHIVED_CANDIDATES_ROUTE)
      return remoteArchivedCandidates?.pagination
    return remoteCandidates?.pagination
  }

  const getQueryFunction = () => {
    if (IS_MY_CANDIDATES_ROUTE) return getMyCandidates
    if (IS_ARCHIVED_CANDIDATES_ROUTE) return getArchivedCandidates
    return getAllCandidates
  }

  const { setOrderBy, setOrderDirection, setSearchQuery, setPage } =
    usePaginationQuery(getQueryFunction())

  const handleOpenModal = () => setIsModalOpen(true)
  const handleCloseModal = () => setIsModalOpen(false)

  const handleOpenFilterModal = () => setIsFilterModalOpen(true)
  const handleCloseFilterModal = () => setIsFilterModalOpen(false)

  const requestSort = (fieldName) => {
    const SAME_FIELD = fieldName === sortField
    SAME_FIELD && setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
    setSortField(fieldName)
  }

  useEffect(() => {
    setOrderBy(sortField)
    setOrderDirection(sortOrder)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortField, sortOrder])

  const search = debounce(
    (searchTerms) => {
      setSearchQuery(searchTerms)
    },
    300,
    {
      trailing: true
    }
  )

  const fetchAll = () => {
    if (remoteCandidates?.data) {
      setCandidates(remoteCandidates.data)
    }
  }

  const fetchMyCandidates = () => {
    if (remoteMyCandidates?.data) {
      setCandidates(remoteMyCandidates.data)
    }
  }

  const fetchArchivedCandidates = () => {
    if (remoteArchivedCandidates?.data) {
      setCandidates(remoteArchivedCandidates.data)
    }
  }

  useEffect(() => {
    if (IS_MY_CANDIDATES_ROUTE) return fetchMyCandidates()
    if (IS_ARCHIVED_CANDIDATES_ROUTE) return fetchArchivedCandidates()
    return fetchAll()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    IS_MY_CANDIDATES_ROUTE,
    IS_ARCHIVED_CANDIDATES_ROUTE,
    IS_ALL_CANDIDATES_ROUTE,
    remoteCandidates?.data,
    remoteMyCandidates?.data,
    remoteArchivedCandidates?.data
  ])

  const _goToCandidateDetails = (id) => {
    navigate(`/recruiter/candidate/${id}/profile/details/about-me`)
  }

  const renderTableLine = (props: any, key) => {
    return (
      <S.TRHoverWrapper
        key={key}
        data-testid={'candidates-tr'}
        onClick={() => _goToCandidateDetails(props?.id)}
      >
        <td>
          <S.InlineWrapper>
            <S.ProfilePicture
              alt={`Avatar image of ${props.full_name}`}
              src={props.image}
            >
              {props.full_name && props.full_name.charAt(0)}
            </S.ProfilePicture>
            <Text type="BodySMB">{props.full_name}</Text>
          </S.InlineWrapper>
        </td>

        <td>
          <S.GrayText type="BodySMR" align="left">
            {truncate(props.job_position, { length: 24 })}
          </S.GrayText>
        </td>

        {!IS_MY_CANDIDATES_ROUTE && (
          <td>
            <S.GrayText type="BodySMR" align="left">
              {truncate(props.recruiter_full_name, { length: 24 })}
            </S.GrayText>
          </td>
        )}

        <td>
          <S.GrayText type="BodySMR" align="left">
            {formatDate(props?.created_at)}
          </S.GrayText>
        </td>

        <td>
          <S.GrayText type="BodySMR" align="left">
            {formatDate(props?.updated_at)}
          </S.GrayText>
        </td>
      </S.TRHoverWrapper>
    )
  }

  const renderDesktopTable = () => (
    <Table testId={'candidates-table'}>
      <thead>
        <tr>
          <S.TableHeaderWrapper onClick={() => requestSort('full_name')}>
            <S.TableHeaderContent>
              <Text type="BodySMB">Candidate Name</Text>
              {sortField === 'full_name' && sortOrder === 'desc' ? (
                <S.AngleSmallDown />
              ) : (
                <S.AngleSmallUp />
              )}
            </S.TableHeaderContent>
          </S.TableHeaderWrapper>

          <S.TableHeaderWrapper onClick={() => requestSort('job_title')}>
            <S.TableHeaderContent>
              <Text type="BodySMB">Job Position</Text>
              {sortField === 'job_title' && sortOrder === 'desc' ? (
                <S.AngleSmallDown />
              ) : (
                <S.AngleSmallUp />
              )}
            </S.TableHeaderContent>
          </S.TableHeaderWrapper>

          {!IS_MY_CANDIDATES_ROUTE && (
            <S.TableHeaderWrapper
              onClick={() => requestSort('recruiter_full_name')}
            >
              <S.TableHeaderContent>
                <Text type="BodySMB">Assignation</Text>
                {sortField === 'recruiter_full_name' && sortOrder === 'desc' ? (
                  <S.AngleSmallDown />
                ) : (
                  <S.AngleSmallUp />
                )}
              </S.TableHeaderContent>
            </S.TableHeaderWrapper>
          )}

          <S.TableHeaderWrapper>
            <S.TableHeaderContent onClick={() => requestSort('created_at')}>
              <Text type="BodySMB">Date Created</Text>
              {sortField === 'created_at' && sortOrder === 'desc' ? (
                <S.AngleSmallDown />
              ) : (
                <S.AngleSmallUp />
              )}
            </S.TableHeaderContent>
          </S.TableHeaderWrapper>

          <S.TableHeaderWrapper>
            <S.TableHeaderContent onClick={() => requestSort('updated_at')}>
              <Text type="BodySMB">Recently Updated</Text>
              {sortField === 'updated_at' && sortOrder === 'desc' ? (
                <S.AngleSmallDown />
              ) : (
                <S.AngleSmallUp />
              )}
            </S.TableHeaderContent>
          </S.TableHeaderWrapper>
        </tr>
      </thead>

      <tbody>{candidates?.map(renderTableLine)}</tbody>

      <TablePagination pagination={getPaginatedData()} setPage={setPage} />
    </Table>
  )

  const renderMobileTable = () => (
    <S.MobileTable>
      {candidates?.map((item, key) => (
        <S.MobileTableItem
          key={key}
          onClick={() => _goToCandidateDetails(item?.id)}
        >
          <S.ProfilePicture
            alt={`Avatar image of ${item.full_name}`}
            src={item.image}
          >
            {item.full_name && item.full_name.charAt(0)}
          </S.ProfilePicture>

          <S.TextWrapper>
            <Text type="BodyMB">{item.full_name}</Text>
            {item.job_position && (
              <Text type="BodySMR">{item.job_position}</Text>
            )}
          </S.TextWrapper>
        </S.MobileTableItem>
      ))}
    </S.MobileTable>
  )

  const HAS_ITEMS = size(candidates) > 0
  const Placeholder = (() => {
    if (IS_MY_CANDIDATES_ROUTE) return MyCandidatesPlaceholder
    if (IS_ARCHIVED_CANDIDATES_ROUTE) return ArchivedCandidatesPlaceholder
    return AllCandidatesPlaceholder
  })()

  const TableWithItems = IS_MOBILE ? renderMobileTable : renderDesktopTable

  return (
    <RecruiterBaseLayout data-testid={testId} {...props}>
      <S.Container>
        <S.Header>
          <S.Title>Candidates</S.Title>
          {IS_MOBILE ? (
            <S.MobileIconsWrapper>
              <S.MobileShareButton
                onClick={handleOpenFilterModal}
                role="button"
                aria-label="Share button"
              >
                <Filter />
              </S.MobileShareButton>
              <S.MobileShareButton
                onClick={handleOpenModal}
                role="button"
                aria-label="Share button"
              >
                <AddUser />
              </S.MobileShareButton>
            </S.MobileIconsWrapper>
          ) : (
            <S.ShareButton
              onClick={handleOpenModal}
              role="button"
              aria-label="Share button"
            >
              Add candidate <PlusSmall />
            </S.ShareButton>
          )}
        </S.Header>

        <S.TabsWrapper>
          <Tab
            active={!!IS_ALL_CANDIDATES_ROUTE}
            onClick={() => navigate('/recruiter/candidate/all')}
          >
            All candidates
          </Tab>
          <Tab
            active={!!IS_MY_CANDIDATES_ROUTE}
            onClick={() => navigate('/recruiter/candidate/my-candidates')}
          >
            My candidates
          </Tab>
          <Tab
            active={!!IS_ARCHIVED_CANDIDATES_ROUTE}
            onClick={() => navigate('/recruiter/candidate/archived')}
          >
            Archived candidates
          </Tab>
        </S.TabsWrapper>
        <S.InputWrapper>
          <S.InputText
            endIcon={<SearchIconPure />}
            placeholder="Search"
            onChange={(e) => {
              e.preventDefault()
              search(e.target.value)
            }}
          ></S.InputText>
        </S.InputWrapper>

        {HAS_ITEMS ? <TableWithItems /> : <Placeholder />}
      </S.Container>

      <Modal isOpen={isModalOpen} handleClose={handleCloseModal}>
        <ModalContentPanel
          testId={testId}
          Icon={<AddUserWithBackground />}
          title="Add candidate"
          body="Share the following link to the candidates you want to add to the program"
          textToCopy={`${window.location.host}/users/sign-up/${user?.secret_id}`}
        />
      </Modal>

      <Modal isOpen={isModalFilterOpen} handleClose={handleCloseFilterModal}>
        <S.FilterModalContainer>
          <Text type="BodyLB">Sort by</Text>

          <S.MobileFilterWrapper>
            <ContentSwitcher
              name={'mobile-candidates-filter'}
              items={mobileFilterChoice}
              onChange={(selectedOption) => {
                setMobileFilterChoice((choices) => {
                  return choices.map((choice) => {
                    choice.value === selectedOption
                      ? (choice.checked = true)
                      : (choice.checked = false)
                    return choice
                  })
                })
              }}
            />
          </S.MobileFilterWrapper>

          <S.FilterButtons>
            <Button
              size="small"
              onClick={() => {
                const selected = mobileFilterChoice.find((item) => item.checked)
                setSortOrder('asc')
                setSortField(selected?.sortKey || 'full_name')
                handleCloseFilterModal()
              }}
            >
              Apply
            </Button>
            <Button
              size="small"
              styling="secondary"
              onClick={() => {
                setMobileFilterChoice((choices) =>
                  choices.map((item) => {
                    item.checked = false
                    return item
                  })
                )
              }}
            >
              Clear
            </Button>
          </S.FilterButtons>
        </S.FilterModalContainer>
      </Modal>
    </RecruiterBaseLayout>
  )
}

import { useUserContext } from '@hooks/user/UserContextProvider'
import { Navigate } from 'react-router-dom'

export const ProtectedRoute = ({ children }) => {
  const { isAuthenticated: isLoggedIn } = useUserContext()
  return isLoggedIn ? <>{children}</> : <Navigate to="/users/sign-up" />
}

export const RedirectUserToProfile = () => {
  const { user, isAuthenticated: isLoggedIn } = useUserContext()
  if (!isLoggedIn) return <Navigate to="/users/sign-in" />

  const IS_RECRUITER = user?.role === 'recruiter'
  return IS_RECRUITER ? (
    <Navigate to="/recruiter/applications" />
  ) : (
    <Navigate to="/candidate/profile" />
  )
}

export const ProtectedRecruiterRoute = ({ children }) => {
  const { user } = useUserContext()
  if (!user) return <>{children}</>

  const IS_RECRUITER = user?.role === 'recruiter'
  return IS_RECRUITER ? <>{children}</> : <Navigate to="/candidate/profile" />
}

import { ProfileCardPlaceholder } from '@components/molecules/ProfileCardPlaceholder'
import { AssessmentIcon } from '@commons/Icons'
import { Card, CardContent } from '../commons/Card.styles'
import { Text } from '@components/atoms/Text'
import * as S from './AssessmentProfileCard.styles'
import { PdfView } from '@components/atoms/PdfView'
import { useEffect, useState } from 'react'
import { UploadPdf } from '@components/molecules/UploadPdf'
import {
  useAssessment,
  useDeleteAssessment
} from '@hooks/assessment/useAssessment'
import { useParams } from 'react-router-dom'
import { useProfileContext } from '@hooks/profile/ProfileContextProvider'

interface AssessmentProfileCardUploadProps {
  testId?: string
  profile: any
  fetch: () => void
}

export const AssessmentProfileCardUpload = ({
  testId = 'assessment-profile-card-id',
  profile,
  fetch
}: AssessmentProfileCardUploadProps) => {
  const [edit, setEdit] = useState(false)
  const { id } = useParams()
  const { loading, response, update } = useAssessment(id)
  const { response: resDelete, deleteAssessment } = useDeleteAssessment(id)

  useEffect(() => {
    if (response?.status === 200) fetch()
  }, [response])

  useEffect(() => {
    if (resDelete?.status === 200) fetch()
  }, [resDelete])

  return (
    <div data-testid={testId}>
      {profile?.assessment_url || edit ? (
        <>
          <Card>
            <CardContent>
              <S.Heading>
                <AssessmentIcon />
                <div>
                  <Text type="GlobalH5">Assessment report</Text>
                  <Text type="BodyMR">
                    View and click through the full Talent Insights assessment
                    report for this candidate.
                  </Text>
                </div>
              </S.Heading>
              <S.UploadWrapper>
                <UploadPdf
                  onFileUpload={(file) => {
                    update(file)
                  }}
                  file={
                    profile?.assessment_url
                      ? {
                          name: profile?.assessment_url.split('/').pop()
                        }
                      : null
                  }
                  onFileRemove={() => deleteAssessment()}
                />
              </S.UploadWrapper>
            </CardContent>
          </Card>

          {profile?.assessment_url && (
            <PdfView fileUrl={profile?.assessment_url} />
          )}
        </>
      ) : (
        <Card>
          <CardContent>
            <ProfileCardPlaceholder
              icon={<AssessmentIcon />}
              title="Assessment"
              subtitle="You haven’t uploaded yet the assesment
              results of your candidate"
              callToAction="Upload assesment"
              onClick={() => setEdit(true)}
            />
          </CardContent>
        </Card>
      )}
    </div>
  )
}

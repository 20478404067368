import styled from 'styled-components'

export const Container = styled.button`
  opacity: ${(props) => (props.disabled ? '0.56' : '1')};
  display: flex;
  align-items: center;
  padding: 0;
  border: none;
  background-color: transparent;
  gap: 1rem;
  cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};

  div {
    background-color: ${({ theme, disabled }) =>
      disabled ? theme.colors.primaryGray : theme.colors.secondaryGreen};
    border-radius: 50%;
    padding: 8px;
    transition: ${({ theme }) => theme.transition.fast};
  }

  &:hover div {
    background-color: ${({ theme, disabled }) =>
      disabled ? theme.colors.primaryGray : theme.colors.shades.primary500};
    svg path {
      fill: ${({ theme, disabled }) =>
        disabled ? theme.colors.primaryBlue : theme.colors.primaryWhite};
    }
  }

  svg {
    display: block;
    width: 16px;
    height: 16px;
  }

  p {
    line-height: 18px;
  }
`

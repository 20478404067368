import {
  yupEmail,
  yupFullName,
  yupPassword,
  yupPasswordConfirmation
} from '@components/commons/Form/validations'
import { Button } from '@components/atoms/Button'
import { InputText } from '@components/atoms/InputText'
import { PasswordField } from '@components/atoms/PasswordField'
import { useUserContext } from '@hooks/user/UserContextProvider'
import { Form, Formik, FormikProps } from 'formik'
import * as Yup from 'yup'
import * as S from '../FormCommons/Form.styles'

interface Values {
  fullName: string
  email: string
  password: string
}

const idContext = `candidate-sign-up-form`

const validationSchema = () => {
  return Yup.object().shape({
    fullName: yupFullName,
    email: yupEmail,
    password: yupPassword,
    confirmPassword: yupPasswordConfirmation
  })
}

type Props = {
  testId?: string
  secretId: string
  redirectToSignIn: () => void
}

export const CandidateSignUpForm = ({
  testId = 'candidate-form-test-id',
  secretId,
  redirectToSignIn
}: Props) => {
  const { signUp } = useUserContext()

  return (
    <S.FormWrapper data-testid={testId}>
      <S.Header>
        <S.Heading>Sign Up</S.Heading>
        <S.Text>Every revolution needs revolutionary people!</S.Text>
      </S.Header>
      <S.Body>
        <Formik
          validationSchema={validationSchema}
          initialValues={{
            email: '',
            fullName: '',
            password: '',
            confirmPassword: ''
          }}
          onSubmit={async (values, actions) => {
            const { email, fullName, password, confirmPassword } = values

            try {
              await signUp(email, fullName, password, confirmPassword, secretId)
            } catch (e: any) {
              const error = e?.response?.data?.error || 'Invalid informations'
              actions.setErrors({
                email: error,
                fullName: error,
                password: error,
                confirmPassword: error
              })
            }

            actions.setSubmitting(false)
          }}
        >
          {(props: FormikProps<Values>) => (
            <Form>
              <S.InputWrapper>
                <InputText
                  label={'Full Name *'}
                  id={`fullname-${idContext}`}
                  placeholder="John Doe"
                  type="text"
                  name="fullName"
                  {...props}
                />
              </S.InputWrapper>
              <S.InputWrapper>
                <InputText
                  label="Email *"
                  id={`email-${idContext}`}
                  placeholder="johndoe@jombine.com"
                  type="email"
                  name="email"
                  {...props}
                />
              </S.InputWrapper>
              <S.InputWrapper>
                <PasswordField
                  name="password"
                  label="Password *"
                  id={`password-${idContext}`}
                  placeholder="Min a 8 character password"
                  {...props}
                />
              </S.InputWrapper>
              <S.InputWrapper>
                <PasswordField
                  name="confirmPassword"
                  label="Confirm Password *"
                  id={`confirm-password-${idContext}`}
                  placeholder="Confirm password"
                  {...props}
                />
              </S.InputWrapper>
              <S.LinkWrapper>
                <Button
                  disabled={!props.isValid}
                  size="full"
                  height="large"
                  type="submit"
                >
                  Sign Up
                </Button>
              </S.LinkWrapper>
              Already have an account? {` `}
              <S.CallToActionButtonWrapper>
                <Button
                  loading={props.isSubmitting}
                  styling="link"
                  size="small"
                  type="button"
                  onClick={redirectToSignIn}
                >
                  Sign In
                </Button>
              </S.CallToActionButtonWrapper>
            </Form>
          )}
        </Formik>
      </S.Body>
    </S.FormWrapper>
  )
}

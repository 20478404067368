import { yupEmail, yupPassword } from '@components/commons/Form/validations'
import { Button } from '@components/atoms/Button'
import { InputText } from '@components/atoms/InputText'
import { PasswordField } from '@components/atoms/PasswordField'
import { useUserContext } from '@hooks/user/UserContextProvider'
import { Form, Formik, FormikProps } from 'formik'
import * as Yup from 'yup'
import * as S from '../FormCommons/Form.styles'

interface Values {
  email: string
  password: string
}

const idContext = `candidate-sign-in-form`

const validationSchema = () => {
  return Yup.object().shape({
    email: yupEmail,
    password: yupPassword
  })
}

type Props = {
  testId?: string
  redirectToForgotPassword: () => void
}

export const CandidateSignInForm = ({
  testId = 'candidate-form-test-id',
  redirectToForgotPassword
}: Props) => {
  const { signIn } = useUserContext()

  return (
    <S.FormWrapper data-testid={testId}>
      <S.Header>
        <S.Heading>Sign In</S.Heading>
        <S.Text>Every revolution needs revolutionary people!</S.Text>
      </S.Header>
      <S.Body>
        <Formik
          validateOnMount
          validationSchema={validationSchema}
          initialValues={{
            email: '',
            password: ''
          }}
          onSubmit={async (values, actions) => {
            const { email, password } = values

            try {
              await signIn(email, password)
            } catch (e: any) {
              const error = e?.response?.data?.error || ''
              actions.setErrors({ email: error, password: error })
            }

            actions.setSubmitting(false)
          }}
        >
          {(props: FormikProps<Values>) => (
            <Form>
              <S.InputWrapper>
                <InputText
                  label="Email *"
                  id={`email-${idContext}`}
                  placeholder="Enter email here"
                  type="email"
                  name="email"
                  {...props}
                />
              </S.InputWrapper>

              <S.InputWrapper>
                <PasswordField
                  name="password"
                  label="Password *"
                  id={`password-${idContext}`}
                  placeholder="Enter password here"
                  {...props}
                />
              </S.InputWrapper>

              <S.LinkWrapper>
                <Button
                  styling="link"
                  size="small"
                  type="button"
                  onClick={redirectToForgotPassword}
                >
                  Forgot your password?
                </Button>
              </S.LinkWrapper>

              <Button
                loading={props.isSubmitting}
                disabled={!props.isValid || !!props.isSubmitting}
                size="full"
                height="large"
                type="submit"
              >
                Log In
              </Button>
            </Form>
          )}
        </Formik>
      </S.Body>
    </S.FormWrapper>
  )
}

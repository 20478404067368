import logo from '@assets/logo.svg'
import * as S from './InitialScreen.styles'

export interface InitialScreenProps {
  children: React.ReactNode
  testId?: string
}

export const InitialScreen = ({
  children,
  testId = 'component-InitialScreen-id'
}: InitialScreenProps) => {
  return (
    <S.Container data-testid={testId}>
      <S.LogoImage src={logo} />
      <S.LeftPanel>
        <S.LeftWrapper>
          <S.ImagesPanel>
            <S.MobileLogo src={logo} />
          </S.ImagesPanel>
          <S.TextPanel>
            <S.Background />
            <S.Title>
              <h1>Let’s start a hiring</h1>
              <h1>revolution</h1>
            </S.Title>

            <S.SubTitle>
              Candidates and Companies coming together to build teams around
              common culture, goals, and values.
            </S.SubTitle>
          </S.TextPanel>
        </S.LeftWrapper>
      </S.LeftPanel>

      <S.RightPanel>
        <S.Wrapper>{children}</S.Wrapper>
      </S.RightPanel>
    </S.Container>
  )
}

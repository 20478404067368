import api from '@api/index'
import { addToast } from '@components/atoms/Toast'
import { useUserContext } from '@hooks/user/UserContextProvider'
import { useState } from 'react'

export const useResume = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [response, setResponse] = useState<any>(null)

  const { logOut } = useUserContext()

  const update = async (file: any) => {
    setLoading(true)
    try {
      const formData = new FormData()
      formData.append('file', file)
      const headersConfig = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      const res = await api.put(
        '/users/profile/resumes',
        formData,
        headersConfig
      )
      setResponse(res)
      addToast({ title: 'Successfully updated' }, 'success', {})
    } catch (error: any) {
      if (error?.response?.status === 401) logOut()
    } finally {
      setLoading(false)
    }
  }

  return { loading, response, update }
}

export const useDeleteResume = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [response, setResponse] = useState<any>(null)

  const { logOut } = useUserContext()

  const deleteResume = async () => {
    setLoading(true)
    try {
      const res = await api.delete('/users/profile/resumes')
      setResponse(res)
      addToast({ title: 'successfully updated' }, 'success', {})
    } catch (error: any) {
      if (error?.response?.status === 401) logOut()
      addToast({ title: error?.response?.data?.message }, 'error', {})
    } finally {
      setLoading(false)
    }
  }

  return { loading, response, deleteResume }
}

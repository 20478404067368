import { Plus } from '@commons/Icons'
import { addToast } from '@components/atoms/Toast'
import { get, invoke, set } from 'lodash'
import React, { useState } from 'react'
import { PhotoUploadProps } from './interfaces'
import * as S from './PhotoUpload.styles'

export const ERROR_MESSAGE_FILE_SIZE = 'File size exceeds 4MB, try again'
export const ERROR_MESSAGE_WRONG_FORMAT =
  'This format is not supported. Try with .png, .jpg or .jpeg'

export const PhotoUpload = ({
  testId = 'PhotoUpload-id',
  onFileUpload,
  loading,
  file,
  ...props
}: PhotoUploadProps) => {
  const [image, setImage] = useState<any>(file)

  const hiddenFileInput = React.useRef(null)
  const HAS_UPLOADED_IMAGE = !!image

  const proccessFileBlob = (fileReader, originalFile) => {
    const image = new Image()
    const blob = fileReader.result
    image.src = blob

    image.onload = () => {
      setImage(originalFile)
      onFileUpload(originalFile, blob)
    }
  }

  const _resetInput = (event?: any) => {
    set(event, 'target.value', null)

    setImage(null)
  }

  const _showToast = (message: string) =>
    addToast({ title: message }, 'error', {})

  const _validateFileSize = (file, event) => {
    const fileSize = get(file, 'size', 1) / 1024 / 1024
    const IS_BIGGER_THAN_4_MEGAS = fileSize > 4
    if (IS_BIGGER_THAN_4_MEGAS) {
      _resetInput(event)
      _showToast(ERROR_MESSAGE_FILE_SIZE)
      throw new Error(ERROR_MESSAGE_FILE_SIZE)
    }
  }

  const _validateFileFormat = (file, event) => {
    const acceptedFormats = ['image/png', 'image/jpeg']
    const HAS_VALID_FORMAT = acceptedFormats.includes(file.type)
    if (!HAS_VALID_FORMAT) {
      _resetInput(event)
      _showToast(ERROR_MESSAGE_WRONG_FORMAT)
      throw new Error(ERROR_MESSAGE_WRONG_FORMAT)
    }
  }

  const _handleUpload = (event: any) => {
    const fileReader: any = new FileReader()
    const file = get(event, 'target.files[0]')

    _validateFileSize(file, event)
    _validateFileFormat(file, event)

    fileReader.onload = () => {
      if (fileReader.readyState === 2) {
        proccessFileBlob(fileReader, file)
      }
    }

    if (file) fileReader.readAsDataURL(file)
  }

  const _handleClick = () => {
    invoke(hiddenFileInput, 'current.click')
  }

  const _handleCancelUpload = () => {
    _resetInput()
    setTimeout(_handleClick, 100)
    onFileUpload(null)
  }

  return (
    <S.Photo
      data-testid={testId}
      title="Upload a .jpg, .png no higher than 4MB."
      onClick={_handleClick}
      loading={!!loading}
      click
      {...props}
    >
      <Plus />
      {HAS_UPLOADED_IMAGE && <img src={URL.createObjectURL(image)} />}
      <input
        accept=".png, .jpg, .jpeg"
        type="file"
        hidden
        ref={hiddenFileInput}
        onChange={_handleUpload}
      />
      {loading && <S.Spinner />}
    </S.Photo>
  )
}

import { addToast } from '@components/atoms/Toast'
import { isString } from '@utils/utils'

type HandleError = (err: any, errorMsg?: string) => void

const getErrorMessage = (response: any) => {
  if (response?.data?.errors) return response?.data?.errors

  if (response?.data?.error_message) return response?.data?.error_message

  if (response?.data?.message) return response?.data?.message

  if (isString(response?.data?.error?.message)) {
    return response?.data?.error?.message
  }

  if (isString(response?.data?.error)) return response?.data?.error

  return 'An error ocurred, please try again later'
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const handleSuccess = (msg: string) => {
  addToast({ title: msg }, 'success')
}

export const handleError: HandleError = (err, errMsg = undefined) => {
  let msg = 'Connection Error'
  let errorType = ''
  if (errMsg) msg = errMsg
  else if (err.response) {
    switch (err.response.status) {
      case 500:
        msg = 'An error has occurred on our server. Please try again later'
        break
      case 422:
        errorType = 'error'
        msg = getErrorMessage(err.response)
        break
      default:
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        msg = getErrorMessage(err.response)
        break
    }
  }
  // handle error message
  if (errorType) {
    addToast({ title: msg }, 'error')
  } else {
    addToast({ title: msg }, 'error')
  }
}

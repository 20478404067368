import { AssessmentIcon } from '@commons/Icons'
import { PdfView } from '@components/atoms/PdfView'
import { Text } from '@components/atoms/Text'
import { ProfileCardPlaceholder } from '@components/molecules/ProfileCardPlaceholder'
import { useProfileContext } from '@hooks/profile/ProfileContextProvider'
import { Card, CardContent } from '../commons/Card.styles'
import * as S from './AssessmentProfileCard.styles'
import { AssessmentProfileCardProps } from './interfaces'

export const AssessmentProfileCard = ({
  testId = 'assessment-profile-card-id',
  profileData
}: AssessmentProfileCardProps) => {
  const { profile: profileGetData } = useProfileContext()

  const profile = profileData ? profileData : profileGetData

  return (
    <div data-testid={testId}>
      {profile?.assessment_url ? (
        <>
          <Card>
            <CardContent>
              <S.Heading>
                <AssessmentIcon />
                <div>
                  <Text type="GlobalH5">Assessment report</Text>
                  <Text type="BodyMR">
                    View and click through the full Talent Insights assessment
                    report for this candidate.
                  </Text>
                </div>
              </S.Heading>
            </CardContent>
          </Card>
          <PdfView fileUrl={profile?.assessment_url} />
        </>
      ) : (
        <Card>
          <CardContent>
            <ProfileCardPlaceholder
              icon={<AssessmentIcon />}
              title="Assessment report"
              subtitle="Your Agent will send an invitation to your email to take our assessments. After completion, your assessments will be uploaded in this section for your review."
            />
          </CardContent>
        </Card>
      )}
    </div>
  )
}

import { Star } from '@commons/Icons'
import { ProfileCardTitleBar } from '@components/molecules/ProfileCardTitleBar'
import { ProfessionalHighlightsForm } from '@components/organisms/ProfessionalHighlightsForm'
import { CardContent } from '../../commons/Card.styles'

export const EditProfessionalHighlights = ({ edit, onCancel, onSucess }) => (
  <>
    <ProfileCardTitleBar
      edit={edit}
      title="Professional highlights"
      icon={<Star width={24} height={24} color="#8E97A0" />}
    />
    <CardContent>
      <ProfessionalHighlightsForm onCancel={onCancel} onSucess={onSucess} />
    </CardContent>
  </>
)

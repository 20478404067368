import { useField } from 'formik'
import * as S from './InputNumber.styles'

export const InputNumber = ({
  testId = 'input-number-id',
  label,
  startIcon,
  endIcon,
  hasError = false,
  ...props
}: any) => {
  const [field, meta] = useField(props)
  return (
    <>
      {label && <S.Label>{label}</S.Label>}
      <S.InputWrapper>
        <S.Input
          data-testid={testId}
          startIcon={startIcon}
          hasError={hasError || (meta.touched && meta.error)}
          {...field}
          {...props}
        />
      </S.InputWrapper>
      {meta.touched && meta.error ? (
        <S.ErrorMessage>{meta.error}</S.ErrorMessage>
      ) : null}
    </>
  )
}

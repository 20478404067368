import * as S from './ProfileScreenTabs.styles'
import { ProfileScreenTabsProps } from './interfaces'
import { Tab } from '@components/atoms/Tab'
import { useNavigate, useParams } from 'react-router-dom'

export const TABS = [
  {
    key: 'about-me',
    label: 'About me'
  },
  {
    key: 'experience',
    label: 'Experience'
  },
  {
    key: 'workplace-dna',
    label: 'Workplace DNA'
  },
  {
    key: 'assessment',
    label: 'Assessment'
  },
  {
    key: 'video-interview',
    label: 'Video interview'
  },
  {
    key: 'references',
    label: 'References'
  }
]

const DEFAULT_TAB = 'about-me'

export const ProfileScreenTabs = ({
  testId = 'profile-screen-tabs-id',
  ...props
}: ProfileScreenTabsProps) => {
  const navigate = useNavigate()
  const { tab } = useParams()

  return (
    <S.Container data-testid={testId} {...props}>
      {TABS.map((tabItem) => {
        const isActive = tabItem.key === (tab || DEFAULT_TAB)
        return (
          <Tab
            key={tabItem.key}
            active={isActive}
            data-testid={`${tabItem.key}-profile-tab`}
            onClick={() => navigate(`/candidate/profile/${tabItem.key}`)}
          >
            {tabItem.label}
          </Tab>
        )
      })}
    </S.Container>
  )
}

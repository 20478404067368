import { Alert } from '@components/atoms/Alert'
import { CSSProperties } from 'react'
import toast, { Toaster, ToastOptions, ToastPosition } from 'react-hot-toast'

const defaultDuration = 5000
const defaultPosition: ToastPosition = 'bottom-center'
const toastBorderRadius = 4
const toastMaxWidth = 600

export const addToast = (
  notification: {
    title: string
    subtitle?: string
    link?: string
    linkUrl?: string
  },
  type: Severity,
  toastOptions: ToastOptions = {}
): void => {
  const options: ToastOptions = {
    duration: defaultDuration,
    position: defaultPosition,
    className: type,
    ...toastOptions
  }
  const toastFn = toast['custom']
  toastFn(
    (t) => (
      <Alert
        severity={type}
        subtitle={notification.subtitle}
        link={notification.link}
        linkUrl={notification.linkUrl}
        onClose={() => toast.dismiss(t.id)}
      >
        {notification.title}
      </Alert>
    ),
    options
  )
}

const toastContainerCss: CSSProperties = {
  padding: 0,
  borderRadius: toastBorderRadius,
  boxShadow: 'none',
  maxWidth: toastMaxWidth
}

export type Severity = 'info' | 'error' | 'warning' | 'success'

export const Toast = () => (
  <Toaster
    toastOptions={{
      style: toastContainerCss
    }}
  />
)

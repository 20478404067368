import ListItemIcon from '@mui/material/ListItemIcon'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'

import { Text } from '@components/atoms/Text'
import Menu from '@mui/material/Menu'

export const CustomMenuItem = ({
  CustomIcon,
  listItemText,
  color,
  ...rest
}: any) => {
  return (
    <MenuItem
      sx={{
        ':hover': { backgroundColor: '#F2F5F7' },
        padding: '12px',
        '& + &': { marginTop: '12px' },
        borderRadius: '8px'
      }}
      {...rest}
    >
      {CustomIcon && (
        <ListItemIcon>
          <CustomIcon />
        </ListItemIcon>
      )}
      <Text type="BodyMR" style={{ color, fontSize: '16px' }}>
        {listItemText}
      </Text>
    </MenuItem>
  )
}

export function DropdownMenu({ children, open = false, ...rest }) {
  return (
    <Menu
      open={open}
      PaperProps={{
        sx: {
          width: 263,
          maxWidth: '100%',
          border: '1.5px solid #E6EBF0',
          borderRadius: '16px',
          marginTop: '30px'
        }
      }}
      {...rest}
    >
      <MenuList sx={{ padding: '12px', outline: 0 }}>{children}</MenuList>
    </Menu>
  )
}

import { BodyMR, BodyS, BodySMR } from '@components/atoms/Text/Text.styles'
import styled from 'styled-components'

import { UserPicture } from '@components/atoms/UserPicture'

export const CandidateProfileDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  flex-wrap: wrap;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-direction: column;
    align-items: flex-start;
    margin: 32px 24px;
  }
`

export const ProfilePicture = styled(UserPicture)`
  width: 104px !important;
  height: 104px !important;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin-bottom: 32px;
  }
`

export const DetailsWrapper = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  margin-left: 40px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 100%;
    margin: 0;
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  gap: 8px 24px;
  flex-wrap: wrap;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    grid-template-columns: 1fr;
    flex-direction: column;
  }
`

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  color: ${({ theme }) => theme.colors.shades.primary300};

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    ${BodySMR}
  }

  ${BodyMR}

  svg {
    width: 16px;
    height: 16px;
  }
`

export const UserFullName = styled.h4`
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin-bottom: 8px;
  }

  ${BodyS}
`

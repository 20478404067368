import * as S from './FormContainer.styles'
import { FormContainerProps } from './interfaces'

export const FormContainer = ({
  testId = 'form-container-id',
  border = true,
  ...props
}: FormContainerProps) => {
  return <S.Container border={border} data-testid={testId} {...props} />
}

import { yupTextArea } from '@commons/Form/validations'
import { UserIconWithoutColor } from '@commons/Icons'
import { InputText } from '@components/atoms/InputText'
import { Select } from '@components/atoms/Select'
import * as Yup from 'yup'
import * as F from './Form.styles'
import * as S from './ReferenceFormScreen.styles'

export const initialValues = {
  fullName: '',
  relationship: ''
}

const idContext = `candidate-reference-form`

const DROPDOWN_OPTIONS = [
  { label: 'Employer', value: 'employer' },
  { label: 'Manager', value: 'manager' },
  { label: 'Co-worker', value: 'co_worker' },
  { label: 'Client', value: 'client' }
]

export const validationSchema = () => {
  return Yup.object().shape({
    fullName: yupTextArea,
    relationship: yupTextArea
  })
}

export const GeneralInformationSection = () => (
  <S.GeneralInformationSection
    title={
      <>
        <UserIconWithoutColor /> <h1>General Info</h1>
      </>
    }
  >
    <GeneralInfoForm />
  </S.GeneralInformationSection>
)

export const GeneralInfoForm = ({
  testId = 'application-GeneralInfoForm-test-id',
  props
}: any) => {
  return (
    <F.FormWrapper data-testid={testId}>
      <F.Body>
        <F.InputWrapper>
          <InputText
            label="Full name *"
            id={`full-name-${idContext}`}
            placeholder="John Doe"
            name="fullName"
            data-testid="input"
            {...props}
          />
        </F.InputWrapper>

        <F.InputWrapper>
          <Select
            id={`relationship-${idContext}`}
            placeholder="Select an option"
            name="relationship"
            data-testid="input"
            label="Relationship *"
            options={DROPDOWN_OPTIONS}
            {...props}
          />
        </F.InputWrapper>
      </F.Body>
    </F.FormWrapper>
  )
}

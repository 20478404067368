import illustration from '@assets/candidate/illustration.png'
import { HiOutlineClock } from 'react-icons/hi'
import styled from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.div`
  align-items: flex-end;
  display: flex;
  justify-content: left;
  width: 100vw;
  min-height: 100vh;

  background: ${({ theme }) =>
    theme.colors.primaryBlue}; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom right,
    #0c1925,
    #1d2f41
  ); /* Chrome 10-25, Safari 5.1-6 */

  background: linear-gradient(
    to bottom right,
    #0c1925,
    #1d2f41
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  ::after {
    content: '';
    position: absolute;
    right: 0;
    top: 207px;
    height: 17px;
    width: calc(((100vw - 1337px) / 2) + 2px);
    background-color: #273543;

    @media (max-width: 1337px) {
      width: calc((100vw - 1170px) / 2);
      top: 199px;
      height: 14px;
    }
  }

  ${media.lessThan('medium')`
		align-items: flex-start;
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
	`}
`

export const CenterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 90px 0 0;
  position: relative;
  width: 1337px;
  margin: 0 auto;

  @media (max-width: 1337px) {
    width: 1170px;
  }

  ${media.lessThan('large')`
	  padding: 90px 4rem 128px;
	`}

  ${media.lessThan('medium')`
	  width: 100%;
		height: 100%;
		min-height: 0;
		margin: 0;
  	padding: 0;
		justify-content: flex-start;
		align-items: flex-start;
	`};
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 220px;
  z-index: 1;

  > p {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.shades.gray900};
  }

  ${media.lessThan('medium')`
	  margin: 0 24px;
		height: auto;
    padding-top: 0;
	`}
`

export const Background = styled.div`
  height: 100%;
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  background-image: url(${illustration});
  background-position: center 140px;
  background-repeat: no-repeat;

  @media (max-width: 1337px) {
    background-size: 1170px;
  }

  ${media.lessThan('large')`
		display: none;
	`}
`

export const Logo = styled.img`
  height: 35px;
  margin-left: 0;
  position: absolute;

  ${media.lessThan('medium')`
		display: none;
	`}
`

export const MobileLogo = styled.img`
  display: none;

  ${media.lessThan('medium')`
		display: block;
		margin: 56px auto;
		position: relative;
  	height: 28px;
	`}
`

export const FooterImage = styled.img`
  display: none;

  ${media.lessThan('medium')`
		display: block;
		position: relative;
		width: 80%;
  	height: 50%;
    margin-top: 24px;
	`}
`
export const TextContent = styled.div`
  width: 600px;

  h1 {
    color: ${({ theme }) => theme.colors.primaryWhite};
    font-size: 96px;
    font-weight: 600;
    line-height: 110%;

    @media (max-height: 940px) {
      font-size: 76px;
    }
  }

  h1 + h1 {
    color: ${({ theme }) => theme.colors.shades.secondary500};
  }

  p {
    color: ${({ theme }) => theme.colors.primaryWhite};
    font-size: 16px;
    font-weight: 400;
    line-height: 160%;
    margin-top: 16px;
    padding-right: 76px;
  }

  ${media.lessThan('medium')`
	  width: 100%;
		height: 100%;

		h1 {
			font-size: 48px;
		}
	`}
`
export const ButtonContainer = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.primaryWhite};
  display: flex;
  flex-direction: row;
  gap: 32px;
  margin-bottom: 24px;
  margin-top: 48px;

  > button {
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    height: 56px;
    letter-spacing: 0.02em;
    width: 224px;
  }

  ${media.lessThan('small')`
		flex-direction: column-reverse;
		align-items: flex-start;
	`}
`

export const ClockContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
  justify-content: center;

  & p {
    color: ${({ theme }) => theme.colors.primaryWhite};
  }
`

export const ClockIcon = styled(HiOutlineClock)`
  color: ${({ theme }) => theme.colors.shades.secondary500};
  height: 24px;
  width: 24px;
`

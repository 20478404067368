import { Logo } from '@components/atoms/Logo'
import { useMediaQuery } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { NotFoundProps } from './interfaces'
import * as S from './NotFound.styles'

export const NotFound = ({
  testId = 'not-found-id',
  title,
  subtitle,
  ...props
}: NotFoundProps) => {
  const IS_MOBILE = useMediaQuery('(max-width: 800px)')

  const defaultTitle = IS_MOBILE ? (
    <>
      Oops!
      <br /> Page not found
    </>
  ) : (
    <>Oops! Page not found</>
  )

  const defaultSubTitle = (
    <>
      This page doesn’t exist or was removed!
      <br />
      We suggest you go back home
    </>
  )

  const navigate = useNavigate()

  const _goToHome = () => {
    navigate('/')
  }

  return (
    <S.Container data-testid={testId} {...props}>
      <S.DesktopIllustration />
      <S.MobileIllustration />

      <S.MainWrapper>
        <S.LogoWrapper>
          <Logo blue />
        </S.LogoWrapper>

        <S.ContentWrapper>
          <S.Title>{title || defaultTitle}</S.Title>
          <S.SubTitle>{subtitle || defaultSubTitle}</S.SubTitle>

          <S.Button onClick={_goToHome}>Back to home</S.Button>
        </S.ContentWrapper>
      </S.MainWrapper>
    </S.Container>
  )
}

import { PhotosProfileCardProps } from './interfaces'
import { ProfileCardTitleBar } from '@components/molecules/ProfileCardTitleBar'
import { ProfileCardPictureIcon } from '@commons/Icons'
import { Photos, PhotosView } from '@components/organisms/Photos'
import { Card, CardContent } from './PhotosProfileCard.styles'
import { useProfileContext } from '@hooks/profile/ProfileContextProvider'

export const PhotosProfileCard = ({
  testId = 'photos-profile-card-id',
  ...props
}: PhotosProfileCardProps) => {
  const { profile, fetchProfile } = useProfileContext()

  return (
    <Card data-testid={testId} {...props}>
      <ProfileCardTitleBar
        title="Photos"
        icon={<ProfileCardPictureIcon width={24} height={24} color="#8E97A0" />}
      />
      <CardContent>
        <Photos pictures={profile?.pictures} onSucess={fetchProfile} />
      </CardContent>
    </Card>
  )
}

export const PhotosCard = ({
  testId = 'photos-card-id',
  profile,
  ...props
}: any) => {
  return (
    <Card data-testid={testId} {...props}>
      <ProfileCardTitleBar
        title="Photos"
        icon={<ProfileCardPictureIcon width={24} height={24} color="#8E97A0" />}
      />
      <CardContent>
        {profile?.pictures ? <PhotosView photos={profile?.pictures} /> : <>-</>}
      </CardContent>
    </Card>
  )
}

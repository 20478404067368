import api from '@api/index'
import { addToast } from '@components/atoms/Toast'
import { useUserContext } from '@hooks/user/UserContextProvider'
import { useState } from 'react'

export const useSettings = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [response, setResponse] = useState<any>(null)
  const { logOut } = useUserContext()
  const { user, setUser } = useUserContext()

  const setGeneralInfo = async ({
    firstName,
    lastName,
    email,
    city,
    phone
  }: any) => {
    setLoading(true)
    try {
      const res = await api.put(`/recruiters/${user.id}/general_infos`, {
        first_name: firstName,
        last_name: lastName,
        email,
        city,
        phone
      })
      setResponse(res)
      addToast({ title: 'General Info successfully changed' }, 'success', {})
      setUser({ ...res.data, token: localStorage.getItem('token') })
    } catch (error: any) {
      if (error?.response?.status === 401) logOut()
    } finally {
      setLoading(false)
    }
  }

  const setPassword = async ({
    confirmNewPassword,
    currentPassword,
    password
  }) => {
    setLoading(true)
    try {
      const res = await api.put(`/settings/password`, {
        current_password: currentPassword,
        password,
        password_confirmation: confirmNewPassword
      })
      setResponse(res)
      addToast({ title: 'Password successfully changed' }, 'success', {})
    } catch (error: any) {
      if (error?.response?.status === 401) logOut()
    } finally {
      setLoading(false)
    }
  }

  return { loading, response, setPassword, setGeneralInfo }
}

import { AngleSmallDownIcon, AngleSmallUpIcon } from '@commons/Icons'
import { Button as OriginalButton } from '@components/atoms/Button'
import { InputTextWithoutFormik } from '@components/atoms/InputText'
import { pillStyle } from '@components/atoms/Pill/Pill.styles'
import { Text } from '@components/atoms/Text'
import { BodyS, BodySB } from '@components/atoms/Text/Text.styles'
import { Avatar } from '@mui/material'
import { colors } from '@styles/colors'
import styled, { css } from 'styled-components'

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.shades.gray300};
  color: ${({ theme }) => theme.colors.primaryBlue};
  width: 100%;
  height: 100%;

  padding: 72px 72px 74px 64px;
  overflow: scroll;

  @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
    padding: 0;
    overflow: initial;
    background-color: #f2f5f7;
  }
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;

  @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
    margin: 32px 24px 40px 24px;
  }
`

export const TabsWrapper = styled.div`
  margin: 0 24px 32px 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin-left: 24px;
  }
`

export const Title = styled.h1`
  ${BodyS}
`

export const GrayText = styled(Text)`
  color: ${({ theme }) => theme.colors.shades.primary300};
`

export const ProfilePicture = styled(Avatar).attrs({
  sx: { bgcolor: colors.shades.secondary100, color: colors.shades.secondary700 }
})`
  width: 32px;
  height: 32px;
`

export const InlineWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`

export const CareerWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`

export const Pill = styled.div<any>`
  ${pillStyle}
  ${BodySB}

  white-space: nowrap;
  margin: 0;
  padding: 4px 12px;
  background-color: ${({ theme }) => theme.colors.shades.gray500};
  height: 28px;

  ${({ status }) => {
    if (status === 'active') {
      return css`
        background-color: ${({ theme }) => theme.colors.shades.secondary300};
      `
    }
  }};
`

export const PoppoverText = styled.p`
  font-size: 12px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  color: white;
`

export const InputWrapper = styled.div`
  margin-bottom: 24px;

  @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
    padding: 0 24px;
    margin-bottom: 32px;
  }
`

export const TableHeaderWrapper = styled.th``

export const TableHeaderContent = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
`

export const AngleSmallUp = styled(AngleSmallUpIcon)`
  width: 16px;
  height: 16px;
`

export const AngleSmallDown = styled(AngleSmallDownIcon)`
  width: 16px;
  height: 16px;
`

export const TRHoverWrapper = styled.tr`
  cursor: pointer;
`

export const ClaimButtonWrapper = styled.td`
  height: 68px;
  min-width: 170px;
`

export const Button = styled(OriginalButton)`
  display: none;
  height: 48px;
  font-size: 16px;
  font-weight: 600;
  line-height: 25.6px;

  ${TRHoverWrapper}:hover & {
    display: block;
  }
`

export const InputText = styled(InputTextWithoutFormik)<any>`
  background-color: ${({ theme }) => theme.colors.shades.gray500};
  border: none;
  height: 48px;

  &:hover,
  &:focus {
    filter: none;
    background-color: #dfe6ed;
    border: none;
  }
`

export const ShareButton = styled(Button).attrs({ styling: 'secondary' })`
  display: flex;
  gap: 10px;
  background-color: transparent;
  height: 48px;
  padding: 18px 22px;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;

  :hover {
    background-color: ${({ theme }) => theme.colors.shades.gray300};
  }
`

export const MobileIconsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`

export const MobileShareButton = styled.button`
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 24px;
    width: 24px;
  }

  cursor: pointer;
`

export const MobileTable = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
  background-color: #f2f5f7;
`

export const MobileTableItem = styled.div`
  background-color: ${({ theme }) => theme.colors.primaryWhite};
  display: flex;
  padding: 18px 24px;
  gap: 20px;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.shades.gray700};
  }
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const FilterModalContainer = styled.div`
  width: 100%;
  height: 100%;
`

export const MobileFilterWrapper = styled.div`
  margin: 56px 0;
`

export const FilterButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

import styled from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.div<{ border: boolean }>`
  border: ${({ border, theme }) =>
    border ? `1px solid ${theme.colors.shades.gray700}` : 'none'};
  padding: 2rem;
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.colors.primaryWhite};

  header {
    margin-bottom: 2.5rem;
  }

  ${media.lessThan('medium')`
    border-radius: 0;
    border-left:0;
    border-right:0;
    padding: 2rem 24px;

    header {
      margin-bottom: 1.5rem;
    }
  `}
`

import { InitialScreen } from '@components/layout/InitialScreen'
import { ForgotPasswordForm } from '@components/organisms/ForgotPasswordForm'

export interface ForgotPasswordProps {
  testId?: string
}

export const ForgotPassword = ({
  testId = 'layout-forgot-pass-id'
}: ForgotPasswordProps) => {
  return (
    <InitialScreen testId={testId}>
      <ForgotPasswordForm />
    </InitialScreen>
  )
}

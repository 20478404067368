import styled, { css } from 'styled-components'
import { NavigationBarProps } from './interfaces'

export const navigationBarStyle = css<NavigationBarProps>`
  background: linear-gradient(326.83deg, #1d2f41 -0.04%, #0c1925 100%);
  box-shadow: 0px 4px 16px rgba(73, 88, 102, 0.04);
  width: 100%;
  display: flex;
  height: 80px;
  justify-content: center;
`

export const MainWrapper = styled.div`
  width: ${({ theme }) => theme.breakpoints.desktop}px;
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;

  @media (max-width: ${({ theme }) => theme.breakpoints.desktop + 48}px) {
    padding-left: 24px;
    padding-right: 24px;
  }
`

import { RoundedBoxProps } from './interfaces'
import * as S from './RoundedBox.styles'

export const RoundedBox = ({
  testId = 'rounded-box-id',
  children,
  ...props
}: RoundedBoxProps) => {
  return (
    <S.Container data-testid={testId} {...props}>
      {children}
    </S.Container>
  )
}

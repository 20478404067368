export const ArrowRight = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.825 12.0001C17.8202 11.474 17.6103 10.971 17.2407 10.6001L12.9924 6.30007C12.8069 6.11382 12.5559 6.00928 12.2942 6.00928C12.0326 6.00928 11.7816 6.11382 11.5961 6.30007C11.5033 6.39303 11.4296 6.50363 11.3793 6.62549C11.329 6.74735 11.3032 6.87806 11.3032 7.01007C11.3032 7.14208 11.329 7.27279 11.3793 7.39465C11.4296 7.5165 11.5033 7.62711 11.5961 7.72007L14.8541 11.0001H4.95123C4.68859 11.0001 4.4367 11.1054 4.25099 11.293C4.06527 11.4805 3.96094 11.7348 3.96094 12.0001C3.96094 12.2653 4.06527 12.5196 4.25099 12.7072C4.4367 12.8947 4.68859 13.0001 4.95123 13.0001H14.8541L11.5961 16.2901C11.4096 16.477 11.3043 16.7312 11.3034 16.9965C11.3025 17.2619 11.406 17.5168 11.5911 17.7051C11.7763 17.8934 12.028 17.9997 12.2907 18.0006C12.5535 18.0016 12.8059 17.897 12.9924 17.7101L17.2407 13.4101C17.6127 13.0367 17.8228 12.5296 17.825 12.0001Z"
      fill="#1C2E40"
    />
  </svg>
)

import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const usePageViews = () => {
  const location = useLocation()
  useEffect(() => {
    window.analytics.page(location.pathname)
  }, [location])
}

export default usePageViews

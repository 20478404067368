import { UrlIcon } from '@commons/Icons'
import { Button } from '@components/atoms/Button'
import { FormContainer } from '@components/atoms/FormContainer'
import { InputText } from '@components/atoms/InputText'
import { useUpdateSocialLinks } from '@hooks/aboutMe/useSocialLinks'
import { useProfileContext } from '@hooks/profile/ProfileContextProvider'
import { Form, Formik, FormikProps } from 'formik'
import get from 'lodash/get'
import { useEffect } from 'react'
import * as S from '../FormCommons/Form.styles'
import { SocialLinksProps } from './interfaces'

interface Values {
  website: string
  linkedin: string
  facebook: string
  instagram: string
}

const idContext = `social-links-form`

export const SocialLinks = ({
  testId = 'social-links-id',
  testSubmit,
  onCancel,
  onSucess,
  ...props
}: SocialLinksProps) => {
  const { loading, update, response } = useUpdateSocialLinks()
  const { profile } = useProfileContext()

  useEffect(() => {
    if (response?.status === 200) onSucess()
  }, [response])

  return (
    <FormContainer data-testid={testId} {...props}>
      <Formik
        initialValues={{
          website: get(profile, 'social_media.website', ''),
          linkedin: get(profile, 'social_media.linkedin', ''),
          facebook: get(profile, 'social_media.facebook', ''),
          instagram: get(profile, 'social_media.instagram', '')
        }}
        enableReinitialize
        onSubmit={(values) => {
          const method = testSubmit ? testSubmit : update
          method(values)
        }}
      >
        {(props: FormikProps<Values>) => (
          <Form>
            <S.InputRow>
              <S.InputCol>
                <InputText
                  label="Personal Website or Portfolio"
                  id={`website-${idContext}`}
                  placeholder="URL here"
                  type="text"
                  name="website"
                  testId="fieldId"
                  disabled={loading}
                  endIcon={<UrlIcon />}
                  {...props}
                />
              </S.InputCol>
            </S.InputRow>
            <S.InputRow>
              <S.InputCol>
                <InputText
                  label="Linkedin"
                  id={`linkedin-${idContext}`}
                  placeholder="URL here"
                  type="text"
                  name="linkedin"
                  testId="fieldId"
                  disabled={loading}
                  endIcon={<UrlIcon />}
                  {...props}
                />
              </S.InputCol>
            </S.InputRow>
            <S.InputRow>
              <S.InputCol>
                <InputText
                  label="Facebook"
                  id={`facebook-${idContext}`}
                  placeholder="URL here"
                  type="text"
                  name="facebook"
                  testId="fieldId"
                  disabled={loading}
                  endIcon={<UrlIcon />}
                  {...props}
                />
              </S.InputCol>
            </S.InputRow>
            <S.InputRow>
              <S.InputCol>
                <InputText
                  label="Instagram"
                  id={`instagram-${idContext}`}
                  placeholder="URL here"
                  type="text"
                  name="instagram"
                  testId="fieldId"
                  disabled={loading}
                  endIcon={<UrlIcon />}
                  {...props}
                />
              </S.InputCol>
            </S.InputRow>
            <S.ButtonsWrapper>
              <Button styling="secondary" type="button" onClick={onCancel}>
                Cancel
              </Button>
              <Button loading={loading} type="submit" testId="fieldId">
                Save
              </Button>
            </S.ButtonsWrapper>
          </Form>
        )}
      </Formik>
    </FormContainer>
  )
}

import * as S from './NavigationItem.styles'
import { NavigationItemProps } from './interfaces'

export const NavigationItem = ({
  testId = 'navigation-item-id',
  Icon,
  label,
  isSelected = false,
  ...props
}: NavigationItemProps) => {
  return (
    <S.NavigationItemWrapper data-testid={testId} {...props}>
      <S.SelectedIndicator isSelected={isSelected} />

      <S.ContentWrapper>
        <S.IconWrapper isSelected={isSelected}>
          {Icon && <Icon />}
        </S.IconWrapper>
        <S.Label isSelected={isSelected}>{label}</S.Label>
      </S.ContentWrapper>
    </S.NavigationItemWrapper>
  )
}

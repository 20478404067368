import { InputText } from '@components/atoms/InputText'
import { useState } from 'react'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
// import { PasswordFieldProps } from './interfaces'

export const PasswordField = ({
  testId = 'password-id',
  label = 'password',
  placeholder = 'Min a 8 character password',
  ...rest
}: any) => {
  const [showPassword, setShowPassword] = useState(false)

  const eyeInvisible = (
    <AiOutlineEyeInvisible
      size={24}
      onClick={() => setShowPassword(!showPassword)}
    />
  )

  const eyeVisible = (
    <AiOutlineEye size={24} onClick={() => setShowPassword(!showPassword)} />
  )

  // FIXME: I was getting a warning about the type of the ref,
  // that's why I used this weird alternative to pass the properties for the component
  const props = {
    placeholder,
    label,
    endIcon: showPassword ? eyeVisible : eyeInvisible,
    type: showPassword ? 'text' : 'password'
  }

  return <InputText testId={testId} {...props} {...rest} />
}

import { NotFound } from '@components/layout/NotFound'
import { RecruiterContextProvider } from '@hooks/recruiter/RecruiterContextProvider'
import { Route, Routes } from 'react-router-dom'
import usePageViews from '../hooks/usePageViews'
import { CandidateRoutes, CANDIDATE_VIEW_PATH } from './CandidateRoutes'
import { RedirectUserToProfile } from './ProtectedRoute'
import { RecruiterRoutes, RECRUITER_VIEW_PATH } from './RecruiterRoutes'
import { SessionRoutes, SESSION_VIEW_PATH } from './SessionRoutes'

const AppRoutes = () => {
  usePageViews()

  return (
    <Routes>
      <Route path={SESSION_VIEW_PATH} element={<SessionRoutes />} />
      <Route path={CANDIDATE_VIEW_PATH} element={<CandidateRoutes />} />
      <Route
        path={RECRUITER_VIEW_PATH}
        element={
          <RecruiterContextProvider>
            <RecruiterRoutes />
          </RecruiterContextProvider>
        }
      />
      <Route path={'/'} element={<RedirectUserToProfile />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default AppRoutes

import {
  BackIcon,
  Badge,
  ChartArrowDown,
  ChartHistogram,
  Comment,
  UserIconGray
} from '@commons/Icons'
import { Button as OriginalButton } from '@components/atoms/Button'
import { Pill as OriginalPill } from '@components/atoms/Pill'
import { Text } from '@components/atoms/Text'
import { ProfileCardTitleBar } from '@components/molecules/ProfileCardTitleBar'
import { Rating } from '@components/organisms/Rating'
import { Reference } from '@hooks/profile/interfaces'
import { get, startCase } from 'lodash'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { Card as CardStyles } from './ReferencesProfileCard.styles'

export interface ReviewerInfoDetailsProps {
  testId?: string
}

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    gap: 24px;
  }
`

const Card = styled(CardStyles)``

const Wrapper = styled.div`
  padding: 40px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 24px;
  }
`

const OverallAssesment = styled.div`
  display: flex;

  h3 {
    margin-right: 22px;
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }
`

const AllEvaluations = styled.div`
  display: flex;
  margin-top: 80px;
  justify-content: space-between;

  > div {
    flex-direction: column;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin-top: 40px;
    flex-direction: column;
  }
`

const EvaluationBlock = styled.div`
  display: flex;
  gap: 40px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    gap: 0;
  }
`

const EvaluationLine = styled.div`
  display: flex;
  gap: 16px;

  /* Attention: DESKTOP */
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    > p {
      width: 150px;
    }

    > span {
      width: 169px;
    }
  }

  /* Attention: MOBILE */
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    justify-content: space-between;
    border-top: 1px solid ${({ theme }) => theme.colors.shades.gray300};
    padding: 24px 0;
  }
`

const ReviewerInfoWrapper = styled.div`
  display: flex;
  gap: 72px;

  > div {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
`

const Pill = styled(OriginalPill)`
  margin: 0;
  line-height: 16px;
`

const Button = styled(OriginalButton)`
  width: fit-content;
  margin-bottom: 2px;

  > svg {
    margin-right: 8px;
  }
`

export const ReviewerInfoDetails = ({
  testId = 'component-ReviewerInfoDetails-id',
  ...props
}: ReviewerInfoDetailsProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const review = get(location, 'state.review') as Reference
  const overallRating = get(location, 'state.overallRating') as any

  return (
    <MainWrapper>
      <Button
        styling="link"
        onClick={() =>
          navigate(location.pathname?.replace('reference-details', ''))
        }
      >
        <BackIcon /> Back
      </Button>
      <Card data-testid={testId} {...props}>
        <ProfileCardTitleBar
          title="Reviewer info"
          icon={<UserIconGray />}
          style={{ height: '72px' }}
        ></ProfileCardTitleBar>
        <Wrapper>
          <ReviewerInfoWrapper>
            <div>
              <Text type="BodySMR">Name</Text>
              <Text type="BodyMB">{review.full_name}</Text>
            </div>

            <div>
              <Text type="BodySMR">Relationship</Text>
              <Pill>{startCase(review.relationship)}</Pill>
            </div>
          </ReviewerInfoWrapper>
        </Wrapper>
      </Card>

      <Card data-testid={testId} {...props}>
        <ProfileCardTitleBar
          title="Evaluation"
          icon={<ChartHistogram />}
          style={{ height: '72px' }}
        ></ProfileCardTitleBar>

        <Wrapper>
          <OverallAssesment>
            <Text type="GlobalH3" as="h3">
              {overallRating}
            </Text>

            <div>
              <Text type="BodySMR">Overall assesment</Text>
              <Rating value={overallRating} precision={0.1} readOnly />
            </div>
          </OverallAssesment>

          <AllEvaluations>
            <EvaluationBlock>
              <EvaluationLine>
                <Text type="BodySMR">Leadership</Text>
                <Rating
                  value={review.leadership}
                  precision={0.1}
                  readOnly
                  gray
                />
              </EvaluationLine>
              <EvaluationLine>
                <Text type="BodySMR">Execution</Text>
                <Rating
                  value={review.execution}
                  precision={0.1}
                  readOnly
                  gray
                />
              </EvaluationLine>
              <EvaluationLine>
                <Text type="BodySMR">Customer service</Text>
                <Rating
                  value={review.customer_service}
                  precision={0.1}
                  readOnly
                  gray
                />
              </EvaluationLine>
              <EvaluationLine>
                <Text type="BodySMR">Work ethic</Text>
                <Rating
                  value={review.work_ethic}
                  precision={0.1}
                  readOnly
                  gray
                />
              </EvaluationLine>
              <EvaluationLine>
                <Text type="BodySMR">Initiative</Text>
                <Rating
                  value={review.initiative}
                  precision={0.1}
                  readOnly
                  gray
                />
              </EvaluationLine>
            </EvaluationBlock>
            <EvaluationBlock>
              <EvaluationLine>
                <Text type="BodySMR">Creativity</Text>
                <Rating
                  value={review.creativity}
                  precision={0.1}
                  readOnly
                  gray
                />
              </EvaluationLine>
              <EvaluationLine>
                <Text type="BodySMR">Resilience</Text>
                <Rating
                  value={review.resilience}
                  precision={0.1}
                  readOnly
                  gray
                />
              </EvaluationLine>
              <EvaluationLine>
                <Text type="BodySMR">Team relationships</Text>
                <Rating
                  value={review.team_relationships}
                  precision={0.1}
                  readOnly
                  gray
                />
              </EvaluationLine>
              <EvaluationLine>
                <Text type="BodySMR">Work quality</Text>
                <Rating
                  value={review.work_quality}
                  precision={0.1}
                  readOnly
                  gray
                />
              </EvaluationLine>
            </EvaluationBlock>
          </AllEvaluations>
        </Wrapper>
      </Card>
      <Card data-testid={testId} {...props}>
        <ProfileCardTitleBar
          title="Strengths"
          icon={<Badge />}
          style={{ height: '72px' }}
        ></ProfileCardTitleBar>
        <Wrapper>
          <Text type="BodyMR">{review.strengths}</Text>
        </Wrapper>
      </Card>

      <Card data-testid={testId} {...props}>
        <ProfileCardTitleBar
          title="Weaknesses"
          icon={<ChartArrowDown />}
          style={{ height: '72px' }}
        ></ProfileCardTitleBar>
        <Wrapper>
          <Text type="BodyMR">{review.weaknesses}</Text>
        </Wrapper>
      </Card>

      <Card data-testid={testId} {...props}>
        <ProfileCardTitleBar
          title="Additional comments"
          icon={<Comment />}
          style={{ height: '72px' }}
        ></ProfileCardTitleBar>

        <Wrapper>
          <Text type="BodyMR">{review.additional_comments}</Text>
        </Wrapper>
      </Card>
    </MainWrapper>
  )
}

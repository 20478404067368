import styled from 'styled-components'

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.primaryBlue};
  color: ${({ theme }) => theme.colors.primaryWhite};
  width: 100vw;
  height: 100vh;
  display: flex;
`

export const Sidebar = styled.nav`
  display: flex;
  flex-direction: column;
  background: linear-gradient(326.83deg, #1d2f41 -0.04%, #0c1925 100%);
  min-width: 288px;
  height: 100%;
`

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 48px 0 56px 0;
`

export const MainContent = styled.main`
  background-color: ${({ theme }) => theme.colors.shades.gray300};
  height: 100%;
  width: 100%;
`

export const Navigation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin-bottom: 32px;
`

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
export const Footer = styled.footer``

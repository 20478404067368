import api from '@api/index'
import { addToast } from '@components/atoms/Toast'
import { useUserContext } from '@hooks/user/UserContextProvider'
import { useState } from 'react'

export const usePhotoUpload = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [response, setResponse] = useState<any>(null)

  const { logOut } = useUserContext()

  const update = async (file: any) => {
    setLoading(true)
    try {
      const formData = new FormData()
      formData.append('picture', file)
      const res = await api.put(`/users/profile_pictures`, formData)
      setResponse(res)
      addToast({ title: 'successfully updated' }, 'success', {})
    } catch (error: any) {
      if (error?.response?.status === 401) logOut()
    } finally {
      setLoading(false)
    }
  }

  const remove = async (picture_id) => {
    setLoading(true)
    try {
      const res = await api.delete(`/users/profile_pictures`, {
        data: { picture_id }
      })
      setResponse(res)
      addToast({ title: 'successfully removed' }, 'success', {})
    } catch (error: any) {
      if (error?.response?.status === 401) logOut()
    } finally {
      setLoading(false)
    }
  }

  return { loading, response, update, remove }
}

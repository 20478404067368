import Select, { SelectProps } from '@mui/material/Select'
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem'
import { styled as MUIStyled } from '@mui/material/styles'
import { colors } from '@styles/colors'
import { typography } from '@styles/typography'
import styled, { css } from 'styled-components'

export const IconWrapper = styled.span`
  position: absolute;
  top: 4px !important;
  bottom: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  right: 1px;
  z-index: 2;
  color: ${({ theme }) => theme.colors.shades.primary300};
`

export const CustomSelect = MUIStyled(Select)<SelectProps>(() => ({
  width: '100%',
  '& 	.MuiSelect-select': {
    padding: '0 16px',
    height: '58px',
    lineHeight: '58px',
    zIndex: 1,
    fontFamily: typography.primary
  },
  '& 	.MuiSelect-select .placeholder': {
    color: colors.shades.primary100
  },
  '& 	.MuiOutlinedInput-notchedOutline': {
    borderRadius: '8px',
    borderColor: colors.shades.primary100,
    top: 0
  },
  '& .MuiSelect-icon': {
    zIndex: 1,
    top: 'calc(50% - 12px)'
  },
  '& 	.MuiOutlinedInput-notchedOutline legend span': { display: 'none' },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline':
    {
      borderWidth: '1px',
      borderColor: colors.shades.primary100,
      filter: 'drop-shadow(0px 0px 5px #4de295)',
      backgroundColor: colors.primaryWhite
    },
  '&.Mui-error .MuiOutlinedInput-notchedOutline': {
    borderColor: colors.alert
  },
  '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
    WebkitTextFillColor: colors.shades.primary100,
    opacity: '1',
    border: `1px solid ${colors.shades.primary100}`,
    boxShadow: '0 0 4px 0 transparent',
    backgroundColor: colors.shades.primary100,
    cursor: 'not-allowed'
  },
  '&.Mui-disabled:hover .MuiOutlinedInput-notchedOutline': {
    filter: 'none'
  }
}))

export const CustomMenuItem = MUIStyled(MenuItem)<any>(() => ({
  fontFamily: typography.primary,
  borderRadius: '8px',
  padding: '12px',
  lineHeight: '26px',

  '&:hover, &.Mui-selected': {
    backgroundColor: colors.shades.gray300
  },

  '& + li': {
    marginTop: '4px'
  }
}))

export const MenuStyle = {
  marginTop: '16px',
  padding: '12px',
  borderRadius: '16px',
  border: '2px solid #E6EBF0',
  boxShadow: 'none',
  maxHeight: '200px'
}

import { ERROR_MESSAGE_WRONG_FORMAT } from '@components/molecules/PhotoUpload'
import { Menu, MenuList } from '@mui/material'
import { get, invoke, set } from 'lodash'
import React from 'react'
import styled from 'styled-components'
import { Button } from '../Button'
import { addToast } from '../Toast'
import * as S from './ProfilePicture.styles'
import { ProfilePictureProps } from './interfaces'

const ProfilePictureWrapper = ({ ...props }: ProfilePictureProps) => (
  <div {...props} />
)

const StyledWrapper = styled<any>(ProfilePictureWrapper)`
  ${S.profilePictureStyle}
`

export function DropdownMenu({ children, open = false, ...rest }) {
  return (
    <Menu
      open={open}
      PaperProps={{
        sx: {
          maxWidth: '100%',
          border: '1.5px solid #E6EBF0',
          borderRadius: '16px',
          marginTop: '30px'
        }
      }}
      {...rest}
    >
      <MenuList sx={{ padding: '12px', outline: 0 }}>{children}</MenuList>
    </Menu>
  )
}

export const ProfilePicture = ({
  testId = 'profile-picture-test-id',
  children,
  size,
  src,
  onChangePicture,
  onRemovePicture,
  hideEditButton,
  ...rest
}: ProfilePictureProps) => {
  const hiddenFileInput = React.useRef(null)

  const _handleChangePictureClick = () => {
    invoke(hiddenFileInput, 'current.click')
  }

  const _handleRemovePictureClick = () => {
    onRemovePicture && onRemovePicture()
  }

  const _showToast = (message: string) =>
    addToast({ title: message }, 'error', {})

  const _validateFileFormat = (file, event) => {
    const acceptedFormats = ['image/png', 'image/jpeg']
    const HAS_VALID_FORMAT = acceptedFormats.includes(file.type)
    if (!HAS_VALID_FORMAT) {
      set(event, 'target.value', null)
      _showToast(ERROR_MESSAGE_WRONG_FORMAT)
      throw new Error(ERROR_MESSAGE_WRONG_FORMAT)
    }
  }

  const _handleUpload = (event: any) => {
    const fileReader: any = new FileReader()
    const file = get(event, 'target.files[0]')

    _validateFileFormat(file, event)

    fileReader.onload = () => {
      if (fileReader.readyState === 2) {
        onChangePicture && onChangePicture(file)
        set(event, 'target.value', null)
      }
    }

    if (file) fileReader.readAsDataURL(file)
  }

  const IS_EDIT_AREA = !hideEditButton && size !== 'small'

  return (
    <S.PhotoWrapper>
      <StyledWrapper data-testid={testId} size={size} src={src} {...rest}>
        {children}
        {!src && <S.ProfilePictureUserIcon size={size} {...S.userIconProps} />}
      </StyledWrapper>

      {IS_EDIT_AREA && (
        <>
          <input
            accept=".png, .jpg, .jpeg"
            type="file"
            hidden
            ref={hiddenFileInput}
            onChange={_handleUpload}
          />

          <S.PhotoActionsContainer>
            <Button
              size="small"
              onClick={_handleChangePictureClick}
              data-testid="profile-picture-edit-button"
            >
              {src ? 'Change photo' : 'Add photo'}
            </Button>

            {src && (
              <S.RemoveButton
                styling="secondary"
                size="small"
                onClick={_handleRemovePictureClick}
              >
                Remove
              </S.RemoveButton>
            )}
          </S.PhotoActionsContainer>
        </>
      )}
    </S.PhotoWrapper>
  )
}

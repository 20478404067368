import { Cross, MenuBurguer } from '@commons/Icons'
import { Logo } from '@components/atoms/Logo'
import { Footer } from '@components/organisms/Footer'
import { useState } from 'react'
import * as S from './MobileHeaderNavigation.styles'

export interface MobileHeaderNavigationProps {
  testId?: string
  sidebarChildren: React.ReactNode
  children: React.ReactNode
  ProfileCompletionChildren?: React.ReactNode
  showFooter?: boolean
}

export const MobileHeaderNavigation = ({
  testId = 'component-MobileHeaderNavigation-id',
  sidebarChildren,
  children,
  showFooter,
  ProfileCompletionChildren,
  ...props
}: MobileHeaderNavigationProps) => {
  const [isSideBarOpen, setIsSideBarOpen] = useState(false)

  const _handleCloseSideBar = () => {
    setIsSideBarOpen(false)
  }
  const _handleOpenSideBar = () => {
    setIsSideBarOpen(true)
  }

  return (
    <S.Container data-testid={testId} {...props}>
      <S.Header>
        <S.HeaderWrapper>
          <S.LogoWrapper>
            <Logo />
          </S.LogoWrapper>

          {isSideBarOpen ? (
            <S.ButtonWrapper
              onClick={_handleCloseSideBar}
              aria-pressed="true"
              aria-label="close sidebar"
            >
              <Cross />
            </S.ButtonWrapper>
          ) : (
            <S.ButtonWrapper
              onClick={_handleOpenSideBar}
              aria-pressed="true"
              aria-label="open sidebar"
            >
              <MenuBurguer />
            </S.ButtonWrapper>
          )}
        </S.HeaderWrapper>
      </S.Header>

      {!isSideBarOpen && ProfileCompletionChildren}

      <S.Main>
        {isSideBarOpen ? (
          sidebarChildren
        ) : (
          <S.MainContent>{children}</S.MainContent>
        )}
      </S.Main>

      {showFooter && <Footer />}
    </S.Container>
  )
}

import { PlusSmall, ProfileCardUserIcon } from '@commons/Icons'
import { IconLabel } from '@components/atoms/IconLabel'
import { FlexColumn, FlexRow } from '@components/layout/Flex'
import { ProfileCardTitleBar } from '@components/molecules/ProfileCardTitleBar'
import { GeneralInfo } from '@components/organisms/GeneralInfo'
import { useProfileContext } from '@hooks/profile/ProfileContextProvider'
import { get, truncate } from 'lodash'
import { useState } from 'react'
import { FiMail, FiMapPin, FiPhone } from 'react-icons/fi'
import * as S from './GeneralInfoProfileCard.styles'
import { GeneralInfoProfileCardProps } from './interfaces'
import NumberFormat from 'react-number-format'

// This wrapper is to prevent html attribute warnings.
// See: https://styled-components.com/docs/faqs#why-am-i-getting-html-attribute-warnings
const CardWrapper = ({ ...props }: GeneralInfoProfileCardProps) => (
  <div {...props} />
)

export const GeneralInfoProfileCard = ({
  testId = 'general-info-profile-card-id',
  ...props
}: GeneralInfoProfileCardProps) => {
  const [edit, setEdit] = useState(false)
  const { profile, fetchProfile } = useProfileContext()

  const update = () => {
    fetchProfile()
    setEdit(false)
  }

  const fullName = `${get(profile, 'general_info.first_name', '')} ${get(
    profile,
    'general_info.last_name',
    ''
  )} `

  const city = get(profile, 'general_info.city', '')
  const email = get(profile, 'general_info.email', '')
  const phone = get(profile, 'general_info.phone', '')

  return (
    <CardWrapper data-testid={testId}>
      <S.Card {...props}>
        <ProfileCardTitleBar
          edit={edit}
          title="General info"
          icon={<ProfileCardUserIcon width={24} height={24} color="#8E97A0" />}
          onEditButtonClick={() => setEdit(true)}
          style={{ height: '72px' }}
        ></ProfileCardTitleBar>
        {!edit ? (
          <S.CardContent>
            <FlexRow className="row">
              <FlexColumn s={12} m={6}>
                <IconLabel icon={<S.UserIcon />}>
                  {truncate(fullName, { length: 32 })}
                </IconLabel>
              </FlexColumn>
              <FlexColumn s={12} m={6}>
                {city ? (
                  <IconLabel icon={<FiMapPin />}>
                    {truncate(city, { length: 32 })}
                  </IconLabel>
                ) : (
                  <S.Pill onClick={() => setEdit(true)} endIcon={<PlusSmall />}>
                    Add location
                  </S.Pill>
                )}
              </FlexColumn>
            </FlexRow>
            <FlexRow className="row">
              <FlexColumn s={12} m={6}>
                <IconLabel icon={<FiMail />}>
                  {truncate(email, { length: 32 })}
                </IconLabel>
              </FlexColumn>
              <FlexColumn s={12} m={6}>
                {phone ? (
                  <IconLabel icon={<FiPhone />}>
                    <NumberFormat
                      value={phone}
                      displayType={'text'}
                      format="### ### ####"
                    />
                  </IconLabel>
                ) : (
                  <S.Pill onClick={() => setEdit(true)} endIcon={<PlusSmall />}>
                    Add phone number
                  </S.Pill>
                )}
              </FlexColumn>
            </FlexRow>
          </S.CardContent>
        ) : (
          <S.CardContent>
            <GeneralInfo
              onCancel={() => setEdit(false)}
              onSucess={() => update()}
              isFirstTime={!profile?.general_info}
            />
          </S.CardContent>
        )}
      </S.Card>
    </CardWrapper>
  )
}

export const GeneralInfoCard = ({
  testId = 'general-info-card-id',
  profile
}: any) => {
  const fullName = `${get(profile, 'general_info.first_name', '')} ${get(
    profile,
    'general_info.last_name',
    ''
  )} `

  const city = get(profile, 'general_info.city', '')
  const email = get(profile, 'general_info.email', '')
  const phone = get(profile, 'general_info.phone', '')

  return (
    <CardWrapper data-testid={testId}>
      <>
        <ProfileCardTitleBar
          title="General info"
          icon={<ProfileCardUserIcon width={24} height={24} color="#8E97A0" />}
          style={{ height: '72px' }}
        ></ProfileCardTitleBar>
        <S.CardContent>
          <FlexRow className="row">
            <FlexColumn s={12} m={6}>
              <IconLabel icon={<S.UserIcon />}>
                {truncate(fullName, { length: 32 })}
              </IconLabel>
            </FlexColumn>
            <FlexColumn s={12} m={6}>
              <IconLabel icon={<FiMapPin />}>
                {truncate(city, { length: 32 })}
              </IconLabel>
            </FlexColumn>
          </FlexRow>
          <FlexRow className="row">
            <FlexColumn s={12} m={6}>
              <IconLabel icon={<FiMail />}>
                {truncate(email, { length: 32 })}
              </IconLabel>
            </FlexColumn>
            <FlexColumn s={12} m={6}>
              <IconLabel icon={<FiPhone />}>
                {truncate(phone, { length: 32 })}
              </IconLabel>
            </FlexColumn>
          </FlexRow>
        </S.CardContent>
      </>
    </CardWrapper>
  )
}

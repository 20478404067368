import React from 'react'
import { GreenChecker } from '../GreenChecker'
import * as S from './ContentSwitcher.styles'

type Props = {
  name: string
  items: {
    value: string
  }
  onChange: (selected: string) => void
}

export const ContentSwitcher = ({
  testId = 'content-switcher-id',
  name = '',
  items = [],
  onChange,
  ...props
}: any) => {
  return (
    <S.Fieldset data-testid={testId} {...props}>
      {items.map((item, index) => (
        <React.Fragment key={index + item.value}>
          <S.SwitchItem
            name={name}
            id={index}
            value={item.value}
            checked={!!item.checked}
            onChange={(e) => onChange(e.target.value)}
          ></S.SwitchItem>
          <S.Label htmlFor={index}>
            <p>{item.value}</p>
            <GreenChecker size={24} />
          </S.Label>
        </React.Fragment>
      ))}
    </S.Fieldset>
  )
}

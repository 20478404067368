import api from '@api/index'
import { addToast } from '@components/atoms/Toast'
import { useUserContext } from '@hooks/user/UserContextProvider'
import { useState } from 'react'

export const useWorkSkills = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [response, setResponse] = useState<any>(null)
  const [workSkills, setWorkSkills] = useState<any>([])

  const { logOut } = useUserContext()

  const fetchWorkSkills = async (query?: string) => {
    setLoading(true)
    try {
      const res = await api.get(`/work_skills${query ? `?query=${query}` : ''}`)
      setWorkSkills(res.data)
    } catch (error: any) {
      if (error?.response?.status === 401) logOut()
    } finally {
      setLoading(false)
    }
  }

  const update = async (data: Array<number>) => {
    setLoading(true)
    try {
      const res = await api.put(`/users/profile`, {
        profile: {
          work_skills_ids: data
        }
      })
      setResponse(res)
      addToast({ title: 'Successfully updated' }, 'success', {})
    } catch (error: any) {
      if (error?.response?.status === 401) logOut()
      addToast({ title: error?.response?.data?.message }, 'error', {})
    } finally {
      setLoading(false)
    }
  }

  return {
    loading,
    response,
    update,
    workSkills,
    fetchWorkSkills
  }
}

import { BodyLB } from '@components/atoms/Text/Text.styles'
import styled from 'styled-components'

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.shades.gray300};
`

export const MainContainer = styled.main`
  width: 822px;
  min-height: 500px;
  margin: 0 auto;

  @media (max-width: 822px) {
    margin: 0;
    width: 100vw;
  }
`

export const SettingsHeading = styled.h1`
  font-size: 32px;
  font-weight: 600;
  line-height: 35px;
  letter-spacing: 0em;
  margin-top: 75px;
  margin-bottom: 48px;

  @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
    margin: 32px 24px;
  }
`

export const Heading = styled.h1`
  ${BodyLB}
`

export const FormContainer = styled.div`
  background-color: #ffffff;
  border-radius: 24px;
  margin-bottom: 111px;

  > div {
    padding: 40px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin-bottom: 32px;

    > div {
      padding: 24px;
    }
  }
`

import { yupTextArea } from '@commons/Form/validations'
import { TextArea } from '@components/atoms/TextArea'
import * as Yup from 'yup'
// import { FORM_STEPS, useApplicationForm } from '../hooks/useApplicationForm'
import * as S from '../commons/commons.styles'

interface Values {
  nextJobTitle: string
  careerDefining: string
}

export const initialValues = {
  nextJobTitle: '',
  careerDefining: ''
}

const idContext = `candidate-application-form-3`
// const CURRENT_STEP = 3

export const validationSchema = () => {
  return Yup.object().shape({
    nextJobTitle: yupTextArea,
    careerDefining: yupTextArea
  })
}

type Props = {
  testId?: string
}

export const Step3 = ({ testId = 'application-step3-test-id', props }: any) => {
  // const { setCurrentFormMetadata, setCurrentStep } = useApplicationForm()
  // setCurrentStep(FORM_STEPS[CURRENT_STEP])

  return (
    <S.FormWrapper data-testid={testId}>
      <S.Body>
        <S.InputWrapper>
          <TextArea
            label={'What do you want your next job title to be? *'}
            id={`next-job-${idContext}`}
            placeholder="Your answer here..."
            name="nextJobTitle"
            data-testid={`input`}
            {...props}
          />
        </S.InputWrapper>
        <S.InputWrapper>
          <TextArea
            label={`What would be your dream "career defining" job? *`}
            id={`career-defining-${idContext}`}
            placeholder="Your answer here..."
            name="careerDefining"
            data-testid={`input`}
            {...props}
          />
        </S.InputWrapper>
      </S.Body>
    </S.FormWrapper>
  )
}

import { Plus, Star, StarIcon } from '@commons/Icons'
import { ButtonIcon } from '@components/atoms/ButtonIcon'
import { EditButton } from '@components/atoms/EditButton'
import { Text } from '@components/atoms/Text'
import { ProfileCardPlaceholder } from '@components/molecules/ProfileCardPlaceholder'
import { ProfileCardTitleBar } from '@components/molecules/ProfileCardTitleBar'
import { PersonalHighlightsForm } from '@components/organisms/PersonalHighlightsForm'
import { useProfileContext } from '@hooks/profile/ProfileContextProvider'
import get from 'lodash/get'
import { useState } from 'react'
import * as S from '../ProfessionalHighlightsProfileCard/ProfessionalHighlightsProfileCard.styles'
import {
  Card,
  CardContainer,
  CardContent,
  CardFooter,
  CardText,
  CardTextContainer
} from '../commons/Card.styles'
import { CardContent as PersonalHighlightsWrapper } from './PersonalHighlightsProfileCard.styles'
import { PersonalHighlightsProfileCardProps } from './interfaces'

export const PersonalHighlightsProfileCard = ({
  testId = 'PersonalHighlights-profile-card-id'
}: PersonalHighlightsProfileCardProps) => {
  const [edit, setEdit] = useState(false)
  const [add, setAdd] = useState(false)
  const [editId, setEditId] = useState(null)
  const { profile, fetchProfile } = useProfileContext()

  const update = () => {
    fetchProfile()
    setEdit(false)
    setAdd(false)
  }

  return (
    <Card data-testid={testId}>
      {!profile?.personal_highlights ? (
        !edit ? (
          <CardContent>
            <ProfileCardPlaceholder
              icon={<StarIcon />}
              title="Personal Highlights"
              subtitle="Outline a max of 5 different personal achievements or highlights to spotlight to potential employers"
              callToAction="Add Highlights"
              onClick={() => setEdit(true)}
            />
          </CardContent>
        ) : (
          <>
            <ProfileCardTitleBar
              edit={edit}
              title="Personal highlights"
              icon={<Star width={24} height={24} color="#8E97A0" />}
            />
            <CardContent>
              <PersonalHighlightsForm
                onCancel={() => setEdit(false)}
                onSucess={update}
              />
            </CardContent>
          </>
        )
      ) : (
        <>
          <ProfileCardTitleBar
            edit={edit}
            title="Personal highlights"
            icon={<Star width={24} height={24} color="#8E97A0" />}
          />
          <CardContainer>
            {get(profile, 'personal_highlights', []).map(
              (it: any, idx, all) => (
                <CardTextContainer key={it.id}>
                  <CardText isEditing={edit}>
                    <Text type="BodyMR">{it.description}</Text>
                    <EditButton
                      onClick={() => {
                        if (!edit) {
                          setEditId(it.id)
                          setEdit(true)
                        }
                      }}
                    ></EditButton>
                  </CardText>
                  {editId === it.id && edit && (
                    <PersonalHighlightsForm
                      defaultValue={it.description}
                      isEditing
                      id={it.id}
                      highlights={all}
                      onCancel={() => setEdit(false)}
                      onSucess={update}
                    />
                  )}
                </CardTextContainer>
              )
            )}
          </CardContainer>
          <CardFooter>
            {add ? (
              <PersonalHighlightsForm
                onCancel={() => {
                  setEdit(false)
                  setAdd(false)
                }}
                onSucess={update}
              />
            ) : (
              <ButtonIcon
                text="Add Highlight"
                onClick={() => {
                  setAdd(true)
                  setEdit(true)
                  setEditId(null)
                }}
                disabled={edit}
              >
                <Plus />
              </ButtonIcon>
            )}
          </CardFooter>
        </>
      )}
    </Card>
  )
}

export const PersonalHighlightsCard = ({ profile }: any) => (
  <>
    {!profile?.personal_highlights ? (
      <CardContent>
        <ProfileCardPlaceholder
          icon={<StarIcon />}
          title="Personal Highlights"
          subtitle="Outline a max of 5 different personal achievements or highlights to spotlight to potential employers"
        />
      </CardContent>
    ) : (
      <>
        <ProfileCardTitleBar
          title="Personal highlights"
          icon={<Star width={24} height={24} color="#8E97A0" />}
        />
        <PersonalHighlightsWrapper>
          {profile?.personal_highlights?.map(({ id, description }, index) => (
            <>
              <Text breakText type="BodyMR" key={id}>
                {description}
              </Text>

              {index + 1 < profile?.professional_highlights?.length && (
                <S.PaddedDivider />
              )}
            </>
          ))}
        </PersonalHighlightsWrapper>
      </>
    )}
  </>
)

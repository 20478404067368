import styled from 'styled-components'
import media from 'styled-media-query'
import backgroundImage from '@assets/sign_up/background.png'

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: minmax(200px, 7fr) minmax(500px, 5fr);

  ${media.lessThan('large')`
	  grid-template-columns: 1fr;
	`}

  background: ${({ theme }) =>
    theme.colors.primaryBlue}; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom right,
    #0c1925,
    #1d2f41
  ); /* Chrome 10-25, Safari 5.1-6 */

  background: linear-gradient(
    to bottom right,
    #0c1925,
    #1d2f41
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
`

export const LeftPanel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  background: ${({ theme }) =>
    theme.colors.primaryBlue}; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom right,
    #0c1925,
    #1d2f41
  ); /* Chrome 10-25, Safari 5.1-6 */

  background: linear-gradient(
    to bottom right,
    #0c1925,
    #1d2f41
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  height: 80%;

  color: ${({ theme }) => theme.colors.primaryWhite};

  ${media.lessThan('large')`
		display: none;
	`}
`

export const LeftWrapper = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  max-width: 768px;
  max-height: 768px;
  height: 100%;
  width: 100%;

  margin: 156px;
`

export const Background = styled.div`
  position: absolute;

  width: 110%;
  height: 100%;

  background-image: url(${backgroundImage});
  background-size: 80%;
  background-repeat: no-repeat;

  @media (max-width: 1200px) {
    background-size: 100%;
    max-height: 80vh;
    max-width: 50vw;
    background-position: 50%;
  }

  @media (min-width: 1600px) {
    max-height: 100vh;
    max-width: 55vw;
    background-position: 20%;
  }
`

export const ImagesPanel = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
`

export const MobileLogo = styled.img`
  position: absolute;
  height: 35px;
  margin-left: 96px;
  margin-top: -150px;

  @media (max-width: 1550px) {
    margin-top: -15%;
  }
`

export const LogoImage = styled.img`
  display: none;

  ${media.lessThan('large')`
		display: block;
  	height: 28px;
		position: relative;
		margin: 32px auto;
	`}
`

export const TextPanel = styled.div`
  max-width: 500px;
  margin-left: 96px;
  margin-right: 196px;

  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
`

export const Title = styled.div`
  width: 550px;

  h1 {
    color: ${({ theme }) => theme.colors.shades.gray100};
    font-size: 96px;
    font-weight: 600;
    line-height: 106px;
    letter-spacing: 0em;
    text-align: left;
  }

  h1 + h1 {
    color: ${({ theme }) => theme.colors.shades.secondary500};
  }
`

export const SubTitle = styled.div`
  max-width: 432px;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
`

export const RightPanel = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primaryWhite};
  display: flex;
  min-height: 900px;
  height: 100%;
  width: 100%;
  justify-content: center;
  border-radius: 99px 0 0 0;

  ${media.lessThan('large')`
		align-items: flex-start;
		border-radius: 48px 0 0 0;
		height: 100vh;
		min-height: initial;
	`}
`

export const Wrapper = styled.div`
  margin: 5rem 10px 100px 10px;
  max-width: 450px;

  ${media.lessThan('large')`
		margin: 48px 24px;
		width: 100%;
		height: 100%;
	`}
`

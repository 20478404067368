import { SmallCrossIcon, UploadedIcon } from '@commons/Icons'
import { Button } from '@components/atoms/Button'
import { Text } from '@components/atoms/Text'
import { addToast } from '@components/atoms/Toast'
import { get, invoke, set } from 'lodash'
import React, { useState } from 'react'
import { UploadAreaProps } from './interfaces'
import * as S from './UploadArea.styles'

const ERROR_MESSAGE_FILE_SIZE = 'File size exceeds 4MB, try again'
const ERROR_MESSAGE_WRONG_FORMAT =
  'This format is not supported. Try with .png, .jpg or .jpeg'

export const UploadArea = ({
  testId = 'upload-button-id',
  onFileUpload,
  file,
  ...props
}: UploadAreaProps) => {
  const [image, setImage] = useState<any>(file)

  const hiddenFileInput = React.useRef(null)
  const HAS_UPLOADED_IMAGE = !!image

  const proccessFileBlob = (fileReader, originalFile) => {
    const image = new Image()
    const blob = fileReader.result
    image.src = blob

    image.onload = () => {
      setImage(originalFile)
      onFileUpload(originalFile)
    }
  }

  const _resetInput = (event?: any) => {
    set(event, 'target.value', null)

    setImage(null)
  }

  const _showToast = (message: string) =>
    addToast({ title: message }, 'error', {})

  const _validateFileSize = (file, event) => {
    const fileSize = get(file, 'size', 1) / 1024 / 1024
    const IS_BIGGER_THAN_4_MEGAS = fileSize > 4
    if (IS_BIGGER_THAN_4_MEGAS) {
      _resetInput(event)
      _showToast(ERROR_MESSAGE_FILE_SIZE)
      throw new Error(ERROR_MESSAGE_FILE_SIZE)
    }
  }

  const _validateFileFormat = (file, event) => {
    const acceptedFormats = ['image/png', 'image/jpeg']
    const HAS_VALID_FORMAT = acceptedFormats.includes(file.type)
    if (!HAS_VALID_FORMAT) {
      _resetInput(event)
      _showToast(ERROR_MESSAGE_WRONG_FORMAT)
      throw new Error(ERROR_MESSAGE_WRONG_FORMAT)
    }
  }

  const _handleUpload = (event: any) => {
    const fileReader: any = new FileReader()
    const file = get(event, 'target.files[0]')

    _validateFileSize(file, event)
    _validateFileFormat(file, event)

    fileReader.onload = () => {
      if (fileReader.readyState === 2) {
        proccessFileBlob(fileReader, file)
      }
    }

    if (file) fileReader.readAsDataURL(file)
  }

  const _handleClick = () => {
    invoke(hiddenFileInput, 'current.click')
  }

  const _handleCancelUpload = () => {
    _resetInput()
    setTimeout(_handleClick, 100)
    onFileUpload(null)
  }

  const renderPreUpload = () => (
    <S.ButtonWrapper>
      <S.Button type="button" onClick={_handleClick}>
        Upload Picture
        <input
          accept=".png, .jpg, .jpeg"
          id="upload-button-input"
          type="file"
          hidden
          ref={hiddenFileInput}
          onChange={_handleUpload}
        />
      </S.Button>
      <Text type="BodySR">Upload a .jpg, .png no higher than 4MB.</Text>
    </S.ButtonWrapper>
  )

  const renderUploadedData = () => (
    <S.ButtonWrapper>
      <S.UploadedButton>
        <UploadedIcon width={24} height={24} />
        <Text type="BodySR">{image.name}</Text>
        <SmallCrossIcon width={24} height={24} onClick={_resetInput} />
      </S.UploadedButton>
      <Button type="button" styling="link" onClick={_handleCancelUpload}>
        Upload Again
      </Button>
    </S.ButtonWrapper>
  )

  return (
    <S.Container data-testid={testId} {...props}>
      <Text type="Label">Profile picture</Text>
      {!HAS_UPLOADED_IMAGE ? renderPreUpload() : renderUploadedData()}
    </S.Container>
  )
}

import { Logo } from '@components/atoms/Logo'
import { NavItem } from '@components/atoms/NavItem'
import { Text } from '@components/atoms/Text'
import { useProfileContext } from '@hooks/profile/ProfileContextProvider'
import { useUserContext } from '@hooks/user/UserContextProvider'
import { isEmpty } from 'lodash'
import get from 'lodash/get'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useWindowSize } from 'react-use'
import styled from 'styled-components'
import { Avatar } from '../Avatar'
import { NavigationBarProps } from './interfaces'
import * as S from './NavigationBar.styles'

const NavigationBarWrapper = ({ ...props }: NavigationBarProps) => (
  <div {...props} />
)

const StyledWrapper = styled(NavigationBarWrapper)`
  ${S.navigationBarStyle}
`

export const NavigationBar = ({
  testId = 'navigation-bar-test-id',
  withoutBackground,
  children,
  ...rest
}: NavigationBarProps) => {
  const size = useWindowSize()
  const IS_DESKTOP = size.width > 768

  const { user } = useUserContext()
  const { fetchProfile, profile } = useProfileContext() as any
  const navigate = useNavigate()

  const _navigateToMyProfile = () => {
    navigate('/candidate/profile')
  }

  useEffect(() => {
    isEmpty(profile) && fetchProfile()
  }, [])

  return (
    <StyledWrapper data-testid={testId} {...rest}>
      <S.MainWrapper>
        <Logo></Logo>
        {IS_DESKTOP && (
          <>
            <NavItem
              style={{ marginLeft: '84px' }}
              onClick={_navigateToMyProfile}
              withoutBackground={withoutBackground}
            >
              <Text type="BodyMB" style={{ color: 'white' }}>
                My Profile
              </Text>
            </NavItem>
            <Avatar
              userName={get(user, 'full_name', '')}
              email={get(user, 'email', '')}
              userImage={
                profile?.profile_picture_url ||
                profile?.application_form_profile_picture_url
              }
            />
          </>
        )}
      </S.MainWrapper>
      {children}
    </StyledWrapper>
  )
}

import { Button as OriginalButton } from '@components/atoms/Button'
import { Pill as OriginalPill } from '@components/atoms/Pill'
import { ProfileCard } from '@components/atoms/ProfileCard'
import { Text as OriginalText } from '@components/atoms/Text'
import styled from 'styled-components'

export const Card = styled(ProfileCard)`
  margin: 0;
  padding: 0;
  height: fit-content;
`

export const CopyReferenceWrapper = styled.div`
  > p {
    color: ${({ theme }) => theme.colors.shades.primary300};
  }

  div {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 8px;
  }
`

export const ReviewsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin: 40px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin: 24px;
    gap: 24px;
  }
`

export const PlaceholderWrapper = styled.div`
  margin: 64px 0;

  > div {
    padding: 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin: 40px 24px;
  }
`

export const Pill = styled(OriginalPill)`
  margin: 0;
  line-height: 16px;
`

export const Button = styled(OriginalButton)`
  gap: 8px;
  width: 130px;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.02em;
  border-bottom-color: ${({ theme }) => theme.colors.shades.primary500};

  &:hover {
    border-bottom-color: ${({ theme }) => theme.colors.shades.secondary500};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin-top: 12px;
  }
`

export const ReviewLine = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
  border-top: 1px solid ${({ theme }) => theme.colors.shades.gray500};
  padding-top: 40px;

  &:first-child {
    padding-top: 0px;
    border: none;
  }

  > p {
    color: ${({ theme }) => theme.colors.shades.primary300};
  }

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    div {
      display: flex;
      align-items: center;
    }

    div + div {
      gap: 10px;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding-top: 24px;
    gap: 12px;

    div {
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;

      div {
        display: flex;
        align-items: flex-start;
      }

      div + div {
        flex-direction: row;
        gap: 10px;
      }
    }
  }
`

export const Text = styled(OriginalText)`
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`

import { lowerCase, sortBy } from 'lodash'
import { KeyboardEvent } from 'react'

export const isString = (variable: any) =>
  typeof variable === 'string' || variable instanceof String

export const isObject = (value) =>
  value && typeof value === 'object' && value.constructor === Object

export const isObjectEmpty = (object) => Object.keys(object).length === 0

export const hasUpperCase = (word: string) => {
  return /[A-Z]/.test(word)
}

export const hasSpecialChars = (word: string) => {
  return /[$&+,:;=?@#|'<>.^*()%!-]/.test(word)
}

export const hasLowerCase = (word: string) => {
  return /[a-z]/.test(word)
}

export const hasNumber = (word: string) => {
  return /\d/.test(word)
}

export const hasMoreOrEqualCharactersThan = (word: string, size: number) => {
  return word.length >= size
}

export const inputOnlyNumbers = (event: KeyboardEvent<HTMLInputElement>) =>
  !/[0-9]/.test(event.key) && event.preventDefault()

export const extractOnlyNumbers = (value = '') => value.replace(/[^0-9]/g, '')

export const search = (data, searchTerms) => {
  const EMPTY_SEARCH_TERMS = searchTerms === ''
  if (EMPTY_SEARCH_TERMS) return data

  const filtered = data.filter((item) => {
    const data = lowerCase(JSON.stringify(item)).includes(
      lowerCase(searchTerms)
    )
    return data
  })

  return filtered
}

type sortProps = {
  data: any[]
  sortIdentifiers: string[]
  sortOrder?: 'asc' | 'desc'
}

export const sort = ({ data, sortIdentifiers, sortOrder }: sortProps) => {
  const sortResult = sortBy(data, sortIdentifiers)
  return sortOrder === 'desc' ? sortResult.reverse() : sortResult
}

export const prependHttpToAString = (url: string) => {
  if (!/^https?:\/\//i.test(url)) {
    return 'http://' + url
  }

  return url
}

export const fixUrlProtocol = (url: string) =>
  !/^https?:\/\//i.test(url) ? `https://${url}` : url

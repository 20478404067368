import upperFirst from 'lodash/upperFirst'

export default function formatDescription(values: any) {
  if (Array.isArray(values)) {
    return values.map((item: any) => ({
      ...item,
      description: upperFirst(item.description)
    }))
  }
  return []
}

import * as S from './ProfileCardTitleBar.styles'
import { ProfileCardTitleBarProps } from './interfaces'

export const ProfileCardTitleBar = ({
  testId = 'profile-card-title-bar-id',
  title,
  icon,
  onEditButtonClick,
  edit = false,
  ...props
}: ProfileCardTitleBarProps) => {
  return (
    <S.Container data-testid={testId} {...props} edit={edit}>
      <S.UserIconContainer>{icon}</S.UserIconContainer>
      <S.Title>{title}</S.Title>
      <S.TitleBarEditButtonContainer>
        {onEditButtonClick && (
          <S.TitleBarEditButton
            data-testid="title-bar-edit-button"
            aria-label="Edit"
            onClick={edit ? () => {} : onEditButtonClick}
          />
        )}
      </S.TitleBarEditButtonContainer>
    </S.Container>
  )
}

import styled from 'styled-components'
import media from 'styled-media-query'

export const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;

  div {
    display: flex;
    align-items: center;
    gap: 40px;
  }

  ${media.lessThan('medium')`
	 flex-direction: column-reverse;
   gap: 56px;

   div {
     justify-content: space-between;

     &:first-child {
       justify-content: center;
     }

     &:last-child button:last-child {
       width: 183px;
     }
   }
	`}
`

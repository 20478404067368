import { CheckIconWithBackground } from '@commons/Icons'
import { Button } from '@components/atoms/Button'
import { Text } from '@components/atoms/Text'
import styled from 'styled-components'
import * as S from '../AccountConfirmMessage/AcccountConfirmMessage.styles'

export interface CheckEmailPasswordProps {
  testId?: string
  email: string
  onResendClick: () => void
}

const Wrapper = styled(S.Wrapper)`
  margin: 0;
`

const GreenCheckerWrapper = styled(S.GreenCheckerWrapper)`
  margin-bottom: 32px;
`

export const CheckEmailPassword = ({
  testId = 'component-check-email-password-id',
  onResendClick,
  ...props
}: CheckEmailPasswordProps) => {
  return (
    <Wrapper data-testid={testId} {...props}>
      <GreenCheckerWrapper>
        <CheckIconWithBackground />
      </GreenCheckerWrapper>

      <S.Heading>Email sent!</S.Heading>

      <S.Body>
        <Text type="SubTitle">
          We sent you an email with a link for you to set up your new password.
        </Text>
      </S.Body>

      <S.Footer>
        <Text type="BodyMB" as="p">
          Didn’t get the link? {'\u00A0'}
        </Text>
        <S.CallToActionButtonWrapper>
          <Button type="button" styling="link" onClick={onResendClick}>
            Resend
          </Button>
        </S.CallToActionButtonWrapper>
      </S.Footer>
    </Wrapper>
  )
}

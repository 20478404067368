import { FormContainer } from '@components/atoms/FormContainer'
import { Progress } from '@components/molecules/Progress'
import { QuestionForm } from '@components/molecules/QuestionForm'
import {
  useGetQuestions,
  useGettingToKnowMe
} from '@hooks/aboutMe/useGettingToKnowMe'
import { capitalize } from 'lodash'
import { useEffect, useState } from 'react'
import * as S from './GettingToKnowMe.styles'
import { GettingToKnowMeProps } from './interfaces'

interface Answers {
  description: string
  id: number
}

export const GettingToKnowMe = ({
  testId = 'GettingToKnowMe-id',
  testSubmit,
  onCancel,
  onSuccess,
  ...props
}: GettingToKnowMeProps) => {
  const { loading, response, update } = useGettingToKnowMe()
  const { data: questions } = useGetQuestions()
  const [step, setStep] = useState(0)
  const [answers, setAnswers] = useState<Answers[]>([])

  useEffect(() => {
    if (step === questions?.length - 1) {
      const method = testSubmit ? testSubmit : update
      method(
        answers.map(({ id, description }) => ({
          getting_to_know_me_id: id,
          description
        }))
      )
    }
  }, [answers])

  useEffect(() => {
    if (response?.status === 200) onSuccess()
  }, [response])

  return (
    <FormContainer data-testid={testId} {...props}>
      <S.ProgressHolder>
        <Progress
          totalSteps={questions?.length || 0}
          currentStep={step + 1}
          styling="slash"
        />
      </S.ProgressHolder>
      {questions.map((question, idx) => (
        <div
          key={question.id}
          style={{ display: step === idx ? 'block' : 'none' }}
        >
          <QuestionForm
            question={capitalize(question.description)}
            isfirstTime
            onBackStep={() => setStep((step) => step - 1)}
            onCompleteLater={onCancel}
            isFirstStep={idx === 0}
            isLastStep={idx === questions.length - 1}
            onSubmit={(val) => {
              setAnswers((answers) => {
                const newAnswers = [...answers]
                newAnswers[idx] = {
                  id: question.id,
                  description: val.question
                }
                return newAnswers
              })
              if (step < questions.length - 1) {
                setStep(idx + 1)
              }
            }}
          />
        </div>
      ))}
    </FormContainer>
  )
}

import { Button as OriginalButton } from '@components/atoms/Button'
import { Card as OriginalCard } from '@components/organisms/ProfileCards/AboutMeProfileCard/AboutMeProfileCard.styles'
import { Avatar } from '@mui/material'
import styled, { css } from 'styled-components'

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.shades.gray300};
  color: ${({ theme }) => theme.colors.primaryBlue};
  width: 100%;
  height: 100%;

  padding: 40px 72px 74px 64px;
  overflow: scroll;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 0;
    overflow: initial;
  }
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const TabsWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.shades.gray300};

  > div {
    margin: 64px 0 32px 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
    > div {
      margin: 0;
      padding: 0;
    }

    padding: 16px 24px 24px 24px;
  }
`

export const AlertWrapper = styled.div`
  margin-top: -24px;
  margin-bottom: 48px;

  > div {
    > div {
      display: flex;
      align-items: center;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin: 8px 24px;

    > div {
      > div {
        > svg {
          display: none;
        }

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }
    }
  }
`

export const BackButton = styled(OriginalButton).attrs({
  styling: 'link'
})`
  svg {
    margin-right: 8px;
  }

  margin-bottom: 56px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin: 24px;
  }
`

export const ProfilePicture = styled(Avatar).attrs({
  sx: { bgcolor: '#DBF9EA', color: '#27714B' }
})`
  width: 32px;
  height: 32px;
`

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 32px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 100%;
    padding: 0 24px 32px 24px;
    flex-direction: column-reverse;
  }
`

export const Button = styled(OriginalButton)<any>`
  height: 56px;
  width: 144px;
  padding: 18px;

  ${({ unarchive }) =>
    unarchive &&
    css`
      width: 232px;
    `}

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 100%;
  }
`

export const InformationWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 320px;
  gap: 32px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    display: flex;
    flex-direction: column-reverse;
    gap: 24px;
    background-color: ${({ theme }) => theme.colors.shades.gray300};
  }
`

export const ProgressWrapper = styled.div`
  h1 {
    margin-bottom: 12px;
  }

  div {
    p {
      margin-right: 16px;
    }
  }
`

export const Card = styled(OriginalCard)`
  margin: 0;
  margin-bottom: 32px;
  margin-top: 0;

  > div {
    > h4 {
      margin-top: 0;
    }
  }

  > div + div {
    min-height: initial;
  }
`

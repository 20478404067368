import { ChartHistogram } from '@commons/Icons'
import { Text } from '@components/atoms/Text'
import { Rating } from '@components/organisms/Rating'
import { snakeCase } from 'lodash'
import * as S from './ReferenceFormScreen.styles'

export const options = [
  'Leadership',
  'Execution',
  'Customer service',
  'Work ethic',
  'Initiative',
  'Creativity',
  'Resilience',
  'Team relationships',
  'Work quality'
]

export const EvaluationSection = ({ setEvaluations }) => {
  return (
    <S.EvaluationSection
      title={
        <>
          <ChartHistogram /> <h1>Evaluation</h1>
        </>
      }
    >
      <S.EvaluationWrapper id="evaluation-section">
        {options.map((option, index) => (
          <S.EvaluationItem key={index}>
            <Text type="BodyMR">{option}</Text>
            <Rating
              name={option}
              onChange={(_, value) =>
                setEvaluations((oldState) => ({
                  ...oldState,
                  [snakeCase(option)]: value
                }))
              }
            >
              {option}
            </Rating>
          </S.EvaluationItem>
        ))}
      </S.EvaluationWrapper>
    </S.EvaluationSection>
  )
}

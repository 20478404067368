import { HelpFillingProfile } from '@components/atoms/HelpFillingProfile'
import { IconLabel } from '@components/atoms/IconLabel'
import { Pill } from '@components/atoms/Pill'
import { ProfilePicture } from '@components/atoms/ProfilePicture'
import { useProfileContext } from '@hooks/profile/ProfileContextProvider'
import { useUploadProfilePicture } from '@hooks/profile/useUploadProfilePicture'
import { get, startCase, truncate } from 'lodash'
import { useEffect } from 'react'
import { FiMail, FiMapPin, FiPhone } from 'react-icons/fi'
import NumberFormat from 'react-number-format'
import styled from 'styled-components'
import * as S from './CandidateProfileOverview.styles'
import { CandidateProfileOverviewProps } from './interfaces'

const CandidateProfileOvervieWrapper = ({
  ...props
}: CandidateProfileOverviewProps) => <div {...props} />

const StyledWrapper = styled(CandidateProfileOvervieWrapper)`
  ${S.candidateProfileOverviewStyles}
  transition: transform 0.3s ease-in-out;
  &.sticky {
    padding-top: 0;
  }
`

export const CandidateProfileOverview = ({
  testId = 'candidate-profile-overview-test-id',
  ...rest
}: CandidateProfileOverviewProps) => {
  const { profile, fetchProfile } = useProfileContext()
  const { uploadProfilePicture, removeProfilePicture } =
    useUploadProfilePicture()

  const isMobile = window?.screen?.width < 700

  const fullName = `${get(profile, 'general_info.first_name', '')} ${get(
    profile,
    'general_info.last_name',
    ''
  )}`

  const city = get(profile, 'general_info.city', '')
  const email = get(profile, 'general_info.email', '')
  const phone = get(profile, 'general_info.phone', '')

  const applicationFormPicture = get(
    profile,
    'application_form_profile_picture_url',
    ''
  )
  const profilePicture = get(profile, 'profile_picture_url', '')

  const _handlePictureUpload = async (image) => {
    await uploadProfilePicture(image)
    fetchProfile()
  }

  const _handlePictureRemoval = async () => {
    await removeProfilePicture()
    fetchProfile()
  }

  const getOffsetTop = (element) => {
    const elementRect = element?.getBoundingClientRect()
    return elementRect?.top
  }

  const listenToScroll = () => {
    const element = document.getElementById('candidate-profile-overview')
    const elementTop = getOffsetTop(element)
    const windowScrollHeight =
      document.body.scrollTop || document.documentElement.scrollTop
    const footerHeight = 430

    const max = document.body.scrollHeight - window.innerHeight - footerHeight

    if (windowScrollHeight > elementTop) {
      if (element && windowScrollHeight < max)
        element.style.transform = `translateY(${windowScrollHeight - 40}px)`
      element?.classList.add('sticky')
    } else {
      if (element) element.style.transform = `translateY(0px)`
      element?.classList.remove('sticky')
    }
  }

  useEffect(() => {
    if (!isMobile) window.addEventListener('scroll', listenToScroll)
    return () => window.removeEventListener('scroll', listenToScroll)
  }, [])

  return (
    <StyledWrapper
      data-testid={testId}
      {...rest}
      id="candidate-profile-overview"
    >
      <ProfilePicture
        onChangePicture={_handlePictureUpload}
        onRemovePicture={_handlePictureRemoval}
        size="large"
        src={profilePicture || applicationFormPicture}
      />

      <S.UserFullName>{truncate(fullName, { length: 32 })}</S.UserFullName>

      {city && (
        <IconLabel icon={<FiMapPin />}>
          {truncate(city, { length: 32 })}
        </IconLabel>
      )}
      {email && (
        <IconLabel icon={<FiMail />}>
          {truncate(email, { length: 32 })}
        </IconLabel>
      )}
      {phone && (
        <IconLabel icon={<FiPhone />}>
          <NumberFormat
            value={phone}
            displayType={'text'}
            format="### ### ####"
          />
        </IconLabel>
      )}

      <HelpFillingProfile />
    </StyledWrapper>
  )
}

export const PublicCandidateProfileOverview = ({
  testId = 'public-candidate-profile-overview-test-id',
  profile,
  ...rest
}: any) => {
  const fullName = `${get(profile, 'general_info.first_name', '')} ${get(
    profile,
    'general_info.last_name',
    ''
  )}`

  const city = get(profile, 'general_info.city', '')
  const email = get(profile, 'general_info.email', '')
  const phone = get(profile, 'general_info.phone', '')
  const jobSearch = get(profile, 'job_status.job_search', '')

  const applicationFormPicture = get(
    profile,
    'application_form_profile_picture_url',
    ''
  )
  const profilePicture = get(profile, 'profile_picture_url', '')

  return (
    <StyledWrapper data-testid={testId} {...rest}>
      <ProfilePicture
        hideEditButton
        size="large"
        src={profilePicture || applicationFormPicture}
      ></ProfilePicture>
      <S.UserFullName>{truncate(fullName, { length: 32 })}</S.UserFullName>

      <S.Wrapper>
        {city && (
          <S.SocialIcon>
            <FiMapPin />
            {truncate(city, { length: 32 })}
          </S.SocialIcon>
        )}
        {email && (
          <S.SocialIcon>
            <FiMail />
            {truncate(email, { length: 32 })}
          </S.SocialIcon>
        )}
        {phone && (
          <S.SocialIcon>
            <FiPhone />
            {truncate(phone, { length: 32 })}
          </S.SocialIcon>
        )}

        {jobSearch && (
          <S.PillContainer>
            <Pill>{startCase(jobSearch) || '-'}</Pill>
          </S.PillContainer>
        )}
      </S.Wrapper>
    </StyledWrapper>
  )
}

import { useMediaQuery } from '@mui/material'
import { useField } from 'formik'
import { useEffect, useRef } from 'react'
import * as S from './TextArea.styles'

export const TextArea = ({
  testId = 'input-text-id',
  label,
  maxLength = 500,
  placeholder = 'Your answer here...',
  showCounter = true,
  ...props
}: any) => {
  const [field, meta] = useField(props)
  const IS_MOBILE = useMediaQuery('(max-width: 800px)')
  const element = useRef<any>(null)

  const resize = (element: any) => {
    if (element.clientHeight < element.scrollHeight) {
      element.style.height = `${element.scrollHeight}px`
    }
  }

  useEffect(() => {
    let localElement: any = null
    if (element.current) localElement = element.current

    localElement?.addEventListener('keyup', (e) => {
      resize(e.target)
    })

    if (localElement) resize(localElement)

    return () => {
      localElement?.removeEventListener('keyup', () => {})
    }
  }, [])

  return (
    <>
      {label && <S.Label>{label}</S.Label>}
      <S.InputWrapper>
        <S.TextArea
          data-testid={testId}
          placeholder={placeholder}
          hasError={meta.touched && meta.error}
          ref={element}
          {...field}
          {...props}
        />
      </S.InputWrapper>

      <S.MessagesWrapper>
        {meta.touched && meta.error ? (
          <S.ErrorMessage>{meta.error}</S.ErrorMessage>
        ) : null}

        {showCounter && (
          <S.CountText hasError={!!meta.touched && !!meta.error}>
            {field?.value?.length || 0} {!IS_MOBILE && ` / ${maxLength}`}
          </S.CountText>
        )}
      </S.MessagesWrapper>
    </>
  )
}

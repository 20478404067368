import { Card, CardContent } from '../commons/Card.styles'
import { WorkGroupsProfileCardProps } from './interfaces'
import { ChartTreeIcon, WorkGroupsIcon } from '@commons/Icons'
import { ProfileCardPlaceholder } from '@components/molecules/ProfileCardPlaceholder'
import { useEffect, useState } from 'react'
import { useProfileContext } from '@hooks/profile/ProfileContextProvider'
import { ProfileCardTitleBar } from '@components/molecules/ProfileCardTitleBar'
import { WorkGroupsForm } from '@components/organisms/WorkGroupsForm'
import { useGetWorkplace, useWorkplace } from '@hooks/workplace/useWorkplace'
import { WorkplaceValues } from '../commons/WorkplaceValues'
import formatDescription from '../commons/FormatDescription'

export const WorkGroupsProfileCard = ({
  testId = 'work-groups-profile-card-id'
}: WorkGroupsProfileCardProps) => {
  const [edit, setEdit] = useState(false)
  const { profile, fetchProfile } = useProfileContext()
  const { loading, data: workGroups } = useGetWorkplace('/work_groups')
  const { response, update: updateWorkGroups } = useWorkplace('work_groups_ids')

  const update = () => {
    fetchProfile()
    setEdit(false)
  }

  useEffect(() => {
    if (response?.status === 200) update()
  }, [response])

  return (
    <Card data-testid={testId}>
      {edit ? (
        <>
          <ProfileCardTitleBar
            edit={true}
            title="Work groups"
            icon={<WorkGroupsIcon width={24} height={24} color="#8E97A0" />}
            onEditButtonClick={() => setEdit(true)}
          />
          <CardContent>
            <WorkGroupsForm
              workGroups={workGroups}
              onCancel={() => setEdit(false)}
              onSubmit={({ work_groups }) => {
                updateWorkGroups(work_groups.map((value) => value.id))
              }}
              initialValues={formatDescription(profile?.work_groups)}
            />
          </CardContent>
        </>
      ) : !profile?.work_groups ? (
        <CardContent>
          <ProfileCardPlaceholder
            icon={<ChartTreeIcon />}
            title="Work groups"
            subtitle="Which of the following work group descriptions do you feel
        best suits your work personality?"
            callToAction="Add work groups"
            onClick={() => setEdit(true)}
          />
        </CardContent>
      ) : (
        <WorkplaceValues
          values={formatDescription(profile?.work_groups)}
          onEditButtonClick={() => setEdit(true)}
          icon={<WorkGroupsIcon width={24} height={24} color="#8E97A0" />}
          title="Work groups"
        />
      )}
    </Card>
  )
}

export const WorkGroupsCard = ({ profile }: any) => {
  return (
    <>
      {profile?.work_groups ? (
        <>
          <WorkplaceValues
            values={profile?.work_groups}
            icon={<WorkGroupsIcon width={24} height={24} color="#8E97A0" />}
            title="Work groups"
          />
        </>
      ) : (
        <CardContent>
          <ProfileCardPlaceholder
            icon={<ChartTreeIcon />}
            title="Work groups"
            subtitle="Which of the following work group descriptions do you feel
        best suits your work personality?"
          />
        </CardContent>
      )}
    </>
  )
}

import { colors } from '@styles/colors'
import React from 'react'
import { FiX, FiInfo, FiAlertCircle, FiSlash, FiCheck } from 'react-icons/fi'
import styled from 'styled-components'
import { typography } from '@styles/typography'
import media from 'styled-media-query'

export type Severity = 'info' | 'error' | 'warning' | 'success'

export interface AlertProps extends React.HTMLAttributes<HTMLDivElement> {
  testId?: string
  severity?: Severity
  inline?: boolean
  hasRoundedCorners?: boolean
  subtitle?: string
  link?: string
  linkUrl?: string
  /**
   * if present it shows the "x" icon
   */
  onClose?(event: React.MouseEvent | React.KeyboardEvent): void
}

const MAP_BY_SEVERITY = {
  info: {
    icon: colors.infoDark,
    background: colors.infoLight,
    text: colors.infoDark,
    border: colors.info,
    component: () => <FiInfo />
  },
  error: {
    icon: colors.alertDark,
    background: colors.alertLight,
    text: colors.alertDark,
    border: colors.alertSecondary,
    component: () => <FiSlash />
  },
  warning: {
    icon: colors.warningDark,
    background: colors.warningLight,
    text: colors.warningDark,
    border: colors.warning,
    component: () => <FiAlertCircle />
  },
  success: {
    icon: colors.successDark,
    background: colors.successLight,
    text: colors.successDark,
    border: colors.successSecondary,
    component: () => <FiCheck />
  }
}

const Wrapper = styled.div<{
  severity: Severity
  inline: boolean
  hasRoundedCorners: boolean
}>`
  display: flex;
  background: ${({ severity }) => MAP_BY_SEVERITY[severity].background};
  color: ${({ severity }) => MAP_BY_SEVERITY[severity].text};
  padding: 16px;
  border-radius: 12px;
  border: 1.5px solid ${({ severity }) => MAP_BY_SEVERITY[severity].border};
  align-items: center;

  ${media.lessThan('medium')`
    padding: 14px;
  `}
`

const IconWrapper = styled.div<{ severity: Severity }>`
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 16px;

  svg {
    width: 20px;
    height: 20px;
    color: ${({ severity }) => MAP_BY_SEVERITY[severity].icon};
  }

  ${media.lessThan('medium')`
    display: none;
  `}
`

const Subtitle = styled.span<{ severity: Severity }>`
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  font-family: ${typography.primary};
  color: ${({ severity }) => MAP_BY_SEVERITY[severity].text};

  ::first-letter {
    text-transform: uppercase;
  }

  ${media.lessThan('medium')`
    font-size: 14px;
  `}
`

const Title = styled(Subtitle)`
  display: block;
  font-weight: 600;

  ::first-letter {
    text-transform: uppercase;
  }
`

const Link = styled.a`
  font-size: 16px;
  font-family: ${typography.primary};
  color: #5382c9;
`

const TextWrapper = styled.div`
  flex-grow: 1;
`

const Button = styled.button<{ severity: Severity }>`
  padding: 0;
  border: 0;
  background-color: transparent;
  margin-left: 16px;
  cursor: pointer;

  svg {
    display: block;
    color: ${({ severity }) => MAP_BY_SEVERITY[severity].text};
  }
`

export const Alert = ({
  testId = 'alert-test-id',
  severity = 'info',
  inline = false,
  onClose,
  children,
  hasRoundedCorners = false,
  subtitle,
  link,
  linkUrl,
  ...rest
}: AlertProps) => {
  const { component: Icon } = MAP_BY_SEVERITY[severity]

  return (
    <Wrapper
      data-testid={testId}
      severity={severity}
      inline={inline}
      hasRoundedCorners={hasRoundedCorners}
      {...rest}
    >
      <IconWrapper severity={severity}>
        <Icon />
      </IconWrapper>
      <TextWrapper>
        <Title severity={severity}>{children}</Title>
        {subtitle && <Subtitle severity={severity}>{subtitle}</Subtitle>}{' '}
        {link && <Link href={linkUrl}>{link}</Link>}
      </TextWrapper>
      <Button
        severity={severity}
        onClick={onClose}
        data-testid="button-close"
        aria-label="close"
      >
        <FiX />
      </Button>
    </Wrapper>
  )
}

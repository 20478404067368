import { Button } from '@components/atoms/Button'
import { InputText } from '@components/atoms/InputText'
import { useUserContext } from '@hooks/user/UserContextProvider'
import { Form, Formik, FormikProps } from 'formik'
import { FiArrowLeft } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import * as S from '../FormCommons/Form.styles'

interface Values {
  email: string
}

const idContext = `candidate-change-password-form`

const validationSchema = () => {
  return Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required')
  })
}

export const ForgotPasswordForm = ({
  testId = 'candidate-forgot-password-form-id'
}) => {
  const { resendForgotPasswordEmail } = useUserContext()
  const navigate = useNavigate()

  return (
    <S.FormWrapper data-testid={testId}>
      <S.BackLinkWrapper>
        <Button
          size="small"
          type="button"
          styling="link"
          onClick={() => navigate(-1)}
        >
          <FiArrowLeft />
          &nbsp; Back
        </Button>
      </S.BackLinkWrapper>
      <S.Header>
        <S.Heading>Forgot password?</S.Heading>
        <S.Text>
          No worries, we’ll send you an email with instructions to reset your
          password.
        </S.Text>
      </S.Header>
      <S.Body>
        <Formik
          validationSchema={validationSchema}
          validateOnMount
          initialValues={{
            email: ''
          }}
          onSubmit={async (values, actions) => {
            const { email } = values

            try {
              await resendForgotPasswordEmail(email)
            } catch (e: any) {
              const error = e?.response?.data?.error || 'Invalid informations'
              actions.setErrors({
                email: error
              })
            }

            actions.setSubmitting(false)
          }}
        >
          {(props: FormikProps<Values>) => (
            <Form>
              <S.InputWrapper>
                <InputText
                  label="Email *"
                  id={`email-${idContext}`}
                  placeholder="Enter email here"
                  type="email"
                  name="email"
                  {...props}
                />
              </S.InputWrapper>

              <S.LinkWrapper>
                <Button
                  disabled={!props.isValid}
                  loading={props.isSubmitting}
                  size="full"
                  height="large"
                  type="submit"
                >
                  Send Email
                </Button>
              </S.LinkWrapper>
            </Form>
          )}
        </Formik>
      </S.Body>
    </S.FormWrapper>
  )
}

import logo from '@assets/logo.svg'
import * as S from './BaseLayout.styles'

export interface CandidateApplicationBaseLayoutProps {
  children?: React.ReactNode
  testId?: string
}

export const CandidateApplicationBaseLayout = ({
  children,
  testId = 'component-CandidateApplicationBaseLayout-id'
}: CandidateApplicationBaseLayoutProps) => {
  return (
    <S.Container data-testid={testId}>
      <S.LeftPanel>{children}</S.LeftPanel>
      <S.RightPanel>
        <S.RightWrapper>
          <S.ImagesPanel>
            <S.LogoImage src={logo} alt="Jombine's logo" />
          </S.ImagesPanel>
          <S.Background />
        </S.RightWrapper>
      </S.RightPanel>
    </S.Container>
  )
}

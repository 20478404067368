import {
  BriefCase,
  LogOutIcon,
  SettingsIcon,
  UserIconWithoutColor
} from '@commons/Icons'
import { Logo } from '@components/atoms/Logo'
import { Text } from '@components/atoms/Text'
import { RecruiterNavbarDropdownMenu } from '@components/molecules/DropdownMenu'
import { NavigationItem } from '@components/molecules/NavigationItem'
import { useUserContext } from '@hooks/user/UserContextProvider'
import { useState } from 'react'
import { useMatch, useNavigate } from 'react-router-dom'
import { useWindowSize } from 'react-use'
import { MobileHeaderNavigation } from '../MobileHeaderNavigation'
import * as M from './RecruiterBaseLayout.mobile.styles'
import * as S from './RecruiterBaseLayout.styles'

export interface RecruiterBaseLayoutProps {
  testId?: string
  children: React.ReactNode
}

const DesktopView = ({ mainContent }) => {
  const navigate = useNavigate()
  const isApplicationsRoute = useMatch('/recruiter/applications/*')
  const isCandidateRoute = useMatch('/recruiter/candidate/*')
  const isMyAccountRoute = useMatch('/recruiter/my-account/*')

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const DropdownMenu = () => (
    <RecruiterNavbarDropdownMenu
      anchorEl={anchorEl}
      id="navbar-dropdown"
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'top' }}
      transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      PaperProps={{
        elevation: 0,
        sx: {
          width: 263,
          maxWidth: '100%',
          border: '1.5px solid #E6EBF0',
          borderRadius: '16px',
          marginTop: '-16px',
          marginLeft: '16px'
        }
      }}
    ></RecruiterNavbarDropdownMenu>
  )

  return (
    <>
      <S.Sidebar>
        <S.LogoWrapper>
          <Logo />
        </S.LogoWrapper>

        <S.Navigation>
          <S.Header>
            <NavigationItem
              isSelected={!!isApplicationsRoute}
              label="Applications"
              Icon={BriefCase}
              onClick={() => navigate('/recruiter/applications')}
            />
            <NavigationItem
              isSelected={!!isCandidateRoute}
              label="Candidates"
              Icon={UserIconWithoutColor}
              onClick={() => navigate('/recruiter/candidate/all')}
            />
          </S.Header>
          <S.Footer
            aria-controls={open ? 'navbar-dropdown' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <NavigationItem
              isSelected={!!isMyAccountRoute}
              label="Settings"
              Icon={SettingsIcon}
              onClick={handleClick}
            />
            <DropdownMenu />
          </S.Footer>
        </S.Navigation>
      </S.Sidebar>
      <S.MainContent>{mainContent}</S.MainContent>
    </>
  )
}

const MobileView = ({ mainContent }) => {
  const navigate = useNavigate()
  const { logOut } = useUserContext()

  const Navigation = (
    <M.Navigation>
      <M.NavigationHeader>
        <M.NavigationItem
          aria-label="applications"
          onClick={() => navigate('/recruiter/applications')}
        >
          Applications
        </M.NavigationItem>
        <M.NavigationItem
          aria-label="candidates"
          onClick={() => navigate('/recruiter/candidate/all')}
        >
          Candidates
        </M.NavigationItem>
      </M.NavigationHeader>
      <M.NavigationFooter>
        <M.FooterItem
          aria-label="my account"
          onClick={() => navigate('/recruiter/my-account')}
        >
          <UserIconWithoutColor />
          <Text type="BodyMR">My account</Text>
        </M.FooterItem>

        <M.FooterItem aria-label="Log out" onClick={logOut}>
          <LogOutIcon />
          <Text type="BodyMR">Log out</Text>
        </M.FooterItem>
      </M.NavigationFooter>
    </M.Navigation>
  )

  return (
    <M.Container>
      <MobileHeaderNavigation showFooter={false} sidebarChildren={Navigation}>
        {mainContent}
      </MobileHeaderNavigation>
    </M.Container>
  )
}

export const RecruiterBaseLayout = ({
  testId = 'component-RecruiterBaseLayout-id',
  children,
  ...props
}: RecruiterBaseLayoutProps) => {
  const { width } = useWindowSize()
  const IS_DESKTOP = width > 800

  return (
    <S.Container data-testid={testId} {...props}>
      {IS_DESKTOP ? (
        <DesktopView mainContent={children} />
      ) : (
        <MobileView mainContent={children} />
      )}
    </S.Container>
  )
}

import { useField } from 'formik'
import * as S from './InputText.styles'

export const InputText = ({
  testId = 'input-text-id',
  label,
  startIcon,
  endIcon,
  hasError = false,
  ...props
}: any) => {
  const [field, meta] = useField(props)
  return (
    <>
      {label && <S.Label>{label}</S.Label>}
      <S.InputWrapper>
        {startIcon && <S.IconWrapper startIcon>{startIcon}</S.IconWrapper>}
        <S.Input
          data-testid={testId}
          startIcon={startIcon}
          hasError={hasError || (meta.touched && meta.error)}
          {...field}
          {...props}
        />
        {endIcon && <S.IconWrapper startIcon={false}>{endIcon}</S.IconWrapper>}
      </S.InputWrapper>
      {meta.touched && meta.error ? (
        <S.ErrorMessage>{meta.error}</S.ErrorMessage>
      ) : null}
    </>
  )
}

export const InputTextWithoutFormik = ({
  testId = 'input-text-raw-id',
  label,
  startIcon,
  endIcon,
  hasError = false,
  ...props
}: any) => {
  return (
    <>
      {label && <S.Label>{label}</S.Label>}
      <S.InputWrapper>
        {startIcon && <S.IconWrapper startIcon>{startIcon}</S.IconWrapper>}
        <S.Input
          data-testid={testId}
          startIcon={startIcon}
          hasError={hasError}
          {...props}
        />
        {endIcon && <S.IconWrapper startIcon={false}>{endIcon}</S.IconWrapper>}
      </S.InputWrapper>
    </>
  )
}

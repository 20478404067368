import styled, { css } from 'styled-components'
import { ProfileCard } from '@components/atoms/ProfileCard'

export const Card = styled(ProfileCard)`
  display: block;
  flex-direction: column;
  padding-top: 0;
  height: auto;
  padding: 0;

  :first-child {
    margin-top: 0;
  }
`

export const CardContent = styled.div`
  padding: 40px;
  min-height: 126px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 24px 0;

    .row {
      padding-left: 40px;
      padding-right: 40px;
    }
  }
`

export const CardContainer = styled(CardContent)`
  padding: 40px 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 24px 0;
  }
`

export const PillsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 0 24px;
  }
`

export const CardText = styled.div<{
  isEditing: boolean
  editButton?: boolean
}>`
  display: flex;
  opacity: ${({ isEditing }) => (isEditing ? '0.5' : '1')};
  gap: 40px;

  ${({ editButton }) =>
    editButton &&
    css`
      > div {
        flex-grow: 1;
        margin-right: 1rem;
      }

      p {
        margin-right: 1rem;
        flex-grow: 1;
        overflow: auto;
      }
    `}

  p + p {
    color: ${({ theme }) => theme.colors.shades.primary300};
  }

  button {
    flex-shrink: 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 0 24px;
    gap: 16px;
  }

  p {
    flex-grow: 1;
  }
`

export const CardTextContainer = styled.div`
  padding: 40px;
  border-bottom: 1px solid #e6ebf0;

  :first-child {
    padding-top: 0;
  }

  > div + div {
    margin-top: 40px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 24px 0;
  }
`

export const CardTextWrapper = styled(CardTextContainer)`
  padding: 40px 0;

  :last-child {
    border-bottom: none;
  }
`

export const CardFooter = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 40px 20px;
  margin-top: -20px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin-top: -8px;
    padding: 0 40px 16px;
  }

  > div {
    flex-grow: 1;
  }
`

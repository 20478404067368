import { PublicCandidateProfileOverview } from '@components/organisms/CandidateProfileOverview/CandidateProfileOverview'
import { Footer } from '@components/organisms/Footer'
import { HeaderWithCenteredLogo } from '@components/organisms/HeaderWithCenteredLogo'
import { usePublicProfile } from '@hooks/profile/usePublicProfile'

import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  PublicProfileScreenTabs,
  TabContent
} from '../RecruiterCandidateProfileView/Tabs'
import { PublicEmployerCandidateProfileProps } from './interfaces'
import * as S from './PublicEmployerCandidateProfile.styles'

export const PublicEmployerCandidateProfile = ({
  testId = 'public-employer-candidate-profile-id',
  ...props
}: PublicEmployerCandidateProfileProps) => {
  const { id: candidateId, tab } = useParams()
  const [candidateInfo, setCandidateInfo] = useState<any | null>()
  const { fetchPublicProfile } = usePublicProfile()

  useEffect(() => {
    const fetch = async () => {
      const candidate = await fetchPublicProfile({ candidateId })
      setCandidateInfo(candidate)
    }

    fetch()
  }, [])

  const TabSection = () => {
    if (!tab) return null
    const selectedTab = TabContent[tab]
    const tabContent = selectedTab({
      ...candidateInfo?.profile,
      isPublic: true
    })
    return tabContent
  }

  const MainContent = () => (
    <S.Main>
      <S.LeftWrapper>
        <PublicCandidateProfileOverview profile={candidateInfo?.profile} />
      </S.LeftWrapper>
      <S.RightWrapper>
        <S.TabsWrapper>
          <PublicProfileScreenTabs profile={candidateInfo?.profile} />
        </S.TabsWrapper>
        <TabSection />
      </S.RightWrapper>
    </S.Main>
  )

  return (
    <S.Container data-testid={testId} {...props}>
      <HeaderWithCenteredLogo />

      <MainContent />

      <Footer />
    </S.Container>
  )
}

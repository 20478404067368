import { colors } from './colors'
import { breakpoints, grid } from './grid'
import { typography } from './typography'

export const light = {
  grid,
  breakpoints,
  border: {
    button: '8px',
    navItem: '12px',
    roundedBox: {
      extraLarge: '96px',
      large: '56px',
      tablet: '64px',
      mobile: '40px'
    }
  },
  typography,
  colors,
  spacings: {},
  layers: {},
  transition: {
    default: '0.3s ease-in-out',
    fast: '0.1s ease-in-out'
  }
} as const

export type ThemeType = typeof light
const theme = light
export default theme

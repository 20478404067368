import { Form, Formik, FormikProps } from 'formik'
import { yupRequired } from '@components/commons/Form/validations'
import { VideoInterviewFormProps } from './interfaces'
import { Text } from '@components/atoms/Text'
import { Button } from '@components/atoms/Button'
import { FormContainer } from '@components/atoms/FormContainer'
import * as Yup from 'yup'
import * as S from '../FormCommons/Form.styles'
import { useEffect } from 'react'
import get from 'lodash/get'
import { InputText } from '@components/atoms/InputText'
import { useVideoInterview } from '@hooks/videoInterview/useVideoInterview'
import { useParams } from 'react-router-dom'

interface Values {
  videoUrl: string
}

const idContext = `VideoInterviewForm-form`

const validationSchema = () => {
  return Yup.object().shape({
    videoUrl: yupRequired
  })
}

export const VideoInterviewForm = ({
  testId = 'VideoInterviewForm-id',
  testSubmit,
  onCancel,
  onSucess,
  profile,
  ...props
}: VideoInterviewFormProps) => {
  const { id } = useParams()
  const { loading, response, update } = useVideoInterview(id)

  useEffect(() => {
    if (response?.status === 200) onSucess()
  }, [response])

  return (
    <FormContainer data-testid={testId} {...props}>
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          videoUrl: get(profile, 'video_interview.video_url', '')
        }}
        enableReinitialize
        onSubmit={(values) => {
          const method = testSubmit ? testSubmit : update
          method(values)
        }}
      >
        {(props: FormikProps<Values>) => (
          <Form>
            <S.InputRow>
              <S.InputCol>
                <InputText
                  label={'URL Video *'}
                  id={`video-${idContext}`}
                  placeholder="www.youtube.com"
                  type="text"
                  name="videoUrl"
                  testId="fieldId"
                  disabled={loading}
                  {...props}
                />
                <Text type={'BodySMR'} style={{ marginTop: 12 }}>
                  We can only support videos uploaded on Youtube and Vimeo
                </Text>
              </S.InputCol>
            </S.InputRow>
            <S.ButtonsWrapper>
              <Button
                styling="secondary"
                size="small"
                type="button"
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button
                loading={loading}
                size="small"
                type="submit"
                testId="fieldId"
              >
                Save
              </Button>
            </S.ButtonsWrapper>
          </Form>
        )}
      </Formik>
    </FormContainer>
  )
}

import * as S from './ButtonIcon.styles'
import { ButtonIconProps } from './interfaces'
import { Text } from '@components/atoms/Text'

export const ButtonIcon: React.FC<ButtonIconProps> = ({
  testId = 'button-icon-id',
  disabled,
  children,
  text,
  ...props
}) => {
  return (
    <S.Container
      type="button"
      data-testid={testId}
      {...props}
      disabled={disabled}
      aria-label="button-icon"
    >
      <div>{children}</div>
      {text && <Text type="BodyMB">{text}</Text>}
    </S.Container>
  )
}

import styled from 'styled-components'
import { ProfileCard } from '@components/atoms/ProfileCard'
import { WorkSkillsForm } from '@components/organisms/WorkSkillsForm'
import { ProfileCardTitleBar } from '@components/molecules/ProfileCardTitleBar'

export const Card = styled(ProfileCard)`
  padding-top: 2px;
  padding-bottom: 0;
  height: auto;
`

export const Form = styled(WorkSkillsForm)``

export const PillContainer = styled.div`
  padding-top: 28px;
  padding-left: 40px;
  padding-bottom: 15px;
`

export const TitleBar = styled(ProfileCardTitleBar)`
  top: 0;
  height: 72px !important;
`

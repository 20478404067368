import theme from '@styles/theme'
import styled, { css } from 'styled-components'
import { PillProps } from './interfaces'

type IconWrapperProps = {
  startIcon?: boolean
}

export const pillStyle = css<PillProps>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  background-color: ${() => theme.colors.shades.gray500};
  width: fit-content;
  max-width: 250px;
  height: 32px;
  padding: 8px 12px;
  border-radius: 99px;
  font-size: ${({ theme }) => theme.typography.sizes.text.extraSmall};
  font-weight: ${({ theme }) => theme.typography.weights.semiBold};
  color: ${() => theme.colors.shades.primary500};
  margin-bottom: 10px;

  > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
export const IconWrapper = styled.span<IconWrapperProps>`
  display: inline-block;
  justify-content: right;
  margin-top: 6px;
  padding-left: 9px;
`

export const Text = styled.span`
  margin-top: 2px;
  line-height: 16px;
`

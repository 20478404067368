import { CandidateStartApplyScreen } from '@components/layout/ApplicationForm/StartApplyScreen'
import { ThanksForApplying } from '@components/layout/ApplicationForm/ThanksForApplying'
import { CandidateProfileScreen } from '@components/layout/CandidateProfileScreen/CandidateProfileScreen'
import { Settings } from '@components/layout/CandidateSettings'
import { PublicEmployerCandidateProfile } from '@components/layout/PublicEmployerCandidateProfile'
import { ReferenceFormScreen } from '@components/layout/ReferenceFormScreen'
import { ReferenceReviewSent } from '@components/layout/ReferenceReviewSent'
import { CandidateApplicationStepManager } from '@components/organisms/ApplicationForm/StepManager'
import { ProfileContextProvider } from '@hooks/profile/ProfileContextProvider'
import { Route, Routes } from 'react-router-dom'

export const CANDIDATE_ROUTES = {
  WELCOME: '/welcome',
  APPLICATION_STEP: '/application-form/:currentStep',
  PROFILE: '/profile',
  PUBLIC_PROFILE: '/public/profile/:id/:tab',
  PROFILE_TAB: '/profile/:tab',
  REFERENCE_DETAILS: '/profile/:tab/reference-details',
  THANKS_FOR_APPLYING: '/application-form/thanks-for-applying',
  REVIEW: '/:id/review/:secret_id',
  REVIEW_SENT: '/review/sent/:name',
  SETTINGS: '/settings'
}

export const CANDIDATE_VIEW_PATH = '/candidate/*'

export const CandidateRoutes = () => {
  return (
    <Routes>
      <Route
        path={CANDIDATE_ROUTES.WELCOME}
        element={<CandidateStartApplyScreen />}
      ></Route>
      <Route
        path={CANDIDATE_ROUTES.APPLICATION_STEP}
        element={<CandidateApplicationStepManager />}
      ></Route>
      <Route
        path={CANDIDATE_ROUTES.PROFILE_TAB}
        element={
          <ProfileContextProvider>
            <CandidateProfileScreen />
          </ProfileContextProvider>
        }
      ></Route>
      <Route
        path={CANDIDATE_ROUTES.REFERENCE_DETAILS}
        element={
          <ProfileContextProvider>
            <CandidateProfileScreen />
          </ProfileContextProvider>
        }
      ></Route>

      <Route
        path={CANDIDATE_ROUTES.PROFILE}
        element={
          <ProfileContextProvider>
            <CandidateProfileScreen />
          </ProfileContextProvider>
        }
      ></Route>

      <Route path={CANDIDATE_ROUTES.PUBLIC_PROFILE}>
        <Route
          path=":detail"
          element={
            <ProfileContextProvider>
              <PublicEmployerCandidateProfile />
            </ProfileContextProvider>
          }
        />
        <Route
          path=""
          element={
            <ProfileContextProvider>
              <PublicEmployerCandidateProfile />
            </ProfileContextProvider>
          }
        />
      </Route>

      <Route
        path={CANDIDATE_ROUTES.THANKS_FOR_APPLYING}
        element={<ThanksForApplying />}
      ></Route>

      <Route
        path={CANDIDATE_ROUTES.REVIEW}
        element={<ReferenceFormScreen />}
      ></Route>

      <Route
        path={CANDIDATE_ROUTES.REVIEW_SENT}
        element={<ReferenceReviewSent />}
      ></Route>

      <Route
        path={CANDIDATE_ROUTES.SETTINGS}
        element={
          <ProfileContextProvider>
            <Settings />
          </ProfileContextProvider>
        }
      ></Route>
    </Routes>
  )
}

import styled, { css } from 'styled-components'
import { ProfileCard } from '@components/atoms/ProfileCard'
import { ProfileCardTitleBar } from '@components/molecules/ProfileCardTitleBar'
import { AddItemButton } from '@components/atoms/AddItemButton'
import { ProfileCardPlaceholder } from '@components/molecules/ProfileCardPlaceholder'
import { NotesForm } from './NotesForm'
import { NotesItem } from './NotesItem'

interface NotesItemProps {
  disabled?: boolean
}

export const Item = styled(NotesItem)<any>`
  .text {
    ${({ disabled }) =>
      disabled
        ? `
    opacity: 0.56;
  `
        : ''}
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
    .form {
      margin-left: -24px;
      margin-right: -24px;
    }
  }
`

export const Form = styled(NotesForm)``

export const Placeholder = styled(ProfileCardPlaceholder)`
  margin: 50px 0;
`

export const Card = styled(ProfileCard)`
  padding-top: 2px;
  padding-bottom: 0;
  height: auto;
`

export const Divider = styled.hr`
  border-top: 1px solid #e6ebf0;
  border-bottom: none;
  border-left: none;
  border-right: none;
  height: 1px;
`

export const PaddedDivider = styled(Divider)`
  margin-left: 30px;
  margin-right: 30px;
`

export const FullWidthDivider = styled(Divider)`
  display: block;
  margin-bottom: 0;
  margin-top: auto;
  width: 100%;
`

export const TitleBar = styled(ProfileCardTitleBar)`
  top: 0;
  height: 72px !important;
`
export const Add = styled(AddItemButton)<{ disabled: boolean }>`
  margin: 28px auto;
  opacity: ${({ disabled }) => (disabled ? 0.56 : 1)};

  ${({ disabled }) => {
    const disabledStyles = css`
      :hover {
        div {
          background-color: #4de295;
          svg path {
            fill: #1c2e40;
          }
        }
    `

    if (disabled) {
      return disabledStyles
    }
  }}
`

import * as S from './AutocompleteSelect.styles'
import { AutocompleteSelectProps } from './interfaces'
import { useState, useRef, useEffect } from 'react'
import { Pill } from '@components/atoms/Pill'
import Highlighter from 'react-highlight-words'
import { Text } from '@components/atoms/Text'
import { ClickAwayListener } from '@mui/material'
import { PlusOrbIcon } from '@commons/Icons'

export const AutocompleteSelect = ({
  testId = 'autocomplete-select-id',
  max,
  placeholder,
  limitReachedText,
  options,
  onChange,
  onQueryChange,
  onAdd,
  showAll,
  initialValues,
  ...props
}: AutocompleteSelectProps) => {
  const [query, setQuery] = useState('')
  const [selectedOptions, setSelectedOptions] = useState<any>(
    initialValues || []
  )
  const [showOptions, setShowOptions] = useState(false)

  const selectOption = (option) => {
    if (max && selectedOptions.length >= max) return
    setSelectedOptions([...selectedOptions, option])
    setShowOptions(false)
    setQuery('')
  }

  let availableOptions = options?.filter(
    (option) =>
      !selectedOptions.find((selectOption) => selectOption.id == option.id)
  )

  if (showAll)
    availableOptions = availableOptions?.filter((option) =>
      option.value.toLowerCase().includes(query.toLowerCase())
    )

  const remove = (option) => {
    setSelectedOptions(
      selectedOptions.filter(
        (selectedOption) => selectedOption.id !== option.id
      )
    )
  }

  const [inputFieldYPosition, setInputFieldYPosition] = useState<
    number | undefined
  >(0)
  const [inputFieldWidth, setInputFieldWidth] = useState<number | undefined>(0)

  const inputFieldRef = useRef<HTMLInputElement>(null)

  const handleScroll = () => {
    const inputFieldY = inputFieldRef.current?.getBoundingClientRect().y
    setInputFieldYPosition(inputFieldY)
  }

  const handleResize = () => {
    const inputFieldWidth = inputFieldRef.current?.getBoundingClientRect().width
    setInputFieldWidth(inputFieldWidth)
  }

  const addOption = () => {
    setQuery('')
    setShowOptions(false)
    onAdd &&
      onAdd(query).then((option) => {
        setSelectedOptions([...selectedOptions, option])
      })
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    handleScroll()
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    onChange && onChange(selectedOptions)
  }, [selectedOptions, onChange])

  useEffect(() => {
    onQueryChange && onQueryChange(query)
  }, [query, onQueryChange])

  return (
    <S.Container {...props}>
      {max && (
        <S.TitleBar>
          <S.ChooseUpToText>Choose up to {max} options</S.ChooseUpToText>
          {(() => {
            const itemsLeft = max - selectedOptions.length
            return (
              <S.ItemsLeft itemsLeft={itemsLeft}>
                {itemsLeft} items left
              </S.ItemsLeft>
            )
          })()}
        </S.TitleBar>
      )}

      <ClickAwayListener onClickAway={() => setShowOptions(false)}>
        <div>
          <S.Input
            ref={inputFieldRef}
            disabled={max && selectedOptions.length >= max}
            value={query}
            onFocus={() => setShowOptions(true)}
            onChange={(event) => setQuery(event.target.value)}
            placeholder={placeholder}
            aria-label={placeholder}
            data-testid={testId}
          />

          {availableOptions && showOptions && availableOptions.length > 0 && (
            <S.Paper
              positionY={inputFieldYPosition}
              width={inputFieldWidth}
              data-testid={`${testId}-paper`}
              showAll={showAll}
            >
              {availableOptions?.map((option) => (
                <S.Option key={option.id} onClick={() => selectOption(option)}>
                  <Highlighter
                    searchWords={[query]}
                    textToHighlight={option.value}
                    highlightTag={({ children }) => <strong>{children}</strong>}
                  ></Highlighter>
                </S.Option>
              ))}
            </S.Paper>
          )}

          {query !== '' &&
            availableOptions &&
            availableOptions.length == 0 &&
            onAdd &&
            showOptions && (
              <S.Paper positionY={inputFieldYPosition} width={inputFieldWidth}>
                <S.Option add={true} onClick={addOption}>
                  <PlusOrbIcon />
                  Add “{query}”
                </S.Option>
              </S.Paper>
            )}
        </div>
      </ClickAwayListener>
      {max && selectedOptions.length >= max && (
        <S.DisabledText>{limitReachedText}</S.DisabledText>
      )}

      <S.Pills>
        {selectedOptions.map((option) => (
          <Pill
            key={option.id}
            maxLength={35}
            endIcon={<S.Delete onClick={() => remove(option)} />}
          >
            {option.value}
          </Pill>
        ))}
      </S.Pills>
    </S.Container>
  )
}

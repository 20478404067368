import { AiOutlineCheck } from 'react-icons/ai'
import styled from 'styled-components'
import media from 'styled-media-query'

export const Wrapper = styled.div`
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.primaryBlue};
  display: flex;
  flex-direction: column;
  font-weight: 600;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin: 40px 12px;
  }
`

export const Container = styled(Wrapper)`
  @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
    justify-content: flex-start;
    height: 100%;
    width: 100%;
  }
`

export const BodyWithoutOverflow = styled.div`
  font-weight: 400;
  margin-top: 12px;
  text-align: center;
  width: 100%;
  max-width: 370px;

  @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
    max-width: initial;
    width: initial;

    p {
      max-width: 270px;
    }
  }
`

export const Body = styled.div`
  font-weight: 400;
  margin-top: 12px;
  text-align: center;
  width: 100%;
  max-width: 370px;

  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
    max-width: initial;
    width: initial;

    p {
      max-width: 270px;
    }
  }
`

export const Heading = styled.h1`
  font-size: 40px;
  font-weight: ${({ theme }) => theme.typography.weights.extraBold};
  font-weight: 600;
  letter-spacing: 0.02em;
  line-height: 47.4px;

  ${media.lessThan('medium')`
		font-size: 24px;
		line-height: 31px;
	`}
`

export const Text = styled.p`
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.02em;
  line-height: 32px;

  ${media.lessThan('medium')`
	  font-size: 16px;
		line-height: 25.6px;
	`}
`

export const Footer = styled.div`
  margin-top: 40px;

  @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
    margin-top: 32px;
  }

  > p {
    display: initial;
  }
`

export const CallToActionButtonWrapper = styled.div`
  display: inline;
  > button {
    font-size: 16px;
    line-height: 17.6px;
  }
`

export const GreenCheckerWrapper = styled.div`
  width: 80px;
  height: 80px;
  margin-bottom: 32px;

  svg {
    height: 100%;
    width: 100%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
    width: 64px;
    height: 64px;
    margin-bottom: 24px;
  }
`

export const ConfirmationIcon = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.shades.secondary500};
  border-radius: 50%;
  display: flex;
  height: 32px;
  justify-content: center;
  margin-bottom: 32px;
  width: 32px;
`

export const CheckIcon = styled(AiOutlineCheck)`
  height: 24px;
  width: 24px;
`

import styled from 'styled-components'
import * as S from './IconLabel.styles'
import { IconLabelProps } from './interfaces'

const IconLabelWrapper = ({ ...props }: IconLabelProps) => <div {...props} />

const StyledWrapper = styled(IconLabelWrapper)`
  ${S.iconLabelStyle}
`

const TextWrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 100%;
`

const Link = styled.a<React.AnchorHTMLAttributes<HTMLAnchorElement>>``

export const IconLabel = ({
  testId = 'icon-label-test-id',
  children,
  icon,
  asLink = false,
  href,
  ...rest
}: IconLabelProps) => {
  return (
    <StyledWrapper icon={icon} data-testid={testId} {...rest}>
      {icon}
      <TextWrapper>
        {asLink ? (
          <Link href={href} target="_blank" rel="noopener noreferrer">
            {children}
          </Link>
        ) : (
          children
        )}
      </TextWrapper>
    </StyledWrapper>
  )
}

import styled from 'styled-components'

export const Container = styled.div`
  background: ${({ theme }) =>
    theme.colors.primaryBlue}; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom right,
    #0c1925,
    #1d2f41
  ); /* Chrome 10-25, Safari 5.1-6 */

  background: linear-gradient(
    to bottom right,
    #0c1925,
    #1d2f41
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
`

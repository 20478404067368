import { Star, StarIcon } from '@commons/Icons'
import { Text } from '@components/atoms/Text'
import { ProfileCardPlaceholder } from '@components/molecules/ProfileCardPlaceholder'
import { ProfileCardTitleBar } from '@components/molecules/ProfileCardTitleBar'
import { useProfileContext } from '@hooks/profile/ProfileContextProvider'
import get from 'lodash/get'
import { useState } from 'react'
import { Card, CardContent } from '../commons/Card.styles'
import { AddHighlight } from './components/AddHighlight'
import { EditProfessionalHighlights } from './components/EditProfessionalHighlights'
import { ProfessionalHighlight } from './components/ProfessionalHighlight'
import { ProfessionalHighlightsProfileCardProps } from './interfaces'
import * as S from './ProfessionalHighlightsProfileCard.styles'
import { CardContent as PersonalHighlightsWrapper } from '../PersonalHighlightsProfileCard/PersonalHighlightsProfileCard.styles'

export const ProfessionalHighlightsProfileCard = ({
  testId = 'professional-highlights-profile-card-id'
}: ProfessionalHighlightsProfileCardProps) => {
  const [edit, setEdit] = useState(false)
  const [add, setAdd] = useState(false)
  const [editId, setEditId] = useState<number | null>(null)
  const { profile, fetchProfile } = useProfileContext()

  const update = () => {
    fetchProfile()
    setEdit(false)
    setAdd(false)
  }

  return (
    <Card data-testid={testId}>
      {!profile?.professional_highlights ? (
        !edit ? (
          <CardContent>
            <ProfileCardPlaceholder
              icon={<StarIcon />}
              title="Professional Highlights"
              subtitle="Outline a max of 5 different personal achievements or highlights to spotlight to potential employers"
              callToAction="Add Highlights"
              onClick={() => setEdit(true)}
            />
          </CardContent>
        ) : (
          <EditProfessionalHighlights
            edit={edit}
            onCancel={() => setEdit(false)}
            onSucess={update}
          />
        )
      ) : (
        <>
          <ProfessionalHighlight
            edit={edit}
            professionalHighlights={get(profile, 'professional_highlights', [])}
            editId={editId}
            editButton={(id) => {
              if (!edit) {
                setEditId(id)
                setEdit(true)
              }
            }}
            onCancel={() => setEdit(false)}
            onSucess={update}
          />
          <AddHighlight
            add={add}
            onCancel={() => {
              setEdit(false)
              setAdd(false)
            }}
            onSucess={update}
            onClick={() => {
              setAdd(true)
              setEdit(true)
              setEditId(null)
            }}
            disabled={edit}
          />
        </>
      )}
    </Card>
  )
}

export const ProfessionalHighlightsCard = ({ profile }: any) => {
  return (
    <>
      {!profile?.professional_highlights ? (
        <CardContent>
          <ProfileCardPlaceholder
            icon={<StarIcon />}
            title="Professional Highlights"
            subtitle="Outline a max of 5 different personal achievements or highlights to spotlight to potential employers"
          />
        </CardContent>
      ) : (
        <>
          <ProfileCardTitleBar
            title="Professional highlights"
            icon={<Star width={24} height={24} color="#8E97A0" />}
          />
          <PersonalHighlightsWrapper>
            {profile?.professional_highlights.map(
              ({ id, description }, index) => (
                <>
                  <Text type="BodyMR" key={id}>
                    {description}
                  </Text>

                  {index + 1 < profile?.professional_highlights!.length && (
                    <S.PaddedDivider />
                  )}
                </>
              )
            )}
          </PersonalHighlightsWrapper>
        </>
      )}
    </>
  )
}

import {
  styled,
  Tooltip as OriginalTooltip,
  tooltipClasses,
  TooltipProps
} from '@mui/material'

export const Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <OriginalTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: '4px',
    padding: '12px',
    backgroundColor: '#0B121A',
    maxWidth: '198px'
  }
}))

import { CheckEmailConfirmation } from '@components/layout/CheckEmailConfirmation'
import { AccountConfirmation } from '@components/layout/AccountConfirmation'
import { SignUp } from '@components/layout/SignUp'
import { SignIn } from '@components/layout/SignIn'
import { Route, Routes } from 'react-router-dom'
import { ResetPassword } from '@components/layout/ResetPassword'
import { ForgotPassword } from '@components/layout/ForgotPassword'
import { CheckPasswordEmailConfirmation } from '@components/layout/CheckEmailPasswordConfirmation'
import { PasswordChangedConfirmation } from '@components/layout/PasswordChangedConfirmation'

export const SESSION_ROUTES = {
  SIGN_UP: '/sign-up/:secret_id',
  SIGN_IN: '/sign-in',
  ACCOUNT_CONFIRMATION: '/email-confirmations/:confirmationToken',
  CONFIRMATION_MESSAGE: '/confirm-email',
  CONFIRMATION_MESSAGE_FORGOT_PASSWORD: '/forgot-password/confirm-email',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password/:passwordToken',
  RESET_PASSWORD_CONFIRMATION_MESSAGE: '/reset-password/confirm'
}

export const SESSION_VIEW_PATH = '/users/*'

export const SessionRoutes = () => {
  return (
    <Routes>
      <Route path={SESSION_ROUTES.SIGN_UP} element={<SignUp />}></Route>
      <Route path={SESSION_ROUTES.SIGN_IN} element={<SignIn />}></Route>
      <Route
        path={SESSION_ROUTES.CONFIRMATION_MESSAGE}
        element={<CheckEmailConfirmation />}
      ></Route>
      <Route
        path={SESSION_ROUTES.ACCOUNT_CONFIRMATION}
        element={<AccountConfirmation />}
      ></Route>

      <Route
        path={SESSION_ROUTES.CONFIRMATION_MESSAGE_FORGOT_PASSWORD}
        element={<CheckPasswordEmailConfirmation />}
      ></Route>

      <Route
        path={SESSION_ROUTES.FORGOT_PASSWORD}
        element={<ForgotPassword />}
      ></Route>
      <Route
        path={SESSION_ROUTES.RESET_PASSWORD}
        element={<ResetPassword />}
      ></Route>

      <Route
        path={SESSION_ROUTES.RESET_PASSWORD_CONFIRMATION_MESSAGE}
        element={<PasswordChangedConfirmation />}
      ></Route>
    </Routes>
  )
}

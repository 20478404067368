import { yupTextArea } from '@commons/Form/validations'
import { TextArea } from '@components/atoms/TextArea'
import * as Yup from 'yup'
// import { FORM_STEPS, useApplicationForm } from '../hooks/useApplicationForm'
import * as S from '../commons/commons.styles'

interface Values {
  careerTrack: string
  biggestProfessionalGoal: string
}

export const initialValues = {
  careerTrack: '',
  biggestProfessionalGoal: ''
}

const idContext = `candidate-application-form-4`
// const CURRENT_STEP = 4

export const validationSchema = () => {
  return Yup.object().shape({
    careerTrack: yupTextArea,
    biggestProfessionalGoal: yupTextArea
  })
}

type Props = {
  testId?: string
}

export const Step4 = ({ testId = 'application-step4-test-id', props }: any) => {
  // const { setCurrentFormMetadata, setCurrentStep } = useApplicationForm()
  // setCurrentStep(FORM_STEPS[CURRENT_STEP])

  return (
    <S.FormWrapper data-testid={testId}>
      <S.Body>
        <S.InputWrapper>
          <TextArea
            label={
              "In 500 words or less, tell us why you've chosen your career track or what you love most about it?"
            }
            id={`career-track-${idContext}`}
            placeholder="Your answer here..."
            name="careerTrack"
            data-testid={`input`}
            {...props}
          />
        </S.InputWrapper>
        <S.InputWrapper>
          <TextArea
            label={`Besides a new job/career, what is your ONE biggest professional goal that you hope to accomplish within the next 3-5 years`}
            id={`biggest-professional-${idContext}`}
            placeholder="Your answer here..."
            name="biggestProfessionalGoal"
            data-testid={`input`}
            {...props}
          />
        </S.InputWrapper>
      </S.Body>
    </S.FormWrapper>
  )
}

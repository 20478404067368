import { TrashIcon } from '@commons/Icons'
import * as S from './DeleteButton.styles'
import { DeleteButtonProps } from './interfaces'
import { Text } from '../Text'

export const DeleteButton = ({
  testId = 'delete-button-id',
  ...props
}: DeleteButtonProps) => {
  return (
    <S.Container data-testid={testId} {...props} type="button">
      <TrashIcon />
      <Text type="BodyMB">Delete</Text>
    </S.Container>
  )
}

import styled, { css } from 'styled-components'

import { CheckboxFieldProps } from './interfaces'

export const Container = styled.div<Pick<CheckboxFieldProps, 'disabled'>>`
  display: flex;
  align-items: center;
  margin-bottom: 28px;
  gap: 9px;
  width: 100%;

  & div {
    display: flex;
    align-items: center;
    justify-content: center;

    input {
      width: 18px;
      height: 18px;
      cursor: pointer;
      accent-color: ${({ theme }) => theme.colors.shades.secondary500};
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      border: 2px solid ${({ theme }) => theme.colors.shades.primary500};
      border-radius: 2px;
      box-sizing: content-box;
      color: red;
      transition-property: border-color,
        ${({ theme }) => theme.colors.shades.primary500}; /* Safari needed border-color. */
      transition-timing-function: ease;
      border-radius: 6px;

      ${({ disabled }) =>
        disabled &&
        css`
          border-color: ${({ theme }) => theme.colors.shades.gray500};
          background-color: ${({ theme }) => theme.colors.shades.gray300};
        `}
    }

    input:checked {
      background: url("data:image/svg+xml,%3Csvg width='24' height='17' viewBox='0 0 24 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22.3189 0.431069L8.49985 14.2491C8.40694 14.3423 8.29653 14.4164 8.17495 14.4669C8.05337 14.5174 7.92301 14.5434 7.79135 14.5434C7.6597 14.5434 7.52934 14.5174 7.40776 14.4669C7.28618 14.4164 7.17576 14.3423 7.08285 14.2491L1.73885 8.90007C1.64594 8.80679 1.53553 8.73278 1.41395 8.68228C1.29237 8.63178 1.16201 8.60578 1.03035 8.60578C0.8987 8.60578 0.768342 8.63178 0.64676 8.68228C0.525177 8.73278 0.414764 8.80679 0.321854 8.90007C0.228577 8.99298 0.154565 9.10339 0.104063 9.22498C0.0535617 9.34656 0.027565 9.47691 0.027565 9.60857C0.027565 9.74022 0.0535617 9.87058 0.104063 9.99216C0.154565 10.1137 0.228577 10.2242 0.321854 10.3171L5.66785 15.6621C6.2318 16.225 6.99605 16.5411 7.79285 16.5411C8.58966 16.5411 9.35391 16.225 9.91785 15.6621L23.7359 1.84707C23.829 1.75418 23.9029 1.64383 23.9533 1.52234C24.0037 1.40085 24.0296 1.2706 24.0296 1.13907C24.0296 1.00753 24.0037 0.877292 23.9533 0.755802C23.9029 0.634311 23.829 0.523961 23.7359 0.431069C23.6429 0.337793 23.5325 0.26378 23.4109 0.213278C23.2894 0.162777 23.159 0.13678 23.0274 0.13678C22.8957 0.13678 22.7653 0.162777 22.6438 0.213278C22.5222 0.26378 22.4118 0.337793 22.3189 0.431069Z' fill='%231C2E40'/%3E%3C/svg%3E%0A")
        no-repeat;
      background-color: ${({ theme }) => theme.colors.shades.secondary500};
      display: inline-block;
      background-size: 80%;
      background-position: center;
      border: 2px solid ${({ theme }) => theme.colors.shades.secondary500};
    }
  }

  label {
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    line-height: 25.6px;
    letter-spacing: 0em;
  }
`

import { CheckIconWithBackground } from '@commons/Icons'
import { Button } from '@components/atoms/Button'
import { Text } from '@components/atoms/Text'
import styled from 'styled-components'
import * as S from './AcccountConfirmMessage.styles'

export interface AccountConfirmMessageProps {
  testId?: string
  goToSignIn: () => void
}

const Container = styled(S.Wrapper)`
  justify-content: flex-start;
  height: 100%;
  width: 100%;
`

export const AccountConfirmMessage = ({
  testId = 'component-check-email-id',
  goToSignIn,
  ...props
}: AccountConfirmMessageProps) => {
  return (
    <S.Container>
      <S.Wrapper data-testid={testId} {...props}>
        <S.GreenCheckerWrapper>
          <CheckIconWithBackground />
        </S.GreenCheckerWrapper>

        <S.Heading>Email confirmed!</S.Heading>

        <S.BodyWithoutOverflow>
          <Text type="BodyLR" breakText>
            Your email was confirmed! Sign in to get started with Jombine!
          </Text>
        </S.BodyWithoutOverflow>

        <S.Footer>
          <Button
            size="large"
            height="large"
            type="button"
            onClick={goToSignIn}
          >
            Sign In
          </Button>
        </S.Footer>
      </S.Wrapper>
    </S.Container>
  )
}

import { VideoCameraIcon, VideoIcon } from '@commons/Icons'
import { ProfileCardPlaceholder } from '@components/molecules/ProfileCardPlaceholder'
import { ProfileCardTitleBar } from '@components/molecules/ProfileCardTitleBar'
import { useProfileContext } from '@hooks/profile/ProfileContextProvider'
import get from 'lodash/get'
import ReactPlayer from 'react-player'
import { Card, CardContent } from '../commons/Card.styles'
import * as S from './VideoInterviewProfileCard.styles'
import { VideoInterviewProfileCardProps } from './interfaces'

export const VideoInterviewProfileCard = ({
  testId = 'video-interview-profile-card-id',
  profileData
}: VideoInterviewProfileCardProps) => {
  const { profile: profileGetData } = useProfileContext()

  const profile = profileData ? profileData : profileGetData

  return (
    <Card data-testid={testId}>
      {profile?.video_interview ? (
        <>
          <ProfileCardTitleBar
            title="Video interview"
            icon={<VideoIcon width={24} height={24} color="#8E97A0" />}
          />
          <CardContent>
            <S.VideoHolder>
              <ReactPlayer
                url={get(profile, 'video_interview.video_url', '')}
                light
                width="100%"
                height="430px"
                controls={true}
                playing
              />
            </S.VideoHolder>
          </CardContent>
        </>
      ) : (
        <CardContent>
          <ProfileCardPlaceholder
            icon={<VideoCameraIcon />}
            title="One video interview for all interested employers! 🙂"
            subtitle="Your Agent will reach out to schedule your video interview. Once completed, they will upload the video here for all interested companies to learn more about you and your best skills and experiences."
          />
        </CardContent>
      )}
    </Card>
  )
}

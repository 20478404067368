import styled from 'styled-components'
import { EditButton } from '@components/atoms/EditButton'
import { Text } from '@components/atoms/Text'

export const Container = styled.div`
  width: 100%;
  padding: 40px;
  border-bottom: 1px solid #e6ebf0;

  .form {
    margin: 40px 0 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
    padding: 40px 24px;
  }
`
export const NotesDate = styled.span`
  color: #8e97a0;
  font-weight: 400;
`

export const Edit = styled(EditButton)`
  display: inline-flex;
  float: right;
`
export const BodyText = styled(Text)`
  word-break: break-all;
`

import { HobbiesProfileCardProps } from './interfaces'
import { BasketballIcon, Hobbies as HobbiesIcon } from '@commons/Icons'
import { ProfileCardPlaceholder } from '@components/molecules/ProfileCardPlaceholder'
import { useState } from 'react'
import { useProfileContext } from '@hooks/profile/ProfileContextProvider'
import { Card, CardContent, PillsContainer } from '../commons/Card.styles'
import { ProfileCardTitleBar } from '@components/molecules/ProfileCardTitleBar'
import { Pill } from '@components/atoms/Pill'
import { Hobbies } from '@components/organisms/Hobbies'
import get from 'lodash/get'

export const HobbiesProfileCard = ({
  testId = 'hobbies-profile-card-id'
}: HobbiesProfileCardProps) => {
  const [edit, setEdit] = useState(false)
  const { profile, fetchProfile } = useProfileContext()

  const update = () => {
    fetchProfile()
    setEdit(false)
  }

  return (
    <Card data-testid={testId}>
      {edit ? (
        <>
          <ProfileCardTitleBar
            edit={true}
            title="Hobbies"
            icon={<HobbiesIcon width={24} height={24} color="#8E97A0" />}
            onEditButtonClick={() => setEdit(true)}
          />
          <CardContent>
            <Hobbies onCancel={() => setEdit(false)} onSuccess={update} />
          </CardContent>
        </>
      ) : !profile?.hobbies ? (
        <CardContent>
          <ProfileCardPlaceholder
            icon={<BasketballIcon />}
            title="Hobbies"
            subtitle="Show off your top hobbies and interests to potential employers."
            callToAction="Add hobbies"
            onClick={() => setEdit(true)}
          />
        </CardContent>
      ) : (
        <>
          <ProfileCardTitleBar
            title="Hobbies"
            icon={<HobbiesIcon width={24} height={24} color="#8E97A0" />}
            onEditButtonClick={() => setEdit(true)}
          />
          <CardContent>
            <PillsContainer>
              {get(profile, 'hobbies', []).map((hobby) => (
                <Pill key={hobby.id}>{hobby.description}</Pill>
              ))}
            </PillsContainer>
          </CardContent>
        </>
      )}
    </Card>
  )
}

export const HobbiesCard = ({ profile }: any) => (
  <>
    {!profile?.hobbies ? (
      <CardContent>
        <ProfileCardPlaceholder
          icon={<BasketballIcon />}
          title="Hobbies"
          subtitle="Show off your top hobbies and interests to potential employers."
        />
      </CardContent>
    ) : (
      <>
        <ProfileCardTitleBar
          title="Hobbies"
          icon={<HobbiesIcon width={24} height={24} color="#8E97A0" />}
        />
        <CardContent>
          <PillsContainer>
            {get(profile, 'hobbies', []).map((hobby) => (
              <Pill key={hobby.id}>{hobby.description}</Pill>
            ))}
          </PillsContainer>
        </CardContent>
      </>
    )}
  </>
)

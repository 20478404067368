import {
  ArrowRightSmall,
  MegaphoneIcon,
  MegaphoneWithoutBackground
} from '@commons/Icons'
import { CopyButton } from '@components/atoms/CopyButton'
import { Text } from '@components/atoms/Text'
import { ProfileCardPlaceholder } from '@components/molecules/ProfileCardPlaceholder'
import { ProfileCardTitleBar } from '@components/molecules/ProfileCardTitleBar'
import { Rating } from '@components/organisms/Rating'
import { Reference } from '@hooks/profile/interfaces'
import { useProfileContext } from '@hooks/profile/ProfileContextProvider'
import { useUserContext } from '@hooks/user/UserContextProvider'
import { size, startCase } from 'lodash'
import { useLocation, useMatch, useNavigate } from 'react-router-dom'
import * as S from './ReferencesProfileCard.styles'
import { ReviewerInfoDetails } from './ReviewerInfoDetails'

type ReviewProps = {
  references?: Reference[]
}

export const References = ({ references }: ReviewProps) => {
  return (
    <>
      {references?.map((review, index) => (
        <ReviewLine key={index} review={review} />
      ))}
    </>
  )
}

type ReviewLineProps = {
  review: Reference
  key: number
}

const calculateAverage = (reference: Reference) => {
  if (!reference) return
  const ratings = [
    reference['leadership'],
    reference['execution'],
    reference['customer_service'],
    reference['work_ethic'],
    reference['initiative'],
    reference['creativity'],
    reference['resilience'],
    reference['team_relationships'],
    reference['work_quality']
  ]

  const average = (arr) =>
    (arr.reduce((a, b) => a + b, 0) / arr.length).toFixed(1)
  return average(ratings)
}

export const ReviewLine = ({ review, ...props }: ReviewLineProps) => {
  const navigate = useNavigate()

  const overallRating = calculateAverage(review)

  return (
    <S.ReviewLine {...props}>
      <div>
        <div>
          <Text type="BodyMB">{review.full_name}</Text>
          <S.Pill>{startCase(review.relationship)}</S.Pill>
        </div>
        <div>
          <Text type="BodyMB">{overallRating}</Text>
          <Rating value={overallRating} precision={0.1} readOnly />
        </div>
      </div>
      <S.Text type="BodyMR">{review.strengths}</S.Text>
      <S.Button
        styling="link"
        onClick={() =>
          navigate('reference-details', {
            state: { review, overallRating }
          })
        }
      >
        See Review <ArrowRightSmall />
      </S.Button>
    </S.ReviewLine>
  )
}

export const ReferencesProfileCard = ({
  testId = 'references-profile-card-id',
  profile: profileParam
}: any) => {
  const IS_CANDIDATE_PROFILE = !!useMatch('/candidate/profile/references')
  const { user } = useUserContext()
  const { profile: currentProfile } = useProfileContext()
  const profile = profileParam || currentProfile

  const location = useLocation()
  const IS_DETAILS = location.pathname?.includes('reference-details')

  const HAS_REVIEWS = size(profile?.references) > 0
  const SHOULD_SHOW_REFERENCES_LINK =
    size(profile?.references) < 3 && IS_CANDIDATE_PROFILE

  const ReviewSection = () => (
    <>
      <ProfileCardTitleBar
        title="References"
        icon={<MegaphoneWithoutBackground />}
        style={{ height: '72px' }}
      ></ProfileCardTitleBar>

      <S.ReviewsWrapper>
        {SHOULD_SHOW_REFERENCES_LINK && (
          <S.CopyReferenceWrapper>
            <div>
              <Text type="BodyMB">Copy references link</Text>
              <CopyButton
                textToCopy={`${window.location.host}/candidate/${profile?.id}/review/${user?.secret_id}`}
              />
            </div>
            <Text type="BodySR">
              Send this link to any work-related persons like past employers,
              co-workers, subordinates, or clients that can emphasize your
              strengths to potential employers.
            </Text>
          </S.CopyReferenceWrapper>
        )}

        <References references={profile?.references} />
      </S.ReviewsWrapper>
    </>
  )

  const ProfileCardSection = () => (
    <S.PlaceholderWrapper>
      {SHOULD_SHOW_REFERENCES_LINK ? (
        <ProfileCardPlaceholder
          icon={<MegaphoneIcon />}
          title="References"
          subtitle="You don't have any references yet. Send this link to any past employers, co-workers, subordinates, or clients that can emphasize your strengths to potential employers."
          copyLinkUrl={`${window.location.host}/candidate/${profile?.id}/review/${user?.secret_id}`}
        />
      ) : (
        <ProfileCardPlaceholder
          icon={<MegaphoneIcon />}
          title="References"
          subtitle="Don't have any references yet."
        />
      )}
    </S.PlaceholderWrapper>
  )

  return IS_DETAILS ? (
    <ReviewerInfoDetails />
  ) : (
    <S.Card data-testid={testId}>
      {HAS_REVIEWS ? <ReviewSection /> : <ProfileCardSection />}
    </S.Card>
  )
}

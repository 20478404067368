import api from '@api/index'
import { addToast } from '@components/atoms/Toast'
import { SESSION_ROUTES } from '@routes/SessionRoutes'
import { fireUserSignedInEvent, fireUserSignedUpEvent } from '@utils/analytics'
import { get } from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

type State = {
  user: any
  token: any
  isAuthenticated: boolean
  resendForgotPasswordEmail: (email: string) => Promise<void>
  resendConfirmEmail: (email: string) => Promise<void>
  confirmUserEmail: (confirmationToken: string) => Promise<void>
  resetUserPassword: (
    passwordToken: string,
    password: string,
    passwordConfirmation: string
  ) => Promise<void>
  signUp: (
    email: string,
    full_name: string,
    password: string,
    password_confirmation: string,
    recruiter_secret_id: string
  ) => Promise<void>
  signOut: () => Promise<void>
  signIn: (email: any, password: any) => Promise<void>
  logOut: () => Promise<void>
  setUser: (userData: any) => void
}

const UserContext = React.createContext<State>({} as State)

const getUserData = ({ data, headers }) => ({
  ...data,
  token: headers?.authorization?.replace('Bearer ', '')
})

const USER_KEY = 'user'

export const UserContextProvider: React.FC = ({ children }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const inSignInScreen = location.pathname === `/users${SESSION_ROUTES.SIGN_IN}`
  const inSignUpScreen = location.pathname.includes('sign-up')
  const inForgotPasswordScreen =
    location.pathname === `/users${SESSION_ROUTES.FORGOT_PASSWORD}`

  const lsUser = localStorage.getItem(USER_KEY)
  const [user, _setUser] = useState(lsUser ? JSON.parse(lsUser) : null)

  useEffect(() => {
    const IS_RECRUITER = user?.role === 'recruiter'
    if (IS_RECRUITER) _isProfileArchived()
  }, [location])

  const setUser = (res) => {
    _setUser(res)

    localStorage.setItem(USER_KEY, JSON.stringify(res))
    localStorage.setItem('token', res.token)
  }

  const signOut = async () => {
    localStorage.clear()
    _setUser(null)
    if (!inSignInScreen) {
      navigate('/users/sign-in', { replace: true })
    }
  }

  const _handleSignInRedirect = async (res) => {
    const userData = getUserData(res)
    fireUserSignedInEvent(res.data)
    const IS_RECRUITER = get(userData, 'role') === 'recruiter'

    if (IS_RECRUITER) {
      const IS_ARCHIVED = await _isProfileArchived()
      if (IS_ARCHIVED) return

      return navigate('/recruiter/applications')
    }

    navigate('/candidate/profile')
  }

  const _handleSignUpRedirect = async (res) => {
    const userData = getUserData(res)
    fireUserSignedUpEvent(res.data)
    localStorage.setItem(USER_KEY, JSON.stringify(userData))
    setUser(userData)
    navigate(`/users${SESSION_ROUTES.CONFIRMATION_MESSAGE}`)
  }

  const _handleForgotPasswordRedirect = async (email) => {
    setUser({ ...user, forgotEmail: email })

    if (inForgotPasswordScreen) {
      return navigate(
        `/users${SESSION_ROUTES.CONFIRMATION_MESSAGE_FORGOT_PASSWORD}`
      )
    }

    addToast(
      { title: 'A new confirmation email has been sent!' },
      'success',
      {}
    )
  }

  const redirectUser = (res) => {
    if (inSignInScreen) {
      return _handleSignInRedirect(res)
    }

    if (inSignUpScreen) {
      return _handleSignUpRedirect(res)
    }
  }

  const signIn = async (email, password) => {
    const res = await api.post('/users/sign_in', {
      user: { email, password }
    })
    setUser(getUserData(res))
    redirectUser(res)
  }

  const _isProfileArchived = async () => {
    if (!user) return
    const userInfo = await api.get(`/users/_me`)
    const IS_ARCHIVED = get(userInfo, 'data.status') === 'archived'
    if (IS_ARCHIVED) {
      logOut()
      addToast(
        { title: 'Your user is disabled, please contact an admin.' },
        'error',
        {}
      )
    }
    localStorage.setItem(USER_KEY, JSON.stringify(userInfo.data))
    return IS_ARCHIVED
  }

  const signUp = async (
    email,
    full_name,
    password,
    password_confirmation,
    recruiter_secret_id
  ) => {
    const res = await api.post('/users', {
      user: {
        email,
        full_name,
        password,
        password_confirmation,
        recruiter_secret_id
      }
    })

    redirectUser(res)
  }

  const logOut = async () => {
    await api.delete('/users/sign_out')
    signOut()
  }

  const resendConfirmEmail = async (email) => {
    await api.post(`/users/confirmation`, {
      user: { email }
    })

    addToast(
      { title: 'A new confirmation email has been sent!' },
      'success',
      {}
    )
  }

  const resendForgotPasswordEmail = async (email) => {
    await api.post(`/users/password`, {
      user: { email }
    })

    _handleForgotPasswordRedirect(email)
  }

  const confirmUserEmail = async (confirmationToken) => {
    const res = await api.get(
      `/users/confirmation?confirmation_token=${confirmationToken}`
    )
    setUser(getUserData(res))
  }

  const resetUserPassword = async (
    passwordToken,
    password,
    password_confirmation
  ) => {
    await api.put('/users/password', {
      user: {
        reset_password_token: passwordToken,
        password,
        password_confirmation
      }
    })

    navigate(`/users${SESSION_ROUTES.RESET_PASSWORD_CONFIRMATION_MESSAGE}`)
  }

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    user,
    token: user?.token,
    isAuthenticated: !!user?.token,
    confirmUserEmail,
    resendForgotPasswordEmail,
    resendConfirmEmail,
    resetUserPassword,
    signOut,
    signUp,
    signIn,
    logOut,
    setUser
  }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export const useUserContext = () => {
  const context = useContext(UserContext)
  if (typeof context === 'undefined') {
    throw new Error('useSession must be used within a SessionContext')
  }
  return context
}

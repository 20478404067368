import { BodyLB, BodyMR } from '@components/atoms/Text/Text.styles'
import styled from 'styled-components'

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.primaryWhite};
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 86px 40px;
  border-radius: 16px;

  @media (max-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    padding: 40px 24px;
    text-align: center;
    border-radius: 0;
  }
`
export const Title = styled.h2`
  margin-top: 24px;

  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  letter-spacing: 0.02em;

  @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
    margin-top: 32px;
    ${BodyLB}
  }
`

export const Subtitle = styled.p`
  margin-top: 4px;
  text-align: center;
  max-width: 400px;

  ${BodyMR}

  @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
    max-width: 330px;
  }
`

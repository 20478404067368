import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import { TextProps } from './interfaces'

export const Display = css`
  font-size: 96px;
  font-weight: 600;
  line-height: 106px;
  letter-spacing: 0em;
`

export const SubDisplay = css`
  font-size: 16px;
  font-weight: 400;
  line-height: 25.6px;
  letter-spacing: 0em;
`

export const Title = css`
  font-size: 40px;
  font-weight: 600;
  line-height: 47px;
  letter-spacing: 0.02em;

  ${media.lessThan('medium')`
		font-size: 24px;
		line-height: 31px;
	`}
`

export const ApplicationFormTitle = css`
  font-size: 32px;
  font-weight: 600;
  line-height: 35px;
  letter-spacing: 0em;

  ${media.lessThan('medium')`
		font-size: 24px;
		font-weight: 600;
		line-height: 31px;
		letter-spacing: 0.02em;
		text-align: center;
	`}
`

export const SubTitle = css`
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.02em;

  ${media.lessThan('medium')`
		font-size: 16px;
		font-weight: 400;
		line-height: 25.6px;
		letter-spacing: 0em;
	`}
`

export const Label = css`
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
`

export const Text = css`
  font-size: 16px;
  font-weight: 400;
  line-height: 25.6px;
  letter-spacing: 0em;
`

export const BodySR = css`
  font-size: 12px;
  font-weight: 400;
  line-height: 19.2px;
  letter-spacing: 0em;
`

export const BodySB = css`
  font-size: 12px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
`

export const BodyLR = css`
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.02em;
`

export const BodySMR = css`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
`

export const BodyMR = css`
  font-size: 16px;
  font-weight: 400;
  line-height: 25.6px;
  letter-spacing: 0em;

  @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
    ${BodySMR}
  }
`

export const BodySMB = css`
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
`

export const BodyMB = css`
  font-size: 16px;
  font-weight: 600;
  line-height: 25.6px;
  letter-spacing: 0em;
  text-align: left;

  @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
    ${BodySMB}
  }
`

export const BodyS = css`
  font-size: 32px;
  font-weight: 600;
  line-height: 35px;
  letter-spacing: 0em;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: 24px;
    font-weight: 600;
    line-height: 31px;
    letter-spacing: 0.02em;
    text-align: left;
  }
`

export const BodyLB = css`
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0.02em;
`

export const ButtonText = css`
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.02em;
`

export const GlobalH3 = css`
  font-size: 48px;
  font-weight: 600;
  line-height: 53px;
  letter-spacing: 0em;
`

export const GlobalH4 = css`
  font-size: 40px;
  font-weight: 600;
  line-height: 47px;
  letter-spacing: 0.02em;
`

export const GlobalH5 = css`
  font-size: 24px;
  font-weight: 600;
  line-height: 31px;
  letter-spacing: 0.02em;
`

const mapping = {
  Display,
  SubDisplay,
  Title,
  ApplicationFormTitle,
  SubTitle,
  Label,
  Text,
  BodySR,
  BodySB,
  BodyLR,
  BodyMR,
  BodySMR,
  BodyMB,
  BodySMB,
  BodyS,
  BodyLB,
  ButtonText,
  GlobalH3,
  GlobalH4,
  GlobalH5
}

export const StyledText = styled.p<TextProps>`
  display: block;
  color: ${({ theme, color }) => (color ? color : theme.colors.primaryBlue)};
  text-align: ${({ align = 'inherit' }) => align};

  ${({ breakText }) =>
    breakText &&
    css`
      word-break: break-word;
    `}

  ${({ type }) => mapping[type]}
`

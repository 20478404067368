import api from '@api/index'
import { addToast } from '@components/atoms/Toast'
import { useUserContext } from '@hooks/user/UserContextProvider'
import { useState } from 'react'

interface HobbyInterface {
  description: string
  _destroy?: boolean
  id?: string | number
  isNew?: boolean
}

export const useUpdateHobbies = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [response, setResponse] = useState<any>(null)

  const { logOut } = useUserContext()

  const update = async (data: HobbyInterface[]) => {
    setLoading(true)
    try {
      const res = await api.put(`/users/profile`, {
        profile: {
          hobbies_attributes: data
        }
      })
      setResponse(res)
      addToast({ title: 'Successfully updated' }, 'success', {})
    } catch (error: any) {
      if (error?.response?.status === 401) logOut()
    } finally {
      setLoading(false)
    }
  }

  return { loading, response, update }
}

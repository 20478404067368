import { FacebookIcon, InstagramIcon, LinkedinIcon } from '@commons/Icons'
import * as S from '../Footer.styles'

export const SocialMediaLinks = () => (
  <S.ButtonsWrapper>
    <a
      href="https://www.linkedin.com/company/jombine"
      target="_blank"
      rel="noopener noreferrer"
      aria-label="Linkedin page"
    >
      <LinkedinIcon />
    </a>
    <a
      href="https://instagram.com/jombine"
      target="_blank"
      rel="noopener noreferrer"
      aria-label="Instagram page"
    >
      <InstagramIcon />
    </a>
    <a
      href="https://www.facebook.com/jombine"
      target="_blank"
      rel="noopener noreferrer"
      aria-label="Facebook page"
    >
      <FacebookIcon />
    </a>
  </S.ButtonsWrapper>
)

import * as S from './HelpFillingProfile.styles'
import { ArrowRight } from './icons'
import { HelpFillingProfileProps } from './interfaces'

const BUTTON_LINK = 'https://www.jombine.com/candidate-onboarding-profile'

export const HelpFillingProfile = ({
  testId = 'help-filling-profile-test-id',
  ...rest
}: HelpFillingProfileProps) => {
  const _handleButtonClick = () => {
    window.open(BUTTON_LINK, '_blank', 'noopener noreferrer')
  }

  return (
    <S.Container data-testid={testId} {...rest}>
      <p>
        Need help? Click here for tips & ideas on completing your Combine
        Evaluation.
      </p>

      <S.Button
        styling="link"
        onClick={_handleButtonClick}
        data-testid="learn-more-button-test-id"
      >
        Learn More <ArrowRight />
      </S.Button>
    </S.Container>
  )
}

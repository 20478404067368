import styled from 'styled-components'
import * as S from './Button.styles'
import { ButtonProps } from './interfaces'

// This wrapper is to prevent html attribute warnings.
// See: https://styled-components.com/docs/faqs#why-am-i-getting-html-attribute-warnings
const ButtonWrapper = ({ ...props }: ButtonProps) => <button {...props} />

const StyledWrapper = styled(ButtonWrapper)`
  ${S.buttonStyle}
`

export const Button = ({
  testId = 'button-id',
  children,
  loading,
  styling = 'primary',
  disabled,
  ...rest
}: ButtonProps) => {
  const isLoading = styling !== 'link' ? loading : undefined

  return (
    <StyledWrapper
      data-testid={testId}
      styling={styling}
      loading={isLoading}
      disabled={isLoading || disabled}
      {...rest}
    >
      {isLoading && (
        <>
          <S.Spinner />
          {'\u00A0'}
        </>
      )}
      {children}
    </StyledWrapper>
  )
}

import { Button as OriginalButton } from '@components/atoms/Button'
import styled from 'styled-components'

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.shades.secondary500};
  display: flex;
  gap: 48px;
  min-height: 295px;

  padding: 88px 96px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 56px 24px;
    flex-direction: column;
    height: initial;
  }
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 600px;
  min-height: fit-content;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 32px;
  align-items: center;
  justify-content: flex-end;
  flex: 1;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const EmployerButton = styled(OriginalButton).attrs({
  styling: 'secondary'
})`
  background-color: transparent;
  padding: 18px 32px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.shades.primary500};
    color: ${({ theme }) => theme.colors.primaryWhite};
  }
`

export const CandidateButton = styled(OriginalButton).attrs({
  styling: 'primary'
})`
  padding: 18px 32px;
  background-color: ${({ theme }) => theme.colors.shades.primary500};
  color: ${({ theme }) => theme.colors.primaryWhite};

  &:hover {
    background-color: ${({ theme }) => theme.colors.shades.primary900};
  }
`

import { ProfilePicture } from '@components/atoms/ProfilePicture'
import styled from 'styled-components'

export const UserInfoWrapper = styled.div`
  width: auto;
  height: 48px;
  margin-left: auto;
  display: flex;
`

export const UserNameAndEmailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 5px;
`

export const UserName = styled.span`
  color: ${({ theme }) => theme.colors.primaryWhite};
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: right;
`

export const UserEmail = styled.span`
  color: ${({ theme }) => theme.colors.shades.gray900};
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: right;
`

export const StyledProfilePicture = styled(ProfilePicture)`
  margin-left: 16px;
`

export const AngleIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: 16px;

  > svg path {
    fill: ${({ theme }) => theme.colors.shades.secondary500};
  }
`

import styled from 'styled-components'
import { ProfileCard } from '@components/atoms/ProfileCard'
import { IndustriesOfExperienceForm } from '@components/organisms/IndustriesOfExperienceForm'
import { ProfileCardTitleBar } from '@components/molecules/ProfileCardTitleBar'
import { ProfileCardPlaceholder } from '@components/molecules/ProfileCardPlaceholder'

export const Card = styled(ProfileCard)`
  padding-top: 2px;
  padding-bottom: 0;
  height: auto;
`

export const Form = styled(IndustriesOfExperienceForm)`
  @media (min-width: ${({ theme }) => theme.breakpoints.phone}px) {
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 10px;
    margin-bottom: 40px;
  }
`

export const TitleBar = styled(ProfileCardTitleBar)`
  top: 0;
  height: 72px !important;
`

export const PillContainer = styled.div`
  padding-top: 28px;
  padding-left: 40px;
  padding-bottom: 15px;
`
export const Placeholder = styled(ProfileCardPlaceholder)`
  margin-top: 50px;
  margin-bottom: 60px;
`

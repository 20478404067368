import styled from 'styled-components'

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.shades.gray300};
  color: ${({ theme }) => theme.colors.primaryBlue};
  width: 100%;
  height: 100%;

  padding: 72px 72px 74px 64px;
  overflow: scroll;

  > div {
    margin-bottom: 32px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
    padding: 0;
    overflow: initial;
    background-color: #f2f5f7;
  }
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;

  @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
    margin: 32px 24px 24px 24px;
  }
`

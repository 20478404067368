import styled from 'styled-components'

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;

  h2 {
    font-weight: 600;
  }
`

export const Field = styled.div`
  position: relative;
  margin-bottom: 24px;

  button {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
  }
`

export const Tags = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
`

export const Tag = styled.button`
  background-color: ${({ theme }) => theme.colors.shades.gray500};
  border: 0;
  padding: 8px 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 99px;
  transition: ${({ theme }) => theme.transition.fast};

  &:hover {
    background-color: ${({ theme }) => theme.colors.shades.gray700};
  }

  span {
    font-family: ${({ theme }) => theme.typography.primary};
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }

  svg {
    width: 10px;
    height: 10px;
  }
`

export const Message = styled.div`
  font-family: ${({ theme }) => theme.typography.primary};
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  margin-bottom: 24px;
  color: ${({ theme }) => theme.colors.shades.primary100};
`

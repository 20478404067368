import footerArrows from '@assets/candidate/double_arrows_thanks.svg'
import logo from '@assets/logo-blue.svg'
import { Button } from '@components/atoms/Button'
import { Text } from '@components/atoms/Text'
import { useCallback } from 'react'
import * as S from './ThanksForApplying.styles'

export interface ThanksForApplyingProps {
  testId?: string
}

const JOMBINE_WEBSITE = 'https://www.jombine.com/'

export const ThanksForApplying = ({
  testId = 'component-ThanksForApplying-id'
}: ThanksForApplyingProps) => {
  const _handleHomeRedirection = useCallback(() => {
    window.location.assign(JOMBINE_WEBSITE)
  }, [])

  return (
    <S.Container data-testid={testId}>
      <S.MobileLogo src={logo} alt="Jombine's logo" />

      <S.CenterWrapper>
        <S.ContentWrapper>
          <S.Logo src={logo} alt="Jombine's logo" />
          <S.Background />
          <S.TextContent>
            <h1>Thanks for applying!</h1>

            <Text type="BodyLR">
              Congrats! You&apos;ve completed our application form. Our Agents
              will review your application and will be in touch with the next
              steps. Please be on the lookout for our next emails.
            </Text>
            <S.ButtonContainer>
              <Button onClick={_handleHomeRedirection}>Home</Button>
            </S.ButtonContainer>
          </S.TextContent>
        </S.ContentWrapper>
      </S.CenterWrapper>

      <S.FooterImage src={footerArrows} alt="Footer image with double arrows" />
    </S.Container>
  )
}

import styled from 'styled-components'
import { RoundedBox } from '../AccountConfirmation/AccountConfirmation.styles'

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.shades.gray300};
  height: 100vh;
  width: 100vw;

  @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
    height: 100%;
    width: 100%;
  }
`

export const Body = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 69px);

  @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
    justify-content: flex-end;
  }
`

export const Rounded = styled(RoundedBox)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.primaryWhite};

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    height: fit-content;
    width: fit-content;
    padding: 72px;

    max-width: initial;
    max-height: initial;
    min-height: initial;

    > div {
      h1 {
        margin-top: 8px;
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
    justify-content: flex-end;
    padding: 12px;

    > div {
      margin-top: 18px;

      h1 {
        font-size: 24px;
        font-weight: 600;
        line-height: 31px;
        letter-spacing: 0.02em;
        text-align: center;
      }

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0.02em;
        text-align: center;
      }
    }
  }
`

export const TextHolder = styled.div`
  font-weight: 400;
  margin-top: 12px;
  text-align: center;
  width: 100%;
  max-width: 370px;

  @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
    max-width: initial;
    width: initial;
  }
`

import styled from 'styled-components'

import { HiOutlinePencil } from 'react-icons/hi'

export const PencilIcon = styled(HiOutlinePencil)`
  font-size: 0.85em;
  color: ${({ theme }) => theme.colors.shades.primary500};
`

export const Container = styled.button`
  background-color: ${({ theme }) => theme.colors.shades.secondary500};
  width: 32px;
  height: 32px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  border: 1px solid transparent;
  outline: 0;
`

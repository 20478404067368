import { LogOutIcon, SettingsIcon, UserIconWithoutColor } from '@commons/Icons'
import { useUserContext } from '@hooks/user/UserContextProvider'
import { useNavigate } from 'react-router-dom'
import { CustomMenuItem, DropdownMenu } from './DropdownMenu'

export const NavbarDropdownMenu = (props) => {
  const { logOut } = useUserContext()
  const navigate = useNavigate()

  const _handleSettingsRedirect = () => {
    navigate('/candidate/settings/')
  }

  return (
    <DropdownMenu {...props}>
      <CustomMenuItem
        listItemText={'Settings'}
        CustomIcon={SettingsIcon}
        onClick={_handleSettingsRedirect}
      />
      <CustomMenuItem
        listItemText={'Log Out'}
        CustomIcon={LogOutIcon}
        onClick={() => logOut()}
      />
    </DropdownMenu>
  )
}

export const RecruiterNavbarDropdownMenu = (props) => {
  const { logOut } = useUserContext()
  const navigate = useNavigate()

  const _handleMyAccountRedirect = () => {
    navigate('/recruiter/my-account')
  }

  return (
    <DropdownMenu {...props}>
      <CustomMenuItem
        listItemText={'My Account'}
        CustomIcon={UserIconWithoutColor}
        onClick={_handleMyAccountRedirect}
      />
      <CustomMenuItem
        listItemText={'Log Out'}
        CustomIcon={LogOutIcon}
        onClick={logOut}
      />
    </DropdownMenu>
  )
}

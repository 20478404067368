import { Plus } from '@commons/Icons'
import { ButtonIcon } from '@components/atoms/ButtonIcon'
import { ProfessionalHighlightsForm } from '@components/organisms/ProfessionalHighlightsForm'
import { CardFooter } from '../../commons/Card.styles'

export const AddHighlight = ({
  add,
  onCancel,
  onClick,
  onSucess,
  disabled
}) => (
  <CardFooter>
    {add ? (
      <ProfessionalHighlightsForm onCancel={onCancel} onSucess={onSucess} />
    ) : (
      <ButtonIcon text="Add Highlight" onClick={onClick} disabled={disabled}>
        <Plus />
      </ButtonIcon>
    )}
  </CardFooter>
)

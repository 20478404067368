import * as S from '../Footer.styles'

export const WhatWeDo = () => (
  <S.LinksColumnContainer>
    <S.ItemTitle>What we do</S.ItemTitle>
    <S.Item href="https://www.jombine.com/employers" rel="noopener noreferrer">
      Employers
    </S.Item>

    <S.Item
      href="https://www.jombine.com/candidate-network"
      rel="noopener noreferrer"
    >
      Candidates Coaching
    </S.Item>

    <S.Item href="https://www.jombine.com/agents" rel="noopener noreferrer">
      Agents (Recruiters)
    </S.Item>

    <S.Item href="https://www.jombine.com/combine" rel="noopener noreferrer">
      Combine Program
    </S.Item>
  </S.LinksColumnContainer>
)

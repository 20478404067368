import styled from 'styled-components'

export const Container = styled.button`
  padding: 0;
  border: 0;
  display: flex;
  align-items: center;
  background-color: transparent;
  gap: 10px;
  cursor: pointer;

  p {
    color: ${({ theme }) => theme.colors.alert};
    line-height: 100%;
    position: relative;
    top: 2px;
  }

  svg {
    display: block;
  }
`

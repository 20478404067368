export const JOB_SEARCH_OPTIONS = [
  {
    label: 'Actively seeking employment',
    value: 'actively_seeking_employment'
  },
  {
    label: 'Passively seeking employment',
    value: 'passively_seeking_employment'
  },
  { label: 'Not seeking employment', value: 'not_seeking_employment' }
]

export const RELOCATION_OPTIONS = [
  { label: 'Yes', value: true },
  { label: 'No', value: false }
]

export const REALOCATE_REGION_OPTIONS = [
  { label: 'Northwest', value: 'northwest' },
  { label: 'Southwest', value: 'southwest' },
  { label: 'West Coast', value: 'west_coast' },
  { label: 'Rocky Mountain', value: 'rocky_mountain' },
  { label: 'Midwest', value: 'midwest' },
  { label: 'Southeast', value: 'southeast' },
  { label: 'North East', value: 'north_east' },
  { label: 'Mid Atlantic', value: 'mid_atlantic' }
]

export const EMPLOYEMENT_STATUS_OPTIONS = [
  { label: 'Full-time employment', value: 'full_time_employment' },
  { label: 'Part-time employment', value: 'part_time_employment' },
  { label: 'Seasonal employment', value: 'seasonal_employment' },
  { label: 'Temporary employment', value: 'temporary_employment' },
  { label: 'Contract employment', value: 'contract_employment' },
  { label: 'Internship', value: 'internship' },
  { label: 'Unemployed', value: 'unemployed' }
]

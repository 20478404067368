import backgroundImage from '@assets/candidate/application_bg.png'
import { RoundedBox } from '@components/atoms/RoundedBox'
import { RoundedBoxProps } from '@components/atoms/RoundedBox/interfaces'
import styled, { css } from 'styled-components'
import media from 'styled-media-query'

const isMobile = window?.screen?.width < 450
const isTablet = window?.screen?.width < 1100

const Gradient = css`
  background: ${({ theme }) =>
    theme.colors.primaryBlue}; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom right,
    #0c1925,
    #1d2f41
  ); /* Chrome 10-25, Safari 5.1-6 */

  background: linear-gradient(
    to bottom right,
    #0c1925,
    #1d2f41
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
`

export const Container = styled.div`
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  min-height: 900px;

  display: flex;
  flex-direction: row;

  ${media.lessThan('large')`
		display: flex;
		flex-direction: column-reverse;
		min-height: none;
		height: 100%;
		min-height: initial;
	`}

  ${Gradient}
`

export const RightPanel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 523px;
  flex-grow: 1;

  ${media.lessThan('large')`
		height: auto;
		width: 100%;
	`}

  color: ${({ theme }) => theme.colors.primaryWhite};

  ${Gradient}
`

export const RightWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`

export const Background = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  min-height: 600px;
  margin: -56px 0;
  background-image: url(${backgroundImage});
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: 75% auto;

  @media (min-width: 1600px) {
    background-position: center;
    background-size: auto 45%;
  }

  ${media.lessThan('large')`
		display: none;
	`}
`

export const ImagesPanel = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
`

export const LogoImage = styled.img`
  position: absolute;
  display: block;
  right: 0;
  margin: 81px 95px;

  ${media.lessThan('large')`
		display: block;
  	height: 28px;
		position: relative;
		margin: 32px auto;
	`}
`

const borders = {
  mobile: {
    size: 'mobile',
    topLeft: true
  },
  tablet: {
    size: 'tablet',
    topRight: true,
    topLeft: true
  },
  desktop: {
    size: 'extraLarge',
    topRight: true,
    bottomRight: true
  }
}

const selectedAttrs =
  borders[isMobile ? 'mobile' : isTablet ? 'tablet' : 'desktop']

export const LeftPanel = styled(RoundedBox).attrs(
  selectedAttrs as RoundedBoxProps
)`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 917px;
  height: 100%;

  z-index: 1;

  @media (min-width: 1600px) {
    width: 100%;
  }

  ${media.lessThan('large')`
		margin-top: 92px;
		width: 100%;
	`}
`

import * as S from './GreenChecker.styles'
import { GreenCheckerProps } from './interfaces'

const isMobile = window?.screen?.width < 700

export const GreenChecker = ({
  testId = 'green-checker-id',
  styling = 'success',
  size,
  ...rest
}: GreenCheckerProps) => {
  const defaultSize = size || (isMobile ? 32 : 48)

  return (
    <S.Wrapper
      data-testid={testId}
      styling={styling}
      size={defaultSize}
      {...rest}
    >
      {styling === 'success' ? (
        <S.CheckIcon size={defaultSize} />
      ) : (
        <S.ErrorIcon size={defaultSize} />
      )}
    </S.Wrapper>
  )
}

import { LocationMarker } from '@commons/Icons'
import { Text } from '@components/atoms/Text'
import { FiMail, FiPhone } from 'react-icons/fi'
import NumberFormat from 'react-number-format'
import * as S from './CandidateProfileDetails.styles'
import { CandidateProfileDetailsProps } from './interfaces'

export const CandidateProfileDetails = ({
  testId = 'candidate-profile-overview-test-id',
  name,
  profilePicture,
  email,
  phone,
  location,
  ...rest
}: CandidateProfileDetailsProps) => {
  return (
    <S.CandidateProfileDetails data-testid={testId} {...rest}>
      <S.ProfilePicture name={name} image={profilePicture} />

      <S.DetailsWrapper>
        <S.UserFullName>{name}</S.UserFullName>

        <S.ContentWrapper>
          <S.IconWrapper>
            <div>
              <FiMail />
            </div>
            {email}
          </S.IconWrapper>
          <S.IconWrapper>
            <div>
              <FiPhone />
            </div>
            <NumberFormat
              value={phone}
              displayType={'text'}
              format="### ### ####"
            />
          </S.IconWrapper>
          <S.IconWrapper>
            <div>
              <LocationMarker />
            </div>
            {location}
          </S.IconWrapper>
        </S.ContentWrapper>
      </S.DetailsWrapper>
    </S.CandidateProfileDetails>
  )
}

export type GridBreakpoints = 's' | 'm' | 'l'
export type GridWidths = 's' | 'm' | 'l'

export const grid = {
  breakpoints: {
    l: 1248,
    m: 800,
    s: 540
  },
  cols: 12,
  gutter: {
    regular: 32,
    mobile: 12
  },
  width: {
    l: 1248,
    m: 800,
    s: 540
  }
} as const

export const breakpoints = {
  desktop: 1248,
  phone: 540,
  tablet: 800
} as const

export type DeviceSizes = keyof typeof breakpoints

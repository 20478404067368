import { InitialScreen } from '@components/layout/InitialScreen'
import { CandidateSignUpForm } from '@components/organisms/CandidateSignUpForm'
import { SESSION_ROUTES } from '@routes/SessionRoutes'
import { useNavigate, useParams } from 'react-router-dom'

export interface SignupProps {
  testId?: string
}

export const SignUp = ({ testId = 'component-signup-id' }: SignupProps) => {
  const navigate = useNavigate()
  const { secret_id } = useParams()

  const _redirectToSignIn = () => {
    navigate(`/users${SESSION_ROUTES.SIGN_IN}`)
  }

  return (
    <InitialScreen testId={testId}>
      <CandidateSignUpForm
        redirectToSignIn={_redirectToSignIn}
        secretId={secret_id || ''}
      />
    </InitialScreen>
  )
}

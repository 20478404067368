import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { FormContainer } from '@components/atoms/FormContainer'
import { Text } from '@components/atoms/Text'
import { HobbiesProps, hobbyInterface } from './interfaces'
import { ButtonsWrapper } from '../FormCommons/Form.styles'
import { Button } from '@components/atoms/Button'
import { ButtonIcon } from '@components/atoms/ButtonIcon'
import { Close, Plus } from '@commons/Icons'
import { useUpdateHobbies } from '@hooks/aboutMe/useHobbies'
import * as S from './Hobbies.styles'
import { InputPure } from '@components/atoms/InputText/InputText.styles'
import { useProfileContext } from '@hooks/profile/ProfileContextProvider'
import { v4 as uuidv4 } from 'uuid'
import { colors } from '../../../styles/colors'

export const Hobbies = ({
  testId = 'hobbies-id',
  testSubmit,
  onCancel,
  onSuccess,
  ...props
}: HobbiesProps) => {
  const limit = 4
  const [value, setValue] = useState<string>('')
  const { profile } = useProfileContext()
  const { loading, update, response } = useUpdateHobbies()

  const { values, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      hobbies: []
    },
    onSubmit: (values) => {
      const method = testSubmit ? testSubmit : update
      const hobbyFilter: hobbyInterface[] = []

      if (profile?.hobbies) {
        profile.hobbies.forEach((hobby: hobbyInterface) => {
          const HAS_HOBBIES = values.hobbies.filter(
            ({ id }: hobbyInterface) => id === hobby.id
          ).length

          hobbyFilter.push({
            ...hobby,
            _destroy: HAS_HOBBIES ? undefined : true
          })
        })
      }

      method([
        ...hobbyFilter,
        ...values.hobbies
          .filter(({ isNew }: hobbyInterface) => isNew)
          .map(({ description }: hobbyInterface) => ({
            description
          }))
      ])
    }
  })

  useEffect(() => {
    profile?.hobbies && setFieldValue('hobbies', profile.hobbies)
  }, [profile])

  useEffect(() => {
    if (response?.status === 200) onSuccess()
  }, [response])

  return (
    <FormContainer testId={testId} {...props}>
      <form onSubmit={handleSubmit}>
        <S.Header>
          <Text type="BodySMB">Choose up to 4 options</Text>
          <Text
            type="BodySMR"
            style={{
              color:
                limit - values.hobbies.length === 0 ? colors.alert : 'inherit'
            }}
          >
            {limit - values.hobbies.length} item left
          </Text>
        </S.Header>
        <S.Field>
          <InputPure
            data-testid="fieldId"
            value={value}
            type="text"
            name="hobbies"
            placeholder="Add a hobbie! Ex. Cooking, hiking"
            onChange={(e) => setValue(e.currentTarget.value)}
            onKeyPress={(event) => {
              if (event.key === 'Enter' || event.charCode === 13) {
                event.preventDefault()
                setValue('')
                setFieldValue('hobbies', [
                  ...values.hobbies,
                  {
                    id: uuidv4(),
                    description: event.currentTarget.value,
                    isNew: true
                  }
                ])
              }
            }}
            disabled={values.hobbies.length >= limit || loading}
            maxLength={50}
          />
          <ButtonIcon
            onClick={() => {
              if (!(values.hobbies.length >= limit) && value) {
                setFieldValue('hobbies', [
                  ...values.hobbies,
                  {
                    id: uuidv4(),
                    description: value,
                    isNew: true
                  }
                ])
                setValue(() => '')
              }
            }}
            disabled={values.hobbies.length >= limit || loading}
          >
            <Plus />
          </ButtonIcon>
        </S.Field>
        {values.hobbies.length >= limit && (
          <S.Message>You reached the max number of hobbies</S.Message>
        )}
        <S.Tags>
          {values.hobbies.map((hobby: any) => (
            <S.Tag
              key={hobby.id}
              type="button"
              onClick={() => {
                setFieldValue(
                  'hobbies',
                  values.hobbies.filter((it: any) => it.id !== hobby.id)
                )
              }}
            >
              <span>{hobby.description}</span>
              <Close />
            </S.Tag>
          ))}
        </S.Tags>
        <ButtonsWrapper>
          <Button
            styling="secondary"
            type="button"
            onClick={onCancel}
            size="small"
          >
            Cancel
          </Button>
          <Button type="submit" testId="fieldId" loading={loading} size="small">
            Save
          </Button>
        </ButtonsWrapper>
      </form>
    </FormContainer>
  )
}

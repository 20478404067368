import { CheckIconWithBackground } from '@commons/Icons'
import { Button } from '@components/atoms/Button'
import { Text } from '@components/atoms/Text'
import styled from 'styled-components'
import * as S from '../AccountConfirmMessage/AcccountConfirmMessage.styles'

export interface CheckEmailProps {
  testId?: string
  email: string
  onResendClick: () => void
}

const GreenCheckerWrapper = styled(S.GreenCheckerWrapper)`
  @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
    margin-top: 0px;
    margin-bottom: 40px;
  }
`

export const CheckEmail = ({
  testId = 'component-check-email-id',
  email,
  onResendClick,
  ...props
}: CheckEmailProps) => {
  return (
    <S.Wrapper data-testid={testId} {...props}>
      <GreenCheckerWrapper>
        <CheckIconWithBackground />
      </GreenCheckerWrapper>

      <S.Heading>Check your email!</S.Heading>

      <S.Body>
        <Text type="SubTitle">
          We’ve sent a confirmation link to
          <br />
          <strong>{email}</strong>
        </Text>
      </S.Body>

      <S.Footer>
        Didn’t get the link? {'\u00A0'}
        <Button type="button" styling="link" onClick={onResendClick}>
          Resend
        </Button>
      </S.Footer>
    </S.Wrapper>
  )
}

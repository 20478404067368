import { InitialScreen } from '@components/layout/InitialScreen'
import { CheckEmail } from '@components/molecules/CheckEmail'
import { useUserContext } from '@hooks/user/UserContextProvider'

export interface SignupProps {
  testId?: string
}

export const CheckEmailConfirmation = ({
  testId = 'component-checkemail-id'
}: SignupProps) => {
  const { user, resendConfirmEmail } = useUserContext()

  const _handleResendEmail = () => {
    resendConfirmEmail(user?.email)
  }

  return (
    <InitialScreen testId={testId}>
      <CheckEmail email={user?.email} onResendClick={_handleResendEmail} />
    </InitialScreen>
  )
}

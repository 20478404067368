import { Text } from '@components/atoms/Text'
import { useState } from 'react'
import { NotesItemProps } from './interfaces'
import * as S from './NotesItem.styles'

export const NotesItem = ({
  testId = 'notes-item-id',
  title,
  body,
  onEdit,
  children,
  ...props
}: NotesItemProps) => {
  return (
    <S.Container data-testid={testId} {...props}>
      <div className="text">
        {!!onEdit && (
          <S.Edit
            onClick={() => onEdit()}
            data-testId={`${testId}-edit-button`}
          />
        )}
        <Text breakText type="BodyMB">
          {title}
        </Text>
        <Text breakText type="BodyMR">
          {body}
        </Text>
      </div>
      {children}
    </S.Container>
  )
}

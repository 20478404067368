import { MyAccount } from '@components/layout/MyAccount'
import { RecruiterAllCandidatesScreen } from '@components/layout/RecruiterAllCandidatesScreen'
import { RecruiterApplicationsScreen } from '@components/layout/RecruiterApplicationsScreen'
import { RecruiterCandidateApplicationFormDetails } from '@components/layout/RecruiterCandidateApplicationFormDetails'
import { RecruiterCandidateProfileView } from '@components/layout/RecruiterCandidateProfileView'
import { ProtectedRecruiterRoute } from '@routes/ProtectedRoute'
import { Route, Routes } from 'react-router-dom'

export const RECRUITER_ROUTES = {
  APPLICATIONS: '/applications',
  CANDIDATE_APPLICATION_FORM_DETAILS: '/applications/details/:id',
  ALL_CANDIDATES: '/candidate/all',
  MY_CANDIDATES: '/candidate/my-candidates',
  ARCHIVED_CANDIDATES: '/candidate/archived',
  MY_ACCOUNT: '/my-account',
  CANDIDATE_PROFILE_DETAILS: '/candidate/:id/profile/details/:tab'
}

export const RECRUITER_VIEW_PATH = '/recruiter/*'

export const RecruiterRoutes = () => {
  return (
    <Routes>
      <Route
        path={RECRUITER_ROUTES.APPLICATIONS}
        element={
          <ProtectedRecruiterRoute>
            <RecruiterApplicationsScreen />
          </ProtectedRecruiterRoute>
        }
      />

      <Route
        path={RECRUITER_ROUTES.CANDIDATE_APPLICATION_FORM_DETAILS}
        element={
          <ProtectedRecruiterRoute>
            <RecruiterCandidateApplicationFormDetails />
          </ProtectedRecruiterRoute>
        }
      />

      <Route
        path={RECRUITER_ROUTES.ALL_CANDIDATES}
        element={
          <ProtectedRecruiterRoute>
            <RecruiterAllCandidatesScreen />
          </ProtectedRecruiterRoute>
        }
      />

      <Route
        path={RECRUITER_ROUTES.MY_CANDIDATES}
        element={
          <ProtectedRecruiterRoute>
            <RecruiterAllCandidatesScreen />
          </ProtectedRecruiterRoute>
        }
      />

      <Route
        path={RECRUITER_ROUTES.ARCHIVED_CANDIDATES}
        element={
          <ProtectedRecruiterRoute>
            <RecruiterAllCandidatesScreen />
          </ProtectedRecruiterRoute>
        }
      />

      <Route path={RECRUITER_ROUTES.CANDIDATE_PROFILE_DETAILS}>
        <Route
          path=":detail"
          element={
            <ProtectedRecruiterRoute>
              <RecruiterCandidateProfileView />
            </ProtectedRecruiterRoute>
          }
        />
        <Route
          path=""
          element={
            <ProtectedRecruiterRoute>
              <RecruiterCandidateProfileView />
            </ProtectedRecruiterRoute>
          }
        />
      </Route>
      <Route path={RECRUITER_ROUTES.MY_ACCOUNT} element={<MyAccount />} />
    </Routes>
  )
}

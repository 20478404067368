import { Plus } from '@commons/Icons'
import * as S from './AddItemButton.styles'
import { AddItemButtonProps } from './interfaces'

export const AddItemButton = ({
  testId = 'add-item-button-id',
  children,
  ...props
}: AddItemButtonProps) => {
  return (
    <S.Container
      type="button"
      data-testid={testId}
      aria-label="button-icon"
      {...props}
    >
      <S.PlusContainer>
        <Plus />
      </S.PlusContainer>
      <S.Label>{children}</S.Label>
    </S.Container>
  )
}

import * as S from './UserPicture.styles'

export interface UserPictureProps {
  testId?: string
  name?: string
  image?: string
}

export const UserPicture = ({
  testId = 'user-picture-id',
  name,
  image,
  ...props
}: UserPictureProps) => {
  return (
    <S.ProfilePicture data-testid={testId} alt={name} src={image} {...props}>
      {name && name.charAt(0)}
    </S.ProfilePicture>
  )
}

import { Card, CardContent, PillsContainer } from '../commons/Card.styles'
import { WorkPerksProfileCardProps } from './interfaces'
import { PlayingCardsIcon, WorkPerksIcon } from '@commons/Icons'
import { ProfileCardPlaceholder } from '@components/molecules/ProfileCardPlaceholder'
import { useEffect, useState } from 'react'
import { useProfileContext } from '@hooks/profile/ProfileContextProvider'
import { ProfileCardTitleBar } from '@components/molecules/ProfileCardTitleBar'
import { Pill } from '@components/atoms/Pill'
import { WorkPerksForm } from '@components/organisms/WorkPerksForm'
import { useGetWorkplace, useWorkplace } from '@hooks/workplace/useWorkplace'
import { WorkplaceValues } from '../commons/WorkplaceValues'
import formatDescription from '../commons/FormatDescription'

export const WorkPerksProfileCard = ({
  testId = 'work-perks-profile-card-id'
}: WorkPerksProfileCardProps) => {
  const [edit, setEdit] = useState(false)
  const { profile, fetchProfile } = useProfileContext()
  const { loading, data: workPerks } = useGetWorkplace('/work_perks')
  const { response, update: updateWorkPerks } = useWorkplace('work_perks_ids')

  const update = () => {
    fetchProfile()
    setEdit(false)
  }

  useEffect(() => {
    if (response?.status === 200) update()
  }, [response])

  return (
    <Card data-testid={testId}>
      {edit ? (
        <>
          <ProfileCardTitleBar
            edit={true}
            title="Work perks"
            icon={<WorkPerksIcon width={24} height={24} color="#8E97A0" />}
            onEditButtonClick={() => setEdit(true)}
          />
          <CardContent>
            <WorkPerksForm
              workPerks={workPerks}
              onCancel={() => setEdit(false)}
              onSubmit={({ work_perks }) => {
                updateWorkPerks(work_perks.map((value) => value.id))
              }}
              initialValues={formatDescription(profile?.work_perks)}
            />
          </CardContent>
        </>
      ) : !profile?.work_perks ? (
        <CardContent>
          <ProfileCardPlaceholder
            icon={<PlayingCardsIcon />}
            title="Work perks"
            subtitle="Which work perks are the most important for you?"
            callToAction="Add work perks"
            onClick={() => setEdit(true)}
          />
        </CardContent>
      ) : (
        <WorkplaceValues
          values={formatDescription(profile?.work_perks)}
          onEditButtonClick={() => setEdit(true)}
          icon={<WorkPerksIcon width={24} height={24} color="#8E97A0" />}
          title="Work perks"
        />
      )}
    </Card>
  )
}

export const WorkPerksCard = ({ profile }: any) => {
  return (
    <>
      {profile?.work_perks ? (
        <>
          <WorkplaceValues
            values={profile?.work_perks}
            icon={<WorkPerksIcon width={24} height={24} color="#8E97A0" />}
            title="Work perks"
          />
        </>
      ) : (
        <CardContent>
          <ProfileCardPlaceholder
            icon={<PlayingCardsIcon />}
            title="Work perks"
            subtitle="Which work perks are the most important for you?"
          />
        </CardContent>
      )}
    </>
  )
}

import * as S from '../Footer.styles'

export const HelpfulLinks = () => (
  <S.LinksColumnContainer>
    <S.ItemTitle>Helpful links</S.ItemTitle>
    <S.Item href="https://www.jombine.com/resources" rel="noopener noreferrer">
      Resources
    </S.Item>

    <S.Item
      href="https://calendly.com/jombine-topher/jombine-demo-clients"
      rel="noopener noreferrer"
    >
      Schedule a Demo
    </S.Item>
  </S.LinksColumnContainer>
)

import { ComputerSkillsCard } from '@components/atoms/ComputerSkillsProfileCard'
import { Tab } from '@components/atoms/Tab'
import { AboutMeSection } from '@components/organisms/ProfileCards/AboutMeProfileCard'
import {
  AssessmentProfileCard,
  AssessmentProfileCardUpload
} from '@components/organisms/ProfileCards/AssessmentProfileCard'
import { CertificationsCard } from '@components/organisms/ProfileCards/CertificationsProfileCard'
import { CompanyValuesCard } from '@components/organisms/ProfileCards/CompanyValuesProfileCard'
import { GettingToKnowMeCard } from '@components/organisms/ProfileCards/GettingToKnowMeProfileCard'
import { HobbiesCard } from '@components/organisms/ProfileCards/HobbiesProfileCard'
import { IndustriesCard } from '@components/organisms/ProfileCards/IndustriesProfileCard'
import { JobStatusCard } from '@components/organisms/ProfileCards/JobStatusProfileCard'
import { ManagementStyleCard } from '@components/organisms/ProfileCards/ManagementStyleProfileCard'
import {
  NotesProfileCard,
  NotesProfileCardView
} from '@components/organisms/ProfileCards/NotesProfileCard'
import { PersonalHighlightsCard } from '@components/organisms/ProfileCards/PersonalHighlightsProfileCard'
import { PhotosCard } from '@components/organisms/ProfileCards/PhotosProfileCard'
import { ProfessionalHighlightsCard } from '@components/organisms/ProfileCards/ProfessionalHighlightsProfileCard'
import { ReferencesProfileCard } from '@components/organisms/ProfileCards/ReferencesProfileCard'
import { SocialLinksCard } from '@components/organisms/ProfileCards/SocialLinksProfileCard'
import { TeamCultureCard } from '@components/organisms/ProfileCards/TeamCultureProfileCard'
import {
  VideoInterviewProfileCard,
  VideoInterviewProfileCardUpload
} from '@components/organisms/ProfileCards/VideoInterviewProfileCard'
import { WorkEnvironmentCard } from '@components/organisms/ProfileCards/WorkEnvironmentProfileCard'
import { WorkGroupsCard } from '@components/organisms/ProfileCards/WorkGroupsProfileCard'
import { WorkPerksCard } from '@components/organisms/ProfileCards/WorkPerksProfileCard'
import { WorkSkillsCard } from '@components/organisms/ProfileCards/WorkSkillsProfileCard'
import * as S from '@components/organisms/ProfileScreen/ProfileScreenTabs/ProfileScreenTabs.styles'
import { useNavigate, useParams } from 'react-router-dom'
import { ResumeCard } from '../../organisms/ProfileCards/ResumeProfileCard'
import { Card } from './RecruiterCandidateProfileView.styles'

export const TabContent = {
  'about-me': (profile) => (
    <div>
      {!profile?.about_me && profile?.isPublic ? null : (
        <Card>
          <AboutMeSection profile={profile} />
        </Card>
      )}
      {!profile?.job_status && profile?.isPublic ? null : (
        <Card>
          <JobStatusCard profile={profile} />
        </Card>
      )}
      {!profile?.personal_highlights && profile?.isPublic ? null : (
        <Card>
          <PersonalHighlightsCard profile={profile} />
        </Card>
      )}
      {!profile?.hobbies && profile?.isPublic ? null : (
        <Card>
          <HobbiesCard profile={profile} />
        </Card>
      )}
      {!profile?.professional_highlights && profile?.isPublic ? null : (
        <Card>
          <ProfessionalHighlightsCard profile={profile} />
        </Card>
      )}
      {!profile?.social_media && profile?.isPublic ? null : (
        <Card>
          <SocialLinksCard profile={profile} />
        </Card>
      )}
      {!profile?.pictures && profile?.isPublic ? null : (
        <Card>
          <PhotosCard profile={profile} />
        </Card>
      )}
      {!profile?.getting_to_know_me && profile?.isPublic ? null : (
        <Card>
          <GettingToKnowMeCard profile={profile} />
        </Card>
      )}
    </div>
  ),

  experience: (profile) => (
    <div>
      <ResumeCard profileData={profile} />
    </div>
  ),

  'workplace-dna': (profile) => (
    <div>
      {!profile?.certifications && profile?.isPublic ? null : (
        <Card>
          <CertificationsCard profile={profile} />
        </Card>
      )}
      {!profile?.industries_of_experiences?.length &&
      profile?.isPublic ? null : (
        <Card>
          <IndustriesCard profile={profile} />
        </Card>
      )}
      {!profile?.work_skills?.length && profile?.isPublic ? null : (
        <Card>
          <WorkSkillsCard profile={profile} />
        </Card>
      )}
      {!profile?.computer_skills?.length && profile?.isPublic ? null : (
        <Card>
          <ComputerSkillsCard profile={profile} />
        </Card>
      )}
      {!profile?.company_values && profile?.isPublic ? null : (
        <Card>
          <CompanyValuesCard profile={profile} />
        </Card>
      )}
      {!profile?.team_cultures && profile?.isPublic ? null : (
        <Card>
          <TeamCultureCard profile={profile} />
        </Card>
      )}
      {!profile?.work_groups && profile?.isPublic ? null : (
        <Card>
          <WorkGroupsCard profile={profile} />
        </Card>
      )}
      {!profile?.management_styles && profile?.isPublic ? null : (
        <Card>
          <ManagementStyleCard profile={profile} />
        </Card>
      )}
      {!profile?.work_environments && profile?.isPublic ? null : (
        <Card>
          <WorkEnvironmentCard profile={profile} />
        </Card>
      )}
      {!profile?.work_perks && profile?.isPublic ? null : (
        <Card>
          <WorkPerksCard profile={profile} />
        </Card>
      )}
    </div>
  ),

  assessment: (profile, fetch) => (
    <div>
      {!profile?.assessment_url && profile?.isPublic ? null : (
        <>
          {profile?.isPublic ? (
            <AssessmentProfileCard profileData={profile} />
          ) : (
            <AssessmentProfileCardUpload profile={profile} fetch={fetch} />
          )}
        </>
      )}
    </div>
  ),

  'video-interview': (profile, fetch) => (
    <div>
      {!profile?.video_interview && profile?.isPublic ? null : (
        <Card>
          {profile?.isPublic ? (
            <VideoInterviewProfileCard profileData={profile} />
          ) : (
            <VideoInterviewProfileCardUpload profile={profile} fetch={fetch} />
          )}
        </Card>
      )}
      {!profile?.video_interview?.notes && profile?.isPublic ? null : (
        <Card>
          {profile?.isPublic ? (
            <NotesProfileCardView profileData={profile} />
          ) : (
            <NotesProfileCard profile={profile} fetch={fetch} />
          )}
        </Card>
      )}
    </div>
  ),

  references: (profile) => (
    <div>
      {!profile?.references?.length && profile?.isPublic ? null : (
        <ReferencesProfileCard profile={profile} />
      )}
    </div>
  )
}

export const getTabs = (profile?: any) => [
  {
    key: 'about-me',
    label: 'About me',
    hide:
      !profile?.about_me &&
      !profile?.job_status &&
      !profile?.personal_highlights &&
      !profile?.hobbies &&
      !profile?.professional_highlights &&
      !profile?.social_media &&
      !profile?.pictures &&
      !profile?.getting_to_know_me
  },
  {
    key: 'experience',
    label: 'Experience',
    hide:
      !profile?.work_experiences &&
      !profile?.educations &&
      !profile?.certifications &&
      !profile?.industries_of_experiences?.length &&
      !profile?.work_skills?.length &&
      !profile?.computer_skills?.length
  },
  {
    key: 'workplace-dna',
    label: 'Workplace DNA',
    hide:
      !profile?.company_values &&
      !profile?.team_cultures &&
      !profile?.work_groups &&
      !profile?.management_styles &&
      !profile?.work_environments &&
      !profile?.work_perks
  },
  {
    key: 'assessment',
    label: 'Assessment',
    hide: !profile?.assessment_url
  },
  {
    key: 'video-interview',
    label: 'Video interview',
    hide: !profile?.video_interview && !profile?.video_interview?.notes
  },
  {
    key: 'references',
    label: 'References',
    hide: !profile?.references?.length
  }
]

const DEFAULT_TAB = 'about-me'

export const RecruiterProfileScreenTabs = ({
  testId = 'recruiter-profile-screen-tabs-id',
  ...props
}: any) => {
  const navigate = useNavigate()
  const { id, tab } = useParams()
  const TABS = getTabs()

  return (
    <S.Container data-testid={testId} {...props}>
      {TABS.map((tabItem) => {
        const isActive = tabItem.key === (tab || DEFAULT_TAB)
        return (
          <Tab
            key={tabItem.key}
            active={isActive}
            data-testid={`${tabItem.key}-recruiter-profile-tab`}
            onClick={() =>
              navigate(
                `/recruiter/candidate/${id}/profile/details/${tabItem.key}`
              )
            }
          >
            {tabItem.label}
          </Tab>
        )
      })}
    </S.Container>
  )
}

export const PublicProfileScreenTabs = ({
  testId = 'public-profile-screen-tabs-id',
  profile,
  ...props
}: any) => {
  const navigate = useNavigate()
  const { id, tab } = useParams()

  const TABS = getTabs(profile)

  return (
    <S.Container data-testid={testId} {...props}>
      {TABS.map((tabItem) => {
        const isActive = tabItem.key === (tab || DEFAULT_TAB)
        return (
          <>
            {tabItem.hide ? null : (
              <Tab
                key={tabItem.key}
                active={isActive}
                data-testid={`${tabItem.key}-public-profile-tab`}
                onClick={() =>
                  navigate(`/candidate/public/profile/${id}/${tabItem.key}`)
                }
              >
                {tabItem.label}
              </Tab>
            )}
          </>
        )
      })}
    </S.Container>
  )
}

import { IndustriesOfExperienceFormProps } from './interfaces'
import { FormContainer } from '@components/atoms/FormContainer'
import { Button } from '@components/atoms/Button'
import * as S from '../FormCommons/Form.styles'
import {
  useIndustriesOfExperience,
  Industry
} from '@hooks/experience/useIndustriesOfExperience'
import { AutocompleteSelect } from '@components/molecules/AutocompleteSelect'
import { AutocompleteOption } from '@components/molecules/AutocompleteSelect/interfaces'
import { useState, useEffect, useCallback } from 'react'
import { debounce } from 'lodash'

export const IndustriesOfExperienceForm = ({
  testId = 'industries-of-experience-form-id',
  onCancel,
  onSubmit,
  initialValues,
  ...props
}: IndustriesOfExperienceFormProps) => {
  const [selectedItems, setSelectedItems] = useState<any>([])
  const {
    industriesOfExperience,
    fetchIndustriesOfExperience,
    createIndustry
  } = useIndustriesOfExperience()

  useEffect(() => {
    fetchIndustriesOfExperience()
  }, [])

  const fetchIndustriesOnce = useCallback(
    debounce(fetchIndustriesOfExperience, 500),
    []
  ) // eslint-disable-line react-hooks/exhaustive-deps

  const transformIndustriesForSelect = (option) => {
    return { id: option.id, value: option.ocupation }
  }

  return (
    <FormContainer data-testid={testId} {...props}>
      <AutocompleteSelect
        onChange={(items) => setSelectedItems(items)}
        initialValues={initialValues?.map(transformIndustriesForSelect)}
        options={industriesOfExperience.map(transformIndustriesForSelect)}
        onQueryChange={fetchIndustriesOnce}
        placeholder="Search for an industry"
        showAll={true}
        limitReachedText="You reached the max number of industries"
        max={4}
        onAdd={async (query) => {
          return new Promise<AutocompleteOption>((resolve) => {
            createIndustry({
              ocupation: query
            } as Industry).then((industry) => {
              if (industry) {
                resolve({
                  id: industry.id,
                  value: industry.ocupation
                })
              }
            })
          })
        }}
      />

      <S.ButtonsWrapper>
        <Button styling="secondary" type="button" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          type="submit"
          testId="fieldId"
          onClick={() =>
            onSubmit({
              industries_of_experience: selectedItems
            })
          }
        >
          Save
        </Button>
      </S.ButtonsWrapper>
    </FormContainer>
  )
}

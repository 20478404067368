import { Cross } from '@commons/Icons'
import { useMediaQuery } from '@mui/material'
import Box from '@mui/material/Box'
import MuiModal from '@mui/material/Modal'
import styled from 'styled-components'

const desktopStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 608,
  height: 495,
  borderRadius: '56px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  outline: 0
}

const mobileStyle = {
  position: 'absolute' as const,
  width: '100vw',
  height: '100%',
  bgcolor: 'background.paper',
  p: '32px 24px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  outline: 0
}

const IconWrapper = styled.div`
  position: absolute;
  right: 48px;
  top: 48px;
  width: 24px;
  height: 24px;
  cursor: pointer;

  @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
    right: 32px;
    top: 32px;
  }
`

export interface ModalProps {
  testId?: string
  isOpen?: boolean
  handleClose?: () => void
  children?: React.ReactNode
}

export const Modal = ({
  testId = 'modal-id',
  handleClose,
  isOpen = false,
  children,
  ...props
}: ModalProps) => {
  const IS_MOBILE = useMediaQuery('(max-width: 800px)')

  return (
    <MuiModal
      data-testid={testId}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      {...props}
    >
      <Box sx={IS_MOBILE ? mobileStyle : desktopStyle}>
        {children}

        <IconWrapper onClick={handleClose}>
          <Cross />
        </IconWrapper>
      </Box>
    </MuiModal>
  )
}

import * as S from './NavItem.styles'
import styled from 'styled-components'
import { NavItemProps } from './interfaces'

const NavItemWrapper = ({ ...props }: NavItemProps) => <button {...props} />

const StyledWrapper = styled(NavItemWrapper)`
  ${S.navItemStyle}
`

export const NavItem = ({
  testId = 'nav-item-test-id',
  children,
  ...rest
}: NavItemProps) => {
  return (
    <StyledWrapper data-testid={testId} {...rest}>
      {children}
    </StyledWrapper>
  )
}

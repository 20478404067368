import { GettingToKnowMeIcon } from '@commons/Icons'
import { EditButton } from '@components/atoms/EditButton'
import { FormContainer } from '@components/atoms/FormContainer'
import { Text } from '@components/atoms/Text'
import { ProfileCardTitleBar } from '@components/molecules/ProfileCardTitleBar'
import { QuestionForm } from '@components/molecules/QuestionForm'
import { capitalize } from 'lodash'
import orderBy from 'lodash/orderBy'
import {
  CardContent,
  CardText,
  CardTextWrapper
} from '../../commons/Card.styles'

interface IQuestion {
  id: number
  answer: string
  question: string
}

interface QuestionProps {
  questions: IQuestion[]
  edit?: boolean
  editId?: number | null
  onCancel?: () => void
  onSubmit?: (id: number, value: string) => void
  editButton?: (id: number) => void
}

export const Question = ({
  questions = [],
  edit = false,
  editId,
  onCancel,
  onSubmit,
  editButton
}: QuestionProps) => (
  <>
    <ProfileCardTitleBar
      edit={edit}
      title="Getting to know me"
      icon={<GettingToKnowMeIcon width={24} height={24} color="#8E97A0" />}
    />
    <CardContent>
      {orderBy(questions, 'id', 'asc').map(({ id, question, answer }) => (
        <CardTextWrapper key={id}>
          {editId === id && edit ? (
            <FormContainer>
              <QuestionForm
                question={question}
                initialValue={answer}
                onCancel={onCancel}
                onSubmit={(value) => onSubmit && onSubmit(id, value.question)}
              />
            </FormContainer>
          ) : (
            <CardText isEditing={edit} editButton={!!editButton}>
              <div>
                <Text type="BodyMB">{capitalize(question)}</Text>
                <Text type="BodyMR">{answer}</Text>
              </div>
              {editButton && (
                <EditButton onClick={() => editButton(id)}></EditButton>
              )}
            </CardText>
          )}
        </CardTextWrapper>
      ))}
    </CardContent>
  </>
)

import { NavigationBar } from '@components/molecules/NavigationBar'
import { Footer } from '@components/organisms/Footer'
import { PasswordInformation } from '@components/organisms/PasswordInformation'
import { useSettings } from '@hooks/candidate/useSettings'
import { useMediaQuery } from '@mui/material'
import {
  MobileSideBar,
  MobileView
} from '../CandidateProfileScreen/CandidateProfileScreen'
import { SettingsProps } from './interfaces'
import * as S from './Settings.styles'

export const Settings = ({
  testId = 'candidate-settings-id',
  ...props
}: SettingsProps) => {
  const IS_MOBILE = useMediaQuery('(max-width: 800px)')
  const { setPassword } = useSettings()

  const _handleSubmit = ({ confirmNewPassword, currentPassword, password }) => {
    setPassword({ confirmNewPassword, currentPassword, password })
  }

  const MainContainer = () => (
    <S.MainContainer>
      <S.SettingsHeading>Settings</S.SettingsHeading>

      <S.FormContainer>
        <PasswordInformation testSubmit={_handleSubmit} />
      </S.FormContainer>
    </S.MainContainer>
  )

  const DesktopView = (
    <S.Container data-testid={testId} {...props}>
      <NavigationBar withoutBackground />

      <MainContainer />

      <Footer />
    </S.Container>
  )

  return IS_MOBILE ? (
    <MobileView sideBar={<MobileSideBar />} Container={MainContainer} />
  ) : (
    DesktopView
  )
}

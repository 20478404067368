import 'core-js'
import { Toast } from '@components/atoms/Toast'
import { UserContextProvider } from '@hooks/user/UserContextProvider'
import { CSSReset, GlobalStyles } from '@styles/global'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import App from './App'
import reportWebVitals from './reportWebVitals'
import theme from './styles/theme'

ReactDOM.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <UserContextProvider>
        <App />
        <Toast />
      </UserContextProvider>
      <GlobalStyles />
      <CSSReset />
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

import * as yup from 'yup'

const passwordMatchesMessage =
  'Must contain 8 characters, one uppercase, one lowercase, one number and one special character.'

export const yupRequired = yup
  .string()
  .nullable()
  .required('This is a required field')

export const yupTextArea = yup
  .string()
  .max(500, 'Max 500 characters')
  .required('This is a required field')

export const yupTextAreaNotRequired = yup
  .string()
  .max(500, 'Max 500 characters')

export const requiredString = (fieldLabel = '') =>
  yup.string().nullable().required().label(fieldLabel)

export const yupEmail = yup
  .string()
  .email('Invalid email')
  .required('Email is required')

export const yupEmailNullable = yup.string().nullable().email('Invalid email')

export const yupFullName = yup.string().required('Your name is required')

export const yupFirstName = yup.string().required('First name is required')

export const yupLastName = yup.string().required('Last name is required')

export const yupCity = yup.string().required('City is required')

// TODO: More secure password validations
export const yupPassword = yup
  .string()
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
    passwordMatchesMessage
  )
  .required(passwordMatchesMessage)

export const yupPasswordConfirmation = yup
  .string()
  .oneOf([yup.ref('password')], 'Passwords do not match')
  .required('Password confirmation is required')

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const yupPhoneRequired = yup
  .string()
  .nullable()
  .matches(phoneRegExp, 'Phone number is not valid')
  .max(10, 'Phone number is too long')
  .required('Phone number is required')

export const yupPhoneNullable = yup
  .string()
  .nullable()
  .matches(phoneRegExp, 'Phone number is not valid')
  .max(10, 'Phone number is too long')

import { Form, Formik, FormikProps } from 'formik'
import { yupTextArea } from '@components/commons/Form/validations'
import { AboutMeFormProps } from './interfaces'
import { TextArea } from '@components/atoms/TextArea'
import { Button } from '@components/atoms/Button'
import { FormContainer } from '@components/atoms/FormContainer'
import * as Yup from 'yup'
import { useUpdateAboutMe } from '@hooks/aboutMe/useAboutMe'
import * as S from '../FormCommons/Form.styles'
import { useProfileContext } from '@hooks/profile/ProfileContextProvider'
import { useEffect } from 'react'
import get from 'lodash/get'

interface Values {
  aboutMe: string
}

const idContext = `general-info-form`

const validationSchema = () => {
  return Yup.object().shape({
    aboutMe: yupTextArea
  })
}

export const AboutMeForm = ({
  testId = 'about-me-id',
  isFirstTime,
  testSubmit,
  onCancel,
  onSucess,
  ...props
}: AboutMeFormProps) => {
  const { loading, response, update } = useUpdateAboutMe()
  const { profile } = useProfileContext()

  useEffect(() => {
    if (response?.status === 200) onSucess()
  }, [response])

  return (
    <FormContainer data-testid={testId} {...props}>
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          aboutMe: isFirstTime ? '' : get(profile, 'about_me', '')
        }}
        enableReinitialize
        onSubmit={(values) => {
          const method = testSubmit ? testSubmit : update
          method(values)
        }}
      >
        {(props: FormikProps<Values>) => (
          <Form>
            <S.InputRow>
              <S.InputCol>
                <TextArea
                  label={'About me *'}
                  id={`aboutMe-${idContext}`}
                  placeholder="Your answer here"
                  type="text"
                  name="aboutMe"
                  testId="fieldId"
                  disabled={loading}
                  {...props}
                />
              </S.InputCol>
            </S.InputRow>
            <S.ButtonsWrapper>
              <Button styling="secondary" type="button" onClick={onCancel}>
                Cancel
              </Button>
              <Button loading={loading} type="submit" testId="fieldId">
                Save
              </Button>
            </S.ButtonsWrapper>
          </Form>
        )}
      </Formik>
    </FormContainer>
  )
}

import * as S from './CertificationItem.styles'
import { CertificationItemProps } from './interfaces'
import { IconLabel } from '@components/atoms/IconLabel'
import { Text } from '@components/atoms/Text'

import { CalendarIcon } from '@commons/Icons'

export const CertificationItem = ({
  testId = 'certification-item-id',
  onEdit,
  completionDate,
  name,
  ...props
}: CertificationItemProps) => {
  return (
    <S.Container data-testid={testId} {...props}>
      {onEdit && (
        <S.Edit
          onClick={() => onEdit()}
          data-testId={`${testId}-edit-button`}
        />
      )}
      <Text breakText type="BodyMB">
        {name}
      </Text>
      <IconLabel icon={<CalendarIcon width={16} height={16} />}>
        <S.CertificationDate>{completionDate}</S.CertificationDate>
      </IconLabel>
    </S.Container>
  )
}

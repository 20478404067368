import { useState } from 'react'
import * as S from './CheckboxField.styles'
import { CheckboxFieldProps } from './interfaces'

export const CheckboxField = ({
  testId = 'checkbox-field-id',
  name = 'checkbox',
  label = '',
  disabled = false,
  checked = false,
  ...props
}: CheckboxFieldProps) => {
  const [isChecked, setChecked] = useState(checked)

  return (
    <S.Container disabled={disabled}>
      <div>
        <input
          data-testid={testId}
          id={`checkbox-id-${name}`}
          type="checkbox"
          name={name}
          aria-checked={checked}
          checked={isChecked}
          disabled={disabled && !isChecked}
          onClick={() => setChecked(!isChecked)}
          {...props}
        />
      </div>
      <label htmlFor={`checkbox-id-${name}`}>{label}</label>
    </S.Container>
  )
}

import { Button as OriginalButton } from '@components/atoms/Button'
import { Text as OriginalText } from '@components/atoms/Text'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  color: ${({ theme }) => theme.colors.primaryBlue};
  width: 464px;

  @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
    width: 100%;
    margin: 24px;
  }
`

export const Title = styled.h5`
  font-size: 24px;
  font-weight: 600;
  line-height: 31px;
  letter-spacing: 0.02em;
  text-align: center;

  margin: 24px 0 16px 0;
`

export const ShareIconWrapper = styled.div`
  & svg {
    display: block;
    height: 80px;
    width: 80px;
  }
`

export const Text = styled(OriginalText).attrs({
  type: 'BodyMR'
})`
  text-align: center !important;
  margin-bottom: 32px;

  @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
  }
`

export const Button = styled(OriginalButton).attrs({ styling: 'secondary' })`
  margin-bottom: 32px;

  @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
    margin-bottom: 40px;
    width: 100%;
  }
`

export const ButtonText = styled.p`
  max-width: 271px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
`

export const CopyWrapper = styled.div`
  display: flex;
  gap: 8px;
`

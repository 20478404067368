import styled, { css } from 'styled-components'
import { TextAreaProps } from './interfaces'

export const InputWrapper = styled.div`
  position: relative;
`

const TextStyles = css`
  font-size: 14px;
  font-weight: 400;
  line-height: 22.4px;
  letter-spacing: 0em;
  text-align: left;
  color: ${({ theme }) => theme.colors.shades.primary500};
`

export const ErrorMessage = styled.div`
  ${TextStyles}

  font-size: 14px;
  margin-top: 12px;
  color: ${({ theme }) => theme.colors.alert};
  max-width: fit-content;
`

export const Label = styled.label`
  color: ${({ theme }) => theme.colors.shades.primary900};
  display: block;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 17px;
  margin-bottom: 12px;
`

export const TextArea = styled.textarea<TextAreaProps>`
  resize: none;
  -webkit-appearance: none;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.shades.primary100};
  color: ${({ theme }) => theme.colors.shades.primary700};
  min-height: 160px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 25.6px;
  outline: none;
  padding: 0 16px;
  transition-property: border, box-shadow;
  transition: 0.2s ease-in-out;
  width: 100%;
  padding: 16px;
  transition: box-shadow 0.3s ease-in-out;

  ${({ hasError }) =>
    hasError &&
    css`
      border: 1.5px solid ${({ theme }) => theme.colors.alert};
      box-sizing: border-box;
      border-radius: 8px;
    `}

  &:focus,
  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.shades.primary500};
    box-sizing: border-box;
    border-radius: 8px;
  }

  &:focus {
    box-shadow: 0px 0px 5px #4de295;
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.shades.primary100};
    opacity: 1;
  }

  &:disabled {
    -webkit-text-fill-color: ${({ theme }) => theme.colors.shades.primary100};
    opacity: 1;
    border: 1px solid ${({ theme }) => theme.colors.shades.primary100};
    box-shadow: 0 0 4px 0 transparent;
    background-color: ${({ theme }) => theme.colors.shades.gray300};
    color: ${({ theme }) => theme.colors.shades.primary100};
    cursor: not-allowed;

    &:hover {
      filter: none;
    }

    &:focus {
      box-shadow: none;
    }
  }
`

export const MessagesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const CountText = styled.p<Pick<TextAreaProps, 'hasError'>>`
  ${TextStyles}
  width: 100%;
  text-align: right;

  ${({ hasError }) =>
    hasError &&
    css`
      width: initial;
      color: ${({ theme }) => theme.colors.alert};
    `}
`

import styled from 'styled-components'

export const VideoHolder = styled.div`
  border-radius: 16px;
  overflow: hidden;

  @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
    border-radius: 0;
  }
`

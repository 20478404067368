import * as S from '../Footer.styles'
export const TERMS_OF_SERVICE_URL = 'https://www.jombine.com/candidate-terms'
export const PRIVACY_POLICY_URL =
  'https://www.jombine.com/candidate-privacy-policy'

export const FooterAdditionalInfo = () => (
  <S.FooterAdditionalInfo>
    <div>
      <a href={TERMS_OF_SERVICE_URL} rel="noopener noreferrer">
        Terms of Service
      </a>{' '}
      |{' '}
      <a href={PRIVACY_POLICY_URL} rel="noopener noreferrer">
        Privacy Policy
      </a>{' '}
      | Jombine, Inc.
      {' ' + new Date().getFullYear()}
    </div>

    <div>9878 W Belleview Ave, Ste 2123, Denver, CO 80123</div>
  </S.FooterAdditionalInfo>
)

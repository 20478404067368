import styled, { css } from 'styled-components'
import { BodyMR } from '../Text/Text.styles'

export const PaginationWrapper = styled.tfoot`
  border-top: 2px solid #f2f5f7;

  tr:hover {
    background: ${({ theme }) => theme.colors.primaryWhite};
  }
`

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  z-index: 2;
`

export const PagesIndicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    ${BodyMR};
  }
`

type IconContainerProps = {
  isDisabled?: boolean
}

export const IconContainer = styled.div<IconContainerProps>`
  align-items: center;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.greyDark};
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;

  ${({ isDisabled }) =>
    isDisabled
      ? css`
          cursor: default;
          color: ${({ theme }) => theme.colors.primaryGray};
        `
      : css`
          :hover {
            cursor: pointer;

            background-color: ${({ theme }) => theme.colors.greyDark};
            color: ${({ theme }) => theme.colors.primaryWhite};
          }
        `}
`

import { yupTextArea } from '@commons/Form/validations'
import { TextArea } from '@components/atoms/TextArea'
import * as Yup from 'yup'
// import { FORM_STEPS, useApplicationForm } from '../hooks/useApplicationForm'
import * as S from '../commons/commons.styles'

interface Values {
  uniqueValueProposition: string
  personalHighlight: string
}
export const initialValues = {
  uniqueValueProposition: '',
  personalHighlight: ''
}

const idContext = 'candidate-application-form-6'
// const CURRENT_STEP = 6

export const validationSchema = () => {
  return Yup.object().shape({
    uniqueValueProposition: yupTextArea,
    personalHighlight: yupTextArea
  })
}

type Props = {
  testId?: string
}

export const Step6 = ({ testId = 'application-Step6-test-id', props }: any) => {
  // const { setCurrentFormMetadata, setCurrentStep } = useApplicationForm()
  // setCurrentStep(FORM_STEPS[CURRENT_STEP])

  return (
    <S.FormWrapper data-testid={testId}>
      <S.Body>
        <S.InputWrapper>
          <TextArea
            label="What about your Unique Value Proposition - the thing that you feel is most valuable and special about yourself in the workplace?"
            id={`unique-value-proposition-${idContext}`}
            placeholder="Your answer here..."
            name="uniqueValueProposition"
            data-testid="input"
            {...props}
          />
        </S.InputWrapper>
        <S.InputWrapper>
          <TextArea
            label="What is an outside of work achievement or personal highlight that describes your personality and individuality?"
            id={`personal-highlight-${idContext}`}
            placeholder="Your answer here..."
            name="personalHighlight"
            data-testid="input"
            {...props}
          />
        </S.InputWrapper>
      </S.Body>
    </S.FormWrapper>
  )
}

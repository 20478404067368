import { StarIconWithoutBackground } from '@commons/Icons'
import * as S from './Rating.styles'

export const Rating = ({ testId = 'rating-id', ...props }: any) => {
  return (
    <S.StyledRating
      data-testid={testId}
      emptyIcon={<StarIconWithoutBackground />}
      {...props}
    />
  )
}

import LeftBackgroundSvg from '@assets/confirmation_screen/left.svg'
import MobileBackgroundSvg from '@assets/confirmation_screen/mobile.svg'
import RightBackgroundSvg from '@assets/confirmation_screen/right.svg'
import ImageLogo from '@assets/logo.svg'
import { AccountConfirmMessage } from '@components/molecules/AccountConfirmMessage'
import { useUserContext } from '@hooks/user/UserContextProvider'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import * as S from './AccountConfirmation.styles'

export interface SignupProps {
  testId?: string
}

export const AccountConfirmation = ({
  testId = 'component-checkemail-id'
}: SignupProps) => {
  const navigate = useNavigate()

  const { confirmUserEmail } = useUserContext()
  const { confirmationToken } = useParams()

  const _handleSignInRedirection = () => {
    navigate('/users/sign-in', { replace: true })
  }

  useEffect(() => {
    confirmUserEmail(confirmationToken as string)
  }, [])

  return (
    <S.Container data-testid={testId}>
      <S.GradientBackground>
        <S.LogoWrapper>
          <S.LogoArea>
            <S.Logo src={ImageLogo} alt="Jombine's logo" />
          </S.LogoArea>
        </S.LogoWrapper>

        <S.MobileLogo src={ImageLogo} alt="Jombine's logo" />

        <S.LeftBackground
          src={LeftBackgroundSvg}
          alt="Background image with an arrow pointing downwards with an o"
        />
        <S.RightBackground
          src={RightBackgroundSvg}
          alt="Background image with an arrow pointing upwards with an x"
        />
        <S.MobileBackground
          src={MobileBackgroundSvg}
          alt="Mobile Background image with arrows"
        />
        <S.RoundedBox>
          <AccountConfirmMessage goToSignIn={_handleSignInRedirection} />
        </S.RoundedBox>
      </S.GradientBackground>
    </S.Container>
  )
}

import { yupTextArea } from '@commons/Form/validations'
import { InputNumber } from '@components/atoms/InputNumber'
import { Select } from '@components/atoms/Select'
import * as Yup from 'yup'
import * as S from '../commons/commons.styles'

export interface Values {
  careerRate: string
  minimumSalary: string
}

export const initialValues = {
  careerRate: '',
  minimumSalary: ''
}

const idContext = `candidate-application-form-4`

export const validationSchema = () => {
  return Yup.object().shape({
    careerRate: yupTextArea,
    minimumSalary: yupTextArea
  })
}

export const DROPDOWN_OPTIONS = [
  {
    value: 'havent_found_my_dream_job_career_yet',
    label: "I haven't found my dream job or career yet"
  },
  {
    value: 'like_my_job_but_wrong_industry',
    label: "I like my job, but don't feel that I'm in the right industry"
  },
  {
    value: 'happy_in_my_job_industry',
    label: "I'm happy and content in my job and industry"
  },
  {
    value: 'my_career_industry_is_my_dream',
    label: 'My career and industry match my passion and professional goals'
  }
]

export const Step5 = ({ testId = 'application-Step5-test-id', props }: any) => {
  return (
    <S.FormWrapper data-testid={testId}>
      <S.Body>
        <S.InputWrapper>
          <Select
            label={'How would you rate your career so far?'}
            id={`career-rate-${idContext}`}
            placeholder="Finding the right job"
            name="careerRate"
            data-testid={`input`}
            options={DROPDOWN_OPTIONS}
            {...props}
          />
        </S.InputWrapper>
        <S.InputWrapper>
          <InputNumber
            label={`What would be the minimum salary ($) that you would accept in your next company?`}
            id={`minimum-salary-${idContext}`}
            placeholder="$0"
            name="minimumSalary"
            data-testid={`input`}
            prefix={'$'}
            thousandSeparator={true}
            {...props}
          />
        </S.InputWrapper>
      </S.Body>
    </S.FormWrapper>
  )
}

import { TeamCultureProfileCardProps } from './interfaces'
import { Card, CardContent } from '../commons/Card.styles'
import { SearchHeartIcon, TeamCultureIcon } from '@commons/Icons'
import { ProfileCardPlaceholder } from '@components/molecules/ProfileCardPlaceholder'
import { useEffect, useState } from 'react'
import { useProfileContext } from '@hooks/profile/ProfileContextProvider'
import { ProfileCardTitleBar } from '@components/molecules/ProfileCardTitleBar'
import { TeamCultureForm } from '@components/organisms/TeamCultureForm/TeamCultureForm'
import { useGetWorkplace, useWorkplace } from '@hooks/workplace/useWorkplace'
import { WorkplaceValues } from '../commons/WorkplaceValues'
import formatDescription from '../commons/FormatDescription'

export const TeamCultureProfileCard = ({
  testId = 'team-culture-profile-card-id'
}: TeamCultureProfileCardProps) => {
  const [edit, setEdit] = useState(false)
  const { profile, fetchProfile } = useProfileContext()
  const { loading, data: teamCultures } = useGetWorkplace('/team_cultures')
  const { response, update: updateTeamCultures } =
    useWorkplace('team_cultures_ids')

  const update = () => {
    fetchProfile()
    setEdit(false)
  }

  useEffect(() => {
    if (response?.status === 200) update()
  }, [response])

  return (
    <Card data-testid={testId}>
      {edit ? (
        <>
          <ProfileCardTitleBar
            edit={true}
            title="Team culture"
            icon={<TeamCultureIcon width={24} height={24} color="#8E97A0" />}
            onEditButtonClick={() => setEdit(true)}
          />
          <CardContent>
            <TeamCultureForm
              teamCultures={teamCultures}
              onCancel={() => setEdit(false)}
              onSubmit={({ team_culture }) => {
                updateTeamCultures(team_culture.map((value) => value.id))
              }}
              initialValues={formatDescription(profile?.team_cultures)}
            />
          </CardContent>
        </>
      ) : !profile?.team_cultures ? (
        <CardContent>
          <ProfileCardPlaceholder
            icon={<SearchHeartIcon />}
            title="Team culture"
            subtitle="What are the five team culture elements that are the most important to you?"
            callToAction="Add team culture"
            onClick={() => setEdit(true)}
          />
        </CardContent>
      ) : (
        <WorkplaceValues
          values={formatDescription(profile?.team_cultures)}
          onEditButtonClick={() => setEdit(true)}
          icon={<TeamCultureIcon width={24} height={24} color="#8E97A0" />}
          title="Team culture"
        />
      )}
    </Card>
  )
}

export const TeamCultureCard = ({ profile }: any) => {
  return (
    <>
      {profile?.team_cultures ? (
        <>
          <WorkplaceValues
            values={profile?.team_cultures}
            icon={<TeamCultureIcon width={24} height={24} color="#8E97A0" />}
            title="Team culture"
          />
        </>
      ) : (
        <CardContent>
          <ProfileCardPlaceholder
            icon={<SearchHeartIcon />}
            title="Team culture"
            subtitle="What are the five team culture elements that are the most important to you?"
          />
        </CardContent>
      )}
    </>
  )
}

import api from '@api/index'
import { addToast } from '@components/atoms/Toast'
import { useUserContext } from '@hooks/user/UserContextProvider'
import { useState } from 'react'

interface Values {
  videoUrl?: string
  note?: {
    id: string
    title: string
    description: string
  }
}

export const useVideoInterview = (candidateId?: string) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [response, setResponse] = useState<any>(null)

  const { logOut } = useUserContext()

  const update = async (data: Values) => {
    const dataMap: any = {}
    if (data?.videoUrl) {
      dataMap.video_url = data?.videoUrl
    }
    if (data?.note) {
      dataMap.notes_attributes = data?.note?.id
        ? {
            ...data.note
          }
        : [{ ...data.note }]
    }
    setLoading(true)
    try {
      const res = await api.put(
        `/candidates/${candidateId}/video_interviews`,
        dataMap
      )
      setResponse(res)
      addToast({ title: 'Successfully updated' }, 'success', {})
    } catch (error: any) {
      if (error?.response?.status === 401) logOut()
    } finally {
      setLoading(false)
    }
  }

  return { loading, response, update }
}

import { UserIcon } from '@commons/Icons'
import styled, { css } from 'styled-components'
import { Button } from '../Button'
import { ProfilePictureProps, Size } from './interfaces'

const sizes = {
  small: {
    width: '48px',
    height: '48px'
  },
  large: {
    width: '174px',
    height: '174px'
  }
}

export const userIconProps = {
  color: '#bbc0c5',
  width: 56,
  height: 56
}

export const profilePictureStyle = css<ProfilePictureProps>`
  width: ${({ size }) => sizes[size!].width};
  height: ${({ size }) => sizes[size!].height};
  background-image: url('${({ src }) => src}');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ src }) => {
    if (!src) {
      return css`
        background-size: 30%;
        background-color: ${({ theme }) => theme.colors.shades.gray500};
      `
    }
  }}

  ${({ size, src }) => {
    if (size != 'small' && src) {
      return css`
        border: 5px solid ${({ theme }) => theme.colors.shades.secondary500};
      `
    }
  }}

  ${({ size }) => {
    if (size === 'large') {
      return css`
        @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
          width: calc(${sizes.large.width} - 20px);
          height: calc(${sizes.large.height} - 20px);
        }
      `
    }
  }}

  display: flex;
  flex-direction: column;
`

export const PencilIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const EditCircle = styled.div`
  background-color: ${({ theme }) => theme.colors.shades.secondary500};
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-right: 8px;
  cursor: pointer;
  align-self: flex-end;
  margin-top: auto;
  margin-bottom: 0;
`

export const ProfilePictureUserIcon = styled(UserIcon)<{
  size?: Size
}>`
  margin-bottom: 0;
  align-self: center;

  ${({ size }) =>
    size === 'small' &&
    css`
      width: 22px;
    `}
`

export const PhotoWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 24px;
  position: relative;
  z-index: 2;
  flex-wrap: wrap;
`

export const PhotoActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
`

export const RemoveButton = styled(Button)`
  border-color: ${({ theme }) => theme.colors.alert};

  &,
  :active,
  :hover,
  :focus {
    color: ${({ theme }) => theme.colors.alert};
  }

  :hover {
    box-shadow: 0px 0px 9px ${({ theme }) => `${theme.colors.alert}c9`};
  }

  :focus {
    background-color: ${({ theme }) => theme.colors.alertLight};
  }
`

import * as S from './Pill.styles'
import styled from 'styled-components'
import { PillProps } from './interfaces'
import { truncate } from 'lodash'

const PillWrapper = ({ ...props }: PillProps) => <div {...props} />

const StyledWrapper = styled(PillWrapper)`
  ${S.pillStyle}
`

export const Pill = ({
  testId = 'pill-test-id',
  children,
  endIcon,
  maxLength,
  ...rest
}: PillProps) => {
  const text = maxLength
    ? truncate(children?.toString(), { length: maxLength })
    : children

  return (
    <StyledWrapper data-testid={testId} {...rest}>
      <S.Text>{text}</S.Text>
      {endIcon && <S.IconWrapper>{endIcon}</S.IconWrapper>}
    </StyledWrapper>
  )
}

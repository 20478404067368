import api from '@api/index'
import { addToast } from '@components/atoms/Toast'
import { useUserContext } from '@hooks/user/UserContextProvider'
import { useEffect, useState } from 'react'

interface Values {
  id?: number
  _destroy?: boolean
  description: string
  getting_to_know_me_id?: number
}

interface Questions {
  id: number
  description: string
  created_at: Date
  updated_at: Date
}

export const useGettingToKnowMe = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [response, setResponse] = useState<any>(null)

  const { logOut } = useUserContext()

  const update = async (data: Values[]) => {
    setLoading(true)
    try {
      const res = await api.put(`/users/profile`, {
        profile: {
          profile_getting_to_know_mes_attributes: data
        }
      })
      setResponse(res)
      addToast({ title: 'Successfully updated' }, 'success', {})
    } catch (error: any) {
      if (error?.response?.status === 401) logOut()
    } finally {
      setLoading(false)
    }
  }

  return { loading, response, update }
}

export const useGetQuestions = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [data, setData] = useState<Questions[]>([])

  const get = async () => {
    setLoading(true)
    try {
      const res = await api.get(`/getting_to_know_mes`)
      setData(res.data)
    } catch (error: any) {
      addToast({ title: error?.response?.data?.message }, 'error', {})
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    get()
  }, [])

  return { loading, data }
}

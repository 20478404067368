import styled from 'styled-components'

export const Links = styled.div`
  display: flex;
  flex-wrap: wrap;

  > div {
    width: 50%;
    padding-right: 32px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
    flex-direction: column;
    padding: 0 24px;

    div {
      width: 100%;
      margin-top: 0;
    }
  }
`

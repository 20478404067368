import { CheckIconWithBackground } from '@commons/Icons'
import { Button } from '@components/atoms/Button'
import styled from 'styled-components'
import * as S from '../AccountConfirmMessage/AcccountConfirmMessage.styles'

export interface PasswordChangedMessageProps {
  testId?: string
  goToSignIn: () => void
}

const Text = styled(S.Text)`
  @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
    max-width: 250px;
  }
`

export const PasswordChangedMessage = ({
  testId = 'component-password-changed-id',
  goToSignIn,
  ...props
}: PasswordChangedMessageProps) => {
  return (
    <S.Container data-testid={testId} {...props}>
      <S.Wrapper>
        <S.GreenCheckerWrapper>
          <CheckIconWithBackground />
        </S.GreenCheckerWrapper>

        <S.Heading>Password changed!</S.Heading>

        <S.Body>
          <Text>We succesfully updated your new password.</Text>
        </S.Body>

        <S.Footer>
          <Button size="large" type="button" onClick={goToSignIn}>
            Sign In
          </Button>
        </S.Footer>
      </S.Wrapper>
    </S.Container>
  )
}

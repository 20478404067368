import styled from 'styled-components'

/**
 * Use <Fieldset /> to group checkboxes, radio buttons, or other related form items.
 * <Fieldset /> is a small wrapper around <fieldset /> that resets browser defaults.
 * It should be used along with <Legend /> element for accessibility reasons.
 */
export const Fieldset = styled.fieldset`
  border: 0;
  padding: 0.01em 0 0 0;
  margin: 0;
  min-width: 0;
`

import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const Header = styled.header`
  height: 120px;
`

export const ButtonWrapper = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin: 60px 24px 32px 24px;

  & svg {
    cursor: pointer;
    width: 24px;
    height: 24px;
  }
`

export const LogoWrapper = styled.div``

export const Main = styled.main`
  height: 100%;
  background-color: ${({ theme }) => theme.colors.shades.tertiary300};
  color: ${({ theme }) => theme.colors.primaryBlue};
`

export const MainContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`

export const Navigation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  background-color: ${({ theme }) => theme.colors.shades.tertiary300};
  color: ${({ theme }) => theme.colors.primaryBlue};
`

export const NavigationItem = styled(ButtonWrapper)`
  display: flex;
  align-items: center;
  padding: 24px;
  width: 100%;
  height: 90px;
  font-size: 24px;
  font-weight: 600;
  line-height: 31px;
  letter-spacing: 0.02em;
  text-align: left;

  border-bottom: 1.5px solid #edece3;

  :hover {
    background-color: ${({ theme }) => theme.colors.shades.gray300};
  }
`

export const NavigationHeader = styled.header``

export const NavigationFooter = styled.footer`
  margin-bottom: 48px;
`

export const FooterItem = styled(ButtonWrapper)`
  display: flex;
  align-items: center;
  width: 100%;
  height: 51px;
  padding-left: 32px;

  & svg {
    margin-right: 16px;
  }

  :hover {
    background-color: ${({ theme }) => theme.colors.shades.gray300};
  }
`

import { HTMLAttributes } from 'react'
import styled from 'styled-components'
import { DeleteIcon } from '@commons/Icons'
import { InputPure } from '@components/atoms/InputText/InputText.styles'
import media from 'styled-media-query'

export const Container = styled.div``

interface OptionProps extends HTMLAttributes<HTMLSpanElement> {
  active?: boolean
  add?: boolean
}

interface ItemsLeftProps extends HTMLAttributes<HTMLSpanElement> {
  itemsLeft: number
}

interface PaperProps extends HTMLAttributes<HTMLDivElement> {
  positionY?: number
  width?: number
  showAll?: boolean
}

export const Paper = styled.div<PaperProps>`
  border: 1.5px solid #e6ebf0;
  border-radius: 16px;
  padding: 10px;
  position: fixed;
  background-color: white;
  z-index: 2;
  max-height: 289px;
  overflow: auto;

  ${media.lessThan('medium')`
    position: sticky !important;
    margin-top: 5px;
  `}

  ${({ showAll }) =>
    showAll
      ? `
      overflow-y: scroll;
      ::-webkit-scrollbar {
        width: 29px;
      }

      ::-webkit-scrollbar-track {
        background-color: transparent;
      }

      ::-webkit-scrollbar-thumb {
        border: 11px solid white;
        background-clip: padding-box;
        border-radius: 9999px;
        background-color: ${({ theme }) => theme.colors.shades.gray500};
      }

      ::-webkit-scrollbar-thumb:hover {
        background-color: ${({ theme }) => theme.colors.shades.gray700};
      }
  `
      : ''}

  ${({ positionY, width }) => `
    top: ${(positionY || 0) + 60}px;
    width: ${width}px !important;
  `}
`

export const Option = styled.span<OptionProps>`
  display: flex;
  padding: 12px;
  border-radius: 8px;
  cursor: pointer;
  align-items: center;

  svg {
    margin-right: 10px;
  }

  :hover {
    background: #f2f5f7;
  }

  span::first-letter {
    text-transform: capitalize;
  }

  ${({ active }) =>
    active
      ? `
      background: #f2f5f7;
      `
      : ''}

  ${({ add }) =>
    add
      ? `
      font-weight: 600;
      `
      : ''}
`

export const Delete = styled(DeleteIcon)`
  cursor: pointer;
`

export const Pills = styled.div`
  margin-top: 24px;
`

export const Input = styled(InputPure)`
  :disabled {
    background: #f2f5f7;
  }
`

export const DisabledText = styled.div`
  margin-top: 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  z-index: 1;
  color: #8e97a0;
`

export const ItemsLeft = styled.span<ItemsLeftProps>`
  display: inline-flex;
  align-self: right;
  margin-right: 5px;
  margin-left: auto;

  ${({ itemsLeft }) => (itemsLeft === 0 ? `color: #de1b3e;` : '')}
`

export const TitleBar = styled.div`
  display: flex;
  margin-bottom: 10px;
`
export const ChooseUpToText = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
`

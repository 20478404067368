import api from '@api/index'
import { addToast } from '@components/atoms/Toast'
import { useUserContext } from '@hooks/user/UserContextProvider'
import { useState } from 'react'

export const useAssessment = (candidateId?: string) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [response, setResponse] = useState<any>(null)

  const { logOut } = useUserContext()

  const update = async (file: any) => {
    setLoading(true)
    try {
      const formData = new FormData()
      formData.append('file', file)
      const headersConfig = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      const res = await api.put(
        `/candidates/${candidateId}/assessments`,
        formData,
        headersConfig
      )
      setResponse(res)
      addToast({ title: 'successfully updated' }, 'success', {})
    } catch (error: any) {
      if (error?.response?.status === 401) logOut()
    } finally {
      setLoading(false)
    }
  }

  return { loading, response, update }
}

export const useDeleteAssessment = (candidateId?: string) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [response, setResponse] = useState<any>(null)

  const { logOut } = useUserContext()

  const deleteAssessment = async () => {
    setLoading(true)
    try {
      const res = await api.delete(`/candidates/${candidateId}/assessments`)
      setResponse(res)
      addToast({ title: 'successfully updated' }, 'success', {})
    } catch (error: any) {
      if (error?.response?.status === 401) logOut()
      addToast({ title: error?.response?.data?.message }, 'error', {})
    } finally {
      setLoading(false)
    }
  }

  return { loading, response, deleteAssessment }
}

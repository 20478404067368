import * as S from './FlexColumn.styles'

export type AlignSelf =
  | 'flex-start'
  | 'center'
  | 'flex-end'
  | 'stretch'
  | 'auto'

export type ColWidth = number | 'fill' | 'auto' | 'hidden'

export interface FlexColumnProps extends React.HTMLAttributes<HTMLDivElement> {
  testId?: string
  align?: AlignSelf
  cols?: number
  gutter?: number
  l?: ColWidth
  m?: ColWidth
  s?: ColWidth
}

export const FlexColumn = ({
  testId = 'flex-col-id',
  align = 'auto',
  ...props
}: FlexColumnProps) => (
  <S.FlexColumn data-testid={testId} align={align} {...props}></S.FlexColumn>
)

export default FlexColumn

import styled, { css } from 'styled-components'
import { RoundedBoxProps } from './interfaces'

const isMobile = window?.screen?.width < 490
const isTablet = window?.screen?.width < 768

const sizes = {
  extraLarge: '96px',
  large: '56px',
  tablet: '64px',
  mobile: '40px'
}

const getSize = (size) => {
  if (size) return size

  if (isMobile) {
    return 'mobile'
  }

  if (isTablet) {
    return 'tablet'
  }

  return 'large'
}

export const Container = styled.div<RoundedBoxProps>`
  width: 100%;
  height: 100%;

  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor ? backgroundColor : theme.colors.primaryWhite};

  ${({ size }) => {
    const selectedSize = getSize(size)

    return css<RoundedBoxProps>`
      border-top-left-radius: ${({ topLeft }) =>
        topLeft ? sizes[selectedSize] : '0px'};
      border-top-right-radius: ${({ topRight }) =>
        topRight ? sizes[selectedSize] : '0px'};
      border-bottom-left-radius: ${({ bottomLeft }) =>
        bottomLeft ? sizes[selectedSize] : '0px'};
      border-bottom-right-radius: ${({ bottomRight }) =>
        bottomRight ? sizes[selectedSize] : '0px'};
    `
  }};
`

import styled from 'styled-components'

export const ProfileCompletion = styled.div`
  background-color: ${(props) => props.theme.colors.primaryWhite};
  padding: 16px calc((100% - 1200px) / 2);
  display: flex;
  gap: 12px;
  border-bottom: 1px solid #e6ebf0;
  > div {
    flex-grow: 1;
  }

  @media (max-width: 1200px) {
    padding: 16px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
    flex-direction: column;
    align-items: center;
    padding: 24px;

    > div {
      width: 100%;
    }

    p {
      font-size: 14px;
      line-height: 23.8px;
    }
  }
`

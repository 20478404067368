import * as S from '../FormCommons/Form.styles'
import { WorkPerksFormProps } from './interfaces'
import { options } from './options'
import { useState } from 'react'
import { FormContainer } from '@components/atoms/FormContainer'
import { Button } from '@components/atoms/Button'
import { AutocompleteSelect } from '@components/molecules/AutocompleteSelect'
import { AutocompleteOption } from '@components/molecules/AutocompleteSelect/interfaces'

export const WorkPerksForm = ({
  testId = 'WorkPerksForm-id',
  workPerks,
  onSubmit,
  onCancel,
  initialValues,
  ...props
}: WorkPerksFormProps) => {
  const [selectedItems, setSelectedItems] = useState<any>([])

  return (
    <FormContainer data-testid={testId} {...props}>
      <AutocompleteSelect
        onChange={(items) => setSelectedItems(items)}
        showAll={true}
        options={workPerks.map(({ id, description }) => ({
          id,
          value: description
        }))}
        placeholder="Choose an option"
        limitReachedText="You reached the max number of skills"
        max={5}
        initialValues={initialValues?.map(({ id, description }) => ({
          id,
          value: description
        }))}
        onAdd={async (query) => {
          return new Promise<AutocompleteOption>((resolve) => {
            resolve({
              id: query,
              value: query
            })
          })
        }}
      />

      <S.ButtonsWrapper>
        <Button styling="secondary" type="button" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          type="submit"
          testId="fieldId"
          onClick={() =>
            onSubmit({
              work_perks: selectedItems
            })
          }
        >
          Save
        </Button>
      </S.ButtonsWrapper>
    </FormContainer>
  )
}

import theme from '@styles/theme'
import { css } from 'styled-components'
import { BodyMR } from '../Text/Text.styles'
import { NavItemProps } from './interfaces'

const colors = {
  text: theme.colors.primaryWhite,
  bg: theme.colors.shades.primary300,
  pressed: {
    bg: theme.colors.shades.primary500,
    text: theme.colors.shades.secondary500
  },
  hover: {
    bg: theme.colors.shades.primary500,
    text: theme.colors.primaryWhite
  }
}

export const navItemStyle = css<NavItemProps>`
  text-decoration: none;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 108px;
  height: 42px;
  padding: 10px 12px;

  font-size: ${({ theme }) => theme.typography.sizes.text.small};
  line-height: ${({ theme }) => theme.typography.sizes.lineHeight.small};
  font-weight: ${({ theme }) => theme.typography.weights.semiBold};
  cursor: pointer;
  border-radius: ${({ theme }) => theme.border.navItem};
  color: ${colors.text};
  border: 1px solid transparent;
  outline: 0;
  transition: ${({ theme }) => theme.transition.fast};
  background: rgba(73, 88, 102, 0.4);

  ${({ disabled }) => {
    const disabledStyles = css<NavItemProps>`
      cursor: not-allowed;
      background: ${({ theme }) => theme.colors.shades.gray700};
      color: ${({ theme }) => theme.colors.shades.primary300};
    `
    const enabledStyles = css`
      &:hover {
        background: ${colors.hover['bg']};
        color: ${colors.hover['text']};
      }
      &:active {
        background: ${colors.pressed.bg};
        color: ${colors.pressed.text};
      }
    `
    return disabled ? disabledStyles : enabledStyles
  }}

  ${({ withoutBackground }) => {
    return (
      withoutBackground &&
      css`
        background: transparent;

        p {
          ${BodyMR}
        }
      `
    )
  }}
`

const colors = {
  primaryWhite: '#FFFFFF',
  primaryGray: '#D2D5D9',
  primaryBlue: '#1C2E40',
  secondaryGreen: '#4DE295',
  testiarySand: '#FFFBE8',

  alertDark: '#851025',
  alert: '#DE1B3E',
  alertLight: '#FFF1F4',
  alertSecondary: '#CBBABE',

  successDark: '#216040',
  success: '#4DE295',
  successLight: '#ECFAF3',
  successSecondary: '#B1CABD',

  warning: '#C8C7B9',
  warningDark: '#35330B',
  warningLight: '#FDFCE8',

  grey: '#818F9B',
  greyDark: '#2C3236',
  greyLight: '#EFEFEF',

  info: '#B2C2CB',
  infoDark: '#182F3F',
  infoLight: '#E8F5FD',

  shades: {
    primary900: '#0B121A',
    primary700: '#14202D',
    primary500: '#1C2E40',
    primary300: '#495866',
    primary100: '#8E97A0',

    secondary900: '#0F2D1E',
    secondary700: '#27714B',
    secondary500: '#4DE295',
    secondary300: '#A6F1CA',
    secondary100: '#DBF9EA',

    tertiary900: '#B3B0A2',
    tertiary700: '#E6E2D1',
    tertiary500: '#FFFBE8',
    tertiary300: '#FFFDF1',
    tertiary100: '#FFFEF8',

    gray900: '#B4BCC6',
    gray700: '#D2D5D9',
    gray500: '#E6EBF0',
    gray300: '#F2F5F7',
    gray100: '#FFFFFF'
  }
} as const

export { colors }

import { Container as OriginalGradientBackground } from '@components/atoms/GradientBackground'
import { RoundedBox as OriginalRoundedBox } from '@components/atoms/RoundedBox'
import styled from 'styled-components'
import media from 'styled-media-query'

const isMobile = window?.screen?.width < 700

export const Container = styled.div`
  height: 100vh;
  width: 100vw;

  @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
    height: 100%;
  }
`

export const GradientBackground = styled(OriginalGradientBackground)`
  height: 100%;
  width: 100%;

  position: relative;
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${media.lessThan('small')`
		justify-content: flex-end;
		align-items: flex-end;
  `}
`
export const RoundedBox = styled(OriginalRoundedBox).attrs({
  topLeft: true,
  topRight: true,
  bottomLeft: isMobile ? false : true,
  bottomRight: isMobile ? false : true
})`
  display: flex;
  align-items: center;
  justify-content: center;

  max-width: 600px;
  max-height: 460px;
  min-height: 491px;

  padding: 93px;

  z-index: 10;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    min-height: 370px;
    margin-top: 442px;
    height: 375px;
    padding: 0;
  }
`

export const LogoWrapper = styled.div`
  ${media.lessThan('small')`
		display: none;
	`}

  display: block;
  display: grid;
  grid-template-areas:
    '. logo  .'
    '. . .'
    '. . .'
    '. . .'
    '. . .';
  grid-template-columns: 1fr 1fr 1fr;
  height: 100%;
  position: absolute;
  width: 100%;
`

export const LogoArea = styled.div`
  align-items: center;
  display: flex;
  grid-area: logo;
  height: 100%;
  justify-content: center;

  @media (max-height: 700px) {
    height: 60%;
  }
`

export const MobileLogo = styled.img`
  ${media.greaterThan('small')`
		display: none;
	`}

  height: 28px;
  width: auto;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 48px;
`

export const Logo = styled.img`
  display: block;
  height: 32px;
  width: auto;
`

export const MobileBackground = styled.img`
  ${media.greaterThan('medium')`
		display: none;
	`}

  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 132px;
`

export const LeftBackground = styled.img`
  ${media.lessThan('small')`
		display: none;
	`}

  display: block;
  position: absolute;
  overflow: auto;
  left: 0;
  top: 173px;
`

export const RightBackground = styled.img`
  ${media.lessThan('small')`
		display: none;
	`}

  display: block;
  position: absolute;
  overflow: hidden;
  right: 0;
  bottom: 73px;
`

import { Form, Formik, FormikProps } from 'formik'
import { yupTextArea } from '@components/commons/Form/validations'
import { ProfessionalHighlightsFormProps } from './interfaces'
import { TextArea } from '@components/atoms/TextArea'
import { Button } from '@components/atoms/Button'
import { FormContainer } from '@components/atoms/FormContainer'
import * as Yup from 'yup'
import { useUpdateProfessionalHighlights } from '@hooks/aboutMe/useProfessionalHighlights'
import * as S from '../FormCommons/Form.styles'
import { useEffect } from 'react'
import { DeleteButton } from '@components/atoms/DeleteButton'

interface Values {
  highlight: string
}

const idContext = `professional-highlights-form`

const validationSchema = () => {
  return Yup.object().shape({
    highlight: yupTextArea
  })
}

export const ProfessionalHighlightsForm = ({
  testId = 'professional-highlights-id',
  isEditing,
  defaultValue,
  testSubmit,
  onCancel,
  id,
  onSucess,
  highlights = [],
  ...props
}: ProfessionalHighlightsFormProps) => {
  const { loading, update, response } = useUpdateProfessionalHighlights()

  const rest = highlights.filter((it) => {
    if (id) {
      return it.id !== id
    } else {
      return true
    }
  })

  const deleteItem = () => {
    update([...rest, { id, _destroy: true }])
  }

  useEffect(() => {
    if (response?.status === 200) onSucess()
  }, [response])

  return (
    <FormContainer data-testid={testId} {...props}>
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          highlight: defaultValue || ''
        }}
        enableReinitialize
        onSubmit={(values) => {
          const method = testSubmit ? testSubmit : update
          method([...rest, { description: values.highlight, id }])
        }}
      >
        {(props: FormikProps<Values>) => (
          <Form>
            <S.InputRow>
              <S.InputCol>
                <TextArea
                  label="Highlight *"
                  id={`highlight-${idContext}`}
                  placeholder="Your answer here"
                  type="text"
                  name="highlight"
                  testId="fieldId"
                  disabled={loading}
                  {...props}
                />
              </S.InputCol>
            </S.InputRow>
            <S.ButtonsContainer>
              {isEditing ? <DeleteButton onClick={deleteItem} /> : <div />}
              <S.ButtonsWrapper>
                <Button styling="secondary" type="button" onClick={onCancel}>
                  Cancel
                </Button>
                <Button loading={loading} type="submit" testId="fieldId">
                  {isEditing ? 'Save' : 'Add'}
                </Button>
              </S.ButtonsWrapper>
            </S.ButtonsContainer>
          </Form>
        )}
      </Formik>
    </FormContainer>
  )
}

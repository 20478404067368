import { Text } from '@components/atoms/Text'
import { RecruiterBaseLayout } from '@components/layout/RecruiterBaseLayout'
import { PasswordInformation } from '@components/organisms/PasswordInformation'
import { PersonalInformation } from '@components/organisms/PersonalInformation'
import { useSettings } from '@hooks/candidate/useSettings'
import { MyAccountProps } from './interfaces'
import * as S from './MyAccount.styles'

export const MyAccount = ({
  testId = 'my-account-id',
  ...props
}: MyAccountProps) => {
  const { setPassword, setGeneralInfo } = useSettings()

  const _handleGeneralInfoSubmit = ({ firstName, lastName }) => {
    setGeneralInfo({ firstName, lastName })
  }

  const _handlePasswordChangeSubmit = ({
    confirmNewPassword,
    currentPassword,
    password
  }) => {
    setPassword({ confirmNewPassword, currentPassword, password })
  }

  return (
    <RecruiterBaseLayout data-testid={testId} {...props}>
      <S.Container>
        <S.Header>
          <Text type="ApplicationFormTitle">My account</Text>
        </S.Header>
        <PersonalInformation testSubmit={_handleGeneralInfoSubmit} />
        <PasswordInformation testSubmit={_handlePasswordChangeSubmit} />
      </S.Container>
    </RecruiterBaseLayout>
  )
}

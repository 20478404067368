import {
  BodyLB,
  BodyMB,
  BodyMR,
  BodySMB,
  BodySMR
} from '@components/atoms/Text/Text.styles'
import styled from 'styled-components'

export const Container = styled.div`
  color: ${({ theme }) => theme.colors.primaryBlue};
  width: 100%;
  margin-bottom: 35px;

  hr {
    border: 1px solid #e6ebf0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin-bottom: 24px;
  }
`

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  background-color: ${({ theme }) => theme.colors.primaryWhite};
  padding: 20px 40px;
  border-radius: 16px 16px 0 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.shades.gray500};

  @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
    border-radius: 0;
    padding: 20px 24px;
  }

  & svg {
    color: ${({ theme }) => theme.colors.shades.primary100};
    @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
      width: 20px;
      height: 20px;
    }
  }

  & h1 {
    ${BodyLB}

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
      ${BodyMB}
    }
  }
`

export const Card = styled.div`
  background-color: ${({ theme }) => theme.colors.primaryWhite};
  padding: 40px;
  border-radius: 0 0 16px 16px;

  @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
    padding: 24px;
    border-radius: 0;
  }
`

export const CardWithBorder = styled(Card)`
  border-radius: 16px;

  @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
    border-radius: 0;
  }
`

export const BorderedSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 32.5px;

  &:last-child {
    margin-bottom: 0;
  }

  hr + & {
    margin-top: 32.5px;
  }

  & strong {
    ${BodyMB}

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
      ${BodySMB}
    }
  }

  & p {
    word-break: break-all;
    ${BodyMR}
    color: ${({ theme }) => theme.colors.shades.primary300};

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
      ${BodySMR}
    }
  }
`

export const AssignationSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  h1 {
    margin-bottom: 8px;
  }

  div {
    margin: 0;
  }

  section {
    display: flex;
    flex-direction: column;

    div {
      line-height: 16px;
    }

    div + div {
      margin-top: 12px;
    }
  }

  section + section {
    h1 {
      margin-bottom: 16px;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
    gap: 24px;
  }
`

import * as S from './CandidatesCardPlaceholder.styles'
import { UserAddIcon } from '@commons/Icons'

export interface CandidatesCardPlaceholderProps {
  testId?: string
  title: string
  subtitle: string
}

export const CandidatesCardPlaceholder = ({
  testId = 'candidates-card-placeholder-id',
  title,
  subtitle,
  ...props
}: CandidatesCardPlaceholderProps) => {
  return (
    <S.Container data-testid={testId} {...props}>
      <UserAddIcon />
      <S.Title>{title}</S.Title>
      <S.Subtitle>{subtitle}</S.Subtitle>
    </S.Container>
  )
}

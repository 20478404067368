import styled from 'styled-components'

export const Container = styled.div`
  background-color: #f2f5f7;
`

export const Main = styled.main`
  display: flex;
  gap: 30px;
  margin: 59px 96px 99px 96px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    gap: 30px;
    margin: initial;
    flex-direction: column;
  }
`

export const TabsWrapper = styled.div`
  > div {
    margin: 32px 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    > div {
      margin: 24px;
    }
  }
`

export const LeftWrapper = styled.aside`
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin-top: 32px;
    margin-bottom: 26px;
  }
`

export const RightWrapper = styled.aside`
  flex: 1;
`

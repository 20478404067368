import { useMediaQuery } from '@mui/material'
import * as S from './Footer.styles'

import { Logo } from '@components/atoms/Logo'
import { MobileAccordion } from './MobileAccordion'
import {
  HelpfulLinks,
  SocialMediaLinks,
  WhatWeDo,
  WhoWeAre
} from './components'
import { FooterAdditionalInfo } from './components/FooterAdditionalInfo'

export const LinksBlock = ({ testId = 'component-links-block-id' }) => {
  const IS_MOBILE = useMediaQuery('(max-width: 540px)')

  const MobileView = () => <MobileAccordion />

  const DesktopView = () => (
    <S.LinksWrapper data-testid={testId}>
      <S.LinksContainer>
        <S.LogoWrapper>
          <Logo size="large" />
        </S.LogoWrapper>

        <WhoWeAre />

        <WhatWeDo />

        <HelpfulLinks />

        <SocialMediaLinks />
      </S.LinksContainer>

      <FooterAdditionalInfo />
    </S.LinksWrapper>
  )

  return IS_MOBILE ? <MobileView /> : <DesktopView />
}

export const Footer = ({
  testId = 'footer-id',
  hideLinksBlock = false,
  ...props
}: any) => {
  const IS_MOBILE = useMediaQuery('(max-width: 700px)')

  return (
    <>
      {hideLinksBlock ? null : <LinksBlock />}
      <S.Container isMobile={IS_MOBILE} data-testid={testId} {...props}>
        {IS_MOBILE && <FooterAdditionalInfo />}
      </S.Container>
    </>
  )
}

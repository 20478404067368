import { BodyLB, BodySMR } from '@components/atoms/Text/Text.styles'
import { Grid } from '@mui/material'
import styled, { css } from 'styled-components'

export const Container = styled.div<{ isMobile?: boolean }>`
  ${({ isMobile }) =>
    isMobile &&
    css`
      height: 48px;
      padding: 12px 96px;
    `}

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;

  p {
    color: ${({ theme }) => theme.colors.primaryWhite};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    align-items: center;
    background: linear-gradient(66deg, #162635 -0.04%, #1d2f41 100%);
    flex-direction: column;
    gap: 32px;
    height: initial;
    justify-content: center;
    padding: 32px 24px;

    p {
      text-align: center;
    }
  }
`

export const ButtonsWrapper = styled(Grid)`
  align-items: center;
  display: flex;
  gap: 20px;

  svg {
    cursor: pointer;
    color: #919292;
  }
`

export const LinksWrapper = styled.div`
  background: #1c2e40;
  display: flex;
  flex-direction: column;
  gap: 76px;
  padding: 40px 96px 16px;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 48px 24px;
  }
`

export const LinksContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  gap: 56px;
  justify-content: center;
`

export const LogoWrapper = styled.div``

export const LinksColumnContainer = styled.div`
  a:last-child {
    margin-bottom: 0px;
  }
`

export const ItemTitle = styled.div`
  ${BodyLB}
  color: white;
  font-size: 18px;
  margin-bottom: 4px;
`

export const Item = styled.a`
  ${BodySMR}

  margin: 30px 0;
  line-height: normal;

  display: block;
  color: white;
  cursor: pointer;
`

export const FooterAdditionalInfo = styled.div`
  align-items: center;
  background: #1c2e40;
  color: ${({ theme }) => theme.colors.primaryWhite};
  display: flex;
  flex-direction: column;
  font-size: 10px;
  gap: 8px;

  @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
    font-size: 12px;
    background: transparent;
  }
`

import styled, { css } from 'styled-components'
import { CandidateProfileOverviewProps } from './interfaces'

export const candidateProfileOverviewStyles = css<CandidateProfileOverviewProps>`
  position: relative;
  background-color: ${({ theme }) => theme.colors.shades.gray300};
  box-shadow: -10px 10px 0px ${({ theme }) => theme.colors.shades.gray300},
    10px 10px 0px ${({ theme }) => theme.colors.shades.gray300};
  border-radius: 8px;

  @media (max-width: ${({ theme }) => theme.breakpoints.desktop + 48}px) {
    padding-left: 24px;
    padding-right: 24px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
    position: relative;
    background-color: transparent;
    box-shadow: none;
  }
`

export const UserFullName = styled.h4`
  font-size: ${({ theme }) => theme.typography.sizes.large.h4};
  color: ${({ theme }) => theme.colors.shades.primary500};
  font-weight: 600;
  line-height: ${({ theme }) => theme.typography.sizes.lineHeight.h4};
  margin-top: 32px;
  margin-bottom: 13px;
  max-width: 400px;

  @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
    font-size: 24px;
  }
`

export const PillContainer = styled.div`
  margin-top: 16px;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const SocialIcon = styled.div<any>`
  display: flex;
  align-items: center;
  line-height: 25.6px;

  > svg {
    width: 16px;
    height: 16px;
    margin-right: 14px;
  }
`

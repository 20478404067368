import {
  initialValues as initialValuesStep1,
  Step1,
  validationSchema as validationSchemaStep1
} from '@components/organisms/ApplicationForm/Step1'
import {
  initialValues as initialValuesStep2,
  Step2,
  validationSchema as validationSchemaStep2
} from '@components/organisms/ApplicationForm/Step2'
import {
  initialValues as initialValuesStep3,
  Step3,
  validationSchema as validationSchemaStep3
} from '@components/organisms/ApplicationForm/Step3'
import {
  initialValues as initialValuesStep4,
  Step4,
  validationSchema as validationSchemaStep4
} from '@components/organisms/ApplicationForm/Step4'
import {
  initialValues as initialValuesStep5,
  Step5,
  validationSchema as validationSchemaStep5
} from '@components/organisms/ApplicationForm/Step5'
import {
  initialValues as initialValuesStep6,
  Step6,
  validationSchema as validationSchemaStep6
} from '@components/organisms/ApplicationForm/Step6'
import {
  initialValues as initialValuesStep7,
  Step7,
  validationSchema as validationSchemaStep7
} from '@components/organisms/ApplicationForm/Step7'
import {
  initialValues as initialValuesStep8,
  Step8,
  validationSchema as validationSchemaStep8
} from '@components/organisms/ApplicationForm/Step8'

export const FORM_STEPS = {
  1: 'STEP_1',
  2: 'STEP_2',
  3: 'STEP_3',
  4: 'STEP_4',
  5: 'STEP_5',
  6: 'STEP_6',
  7: 'STEP_7',
  8: 'STEP_8'
}

export const FORMS = {
  1: {
    title: 'Profile information',
    component: <Step1 />,
    validation: validationSchemaStep1
  },
  2: {
    title: 'Profile information',
    component: <Step2 />,
    validation: validationSchemaStep2
  },
  3: {
    title: 'Goals & aspirations',
    component: <Step3 />,
    validation: validationSchemaStep3
  },
  4: {
    title: 'Goals & aspirations',
    component: <Step4 />,
    validation: validationSchemaStep4
  },
  5: {
    title: 'Goals & aspirations',
    component: <Step5 />,
    validation: validationSchemaStep5
  },
  6: {
    title: 'Career background',
    component: <Step6 />,
    validation: validationSchemaStep6
  },
  7: {
    title: 'Career background',
    component: <Step7 />,
    validation: validationSchemaStep7
  },
  8: {
    title: 'Career background',
    component: <Step8 />,
    validation: validationSchemaStep8
  }
}

export const MAX_STEP_SIZE = Object.keys(FORMS).length

export const INITIAL_VALUES = {
  ...initialValuesStep1,
  ...initialValuesStep2,
  ...initialValuesStep3,
  ...initialValuesStep4,
  ...initialValuesStep5,
  ...initialValuesStep6,
  ...initialValuesStep7,
  ...initialValuesStep8
} as const

import styled from 'styled-components'
import { Button } from '@components/atoms/Button'
import { Text } from '@components/atoms/Text'
import { CopyButton } from '@components/atoms/CopyButton'

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.primaryWhite};
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 300px;
  flex-direction: column;

  @media (max-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    padding-left: 56px;
    padding-right: 56px;
    text-align: center;
    height: auto;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
    > svg {
      width: 72px;
      height: 72px;
    }
  }
`
export const Title = styled.h2`
  font-size: 24px;
  line-height: 31px;
  margin-top: 40px;
  font-weight: ${({ theme }) => theme.typography.weights.semiBold};

  @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
    font-size: 20px;
  }
`

export const Subtitle = styled.h2`
  margin-top: 4px;
  font-size: 16px;
  font-weight: ${({ theme }) => theme.typography.weights.thin};
  line-height: 26px;
  margin-bottom: 32px;
  max-width: 550px;
  text-align: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
    font-size: 14px;
  }
`

export const CallToActionButton = styled(Button)`
  height: 48px;
`

export const ReferencesText = styled(Text)`
  font-size: ${({ theme }) => theme.typography.sizes.text.small};
  font-weight: 600;
`

export const ReferencesContainer = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
`

export const CopyLinkButton = styled(CopyButton)`
  margin-left: 8px;
  margin-top: -2px;
`

import { BackIcon } from '@commons/Icons'
import { Text } from '@components/atoms/Text'
import { Progress } from '@components/molecules/Progress'
import { Form, Formik, FormikProps } from 'formik'

import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { CandidateApplicationBaseLayout } from '../../../layout/ApplicationForm/BaseLayout'
import { useApplicationForm } from '../hooks/useApplicationForm'
import { FORMS, INITIAL_VALUES, MAX_STEP_SIZE } from './FormMetadata'
import { CandidateApplicationStepManagerProps } from './interfaces'
import * as S from './StepManager.styles'

import { useMediaQuery } from '@mui/material'
import { CANDIDATE_ROUTES } from '@routes/CandidateRoutes'

export const CandidateApplicationStepManager = ({
  testId = 'candidate-application-step-manager-id',
  ...props
}: CandidateApplicationStepManagerProps) => {
  const { currentStep: currentStepParam } = useParams()
  const navigate = useNavigate()
  const [formStep, setFormStep] = useState(Number(currentStepParam) || 1)
  const { error, sendApplicationForm } = useApplicationForm()
  const isMobile = useMediaQuery('(max-width: 600px)')

  const _handleBackStep = () => {
    if (formStep > 1) {
      const NEXT_STEP = formStep - 1
      return setFormStep(NEXT_STEP)
    }

    navigate('/candidate' + CANDIDATE_ROUTES.WELCOME)
  }

  const _handleNavigateToThanksScreen = () => {
    navigate('/candidate' + CANDIDATE_ROUTES.THANKS_FOR_APPLYING)
  }

  const IS_LAST_STEP = formStep === MAX_STEP_SIZE

  const getCurrentFormStep = () => {
    const IS_VALID = formStep >= 0 && formStep <= MAX_STEP_SIZE
    !IS_VALID && setFormStep(1) // when invalid, reset the form step to 1
    return FORMS[formStep].component
  }

  const formikRef = useRef<any>()
  useEffect(() => {
    formikRef && formikRef.current && formikRef.current.validateForm()
  }, [formStep])

  return (
    <CandidateApplicationBaseLayout testId={testId} {...props}>
      <Formik
        innerRef={formikRef}
        isInitialValid={false}
        validateOnChange
        validationSchema={FORMS[formStep].validation}
        initialValues={{ ...INITIAL_VALUES, formStep }}
        onSubmit={async (values, actions) => {
          if (IS_LAST_STEP) {
            await sendApplicationForm(values)
            if (error) {
              return
            }
            _handleNavigateToThanksScreen()
          }

          if (formStep < MAX_STEP_SIZE) {
            setFormStep(formStep + 1)
            actions.setTouched({}, false)
          }

          actions.setSubmitting(false)
        }}
      >
        {(props: FormikProps<any>) => (
          <S.Container data-current-step={formStep}>
            <S.Header>
              <S.ProgressWrapper>
                <Progress
                  totalSteps={MAX_STEP_SIZE}
                  currentStep={formStep}
                  styling="slash"
                />
              </S.ProgressWrapper>
              <Text type="ApplicationFormTitle" as="h1">
                {FORMS[formStep].title}
              </Text>
            </S.Header>

            <Form>
              <S.FormWrapper>{getCurrentFormStep()}</S.FormWrapper>

              <S.Footer>
                <S.BackButton
                  type="button"
                  aria-label="Back button"
                  onClick={_handleBackStep}
                >
                  <BackIcon />
                </S.BackButton>
                <S.Button
                  disabled={!props.isValid}
                  aria-label="Next step"
                  type="submit"
                  loading={props.isSubmitting}
                >
                  {IS_LAST_STEP
                    ? isMobile
                      ? 'Send'
                      : 'Send application'
                    : 'Next'}
                </S.Button>
              </S.Footer>
            </Form>
          </S.Container>
        )}
      </Formik>
    </CandidateApplicationBaseLayout>
  )
}

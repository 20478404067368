import { CopyButton } from '@components/atoms/CopyButton'
import { Text } from '@components/atoms/Text'
import * as S from './ModalContentPanel.styles'

export interface ModalContentPanelProps {
  testId?: string
  Icon?: any
  textToCopy: string
  title: string
  body: any
}

export const ModalContentPanel = ({
  testId = 'modal-content-panel-id',
  Icon,
  textToCopy = '',
  title,
  body
}: ModalContentPanelProps) => {
  return (
    <S.Wrapper data-testid={testId}>
      <S.ShareIconWrapper>{Icon}</S.ShareIconWrapper>
      <S.Title>{title}</S.Title>
      <S.Text>{body}</S.Text>
      <S.Button aria-label="text to be copied">
        <S.ButtonText>{textToCopy}</S.ButtonText>
      </S.Button>

      <S.CopyWrapper>
        <Text type="BodyMB">Copy</Text>
        <CopyButton textToCopy={textToCopy} />
      </S.CopyWrapper>
    </S.Wrapper>
  )
}

import { Button as OriginalButton } from '@components/atoms/Button'
import { Avatar } from '@mui/material'
import styled from 'styled-components'

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.shades.gray300};
  color: ${({ theme }) => theme.colors.primaryBlue};
  width: 100%;
  height: 100%;

  padding: 40px 72px 74px 64px;
  overflow: scroll;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 0;
    overflow: initial;
  }
`

export const Header = styled.div`
  margin-bottom: 32px;
  display: grid;
  grid-template-columns: 1fr 320px;
  gap: 32px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-direction: column;
    align-items: flex-start;
    display: flex;
    gap: 0;
  }
`

export const BackButton = styled(OriginalButton).attrs({
  styling: 'link'
})`
  svg {
    margin-right: 8px;
  }

  margin-bottom: 56px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin: 24px;
  }
`

export const ProfilePicture = styled(Avatar).attrs({
  sx: { bgcolor: '#DBF9EA', color: '#27714B' }
})`
  width: 32px;
  height: 32px;
`

export const ButtonWrapper = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 100%;
    padding: 0 24px 32px 24px;
  }
`

export const Button = styled(OriginalButton).attrs({
  styling: 'primary'
})`
  height: 56px;
  padding: 18px 40px;
  width: 240px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 100%;
  }
`

export const InformationWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 320px;
  gap: 32px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    display: flex;
    flex-direction: column-reverse;
    gap: 24px;
    background-color: ${({ theme }) => theme.colors.shades.gray300};
  }
`

export const AssignationText = styled.div`
  display: flex;
  align-items: center;
  padding-top: 8px;
  gap: 0;

  > div {
    height: 24px;
    width: 24px;
    display: inline-flex;
    margin-right: 8px;
  }

  span {
    display: initial;
  }
`

import { Container as BackgroundGradient } from '@components/atoms/GradientBackground/GradientBackground.styles'
import styled from 'styled-components'

export const Container = styled(BackgroundGradient)`
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
    height: 92px;
    align-items: flex-end;
    padding-bottom: 28px;
  }
`

import * as S from './WorkSkillsProfileCard.styles'
import { WorkSkillsProfileCardProps } from './interfaces'
import { ProfileCardPlaceholder } from '@components/molecules/ProfileCardPlaceholder'
import { useWorkSkills } from '@hooks/experience/useWorkSkills'
import { BookIcon } from '@commons/Icons'
import { useEffect, useState } from 'react'
import { capitalize } from 'lodash'
import { useProfileContext } from '@hooks/profile/ProfileContextProvider'
import { Pill } from '@components/atoms/Pill'
import { BookSymbol } from '@commons/Icons'
import { PillViewContainer } from '@components/atoms/ComputerSkillsProfileCard/ComputerSkillsProfileCard.styles'
import { Placeholder } from '../IndustriesProfileCard/IndustriesProfileCard.styles'

export const WorkSkillsProfileCard = ({
  testId = 'work-skills-profile-card-id'
}: WorkSkillsProfileCardProps) => {
  const { fetchWorkSkills, workSkills, update } = useWorkSkills()
  const { fetchProfile, profile } = useProfileContext()
  const [edit, setEdit] = useState(false)
  const profileWorkSkills = edit ? [] : profile.work_skills || []

  const transformOptions = (option) => {
    return {
      id: option.id,
      value: capitalize(option.description)
    }
  }

  useEffect(() => {
    fetchWorkSkills()
  }, [])

  return (
    <S.Card data-testid={testId}>
      {(profileWorkSkills?.length || edit) && (
        <S.TitleBar
          edit={edit}
          title="Work skills"
          onEditButtonClick={() => setEdit(true)}
          icon={<BookSymbol />}
        />
      )}

      {!edit && profileWorkSkills?.length < 1 && (
        <ProfileCardPlaceholder
          icon={<BookIcon />}
          title="Work skills"
          subtitle="Add your work skills here"
          callToAction="Add work skills"
          onClick={() => setEdit(true)}
        />
      )}

      <S.PillContainer>
        {profileWorkSkills?.map((skill) => (
          <Pill key={skill.id}>{capitalize(skill.description)}</Pill>
        ))}
      </S.PillContainer>

      {edit && (
        <S.Form
          testId={`${testId}-form`}
          options={workSkills.map(transformOptions)}
          onSubmit={async (data) => {
            await update(data.work_skills.map((item) => item.id))
            fetchProfile()
            setEdit(false)
          }}
          initialValues={profile.work_skills?.map(transformOptions)}
          onCancel={() => setEdit(false)}
        ></S.Form>
      )}
    </S.Card>
  )
}

export const WorkSkillsCard = ({ profile }) => {
  return (
    <>
      {profile?.work_skills?.length ? (
        <>
          <S.TitleBar title="Work Skills" icon={<BookSymbol />} />
          <PillViewContainer>
            {profile?.work_skills?.map((skill) => (
              <Pill key={skill.id}>{capitalize(skill.description)}</Pill>
            ))}
          </PillViewContainer>
        </>
      ) : (
        <Placeholder
          icon={<BookIcon />}
          title="Work skills"
          subtitle="Add your work skills here"
        />
      )}
    </>
  )
}

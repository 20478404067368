import styled from 'styled-components'
import { Button as OriginalButton } from '@components/atoms/Button'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;

  @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
    align-items: flex-start;
    flex-direction: column;
  }

  & p {
    color: ${({ theme }) => theme.colors.shades.primary300};
  }
`

export const Button = styled(OriginalButton).attrs({
  styling: 'secondary'
})`
  background-color: white;
  color: ${({ theme }) => theme.colors.primaryBlue};
  cursor: pointer;
`

export const UploadedButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  height: 44px;
  padding: 12.5px 19px;
  background-color: ${({ theme }) => theme.colors.shades.gray300};
  color: ${({ theme }) => theme.colors.primaryBlue};

  & p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 200px;
  }
`

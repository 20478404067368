import * as S from './ProfileCard.styles'
import { ProfileCardProps } from './interfaces'

export const ProfileCard = ({
  testId = 'profile-card-id',
  children,
  ...props
}: ProfileCardProps) => {
  return (
    <S.Container data-testid={testId} {...props}>
      {children}
    </S.Container>
  )
}

import { css } from 'styled-components'
import { TabProps } from './interfaces'

export const tabStyle = css<TabProps>`
  display: inline-block;
  border: 1px solid transparent;
  outline: 0;
  background-color: transparent;
  font-size: 16px;
  line-height: 26px;
  cursor: pointer;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 6px;
  margin-right: 32px;
  min-width: fit-content;

  ${({ active }) => {
    if (active) {
      return css`
        border-bottom: 4px ${({ theme }) => theme.colors.shades.secondary500}
          solid;
        font-weight: ${({ theme }) => theme.typography.weights.regular};
      `
    } else {
      return css`
        font-weight: ${({ theme }) => theme.typography.weights.thin};
      `
    }
  }}
`

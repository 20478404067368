import { Form, Formik, FormikProps } from 'formik'
import {
  yupEmail,
  yupFirstName,
  yupLastName,
  yupPhoneRequired,
  yupCity
} from '@components/commons/Form/validations'
import { GeneralInfoProps } from './interfaces'
import * as Yup from 'yup'
import { InputText } from '@components/atoms/InputText'
import * as S from '../FormCommons/Form.styles'
import { Button } from '@components/atoms/Button'
import { FormContainer } from '@components/atoms/FormContainer'
import { inputOnlyNumbers } from '@utils/utils'
import { useUpdateGeneralInfo } from '@hooks/aboutMe/useGeneralInfo'
import { useEffect } from 'react'
import get from 'lodash/get'
import { useProfileContext } from '@hooks/profile/ProfileContextProvider'
import { InputNumber } from '@components/atoms/InputNumber'

interface Values {
  name: string
  lastName: string
  city: string
  phoneNumber: string
  email: string
}

const idContext = `general-info-form`

const validationSchema = () => {
  return Yup.object().shape({
    name: yupFirstName,
    lastName: yupLastName,
    city: yupCity,
    phoneNumber: yupPhoneRequired.max(12, 'Phone number is too long'),
    email: yupEmail
  })
}

export const GeneralInfo = ({
  testId = 'general-info-id',
  isFirstTime,
  testSubmit,
  onCancel,
  onSucess,
  ...props
}: GeneralInfoProps) => {
  const { loading, response, update } = useUpdateGeneralInfo()
  const { profile } = useProfileContext()

  useEffect(() => {
    if (response?.status === 200) onSucess()
  }, [response])

  return (
    <FormContainer data-testid={testId} {...props}>
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          name: isFirstTime ? '' : get(profile, 'general_info.first_name', ''),
          lastName: isFirstTime
            ? ''
            : get(profile, 'general_info.last_name', ''),
          city: isFirstTime ? '' : get(profile, 'general_info.city', ''),
          phoneNumber: isFirstTime
            ? ''
            : get(profile, 'general_info.phone', ''),
          email: isFirstTime ? '' : get(profile, 'general_info.email', '')
        }}
        enableReinitialize
        onSubmit={(values) => {
          const method = testSubmit ? testSubmit : update
          method(values)
        }}
      >
        {(props: FormikProps<Values>) => (
          <Form>
            <S.InputRow>
              <S.InputCol>
                <InputText
                  label={'Name *'}
                  id={`name-${idContext}`}
                  placeholder="Your name"
                  type="text"
                  name="name"
                  testId="fieldId"
                  disabled={loading}
                  {...props}
                />
              </S.InputCol>
              <S.InputCol>
                <InputText
                  label={'Last name *'}
                  id={`lastName-${idContext}`}
                  placeholder="Your last name"
                  type="text"
                  name="lastName"
                  testId="fieldId"
                  disabled={loading}
                  {...props}
                />
              </S.InputCol>
            </S.InputRow>
            <S.InputRow>
              <S.InputCol>
                <InputText
                  label={'City *'}
                  id={`city-${idContext}`}
                  placeholder="City"
                  type="text"
                  name="city"
                  testId="fieldId"
                  disabled={loading}
                  {...props}
                />
              </S.InputCol>
              <S.InputCol>
                <InputNumber
                  label="Phone Number *"
                  id={`phoneNumber-${idContext}`}
                  placeholder="123 456 7890"
                  format="### ### ####"
                  mask="_"
                  name="phoneNumber"
                  testId="fieldId"
                  {...props}
                />
              </S.InputCol>
            </S.InputRow>
            <S.InputRow>
              <S.InputCol>
                <InputText
                  label={'Email address *'}
                  id={`email-${idContext}`}
                  placeholder="email@example.com"
                  type="email"
                  name="email"
                  testId="fieldId"
                  disabled={loading}
                  {...props}
                />
              </S.InputCol>
            </S.InputRow>
            <S.ButtonsWrapper>
              <Button styling="secondary" type="button" onClick={onCancel}>
                Cancel
              </Button>
              <Button loading={loading} type="submit" testId="fieldId">
                Save
              </Button>
            </S.ButtonsWrapper>
          </Form>
        )}
      </Formik>
    </FormContainer>
  )
}

import AppRoutes from './routes'

declare global {
  interface Window {
    analytics: any
  }
}

const App = () => <AppRoutes />

export default App

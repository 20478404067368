import * as S from './ProfileCardPlaceholder.styles'
import { ProfileCardPlaceholderProps } from './interfaces'
import { PlusSmall } from '@commons/Icons'

export const ProfileCardPlaceholder = ({
  testId = 'profile-card-placeholder-id',
  onClick,
  title,
  subtitle,
  callToAction,
  copyLinkUrl,
  icon,
  ...props
}: ProfileCardPlaceholderProps) => {
  return (
    <S.Container data-testid={testId} {...props}>
      {icon}
      <S.Title>{title}</S.Title>
      <S.Subtitle>{subtitle}</S.Subtitle>
      {callToAction && (
        <S.CallToActionButton
          styling="secondary"
          data-testid="profile-card-placeholder-cta"
          aria-label={callToAction}
          onClick={onClick}
        >
          <PlusSmall /> {'\u00A0'} {'\u00A0'} {callToAction}
        </S.CallToActionButton>
      )}

      {copyLinkUrl && (
        <S.ReferencesContainer>
          <S.ReferencesText type="SubDisplay" align="left">
            Copy references link
          </S.ReferencesText>
          <S.CopyLinkButton textToCopy={copyLinkUrl} />
        </S.ReferencesContainer>
      )}
    </S.Container>
  )
}

import api from '@api/index'
import { addToast } from '@components/atoms/Toast'
import { useUserContext } from '@hooks/user/UserContextProvider'
import { resolve } from 'path'
import { useState } from 'react'

export interface ComputerSkill {
  id?: any
  description: string
}

export const useComputerSkills = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [response, setResponse] = useState<any>(null)
  const [computerSkills, setComputerSkills] = useState<any>([])

  const { logOut } = useUserContext()

  const fetchComputerSkills = async (query?: string) => {
    setLoading(true)
    try {
      const res = await api.get(
        `/computer_skills${query ? `?description=${query}` : ''}`
      )
      setComputerSkills(res.data)
    } catch (error: any) {
      if (error?.response?.status === 401) logOut()
    } finally {
      setLoading(false)
    }
  }

  const createComputerSkills = async (
    computerSkill: ComputerSkill
  ): Promise<ComputerSkill | void> => {
    setLoading(true)
    try {
      const res = await api.post('/computer_skills', computerSkill)
      return res.data as ComputerSkill
    } catch (error: any) {
      if (error?.response?.status === 401) logOut()
    } finally {
      setLoading(false)
    }
  }

  const update = async (data: Array<number>) => {
    setLoading(true)
    try {
      const res = await api.put(`/users/profile`, {
        profile: {
          computer_skills_ids: data
        }
      })
      setResponse(res)
      addToast({ title: 'Successfully updated' }, 'success', {})
    } catch (error: any) {
      if (error?.response?.status === 401) logOut()
    } finally {
      setLoading(false)
    }
  }

  return {
    loading,
    response,
    update,
    computerSkills,
    fetchComputerSkills,
    createComputerSkills
  }
}

import { PdfViewProps } from './interfaces'
import { Document, Page, pdfjs } from 'react-pdf'
import * as S from './PdfView.styles'
import { useMediaQuery } from '@mui/material'
import { useState } from 'react'
import {
  FiZoomIn,
  FiZoomOut,
  FiChevronLeft,
  FiChevronRight
} from 'react-icons/fi'

export const PdfView = ({
  testId = 'pdf-view-id',
  fileUrl,
  ...props
}: PdfViewProps) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

  const IS_MOBILE = useMediaQuery('(max-width: 800px)')

  const [numPages, setNumPages] = useState(0)
  const [pageNumber, setPageNumber] = useState(1)
  const [scale, setScale] = useState(IS_MOBILE ? 1 : 1.3)

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
  }

  return (
    <S.Container data-testid={testId} {...props}>
      <S.ButtonsWrapper>
        <S.NaviButton
          type="button"
          aria-label="ZoomIn"
          onClick={() => setScale((val) => val + 0.2)}
        >
          <FiZoomIn />
        </S.NaviButton>
        <S.NaviButton
          type="button"
          aria-label="ZoomOut"
          onClick={() => setScale((val) => val - 0.2)}
        >
          <FiZoomOut />
        </S.NaviButton>
      </S.ButtonsWrapper>
      <S.ViewWrapper>
        <S.View>
          <Document
            file={fileUrl}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={
              <S.SpinnerWrapper>
                <S.Spinner />
              </S.SpinnerWrapper>
            }
          >
            <Page
              pageNumber={pageNumber}
              renderAnnotationLayer={false}
              renderTextLayer={false}
              scale={scale}
            />
          </Document>
        </S.View>
        <S.Navigation>
          <S.NaviButton
            type="button"
            aria-label="previous page"
            onClick={() => setPageNumber((val) => (val > 1 ? val - 1 : val))}
          >
            <FiChevronLeft />
          </S.NaviButton>
          <span>
            {pageNumber} of {numPages}
          </span>
          <S.NaviButton
            type="button"
            aria-label="next page"
            onClick={() =>
              setPageNumber((val) => (val < numPages ? val + 1 : val))
            }
          >
            <FiChevronRight />
          </S.NaviButton>
        </S.Navigation>
      </S.ViewWrapper>
    </S.Container>
  )
}

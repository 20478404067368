import styled from 'styled-components'
import { Button as ButtonPrimitive } from '../Button'
import { BodyMR } from '../Text/Text.styles'

export const Container = styled.div`
  ${BodyMR}

  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 54px;
`

export const Button = styled(ButtonPrimitive)`
  align-items: center;
  display: flex;
  gap: 8px;
  justify-content: center;
  width: fit-content;
`

import styled, { css } from 'styled-components'
import { InputTextProps } from './interfaces'

type IconWrapperProps = {
  startIcon?: boolean
}

export const InputWrapper = styled.div`
  position: relative;
`

export const IconWrapper = styled.span<IconWrapperProps>`
  position: absolute;
  top: 1px;
  bottom: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  color: ${({ theme }) => theme.colors.shades.primary300};
  ${({ startIcon }) =>
    startIcon
      ? css`
          left: 1px;
        `
      : css`
          right: 1px;
        `}
`

export const ErrorMessage = styled.div`
  font-size: 14px;
  margin-top: 12px;
  color: ${({ theme }) => theme.colors.alert};
  max-width: fit-content;
`

export const Label = styled.label`
  color: ${({ theme }) => theme.colors.shades.primary900};
  display: block;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 17px;
  margin-bottom: 12px;
`

export const InputCss = css<InputTextProps>`
  -webkit-appearance: none;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.shades.primary100};
  box-shadow: 0 0 4px 0 transparent;
  color: ${({ theme }) => theme.colors.shades.primary700};
  font-size: 16px;
  font-weight: 400;
  height: 56px;
  letter-spacing: 0em;
  line-height: 25.6px;
  outline: none;
  padding-left: ${({ startIcon }) => !!startIcon && '60px'};
  padding-right: ${({ endIcon }) => !!endIcon && '60px'};
  padding: 0 16px;
  transition-property: border, box-shadow;
  transition: 0.2s ease-in-out;
  width: 100%;
  transition: box-shadow 0.3s ease-in-out;

  ${({ startIcon }) =>
    startIcon &&
    css`
      padding: 0 48px;
    `}

  ${({ hasError }) =>
    hasError &&
    css`
      border: 1.5px solid ${({ theme }) => theme.colors.alert};
      box-sizing: border-box;
      border-radius: 8px;
    `}

  &:focus,
  &:hover {
    border: 1.5px solid ${({ theme }) => theme.colors.shades.primary500};
    box-sizing: border-box;
    border-radius: 8px;
  }

  &:focus {
    box-shadow: 0px 0px 5px #4de295;
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.shades.primary100};
    opacity: 1;
  }

  &:disabled {
    -webkit-text-fill-color: ${({ theme }) => theme.colors.shades.primary100};
    opacity: 1;
    border: 1px solid ${({ theme }) => theme.colors.shades.primary100};
    box-shadow: 0 0 4px 0 transparent;
    background-color: ${({ theme }) => theme.colors.shades.gray300};
    color: ${({ theme }) => theme.colors.shades.primary100};
    cursor: not-allowed;

    &:hover {
      filter: none;
    }

    &:focus {
      box-shadow: none;
    }
  }
`

export const Input = styled.input<InputTextProps>`
  ${InputCss}
`

export const InputPure = styled.input<any>`
  ${InputCss}
`

import styled from 'styled-components'

export const Heading = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;

  > div {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
`

export const PdfViewerContainer = styled.div`
  margin-top: 32px;
`

export const UploadWrapper = styled.div`
  margin-top: 32px;
  padding-top: 32px;
  border-top: 1px solid #e6ebf0;
`

import { FaSpinner } from 'react-icons/fa'
import styled from 'styled-components'

export const Spinner = styled(FaSpinner)`
  animation: spin infinite 1s linear;
  width: 30px;
  height: 30px;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`

export const Photo = styled.div<{
  loading?: boolean
  click?: boolean
  isRemovable?: boolean
}>`
  width: 100%;
  height: 120px;
  background-color: ${({ theme }) => theme.colors.shades.gray300};
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: ${({ click }) => (click ? 'pointer' : 'default')};

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 16px;
  }

  &:after {
    content: '';
    display: ${({ loading }) => (loading ? 'block' : 'none')};
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.5);
  }

  svg path {
    fill: #b4bcc6;
  }

  ${Spinner} {
    position: absolute;
    z-index: 3;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
    width: 100%;
    height: 200px;
  }
`

export const RemoveIcon = styled.div`
  display: none;

  ${Photo}:hover & {
    cursor: pointer;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background-color: ${({ theme }) => theme.colors.shades.secondary500};
    border-radius: 50%;
    top: -12px;
    right: -12px;
    z-index: 1;

    > svg path {
      fill: ${({ theme }) => theme.colors.shades.primary500};
    }
  }
`

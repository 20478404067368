import { LogOutIcon } from '@commons/Icons'
import { ComputerSkillsProfileCard } from '@components/atoms/ComputerSkillsProfileCard'
import { NavigationBar } from '@components/molecules/NavigationBar'
import { ProfileCompletion } from '@components/molecules/ProfileCompletion'
import { Footer } from '@components/organisms/Footer'
import { AboutMeProfileCard } from '@components/organisms/ProfileCards/AboutMeProfileCard'
import { AssessmentProfileCard } from '@components/organisms/ProfileCards/AssessmentProfileCard'
import { CertificationsProfileCard } from '@components/organisms/ProfileCards/CertificationsProfileCard'
import { CompanyValuesProfileCard } from '@components/organisms/ProfileCards/CompanyValuesProfileCard'
import { GeneralInfoProfileCard } from '@components/organisms/ProfileCards/GeneralInfoProfileCard'
import { GettingToKnowMeProfileCard } from '@components/organisms/ProfileCards/GettingToKnowMeProfileCard'
import { HobbiesProfileCard } from '@components/organisms/ProfileCards/HobbiesProfileCard'
import { IndustriesProfileCard } from '@components/organisms/ProfileCards/IndustriesProfileCard'
import { JobStatusProfileCard } from '@components/organisms/ProfileCards/JobStatusProfileCard'
import { ManagementStyleProfileCard } from '@components/organisms/ProfileCards/ManagementStyleProfileCard'
import { PersonalHighlightsProfileCard } from '@components/organisms/ProfileCards/PersonalHighlightsProfileCard'
import { PhotosProfileCard } from '@components/organisms/ProfileCards/PhotosProfileCard'
import { ProfessionalHighlightsProfileCard } from '@components/organisms/ProfileCards/ProfessionalHighlightsProfileCard'
import { ReferencesProfileCard } from '@components/organisms/ProfileCards/ReferencesProfileCard'
import { SocialLinksProfileCard } from '@components/organisms/ProfileCards/SocialLinksProfileCard'
import { TeamCultureProfileCard } from '@components/organisms/ProfileCards/TeamCultureProfileCard'
import { VideoInterviewProfileCard } from '@components/organisms/ProfileCards/VideoInterviewProfileCard'
import { WorkEnvironmentProfileCard } from '@components/organisms/ProfileCards/WorkEnvironmentProfileCard'
import { WorkGroupsProfileCard } from '@components/organisms/ProfileCards/WorkGroupsProfileCard'
import { WorkPerksProfileCard } from '@components/organisms/ProfileCards/WorkPerksProfileCard'
import { WorkSkillsProfileCard } from '@components/organisms/ProfileCards/WorkSkillsProfileCard'
import { ProfileScreenTabs } from '@components/organisms/ProfileScreen/ProfileScreenTabs'
import { useProfileContext } from '@hooks/profile/ProfileContextProvider'
import { useUserContext } from '@hooks/user/UserContextProvider'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useEffect } from 'react'
import { useMatch, useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { ResumeProfileCard } from '../../organisms/ProfileCards/ResumeProfileCard'
import { MobileHeaderNavigation } from '../MobileHeaderNavigation'
import * as M from '../MobileHeaderNavigation/MobileHeaderNavigation.styles'
import * as S from './CandidateProfileScreen.styles'
import { CandidateProfileScreenProps } from './interfaces'

const CandidateProfileScreenWrapper = ({
  ...props
}: CandidateProfileScreenProps) => <div {...props} />

const StyledWrapper = styled(CandidateProfileScreenWrapper)`
  ${S.candidateProfileScreenProps}
`

const TabFragmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`

const TAB_CONTENT = {
  'about-me': (
    <TabFragmentContainer data-testid="about-me-tab-fragment">
      <GeneralInfoProfileCard />
      <AboutMeProfileCard />
      <JobStatusProfileCard />
      <PersonalHighlightsProfileCard />
      <HobbiesProfileCard />
      <ProfessionalHighlightsProfileCard />
      <SocialLinksProfileCard />
      <PhotosProfileCard />
      <GettingToKnowMeProfileCard />
    </TabFragmentContainer>
  ),

  experience: (
    <TabFragmentContainer data-testid="experience-tab-fragment">
      <ResumeProfileCard />
    </TabFragmentContainer>
  ),

  'workplace-dna': (
    <TabFragmentContainer data-testid="workplace-dna-fragment">
      <CertificationsProfileCard />
      <IndustriesProfileCard />
      <WorkSkillsProfileCard />
      <ComputerSkillsProfileCard />
      <CompanyValuesProfileCard />
      <TeamCultureProfileCard />
      <WorkGroupsProfileCard />
      <ManagementStyleProfileCard />
      <WorkEnvironmentProfileCard />
      <WorkPerksProfileCard />
    </TabFragmentContainer>
  ),

  'video-interview': (
    <TabFragmentContainer data-testid="video-interview-fragment">
      <VideoInterviewProfileCard />
    </TabFragmentContainer>
  ),

  references: (
    <TabFragmentContainer data-testid="references-fragment">
      <ReferencesProfileCard />
    </TabFragmentContainer>
  ),

  assessment: (
    <TabFragmentContainer data-testid="assessment-fragment">
      <AssessmentProfileCard />
    </TabFragmentContainer>
  )
}

const DEFAULT_TAB = 'about-me'

export const MobileSideBar = () => {
  const navigate = useNavigate()
  const { logOut } = useUserContext()

  const _handleSettingsRedirect = () => {
    navigate('/candidate/settings/')
  }

  const _handleMyProfileRedirect = () => {
    navigate('/candidate/profile')
  }

  return (
    <M.Navigation>
      <M.NavigationHeader>
        <M.NavigationItem
          aria-label="my profile"
          onClick={_handleMyProfileRedirect}
        >
          My profile
        </M.NavigationItem>
        <M.NavigationItem
          aria-label="settings"
          onClick={_handleSettingsRedirect}
        >
          Settings
        </M.NavigationItem>
        <M.NavigationItem aria-label="log out" onClick={logOut}>
          Log out <LogOutIcon />
        </M.NavigationItem>
      </M.NavigationHeader>
    </M.Navigation>
  )
}

export const MobileView = ({
  sideBar,
  Container,
  ProfileCompletionChildren
}: any) => {
  return (
    <MobileHeaderNavigation
      showFooter
      sidebarChildren={sideBar}
      ProfileCompletionChildren={ProfileCompletionChildren}
    >
      <Container />
    </MobileHeaderNavigation>
  )
}

export const CandidateProfileScreen = ({
  testId = 'candidate-profile-screen-test-id',
  ...rest
}: CandidateProfileScreenProps) => {
  const { tab } = useParams()
  const IS_REFERENCE_DETAILS = !!useMatch(
    '/candidate/profile/references/reference-details'
  )

  const { fetchProfile } = useProfileContext()
  const IS_MOBILE = useMediaQuery('(max-width: 800px)')

  useEffect(() => {
    fetchProfile()
  }, [])

  const MainContainer = () => (
    <S.Container gutter={0}>
      <S.ContainerRow>
        <S.ContentOverview s={12} m={3}>
          <S.ProfileOverview />
        </S.ContentOverview>
        <S.ContentColumn s={12} m={12} l={9}>
          {!IS_REFERENCE_DETAILS && <ProfileScreenTabs />}
          <S.ContentContainer>
            {TAB_CONTENT[tab || DEFAULT_TAB]}
          </S.ContentContainer>
        </S.ContentColumn>
      </S.ContainerRow>
    </S.Container>
  )

  const DesktopView = () => (
    <StyledWrapper data-testid={testId} {...rest}>
      <NavigationBar />
      <ProfileCompletion />
      <MainContainer />
      <Footer />
    </StyledWrapper>
  )

  return IS_MOBILE ? (
    <MobileView
      sideBar={<MobileSideBar />}
      Container={MainContainer}
      ProfileCompletionChildren={<ProfileCompletion />}
    />
  ) : (
    <DesktopView />
  )
}

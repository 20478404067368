import styled from 'styled-components'
import { EditButton } from '@components/atoms/EditButton'

export const Container = styled.div`
  width: 100%;
  padding: 40px;
`
export const CertificationDate = styled.span`
  color: #8e97a0;
  font-weight: 400;
`

export const Edit = styled(EditButton)`
  display: inline-flex;
  float: right;
`

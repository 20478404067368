import * as S from './IndustriesProfileCard.styles'
import { IndustriesProfileCardProps } from './interfaces'
import { BuildingIcon } from '@commons/Icons'
import { useIndustriesOfExperience } from '@hooks/experience/useIndustriesOfExperience'
import { useProfileContext } from '@hooks/profile/ProfileContextProvider'
import { useState } from 'react'
import { Pill } from '@components/atoms/Pill'
import { BuildingSymbol } from '@commons/Icons'
import { PillViewContainer } from '@components/atoms/ComputerSkillsProfileCard/ComputerSkillsProfileCard.styles'
import { capitalize } from 'lodash'

export const IndustriesProfileCard = ({
  testId = 'industries-profile-card-id'
}: IndustriesProfileCardProps) => {
  const { update } = useIndustriesOfExperience()
  const { profile, fetchProfile } = useProfileContext()
  const [edit, setEdit] = useState(false)
  const industries = edit ? [] : profile.industries_of_experiences || []

  return (
    <S.Card data-testid={testId}>
      {(industries?.length || edit) && (
        <S.TitleBar
          edit={edit}
          title="Industries of experience"
          onEditButtonClick={() => setEdit(true)}
          icon={<BuildingSymbol />}
        />
      )}

      {!edit && industries?.length < 1 && (
        <S.Placeholder
          icon={<BuildingIcon />}
          title="Industries of experience"
          subtitle="What are industries that you have the most experience in?"
          callToAction="Add industries"
          onClick={() => {
            setEdit(true)
          }}
        />
      )}

      <S.PillContainer>
        {industries?.map((industry) => (
          <Pill key={industry.id} maxLength={45}>
            {capitalize(industry.ocupation)}
          </Pill>
        ))}
      </S.PillContainer>

      {edit && (
        <S.Form
          testId={`${testId}-form`}
          onSubmit={async (data) => {
            await update(data.industries_of_experience.map((item) => item.id))
            fetchProfile()
            setEdit(false)
          }}
          initialValues={profile.industries_of_experiences}
          onCancel={() => setEdit(false)}
        ></S.Form>
      )}
    </S.Card>
  )
}

export const IndustriesCard = ({ profile }) => {
  return (
    <>
      {profile?.industries_of_experiences?.length ? (
        <>
          <S.TitleBar
            title="Industries of experience"
            icon={<BuildingSymbol />}
          />
          <PillViewContainer>
            {profile?.industries_of_experiences?.map((industry) => (
              <Pill key={industry.id}>{capitalize(industry.ocupation)}</Pill>
            ))}
          </PillViewContainer>
        </>
      ) : (
        <S.Placeholder
          icon={<BuildingIcon />}
          title="Industries of experience"
          subtitle="What are industries that you have the most experience in?"
        />
      )}
    </>
  )
}

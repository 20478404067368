import theme from '@styles/theme'
import { FaSpinner } from 'react-icons/fa'
import styled, { css } from 'styled-components'
import { ButtonProps } from './interfaces'

export type Styling = 'primary' | 'secondary' | 'link'
const isMobile = window?.screen?.width < 700

const colorMap = {
  primary: {
    text: theme.colors.shades.primary500,
    bg: theme.colors.shades.secondary500,
    pressed: {
      bg: theme.colors.shades.primary500,
      text: theme.colors.shades.secondary500
    },
    hover: {
      bg: theme.colors.shades.primary500,
      text: theme.colors.primaryWhite
    }
  },
  secondary: {
    text: theme.colors.shades.primary500,
    bg: 'transparent',
    pressed: {
      bg: 'transparent',
      text: theme.colors.shades.primary500
    },
    hover: {
      bg: 'transparent',
      text: theme.colors.shades.primary500
    }
  },
  link: {
    text: theme.colors.primaryBlue,
    bg: 'transparent',
    pressed: {
      bg: 'transparent',
      text: theme.colors.shades.primary500
    },
    hover: {
      bg: 'transparent',
      text: theme.colors.shades.primary500
    }
  }
}

export const buttonStyle = css<ButtonProps>`
  text-decoration: none;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  width: ${({ size }) => {
    if (size === 'full') {
      return '100%'
    }

    if (size === 'large') {
      return '180px'
    }

    return ''
  }};

  padding: ${({ size, height }) => {
    if (isMobile && !height) {
      return '12px 24px'
    }
    return size === 'large' || height === 'large' ? '18px 32px' : '12px 24px'
  }};

  cursor: pointer;
  border-radius: ${({ theme }) => theme.border.button};
  color: ${({ styling = 'primary' }) => colorMap[styling].text};
  border: 1px solid transparent;
  outline: 0;
  transition: ${({ theme }) => theme.transition.fast};
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.02em;
  height: ${({ size }) => (size === 'small' ? '48px' : '56px')};
  transition: box-shadow 0.3s ease-in-out;

  ${({ styling = 'primary' }) => {
    const { bg } = colorMap[styling]
    return css`
      background-color: ${bg};
    `
  }}

  ${({ styling }) => {
    const isSecondary = styling === 'secondary'

    const secondaryHover = css`
      &:hover {
        box-shadow: 0px 0px 9px rgba(77, 226, 149, 0.79);
      }

      &:focus {
        background-color: #dbf9ea;
      }

      border: 2px solid #14202d;
      box-sizing: border-box;
      border-radius: 8px;
    `

    return isSecondary && secondaryHover
  }}

${({ styling }) => {
    const isLink = styling === 'link'
    return (
      isLink &&
      css`
        border-bottom: 2px solid
          ${({ theme }) => theme.colors.shades.secondary500};
        padding: 0;
        border-radius: 0;
        width: initial;
        font-size: 16px;
        font-weight: 600;
        line-height: 17.6px;
        letter-spacing: 0.02em;
        padding-bottom: 4px;
        height: auto;

        &:hover {
          background: ${colorMap[styling].hover['bg']};
          color: ${colorMap[styling].hover['text']};
          border-bottom: 2px solid
            ${({ theme }) => theme.colors.shades.primary500};
        }
      `
    )
  }}

  ${({ disabled, styling = 'primary' }) => {
    const disabledStyles = css<ButtonProps>`
      cursor: not-allowed;
      background: ${({ theme }) => theme.colors.shades.gray700};
      color: ${({ theme }) => theme.colors.shades.primary300};
    `
    const enabledStyles = css`
      &:hover {
        background: ${colorMap[styling].hover['bg']};
        color: ${colorMap[styling].hover['text']};
      }
      &:active {
        background: ${colorMap[styling].pressed.bg};
        color: ${colorMap[styling].pressed.text};
      }
    `
    return disabled ? disabledStyles : enabledStyles
  }}
`

export const Spinner = styled(FaSpinner)`
  animation: spin infinite 1s linear;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`

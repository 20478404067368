import {
  yupPassword,
  yupPasswordConfirmation
} from '@components/commons/Form/validations'
import { Button } from '@components/atoms/Button'
import { PasswordField } from '@components/atoms/PasswordField'
import { ValidationChecker } from '@components/molecules/ValidationChecker'
import { useUserContext } from '@hooks/user/UserContextProvider'
import * as utils from '@utils/utils'
import { Form, Formik, FormikProps } from 'formik'
import { useState } from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'
import * as Yup from 'yup'
import * as S from '../FormCommons/Form.styles'

interface Values {
  password: string
  confirmPassword: string
}

const idContext = `candidate-change-password-form`

const FormWrapper = styled.div`
  width: 472px;
`

const Header = styled(S.Header)`
  ${media.lessThan('small')`
    > h1,
    p {
      text-align: center;
    }
  `}
`

const CheckerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  margin: 20px 0;
`

export const ButtonWrapper = styled.div`
  > button {
    font-size: 18px;
    line-height: 19.8px;
  }
`

const validationSchema = () => {
  return Yup.object().shape({
    password: yupPassword,
    confirmPassword: yupPasswordConfirmation
  })
}

export const ChangePasswordForm = ({
  testId = 'candidate-change-password-form',
  passwordToken = ''
}) => {
  const { resetUserPassword } = useUserContext()

  const [isCharactersValid, setIsCharactersValid] = useState(false)
  const [isUppercaseValid, setIsUppercaseValid] = useState(false)
  const [isSpecialCharsValid, setSpecialCharsValid] = useState(false)
  const [isNumberValid, setIsNumberValid] = useState(false)

  const _validatePassword = (password) => {
    setIsCharactersValid(utils.hasMoreOrEqualCharactersThan(password, 8))
    setIsUppercaseValid(utils.hasUpperCase(password))
    setSpecialCharsValid(utils.hasSpecialChars(password))
    setIsNumberValid(utils.hasNumber(password))
  }

  return (
    <FormWrapper data-testid={testId}>
      <Header>
        <S.Heading>Change password</S.Heading>
        <S.Text>Enter your new password here</S.Text>
      </Header>
      <S.Body>
        <Formik
          validationSchema={validationSchema}
          initialValues={{
            password: '',
            confirmPassword: ''
          }}
          onSubmit={async (values, actions) => {
            const { password, confirmPassword } = values
            await resetUserPassword(passwordToken, password, confirmPassword)
            actions.setSubmitting(false)
          }}
        >
          {(props: FormikProps<Values>) => (
            <Form>
              <S.InputWrapper>
                <PasswordField
                  validate={_validatePassword}
                  name="password"
                  label="Password *"
                  id={`password-${idContext}`}
                  placeholder="New password"
                  value={props.values.password}
                  {...props}
                />
              </S.InputWrapper>

              <S.InputWrapper>
                <PasswordField
                  name="confirmPassword"
                  label="Confirm Password *"
                  id={`confirm-password-${idContext}`}
                  placeholder="Confirm new password"
                  {...props}
                />
              </S.InputWrapper>

              <CheckerWrapper>
                <ValidationChecker isValid={isCharactersValid}>
                  At least 8 characters
                </ValidationChecker>
                <ValidationChecker isValid={isUppercaseValid}>
                  Add uppercase letters
                </ValidationChecker>
                <ValidationChecker isValid={isSpecialCharsValid}>
                  Add special characters
                </ValidationChecker>
                <ValidationChecker isValid={isNumberValid}>
                  Add numbers
                </ValidationChecker>
              </CheckerWrapper>

              <Button
                disabled={!props.isValid}
                size="full"
                height="large"
                type="submit"
                loading={props.isSubmitting}
              >
                Reset password
              </Button>
            </Form>
          )}
        </Formik>
      </S.Body>
    </FormWrapper>
  )
}

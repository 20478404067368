import { yupTextArea } from '@commons/Form/validations'
import { TextArea } from '@components/atoms/TextArea'
import * as Yup from 'yup'
import * as S from '../commons/commons.styles'

interface Values {
  noteworthyAccomplishment: string
  biggestWorkedProject: string
}
export const initialValues = {
  noteworthyAccomplishment: '',
  biggestWorkedProject: ''
}

const idContext = `candidate-application-form-7`
// const CURRENT_STEP = 7

export const validationSchema = () => {
  return Yup.object().shape({
    noteworthyAccomplishment: yupTextArea,
    biggestWorkedProject: yupTextArea
  })
}

export const Step7 = ({ testId = 'application-Step7-test-id', props }: any) => {
  return (
    <S.FormWrapper data-testid={testId}>
      <S.Body>
        <S.InputWrapper>
          <TextArea
            label="My biggest, and most noteworthy, professional accomplishment is?"
            id={`career-track-${idContext}`}
            placeholder="Your answer here..."
            name="noteworthyAccomplishment"
            data-testid="input"
            {...props}
          />
        </S.InputWrapper>
        <S.InputWrapper>
          <TextArea
            label="In 500 words or less, describe the BIGGEST project/task that you've ever worked on?"
            id={`biggest-professional-${idContext}`}
            placeholder="Your answer here..."
            name="biggestWorkedProject"
            data-testid="input"
            {...props}
          />
        </S.InputWrapper>
      </S.Body>
    </S.FormWrapper>
  )
}

import { Rating as MUIRating } from '@mui/material'
import { styled } from '@mui/system'
import { colors } from '@styles/colors'

const isMobile = window?.screen?.width < 700
const bigSizes = isMobile
  ? {
      margin: '0 5.33px'
    }
  : {
      margin: '0 4.57px'
    }

export const StyledRating = styled(MUIRating)(({ gray }: any) => ({
  '& .MuiRating-iconEmpty': {
    color: colors.shades.gray500,
    ...bigSizes
  },

  '& .MuiRating-iconFilled': {
    color: gray ? colors.shades.primary300 : colors.shades.secondary500,
    ...bigSizes
  },

  '& .MuiRating-iconHover': {
    color: gray ? colors.shades.primary300 : colors.shades.secondary500,
    ...bigSizes
  }
}))

import desktopIllustration from '@assets/not_found/bg_desktop.png'
import mobileIllustration from '@assets/not_found/bg_mobile.png'

import { Button as OriginalButton } from '@components/atoms/Button'
import styled from 'styled-components'

export const Container = styled.div`
  height: 100vh;
  min-height: 700px;
  background: linear-gradient(
      72.72deg,
      rgba(230, 235, 240, 0.4) 1.76%,
      rgba(255, 255, 255, 0) 61.63%
    ),
    #ffffff;
  color: ${({ theme }) => theme.colors.primaryBlue};

  @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
    min-height: initial;
  }
`

export const MainWrapper = styled.div`
  padding: 56px 97px;

  @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
    display: flex;
    padding: 0 24px;
  }
`

export const LogoWrapper = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
    display: none;
  }
`

export const ContentWrapper = styled.div`
  z-index: 1;
  position: relative;
  margin-top: 235px;

  @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
    margin-top: 353px;
  }

  /* MOBILE ULTRA SMALL */
  @media (max-width: 350px) {
    margin-top: 270px;
  }
`

export const Title = styled.h1`
  font-size: 64px;
  font-weight: 600;
  line-height: 70px;
  letter-spacing: 0em;
  margin-bottom: 24px;

  @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
    font-size: 32px;
    font-weight: 600;
    line-height: 35px;
    letter-spacing: 0em;
  }
`

export const SubTitle = styled.p`
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.02em;
  margin-bottom: 40px;

  @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    margin-bottom: 32px;
  }
`

export const Button = styled(OriginalButton)`
  height: 56px;
  width: 193px;

  @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
    width: 100%;
  }
`

export const DesktopIllustration = styled.div`
  z-index: 0;

  display: block;
  position: absolute;
  height: 100%;
  width: 95%;

  background-image: url(${desktopIllustration});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 70%;

  @media (max-width: 1600px) {
    background-position: right;
  }

  @media (min-width: 1600px) {
    width: 90%;
    background-position: right;
  }

  @media (max-width: 1100px) {
    display: none;
  }
`

export const MobileIllustration = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;

  background-image: url(${mobileIllustration});
  background-repeat: no-repeat;
  background-position: top center;

  /* MOBILE ULTRA SMALL */
  @media (max-width: 350px) {
    background-size: 70%;
  }

  /* DESKTOP */
  @media (min-width: ${({ theme }) => theme.breakpoints.phone}px) {
    display: none;
  }
`

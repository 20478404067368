import { useEffect, useReducer } from 'react'
import { ACTION_TYPES } from './constants'
import { OrderDirection, Query } from './interfaces'
import { searchQueryReducer } from './utils'

export const usePaginationQuery = (
  fetchData: Query,
  initialQuery = '',
  initialPage = 1,
  initialOrderBy = '',
  initialOrderDirection: OrderDirection = 'asc'
) => {
  const [state, dispatch] = useReducer(searchQueryReducer, {
    search: initialQuery,
    page: initialPage,
    order_by: initialOrderBy,
    order_direction: initialOrderDirection
  })

  useEffect(() => {
    const queryString = `?search=${state.search}&page=${state.page}&order_by=${state.order_by}&order_direction=${state.order_direction}`
    fetchData(queryString)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.search, state.page, state.order_by, state.order_direction])

  const setSearchQuery = (newQuery: string) => {
    dispatch({ type: ACTION_TYPES.SET_SEARCH_QUERY, payload: newQuery })
  }

  const setPage = (newPage: number) => {
    dispatch({ type: ACTION_TYPES.SET_PAGE, payload: newPage })
  }

  const setOrderBy = (newOrderBy: string) => {
    dispatch({ type: ACTION_TYPES.SET_ORDER_BY, payload: newOrderBy })
  }

  const setOrderDirection = (newOrderDirection: OrderDirection) => {
    dispatch({
      type: ACTION_TYPES.SET_ORDER_DIRECTION,
      payload: newOrderDirection
    })
  }

  return {
    currentSearch: state.search,
    currentPage: state.page,
    currentOrder: state.order_by,
    currentOrderDirection: state.order_direction,
    setSearchQuery,
    setPage,
    setOrderBy,
    setOrderDirection
  }
}

import api from '@api/index'
import { useUserContext } from '@hooks/user/UserContextProvider'
import React, { useContext, useState } from 'react'
import { State, Profile } from './interfaces'

const ProfileContext = React.createContext<State>({} as State)

export const ProfileContextProvider: React.FC = ({ children }) => {
  const [profile, setProfile] = useState<Profile>({} as Profile)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<any>(null)

  const { logOut } = useUserContext()

  const fetchProfile = async () => {
    setLoading(true)
    try {
      const res = await api.get(`/users/profile`)
      setProfile(res?.data)
    } catch (error: any) {
      if (error?.response?.status === 401) logOut()
      setError(error?.response?.data?.message)
    } finally {
      setLoading(false)
    }
  }

  const fetchPublicProfile = async (profileHash) => {
    setLoading(true)
    try {
      const res = await api.get(`/candidates/${profileHash}/public_view`)
      setProfile(res?.data)
    } catch (error: any) {
      setError(error?.response?.data?.message)
    } finally {
      setLoading(false)
    }
  }

  const value = {
    profile,
    fetchProfile,
    fetchPublicProfile,
    loading,
    error
  }

  return (
    <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>
  )
}

export const useProfileContext = () => {
  const context = useContext(ProfileContext)
  if (typeof context === 'undefined') {
    throw new Error('useProfile must be used within a ProfileContext')
  }
  return context
}

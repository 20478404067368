import { GlobalH5 } from './../../atoms/Text/Text.styles'
import { Button as OriginalButton } from '@components/atoms/Button'
import { BodyMB, BodySMB, BodySMR } from '@components/atoms/Text/Text.styles'
import { UserPicture as OriginalUserPicture } from '@components/atoms/UserPicture'
import { CareerInformationSection as OriginalCareerSection } from '@components/molecules/CareerInformationSection'
import styled from 'styled-components'

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.primaryBlue};
  color: ${({ theme }) => theme.colors.primaryWhite};
`

export const Main = styled.main`
  background-color: ${({ theme }) => theme.colors.shades.gray300};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const FloatingButton = styled(OriginalButton)`
  position: absolute;
  top: 688px;
  right: 96px;
  padding: 0;

  border-radius: 50%;
  height: 56px;
  width: 56px;

  @media (max-width: 1100px) {
    display: none;
  }
`

export const SubmitWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
  margin-bottom: 90px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin: 48px 24px;
  }
`

export const Button = styled(OriginalButton)`
  padding: 18px 32px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 100%;
  }
`

export const BodyWrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 72px;
  width: 821px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 100%;
  }

  form {
    width: 100%;
  }
`

export const Header = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 535px;
  gap: 24px;
  margin-bottom: 48px;

  > h1 {
    width: 450px;
  }

  p,
  h1 {
    text-align: center;
  }

  b {
    ${BodyMB}
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 100%;
    padding: 0 24px;

    h1 {
      ${GlobalH5}
      text-align: center;
      width: 270px;
    }

    p {
      ${BodySMR}
      text-align: center;
    }

    b {
      ${BodySMB}
    }
  }
`

export const UserPicture = styled(OriginalUserPicture)`
  height: 140px !important;
  width: 140px !important;

  border: 4px solid ${({ theme }) => theme.colors.shades.secondary500};
`

export const CareerInformationSection = styled(OriginalCareerSection)`
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    background-color: white;

    > div:first-child {
      padding: 24px 0;
      margin: 0 24px;
      border-bottom: 1px solid ${({ theme }) => theme.colors.shades.gray500};
    }

    div {
      padding: 24px;
      border-radius: 0;
      margin: 0;
    }

    div + div {
      margin: 0;
      padding: 0;
    }
  }
`

export const GeneralInformationSection = styled(CareerInformationSection)`
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    > div:first-child {
      padding: 24px 0;
      margin: 0 24px;
      border-bottom: 1px solid ${({ theme }) => theme.colors.shades.gray500};
    }

    div {
      padding: 0;
      border-bottom: none;
    }

    > div + div {
      margin: 0;
      padding: 24px;
    }
  }
`

export const EvaluationSection = styled(GeneralInformationSection)`
  > div + div {
    padding-top: 8px;

    div:first-child {
      padding-top: 0px;
      margin-top: 0;
    }

    div:last-child {
      padding: 0;
      padding-top: 32px;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    > div + div {
      div {
        padding: 24px 0;
        margin: 0;
      }

      div:first-child {
        padding-top: 0px;
        margin-top: 0;
      }

      div:last-child {
        padding: 0;
        padding-top: 24px;
      }
    }
  }
`

export const EvaluationWrapper = styled.div`
  margin: 0;

  div:first-child {
    border: none;
  }
`

export const EvaluationItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px 0;
  border-top: 1px solid ${({ theme }) => theme.colors.shades.gray500};
`

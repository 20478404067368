import { Text } from '@components/atoms/Text'
import { ProgressProps } from './interfaces'
import * as S from './Progress.styles'

const getStepPosition = (steps: number, stepIndex: number) => {
  const position = (100 / (steps - 1)) * stepIndex
  if (position < 0) {
    return 0
  }
  if (position > 100) {
    return 100
  }
  return position
}

export const Progress = ({
  totalSteps,
  currentStep,
  styling = 'slash',
  text = '',
  progressColor,
  showPercentageOnMobile = false,
  testId = 'progress-id',
  ...rest
}: ProgressProps) => {
  const IS_PERCENTAGE = styling === 'percentage'
  return (
    <S.ProgressWrapper showPercentageOnMobile={showPercentageOnMobile}>
      {!IS_PERCENTAGE && (
        <Text type="BodyMB" className="slash">
          {currentStep} / {totalSteps}
        </Text>
      )}

      {IS_PERCENTAGE && (
        <Text type="BodyMB" className="percentage">
          {text} {currentStep}%
        </Text>
      )}

      <S.ProgressBar
        data-testid={testId}
        role="progressbar"
        aria-valuemax={totalSteps}
        aria-valuenow={currentStep}
        aria-label={`Static progress bar showing step ${currentStep} of ${totalSteps}`}
        {...rest}
      >
        <S.Progression
          position={getStepPosition(totalSteps, currentStep - 0.5)}
          progressColor={progressColor}
        ></S.Progression>
      </S.ProgressBar>
    </S.ProgressWrapper>
  )
}

import { colors } from '@styles/colors'
import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import { ProgressionProps } from './interfaces'

export const ProgressWrapper = styled.div<any>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  & p {
    white-space: nowrap;
  }

  & .percentage {
    ${({ showPercentageOnMobile }) =>
      media.lessThan('small') &&
      !showPercentageOnMobile &&
      css`
        display: none;
      `}
  }
`

export const ProgressBar = styled.div`
  position: relative;
  width: 100%;
  border-radius: 100px;
  background: ${({ theme }) => theme.colors.shades.gray500};
  height: 4px;
  margin-left: 16px;
`

export const Progression = styled.div<ProgressionProps>`
  width: ${({ position }) => `${position}%`};
  position: relative;
  border-radius: 100px;
  height: 4px;
  display: block;
  background: ${({ progressColor = colors.shades.secondary500 }) =>
    progressColor};
  transition: width 0.5s ease-in-out;
`

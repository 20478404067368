import { GreenChecker } from '@components/atoms/GreenChecker'
import * as S from './ValidationChecker.styles'

export type ValidationCheckerProps = {
  testId?: string
  children?: React.ReactNode
  isValid?: boolean
}

export const ValidationChecker = ({
  testId = 'validation-checker-id',
  isValid = false,
  children,
  ...rest
}: ValidationCheckerProps) => {
  return (
    <S.Wrapper data-testid={testId}>
      <GreenChecker
        size={24}
        styling={isValid ? 'success' : 'error'}
        {...rest}
      />
      <S.Text>{children}</S.Text>
    </S.Wrapper>
  )
}

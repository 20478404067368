import styled from 'styled-components'
import { ProfileCard } from '@components/atoms/ProfileCard'
import { ComputerSkillsForm } from '@components/organisms/ComputerSkillsForm'
import { ProfileCardTitleBar } from '@components/molecules/ProfileCardTitleBar'

export const Card = styled(ProfileCard)`
  padding-top: 2px;
  padding-bottom: 0;
  height: auto;
`

export const Form = styled(ComputerSkillsForm)``
export const PillContainer = styled.div`
  padding-top: 28px;
  padding-left: 40px;
  padding-bottom: 15px;
`

export const PillViewContainer = styled.div`
  padding: 40px;
`

export const TitleBar = styled(ProfileCardTitleBar)`
  top: 0;
  height: 72px !important;
`

import * as S from '../FormCommons/Form.styles'
import { WorkSkillsFormProps } from './interfaces'

import { useState } from 'react'
import { Button } from '@components/atoms/Button'
import { AutocompleteSelect } from '@components/molecules/AutocompleteSelect'

export const WorkSkillsForm = ({
  testId = 'work-skills-form-id',
  onSubmit,
  options,
  onCancel,
  initialValues,
  ...props
}: WorkSkillsFormProps) => {
  const [selectedItems, setSelectedItems] = useState<any>([])

  return (
    <S.PaddedFormContainer data-testid={testId} {...props}>
      <AutocompleteSelect
        onChange={(items) => setSelectedItems(items)}
        showAll={true}
        options={options}
        placeholder="Choose an option"
        limitReachedText="You reached the max number of skills"
        max={10}
        initialValues={initialValues}
      />

      <S.ButtonsWrapper>
        <Button styling="secondary" type="button" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          type="submit"
          testId="fieldId"
          onClick={() =>
            onSubmit({
              work_skills: selectedItems
            })
          }
        >
          Save
        </Button>
      </S.ButtonsWrapper>
    </S.PaddedFormContainer>
  )
}

import { BackIcon, ShareIcon } from '@commons/Icons'
import { Alert } from '@components/atoms/Alert'
import { Modal } from '@components/atoms/Modal'
import { Pill } from '@components/atoms/Pill'
import { Text } from '@components/atoms/Text'
import { RecruiterBaseLayout } from '@components/layout/RecruiterBaseLayout'
import { CareerInformationSection } from '@components/molecules/CareerInformationSection'
import { AssignationSection } from '@components/molecules/CareerInformationSection/CareerInformationSection.styles'
import { Progress } from '@components/molecules/Progress'
import { CandidateProfileDetails } from '@components/organisms/CandidateProfileDetails'
import { ModalContentPanel } from '@components/organisms/ModalContentPanel'
import { useArchive } from '@hooks/candidate/useArchive'
import { useRecruiterContext } from '@hooks/recruiter/RecruiterContextProvider'
import { useMediaQuery } from '@mui/material'
import { get, startCase } from 'lodash'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import * as S from './RecruiterCandidateProfileView.styles'
import { RecruiterProfileScreenTabs, TabContent } from './Tabs'

export interface RecruiterCandidateProfileViewProps {
  testId?: string
}

export const RecruiterCandidateProfileView = ({
  testId = 'recruiter-candidate-profile-view-id',
  ...props
}: RecruiterCandidateProfileViewProps) => {
  const { id, tab } = useParams()
  const { fetchCandidate } = useRecruiterContext()

  const IS_MOBILE = useMediaQuery('(max-width: 800px)')

  const [isModalOpen, setIsModalOpen] = useState(false)
  const handleOpenModal = () => setIsModalOpen(true)
  const handleCloseModal = () => setIsModalOpen(false)

  const navigate = useNavigate()

  const [candidateInfo, setCandidateInfo] = useState<any | null>()
  const fetchCandidateInfo = async () => {
    const data = await fetchCandidate({ id })
    setCandidateInfo(data)
  }

  useEffect(() => {
    fetchCandidateInfo()
  }, [])

  const CANDIDATE_URL = `${window.location.host}/candidate/public/profile/${candidateInfo?.secret_id}/about-me`
  const IS_ARCHIVED = candidateInfo?.status === 'archived'
  const { archive, unarchive } = useArchive()
  const _handleArchive = async () => {
    await archive(id)
    fetchCandidateInfo()
  }

  const _handleUnarchive = async () => {
    await unarchive(id)
    fetchCandidateInfo()
  }

  const TabSection = () => {
    if (!tab) return null
    const selectedTab = TabContent[tab]
    const tabContent = selectedTab(candidateInfo?.profile, fetchCandidateInfo)
    return tabContent
  }

  const assignationSection = (
    <CareerInformationSection>
      <AssignationSection>
        <section>
          <S.ProgressWrapper>
            <Text type="BodyMB" as="h1">
              Profile completion
            </Text>
            <Progress
              showPercentageOnMobile
              totalSteps={100}
              currentStep={candidateInfo?.completion || 0}
              styling="percentage"
            />
          </S.ProgressWrapper>
        </section>

        <section>
          <Text type="BodyMB" as="h1">
            Career status
          </Text>

          <Pill>
            {startCase(
              get(candidateInfo, 'profile.job_status.job_search', '')
            ) || '-'}
          </Pill>
        </section>
        <section>
          <Text type="BodyMB" as="h1">
            Relocation
          </Text>

          <Pill>
            {startCase(
              get(candidateInfo, 'profile.job_status.where_to_relocate', '')
            ) || '-'}
          </Pill>
        </section>
        <section>
          <Text type="BodyMB" as="h1">
            Employment status
          </Text>

          <Pill>
            {startCase(
              get(candidateInfo, 'profile.job_status.employment_status', '')
            ) || '-'}
          </Pill>
        </section>
        <section>
          <Text type="BodyMB" as="h1">
            Job title
          </Text>

          <Pill>
            {startCase(
              get(candidateInfo, 'profile.job_status.job_title', '')
            ) || '-'}
          </Pill>
        </section>
      </AssignationSection>
    </CareerInformationSection>
  )

  return (
    <RecruiterBaseLayout data-testid={testId} {...props}>
      <S.Container>
        <S.BackButton onClick={() => navigate('/recruiter/candidate/all')}>
          <BackIcon /> All Candidates
        </S.BackButton>

        {IS_ARCHIVED ? (
          <S.AlertWrapper>
            <Alert
              severity="warning"
              subtitle="To share it, you’ll have to unarchive it and make it public again."
            >
              This profile is archived. &nbsp;
            </Alert>
          </S.AlertWrapper>
        ) : null}

        <S.Header>
          {candidateInfo && (
            <CandidateProfileDetails
              name={candidateInfo?.profile?.general_info?.first_name}
              email={candidateInfo?.profile?.general_info?.email}
              profilePicture={
                candidateInfo?.profile?.general_info?.profile_picture_url
              }
              phone={candidateInfo?.profile?.general_info?.phone}
              location={candidateInfo?.profile?.general_info?.city}
            />
          )}

          <S.ButtonWrapper>
            {IS_ARCHIVED ? (
              <S.Button
                unarchive
                styling="secondary"
                onClick={_handleUnarchive}
              >
                Unarchive
              </S.Button>
            ) : (
              <>
                <S.Button styling="secondary" onClick={_handleArchive}>
                  Archive
                </S.Button>
                <S.Button styling="primary" onClick={handleOpenModal}>
                  Share
                </S.Button>
              </>
            )}
          </S.ButtonWrapper>
        </S.Header>

        {IS_MOBILE ? (
          <>
            <S.InformationWrapper>{assignationSection}</S.InformationWrapper>

            <S.TabsWrapper>
              <RecruiterProfileScreenTabs />
            </S.TabsWrapper>

            <S.InformationWrapper>
              <TabSection />
            </S.InformationWrapper>
          </>
        ) : (
          <>
            <S.TabsWrapper>
              <RecruiterProfileScreenTabs />
            </S.TabsWrapper>

            <S.InformationWrapper>
              <TabSection />

              {assignationSection}
            </S.InformationWrapper>
          </>
        )}
      </S.Container>

      <Modal isOpen={isModalOpen} handleClose={handleCloseModal}>
        <ModalContentPanel
          Icon={<ShareIcon />}
          title="Share profile"
          body={
            <Text
              type="BodyMR"
              style={{ display: 'inline-block', textAlign: 'center' }}
            >
              Share the following link to employers that might be a fit to your
              candidate
            </Text>
          }
          textToCopy={CANDIDATE_URL}
        />
      </Modal>
    </RecruiterBaseLayout>
  )
}

import { SelectProps } from './interfaces'
import * as S from './Select.styles'
import useFormikField from '@commons/Form/hooks/useFormikField'
import { ErrorMessage, Label } from '../InputText/InputText.styles'
import { ArrowDown } from '@commons/Icons'

export const Select = ({
  testId = 'select-id',
  options = [],
  placeholder = '',
  id = 'select-id',
  label,
  disabled,
  endIcon,
  ...props
}: SelectProps) => {
  const { hasError, value, setValue, errorMsg, touched, setTouched } =
    useFormikField(props.name)

  return (
    <>
      {label && <Label>{label}</Label>}
      <S.CustomSelect
        data-testid={testId}
        id={id}
        defaultValue=""
        displayEmpty
        value={value}
        onChange={(event) => {
          setValue(event.target.value)
        }}
        onBlur={() => setTouched(true)}
        renderValue={(value: any) => {
          const HAS_VALUE = value !== undefined && value !== ''
          return HAS_VALUE ? (
            options.find((it) => it.value === value)?.label
          ) : (
            <span className="placeholder">{placeholder}</span>
          )
        }}
        MenuProps={{
          PaperProps: {
            style: S.MenuStyle
          }
        }}
        IconComponent={(props) =>
          endIcon ? (
            <S.IconWrapper {...props}>{endIcon}</S.IconWrapper>
          ) : (
            <ArrowDown width={24} height={24} {...props} />
          )
        }
        error={hasError}
        disabled={disabled}
      >
        {options?.map((option) => (
          <S.CustomMenuItem value={option.value} key={option.label}>
            {option.label}
          </S.CustomMenuItem>
        ))}
      </S.CustomSelect>

      {hasError && touched ? <ErrorMessage>{errorMsg}</ErrorMessage> : null}
    </>
  )
}

import styled from 'styled-components'
import media from 'styled-media-query'
const isMobile = window?.screen?.width < 700

import * as OriginalStyles from '../AccountConfirmation/AccountConfirmation.styles'

export const Container = styled(OriginalStyles.Container)`
  @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
    height: 100%;
  }
`

export const LogoWrapper = styled(OriginalStyles.LogoWrapper)`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  height: fit-content;

  ${media.lessThan('small')`
		height: 84px;
	`}
`
export const LogoArea = OriginalStyles.LogoArea
export const Logo = styled(OriginalStyles.Logo)`
  display: flex;
`
export const LeftBackground = OriginalStyles.LeftBackground
export const RightBackground = OriginalStyles.RightBackground

export const GradientBackground = styled(OriginalStyles.GradientBackground)`
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  ${media.lessThan('small')`
		justify-content: flex-start;
	`}
`

export const MobileBackground = styled(OriginalStyles.MobileBackground)`
  display: none;
`

export const RoundedBox = styled(OriginalStyles.RoundedBox).attrs({
  topLeft: true,
  topRight: true,
  bottomLeft: isMobile ? false : true,
  bottomRight: isMobile ? false : true
})`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 600px;
  max-height: 870px;
  height: fit-content;
  padding: 72px;
  z-index: 10;

  ${media.lessThan('small')`
		align-items: flex-start;
		height: 100%;
		max-height: 100%;
		padding: 40px 24px 100px 24px;
	`}
`

import { TextProps } from './interfaces'
import * as S from './Text.styles'

export const Text = ({
  testId = 'component-Text-id',
  as = 'p',
  ...props
}: TextProps) => {
  return <S.StyledText data-testid={testId} as={as} {...props} />
}

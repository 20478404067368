import styled from 'styled-components'
import { BodySMB } from '@components/atoms/Text/Text.styles'

export const Table = styled.table`
  overflow-x: auto;
  background-color: white;
  margin-top: 15px;
  width: 100%;
  text-align: left;
  overflow: hidden;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  border-collapse: collapse;

  & th {
    padding: 10px 24px;
    border-bottom: 2px solid #f2f5f7;
    background-color: white;

    ${BodySMB}
  }

  & tr {
    &:hover {
      background: rgba(242, 245, 247, 0.56);
    }

    td {
      padding: 13px 24px;
    }

    td:first-child {
      border-radius: 10px 0 0 10px;
    }

    td:last-child {
      border-radius: 0 10px 10px 0;
      text-align: right;
    }
  }
`

import { CompanyValuesProfileCardProps } from './interfaces'
import { FlagIcon, CompanyValuesIcon } from '@commons/Icons'
import { ProfileCardPlaceholder } from '@components/molecules/ProfileCardPlaceholder'
import { useEffect, useState } from 'react'
import { useProfileContext } from '@hooks/profile/ProfileContextProvider'
import { Card, CardContent } from '../commons/Card.styles'
import { ProfileCardTitleBar } from '@components/molecules/ProfileCardTitleBar'
import { CompanyValuesForm } from '@components/organisms/CompanyValuesForm'
import { useGetWorkplace, useWorkplace } from '@hooks/workplace/useWorkplace'
import { WorkplaceValues } from '../commons/WorkplaceValues'
import formatDescription from '../commons/FormatDescription'

export const CompanyValuesProfileCard = ({
  testId = 'company-values-profile-card-id'
}: CompanyValuesProfileCardProps) => {
  const [edit, setEdit] = useState(false)
  const { profile, fetchProfile } = useProfileContext()
  const { loading, data: companyValues } = useGetWorkplace('/company_values')
  const { response, update: updateCompanyValues } =
    useWorkplace('company_values_ids')

  const update = () => {
    fetchProfile()
    setEdit(false)
  }

  useEffect(() => {
    if (response?.status === 200) update()
  }, [response])

  return (
    <Card data-testid={testId}>
      {edit ? (
        <>
          <ProfileCardTitleBar
            edit={true}
            title="Company values"
            icon={<CompanyValuesIcon width={24} height={24} color="#8E97A0" />}
            onEditButtonClick={() => setEdit(true)}
          />
          <CardContent>
            <CompanyValuesForm
              companyValues={companyValues}
              onCancel={() => setEdit(false)}
              onSubmit={({ company_values }) => {
                updateCompanyValues(company_values.map((value) => value.id))
              }}
              initialValues={formatDescription(profile?.company_values)}
            />
          </CardContent>
        </>
      ) : !profile?.company_values ? (
        <CardContent>
          <ProfileCardPlaceholder
            icon={<FlagIcon />}
            title="Company values"
            subtitle="What are the five company values that you hope would be central
        in your next company?"
            callToAction="Add company values"
            onClick={() => setEdit(true)}
          />
        </CardContent>
      ) : (
        <WorkplaceValues
          values={formatDescription(profile?.company_values)}
          onEditButtonClick={() => setEdit(true)}
          icon={<CompanyValuesIcon width={24} height={24} color="#8E97A0" />}
          title="Company values"
        />
      )}
    </Card>
  )
}

export const CompanyValuesCard = ({ profile }: any) => {
  return (
    <>
      {profile?.company_values ? (
        <>
          <WorkplaceValues
            values={profile?.company_values}
            icon={<CompanyValuesIcon width={24} height={24} color="#8E97A0" />}
            title="Company values"
          />
        </>
      ) : (
        <CardContent>
          <ProfileCardPlaceholder
            icon={<FlagIcon />}
            title="Company values"
            subtitle="What are the five company values that you hope would be central in your next company?"
          />
        </CardContent>
      )}
    </>
  )
}

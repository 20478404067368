import styled from 'styled-components'
import { Fieldset as DefaultFieldset } from '../Fieldset'
import { BodyMB, BodyMR } from '../Text/Text.styles'

export const Fieldset = styled(DefaultFieldset)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const SwitchItem = styled.input.attrs({
  type: 'radio'
})`
  display: none;

  &:checked + label {
    background-color: ${({ theme }) => theme.colors.shades.gray300};

    p {
      ${BodyMB}
    }

    div {
      display: flex;
    }
  }
`
export const Label = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  border-radius: 8px;

  width: 100%;
  height: 50px;
  cursor: pointer;

  div {
    display: none;
  }

  ${BodyMR}
`

import { VideoCameraIcon, VideoIcon } from '@commons/Icons'
import { ProfileCardPlaceholder } from '@components/molecules/ProfileCardPlaceholder'
import { Card, CardContent } from '../commons/Card.styles'
import ReactPlayer from 'react-player'
import * as S from './VideoInterviewProfileCard.styles'
import { useState } from 'react'
import { ProfileCardTitleBar } from '@components/molecules/ProfileCardTitleBar'
import { VideoInterviewForm } from '@components/organisms/VideoInterviewForm'
import get from 'lodash/get'

interface VideoInterviewProfileCardUploadProps {
  testId?: string
  profile: any
  fetch: () => void
}

export const VideoInterviewProfileCardUpload = ({
  testId = 'video-interview-profile-card-id',
  profile,
  fetch
}: VideoInterviewProfileCardUploadProps) => {
  const [edit, setEdit] = useState(false)

  return (
    <Card data-testid={testId}>
      {edit ? (
        <>
          <ProfileCardTitleBar
            edit={edit}
            title="Video interview"
            icon={<VideoIcon width={24} height={24} color="#8E97A0" />}
            onEditButtonClick={() => setEdit(true)}
          />
          <CardContent>
            <VideoInterviewForm
              onCancel={() => setEdit(false)}
              onSucess={() => fetch()}
              profile={profile}
            />
          </CardContent>
        </>
      ) : !profile?.video_interview ? (
        <CardContent>
          <ProfileCardPlaceholder
            icon={<VideoCameraIcon />}
            title="Video interview"
            subtitle="You haven’t linked yet the video interview
            of your candidate. "
            callToAction="Link video"
            onClick={() => setEdit(true)}
          />
        </CardContent>
      ) : (
        <>
          <ProfileCardTitleBar
            title="Video interview"
            icon={<VideoIcon width={24} height={24} color="#8E97A0" />}
            onEditButtonClick={() => setEdit(true)}
          />
          <CardContent>
            <S.VideoHolder>
              <ReactPlayer
                url={get(profile, 'video_interview.video_url', '')}
                light
                width="100%"
                height="430px"
                controls
                playing
              />
            </S.VideoHolder>
          </CardContent>
        </>
      )}
    </Card>
  )
}

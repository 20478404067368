import { Card, CardContent } from '../commons/Card.styles'
import { ManagementStyleProfileCardProps } from './interfaces'
import { ChessPieceIcon, ManagementStyleIcon } from '@commons/Icons'
import { ProfileCardPlaceholder } from '@components/molecules/ProfileCardPlaceholder'
import { useEffect, useState } from 'react'
import { useProfileContext } from '@hooks/profile/ProfileContextProvider'
import { ProfileCardTitleBar } from '@components/molecules/ProfileCardTitleBar'
import { ManagementStyleForm } from '@components/organisms/ManagementStyleForm/ManagementStyleForm'
import { useGetWorkplace, useWorkplace } from '@hooks/workplace/useWorkplace'
import { WorkplaceValues } from '../commons/WorkplaceValues'
import formatDescription from '../commons/FormatDescription'

export const ManagementStyleProfileCard = ({
  testId = 'management-style-profile-card-id'
}: ManagementStyleProfileCardProps) => {
  const [edit, setEdit] = useState(false)
  const { profile, fetchProfile } = useProfileContext()
  const { loading, data: managementStyles } =
    useGetWorkplace('/management_styles')
  const { response, update: updateManagementStyles } = useWorkplace(
    'management_styles_ids'
  )

  const update = () => {
    fetchProfile()
    setEdit(false)
  }

  useEffect(() => {
    if (response?.status === 200) update()
  }, [response])

  return (
    <Card data-testid={testId}>
      {edit ? (
        <>
          <ProfileCardTitleBar
            edit={true}
            title="Management style"
            icon={
              <ManagementStyleIcon width={24} height={24} color="#8E97A0" />
            }
            onEditButtonClick={() => setEdit(true)}
          />
          <CardContent>
            <ManagementStyleForm
              managementStyles={managementStyles}
              onCancel={() => setEdit(false)}
              onSubmit={({ management_style }) => {
                updateManagementStyles(
                  management_style.map((value) => value.id)
                )
              }}
              initialValues={formatDescription(profile?.management_styles)}
            />
          </CardContent>
        </>
      ) : !profile?.management_styles ? (
        <CardContent>
          <ProfileCardPlaceholder
            icon={<ChessPieceIcon />}
            title="Management style"
            subtitle="Which of the following management style descriptions
        do you feel supports your best work?"
            callToAction="Add management style"
            onClick={() => setEdit(true)}
          />
        </CardContent>
      ) : (
        <WorkplaceValues
          values={formatDescription(profile?.management_styles)}
          onEditButtonClick={() => setEdit(true)}
          icon={<ManagementStyleIcon width={24} height={24} color="#8E97A0" />}
          title="Management style"
        />
      )}
    </Card>
  )
}

export const ManagementStyleCard = ({ profile }: any) => {
  return (
    <>
      {profile?.management_styles ? (
        <>
          <WorkplaceValues
            values={profile?.management_styles}
            icon={
              <ManagementStyleIcon width={24} height={24} color="#8E97A0" />
            }
            title="Management style"
          />
        </>
      ) : (
        <CardContent>
          <ProfileCardPlaceholder
            icon={<ChessPieceIcon />}
            title="Management style"
            subtitle="Which of the following management style descriptions do you feel supports your best work?"
          />
        </CardContent>
      )}
    </>
  )
}

import illustration from '@assets/candidate/Illustration_thanks.png'
import styled from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.div`
  align-items: flex-end;
  display: flex;
  justify-content: left;
  width: 100vw;
  min-height: 1024px;
  height: 100vh;
  overflow: hidden;

  background: ${({ theme }) => theme.colors.shades.tertiary300};

  ${media.lessThan('medium')`
		align-items: flex-start;
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
		min-height: initial;
	`}
`

export const CenterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 900px;
  padding: 91px 95px;
  position: relative;
  width: 100%;
  height: 100%;

  ${media.lessThan('medium')`
	  width: 100%;
		height: 100%;
		min-height: 0;
		margin: 0;
  	padding: 0;
		justify-content: flex-start;
		align-items: flex-start;
	`};
`

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  max-width: 1440px;
  width: 100%;
  height: 100%;

  > p {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.shades.gray900};
  }

  ${media.lessThan('medium')`
	  padding: 118px 24px 58px 24px;
		height: auto;
		width: 100%;
	`}
`

export const Background = styled.div`
  height: 100%;
  width: 94%;
  position: absolute;

  background-image: url(${illustration});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 70%;

  @media (max-width: 1600px) {
    background-position: right;
  }

  @media (min-width: 1600px) {
    width: 70%;
    background-position: right;
    /* background-size: 50%; */
  }

  ${media.lessThan('large')`
		display: none;
	`}
`

export const Logo = styled.img`
  height: 32px;
  position: absolute;
  top: 91px;
  z-index: 10;

  ${media.lessThan('medium')`
		display: none;
	`}
`

export const MobileLogo = styled.img`
  display: none;

  ${media.lessThan('medium')`
		display: block;
		margin: 56px auto;
		position: relative;
  	height: 28px;
	`}
`

export const FooterImage = styled.img`
  display: none;

  ${media.lessThan('medium')`
		display: block;
		position: relative;
		width: 80%;
  	height: 50%;
    margin-top: 24px;
		margin-bottom: 124px;
	`}
`

export const TextContent = styled.div`
  width: 600px;
  z-index: 10;
  margin-top: 140px;

  & h1 {
    color: ${({ theme }) => theme.colors.primaryBlue};
    font-size: 96px;
    font-weight: 600;
    line-height: 106px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 24px;

    @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
      font-size: 48px;
      font-weight: 600;
      line-height: 52.8px;
      letter-spacing: 0em;
      text-align: left;
    }
  }

  ${media.lessThan('medium')`
	  width: 100%;
		height: 100%;
		margin-top: 0;
	`}
`

export const ButtonContainer = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.primaryWhite};
  display: flex;
  flex-direction: row;
  margin-top: 32px;

  > button {
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    height: 56px;
    letter-spacing: 0.02em;
    width: 118px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.phone}px) {
    flex-direction: column-reverse;
    align-items: flex-start;

    > button {
      width: 100%;
    }
  }
`

import styled from 'styled-components'
import { FaSpinner } from 'react-icons/fa'

export const Container = styled.div`
  margin-top: 40px;
  padding: 2rem 0;
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.colors.primaryWhite};
`

export const View = styled.div`
  overflow: auto;
  width: 100%;
  position: relative;
  border: 1px solid ${({ theme }) => theme.colors.shades.gray700};
`
export const ViewWrapper = styled.div`
  position: relative;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1rem;
`

export const Navigation = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.12);
  position: absolute;
  padding: 10px;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10px;
  background-color: ${({ theme }) => theme.colors.primaryWhite};
`

export const NaviButton = styled.button`
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;

  svg {
    display: block;
    width: 24px;
    height: 24px;
    color: #1c2e40;
  }
`

export const Spinner = styled(FaSpinner)`
  animation: spin infinite 1s linear;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`

export const SpinnerWrapper = styled.div`
  width: 100%;
  height: 500px;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 50px;
    height: 50px;
  }
`

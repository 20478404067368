import { Button } from '@components/atoms/Button'
import { DeleteButton } from '@components/atoms/DeleteButton'
import { InputText } from '@components/atoms/InputText'
import { TextArea } from '@components/atoms/TextArea'
import { Toast } from '@components/atoms/Toast'
import { yupRequired, yupTextArea } from '@components/commons/Form/validations'
import { Form, Formik, FormikProps, useFormikContext } from 'formik'
import * as Yup from 'yup'
import * as S from '../../../FormCommons/Form.styles'

interface Values {
  title: string
  description: string
}

const idContext = `experience-form`

const validationSchema = () => {
  const date = new Date()
  date.setDate(date.getDate() + 1)

  return Yup.object().shape({
    title: yupRequired,
    description: yupTextArea
  })
}

export const NotesForm = ({
  testId = 'notes-form-id',
  loading,
  initialData,
  onCancel,
  onSubmit,
  ...props
}: any) => {
  const FormContent = ({ ...props }) => {
    return (
      <Form>
        <S.InputRow>
          <S.InputCol>
            <InputText
              label="Title *"
              id={`title-${idContext}`}
              placeholder="Title"
              type="text"
              name="title"
              {...props}
            />
          </S.InputCol>
        </S.InputRow>
        <S.InputRow>
          <S.InputCol>
            <TextArea
              label={'Description *'}
              id={`notes-${idContext}`}
              placeholder="Your answer here"
              type="text"
              name="description"
              disabled={loading}
              {...props}
            />
          </S.InputCol>
        </S.InputRow>
        <S.ButtonsContainer>
          <S.ButtonsWrapper>
            <Button
              styling="secondary"
              type="button"
              size="small"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              loading={loading}
              size="small"
              type="submit"
              testId="fieldId"
            >
              Save
            </Button>
          </S.ButtonsWrapper>
        </S.ButtonsContainer>
      </Form>
    )
  }

  return (
    <S.PaddedFormContainer data-testid={testId} {...props} className="form">
      <Formik
        initialValues={initialData}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={(data) => {
          onSubmit(data)
        }}
      >
        {(props: FormikProps<Values>) => <FormContent {...props} />}
      </Formik>
      <Toast />
    </S.PaddedFormContainer>
  )
}

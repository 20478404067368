import { Text } from '@components/atoms/Text'
import { useProfileContext } from '@hooks/profile/ProfileContextProvider'
import { Progress } from '../Progress'
import { ProfileCompletionProps } from './interfaces'
import * as S from './ProfileCompletion.styles'

export const ProfileCompletion = ({
  testId = 'profile-completion-id'
}: ProfileCompletionProps) => {
  const { profile } = useProfileContext()

  return (
    <S.ProfileCompletion data-testid={testId}>
      <Text type="BodyMB">Profile completion {profile?.completion || 0}%</Text>
      <Progress
        totalSteps={100}
        currentStep={profile?.completion || 0}
        styling="percentage"
      />
    </S.ProfileCompletion>
  )
}

import { grid, GridBreakpoints } from '@styles/grid'
import styled, { css } from 'styled-components'
import { FlexColumnProps } from './interfaces'

export type AlignSelf =
  | 'flex-start'
  | 'center'
  | 'flex-end'
  | 'stretch'
  | 'auto'
export type ColWidth = number | 'fill' | 'auto' | 'hidden'

const genHidden = (breakpoint: GridBreakpoints) => css`
  @media (max-width: ${grid.breakpoints[breakpoint]}px) {
    display: none !important;
  }
`

const genFillWidth = (breakpoint: GridBreakpoints) => css`
  @media (min-width: ${grid.breakpoints[breakpoint]}px) {
    display: block;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
`

const genAutoWidth = (breakpoint: GridBreakpoints) => css`
  @media (min-width: ${grid.breakpoints[breakpoint]}px) {
    display: block;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
`

const genRelativeWidth = (
  breakpoint: GridBreakpoints,
  colWidth: ColWidth,
  cols: FlexColumnProps['cols']
) => css`
  @media (min-width: ${grid.breakpoints[breakpoint]}px) {
    display: block;
    ${typeof colWidth === 'number' && typeof cols === 'number'
      ? `
    flex: 0 0 ${(colWidth / cols) * 100}%;
    max-width: ${(colWidth / cols) * 100}%;
    `
      : null};
  }
`

const getWidth = (
  breakpoint: GridBreakpoints,
  colWidth: ColWidth,
  cols: FlexColumnProps['cols']
) => {
  switch (colWidth) {
    case 'hidden':
      return genHidden(breakpoint)
    case 'auto':
      return genAutoWidth(breakpoint)
    case 'fill':
      return genFillWidth(breakpoint)
    default:
      return genRelativeWidth(breakpoint, colWidth, cols)
  }
}

export const FlexColumn = styled.div<FlexColumnProps>`
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: ${({ gutter }) => gutter}px;
  padding-left: ${({ gutter }) => gutter}px;
  align-self: ${({ align }) => align};
  ${(props) =>
    Object.keys(grid.breakpoints)
      .sort((a, b) => {
        return (
          grid.breakpoints[a as GridBreakpoints] -
          grid.breakpoints[b as GridBreakpoints]
        )
      })
      .filter((breakpoint) => Object.keys(props).includes(breakpoint))
      .map((breakpoint) => {
        const colWidth = props[breakpoint as GridBreakpoints] || 'auto'
        return getWidth(breakpoint as GridBreakpoints, colWidth, props.cols)
      })}
`
